import Decimal from 'decimal.js-light';

import { CreditCard } from 'services/FanTickets/types';

export interface RefundTicketSummaryParams {
	fanId: string;
	ticketIds: string;
}

export enum OptionsEnum {
	FULL = 'full',
	PARTIAL = 'partial',
}

export enum ResolverEnum {
	dollars = '$',
	percentage = '%',
}

export interface CreditCardInfo {
	isMultipleCards: boolean;
	cardsInfo: CreditCard[];
}

export interface RefundSuccess {
	ID: number;
	CreatedAt: Date;
	UpdatedAt: Date;
	user_id: number;
	price: number;
	promo_amount_applied: number;
	net_price: number;
	our_tax: number;
	vendor_tax: number;
	tax_rate: number;
	quantity: number;
	transaction_type: string;
	date_time: Date;
	transaction_code: string;
	card_id: string;
	payment_provider_fee: number;
	fixed_payment_provider_fee: number;
	service_fee: number;
	fixed_service_fee: number;
	total_service_fee: number;
	recipient_email: string;
	sender: number;
	auth_code: string;
	session_token_id: string;
	prev_transaction: number;
	client_unique_id: string;
}

export interface RefundError {
	ticket_id: number;
	error: {
		status: number;
		Error: string;
	};
}

export interface RefundData {
	refunds: RefundSuccess[];
	errors: RefundError[];
}

export interface RefundPerTicket {
	[propName: string]: Decimal;
}

export interface TicketRefundDetails {
	id: number;
	amount: number;
}

export interface TicketRefundPostArgs {
	refundPerTicket: RefundPerTicket;
	description: string;
}

export interface GetRefundDetails {
	purchaseTotal: Decimal;
	refundTotal: Decimal;
	refundPercent: string;
	refundPerTicket: RefundPerTicket;
	minTicketPrice: Decimal;
}

export interface RefundCalculations {
	purchaseTotal: Decimal;
	refundTotal: Decimal;
	minTicketPrice: Decimal;
	refundPerTicket: RefundPerTicket;
}
