import { PayoutBy } from 'hooks/useChart/types';
import { Highlights } from './types';

export const convertToKey = (date: Date | string): string =>
	typeof date === 'string' ? `${new Date(date).valueOf()}` : `${date.valueOf()}`;

export const getDays = (data: Highlights<string>) => {
	const salesbyDay: PayoutBy = {};

	for (const [i, salesDate] of data.sales.entries()) {
		const { date, payout, ticket_types } = salesDate;
		salesbyDay[convertToKey(date)] = { date: new Date(date), payout, ticket_types: ticket_types };
		const currDate = new Date(date);
		const nextDate = new Date(data.sales[i + 1]?.date || currDate);
		while (Math.abs(currDate.valueOf() - nextDate.valueOf()) > 24 * 60 * 60 * 1000) {
			currDate.setDate(currDate.getDate() + 1);
			salesbyDay[convertToKey(currDate)] = { date: new Date(currDate), payout: 0, ticket_types: [] };
		}
	}

	return { ...data, sales: Object.values(salesbyDay || {}) };
};
