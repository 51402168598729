import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DesktopFilterProps } from './types';

import {
	Container,
	DesktopContainer,
	Icon,
	Option,
	OptionsContainer,
} from './Filter.styles';
import { RadioButton } from 'features/common/components/RadioButton/RadioButton.styles';

const DesktopFilter: FC<DesktopFilterProps> = ({
	onClickSelect,
	isOpened,
	isLargeScreen,
	options,
	onClickOption,
	selectedOption,
	secondaryStyle,
}: DesktopFilterProps) => {
	const { t } = useTranslation();

	const isCheckedOption = (option: string) => option === selectedOption?.value;

	return (
		<DesktopContainer
			isOpened={isOpened}
			onClick={onClickSelect}
			secondaryStyle={secondaryStyle}
		>
			<Container>
				<div>{selectedOption?.label || t('events-filter')}</div>
				{!isOpened && (
					<Icon iconName={secondaryStyle ? 'arrowDownGreen' : 'arrowDown'} />
				)}
			</Container>
			<OptionsContainer
				isOpened={isOpened}
				isLargeScreen={isLargeScreen}
				secondaryStyle={secondaryStyle}
			>
				{isOpened &&
					options.map((option) => (
						<Option key={option.value} onClick={() => onClickOption(option)}>
							<RadioButton
								type="radio"
								checked={isCheckedOption(option.value)}
								name={option.label}
								value={option.value}
								onClick={() => onClickOption(option)}
							/>
							{option.label}
						</Option>
					))}
			</OptionsContainer>
		</DesktopContainer>
	);
};

export { DesktopFilter };
