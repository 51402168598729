import { RefObject, useEffect } from 'react';

function useClickAway(ref: RefObject<HTMLElement>, onClick: () => void) {
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			const targetNode = event.target as Node | null;
			if (targetNode) {
				if (ref.current && !ref.current.contains(targetNode)) {
					onClick();
				}
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

export { useClickAway };
