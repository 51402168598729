import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useMutation } from '@tanstack/react-query';

import { post } from 'services/api';
import { closeFanModal } from 'features/FanDatabase/Slices/FanDatabaseSlice';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { Modal } from 'features/common/components/Modal';
import { QUATERNARY } from 'features/common/components/Button/constants';
import { Modals } from 'features/FanDatabase/Slices/types';

import * as S from '../../FansTable.styles';
import { BodyWrapper, ResetInfoContainer } from './ResetModal.styles';

export const ResetModal: FC = () => {
	const { t } = useTranslation();
	const { openModal, fanData } = useSelector(
		(state: RootState) => state.fanDatabase.modalStatus
	);
	const dispatch = useDispatch();

	const { mutate: sendResetEmail } = useMutation({
		mutationFn: () => {
			return post('/public/users/forgot-password', { email: fanData.email });
		},
	});

	return (
		<>
			{openModal === Modals.reset && (
				<S.CustomModal onClose={() => dispatch(closeFanModal())}>
					<Modal.Header>{t('fanDatabase-reset-password')}</Modal.Header>

					<BodyWrapper>
						<S.UserIcon iconName="lockGrey" />
						<S.ModalMessage element="p">
							{t('fanDatabase-reset-password-message')}
						</S.ModalMessage>
						<ResetInfoContainer>
							<FlexContainer gap="8px" alignItems="center">
								<S.MailIcon iconName="mail" />
								<S.Email> {fanData.email}</S.Email>
							</FlexContainer>
						</ResetInfoContainer>
					</BodyWrapper>

					<S.ActionsWrapper>
						<S.ModalButton
							text={t('fanDatabase-cancel')}
							category={QUATERNARY}
							onClick={() => dispatch(closeFanModal())}
							type="button"
						/>
						<S.ModalButton
							text={t('fanDatabase-confirm')}
							onClick={() => {
								sendResetEmail();
								dispatch(closeFanModal());
							}}
							type="button"
						/>
					</S.ActionsWrapper>
				</S.CustomModal>
			)}
		</>
	);
};
