import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';

export const CustomContainer = styled.div`
	width: 100%;
	overflow-y: visible;
	overflow-x: auto;
`;

export const TableTitle = styled(Text)`
	font-weight: 600;
	font-size: 20px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
	}
`;

export const AddMemberBtn = styled(Button)`
	margin: 0;
	padding: 8px 20px;
	width: 150px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 8px 16px;
	}
`;
