import styled from 'styled-components';

export const NumberDot = styled.span`
	display: flex;
	padding: 2px;
	justify-content: center;
	align-items: center;
	position: relative;
	top: -21px;
	left: 65px;
	border-radius: 50%;
	height: 18px;
	width: 18px;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 700;
	font-size: 11px;
	line-height: 12px;
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.green};
`;
