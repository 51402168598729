import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { deleteData } from 'services/api';
import { DeleteAffiliateProps } from './types';
import { useToastNotification } from 'hooks/useToastNotification';
import { queryClient } from 'index';

export const useDeleteAffiliate = ({ affiliateId, setShowModal, queryKey }: DeleteAffiliateProps) => {
	const { showNotification, TYPES } = useToastNotification();
	const { t } = useTranslation();

	const deleteAffiliate = useMutation({
		mutationFn: ({ affiliateId }: { affiliateId: number }) => deleteData(`/events/affiliates/${affiliateId}`),
		onSuccess: () => {
			showNotification({ message: t('dashboard-affiliate-deleteSuccess'), type: TYPES.SUCCESS });
			queryClient.refetchQueries({ queryKey, exact: true });
		},
		onError: () => showNotification({ message: t('dashboard-affiliate-deleteErr'), type: TYPES.ERROR }),
	});

	return {
		modalTitle: t('dashboard-affiliate-deleteTitle'),
		modalMessage: t('dashboard-affiliate-deleteInfo'),
		onDelete: () => {
			deleteAffiliate.mutate({ affiliateId });
			setShowModal(false);
		},
	};
};
