export const COLUMN_WIDTH = [50, 250, 225, 150, 150, 150, 250, 225];
export const GRID_COLUMNS = '1fr 3fr 3fr 2fr 2fr 2fr 2fr 3fr';
export const COLUMN_HEADERS = [
	'',
	'refundableTickets-ticketTable-event',
	'refundableTickets-ticketTable-date-time',
	'refundableTickets-ticketTable-ticket-type',
	'refundableTickets-ticketTable-ticket-holder',
	'refundableTickets-ticketTable-price-paid',
	'refundableTickets-ticketTable-purchase-date',
	'refundableTickets-ticketTable-notes',
];
