import styled from 'styled-components';

import { Icon } from 'features/common/components/Icon';

export const BackIcon = styled(Icon)`
	top: 30px;
	left: 30px;
	z-index: 2;
	position: absolute;
	cursor: pointer;
	padding: 10px;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		left: 70px;
	}
`;

export const ScreenTitle = styled.div`
	position: fixed;
	height: 100px;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 3;
	background: ${({ theme }) => theme.colors.blackGrey};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		top: 125px;
		background: transparent;
		position: unset;
	}
`;

export const FieldSet = styled.div`
	box-sizing: border-box;
	gap: 16px;
	width: 100%;
	display: flex;
	overflow: scroll;
	max-width: 700px;
	margin-top: 100px;
	margin-bottom: 134px;
	align-items: center;
	flex-direction: column;
	padding: 0 32px;

	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 0 0;
	}
`;
