import styled, { css } from 'styled-components';

import { Icon } from 'features/common/components/Icon';
import { Text } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';

export const Container = styled.div`
	width: 100vw;
	height: calc(100vh - 186px);
	position: relative;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: calc(100vh - 225px);
	}
`;

export const GridWrapper = styled.div`
	margin-top: 25vh;
	max-width: 750px;
	z-index: 4;

	display: grid;
	grid-template-columns: repeat(2, auto);
	column-gap: 56px;
	row-gap: 32px;
	grid-template-rows: repeat(3, auto);

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 75px 30px 0 30px;
		grid-template-rows: repeat(3, auto);

		grid-template-columns: 1fr;
	}
`;

export const CustomIcon = styled(Icon)`
	margin-top: 8px;
	height: 112px;
	width: 112px;
	grid-row: 1/4;
	min-width: 80px;
	min-height: 80px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: min(112px, 20vw);
		height: min(112px, 20vw);
		grid-row: 1;
	}
`;

export const PrimaryText = styled(Text)`
	font-weight: 600;
	text-transform: none;
	font-size: 32px;
	line-height: 48px;
	margin: 0;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: min(32px, 8vw);
	}
`;

export const SecondaryText = styled(Text)`
	font-weight: 400;
	text-transform: none;
	margin: 0;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: min(24px, 7vw);
	}
`;

export const CustomButton = styled(Button)`
	margin: 0;
	width: 150px;
	padding: 8px 0 8px 0;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		position: absolute;
		bottom: 5%;
		left: 5%;
		width: 90%;
		height: 56px;
		font-size: 18px;

		z-index: 4;
	}
`;

export const PinkGradient = styled.div`
	position: absolute;
	top: -100px;
	left: -100px;
	width: 444px;
	height: 443px;

	background: linear-gradient(
		180deg,
		rgba(156, 110, 186, 0.25) 76.88%,
		rgba(205, 82, 106, 0.25) 119.74%
	);
	opacity: 0.5;
	filter: blur(62.5px);
`;

export const GreenGradient = styled.div`
	position: absolute;
	bottom: -100px;
	right: -75px;
	height: 414px;
	width: 414px;

	background: linear-gradient(
		180deg,
		rgba(108, 191, 191, 0.3) 20.94%,
		rgba(162, 193, 102, 0.3) 119.74%
	);
	opacity: 0.5;
	filter: blur(62.5px);
`;

//COMPONENTS THAT YOU ONLY SEE ON EITHER DESKTOP OR MOBILE
const noShowOnMobile = css`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: none;
	}
`;
const noShowOnDesktop = css`
	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: none;
	}
`;

export const GreenBlob = styled.img`
	${noShowOnMobile}
	position: absolute;
	right: -5px;
	top: -185px;
`;

export const PinkBlob = styled.img`
	${noShowOnMobile}
	position: absolute;
	bottom: -5px;
	left: -75px;
`;
export const GreenBlobMobile = styled.img`
	${noShowOnDesktop}
	position: absolute;
	right: -5px;
	top: -225px;
`;

export const PinkBlobMobile = styled.img`
	${noShowOnDesktop}
	position: absolute;
	bottom: -25px;
	left: -15px;
`;
