import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { useResponsiveScreen } from 'hooks/useWindowSize';
import { OptionsButtons } from './components/OptionsButtons';
import { COLUMN_MIN_WIDTH } from '../../constants';
import { FanData, Modals } from 'features/FanDatabase/Slices/types';
import { TableProps } from '../../types';
import { openFanModal } from 'features/FanDatabase/Slices/FanDatabaseSlice';
import { Table } from 'features/common/components/Table';

export const TableBody: FC<TableProps> = ({ fanQueryObject }) => {
	const isLargeScreen = useResponsiveScreen();
	const dispatch = useDispatch();

	const { data } = fanQueryObject;

	const rowClick = (fanData: FanData) => {
		if (isLargeScreen) {
			/*reroute me to the fan profile page! */
		} else {
			dispatch(openFanModal({ openModal: Modals.optionsModal, fanData }));
		}
	};

	return (
		<tbody>
			{data?.pages.map((page) =>
				page.map((fan: FanData) => (
					<Table.DataRow
						gridColumns="repeat(3, 1fr)"
						key={fan.email}
						onClick={() => rowClick(fan)}
					>
						<Table.Cell minWidth={COLUMN_MIN_WIDTH[0]}>{fan.email}</Table.Cell>
						<Table.Cell minWidth={COLUMN_MIN_WIDTH[1]}>
							{fan.full_name}
						</Table.Cell>
						<Table.Cell minWidth={COLUMN_MIN_WIDTH[2]} className="desktopOnly">
							<OptionsButtons fanData={fan} />
						</Table.Cell>
					</Table.DataRow>
				))
			)}
		</tbody>
	);
};
