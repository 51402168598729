import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Form } from 'features/common/components/Form';
import { AUTH } from 'features/common/components/Form/Input/constants';
import { WelcomeFormProps } from './types';

import { Button, Description, ErrorWrapper, FormContainer, Line, RelativeWrap, Title } from './WelcomeForm.styles';
import { ErrorText, SupportLink } from '../LoginForm/LoginForm.styles';

export const WelcomeForm = ({ errors }: WelcomeFormProps) => {
	const { t } = useTranslation();
	const { location } = useHistory();
	const { setValue, watch } = useFormContext();
	const { oobCodeError, linkToFirebaseError } = errors;
	const searchParams = new URLSearchParams(location.search);

	const orgName = searchParams.get('partner_name');
	const invitedBy = searchParams.get('invited_by');
	const email = searchParams.get('email');
	const userId = searchParams.get('user_id');
	const partnerId = searchParams.get('partner_id');

	const password = watch('password');
	const confirmPassword = watch('confirmPassword');
	const meetsMinLength = password.length < 6;
	const passwordsMatch = password !== confirmPassword;
	const determineErrorMsg = () => {
		if (linkToFirebaseError) {
			return (
				<ErrorText>
					{t('login-welcomeForm-contact-support')}
					<SupportLink href="https://help.tixologi.com/kb-tickets/new">
						https://help.tixologi.com/kb-tickets/new
					</SupportLink>
				</ErrorText>
			);
		} else if (oobCodeError) {
			return <ErrorText> {t('login-welcomeForm-invalid-link')}</ErrorText>;
		} else {
			return (
				<>
					{password.length > 0 && meetsMinLength && <ErrorText> {t('login-welcomeForm-password-length')}</ErrorText>}
					{passwordsMatch && <ErrorText> {t('login-welcomeForm-passwords-match')}</ErrorText>}
				</>
			);
		}
	};

	useEffect(() => {
		setValue('userId', userId);
		setValue('partnerId', partnerId);
	}, [userId, partnerId]);

	return (
		<FormContainer>
			<Title>{t('login-welcome-title')}</Title>
			<Description>{t('login-welcome-description', { orgName, invitedBy })}</Description>
			<Description>{t('login-welcome-email', { email })}</Description>
			<Form.Input id="password" name="password" label="Enter Password" category={AUTH} type="password" />
			<Form.Input
				id="confirmPassword"
				name="confirmPassword"
				label="Confirm Password"
				category={AUTH}
				type="password"
			/>
			<Line />
			<Button text={t('createAccount')} type="submit" disabled={meetsMinLength || passwordsMatch} />
			<RelativeWrap>
				<ErrorWrapper>{determineErrorMsg()}</ErrorWrapper>
			</RelativeWrap>
		</FormContainer>
	);
};
