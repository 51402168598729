import styled from 'styled-components';

import { Icon } from '../Icon';

import { StdText } from '../Text/Text.styles';

export const Container = styled.div<{ isCentered?: boolean }>`
	background-color: ${({ theme }) => theme.colors.grey};
	border-radius: 8px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;
	padding: 32px;
	width: 100%;
	cursor: pointer;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px;
	}
`;

export const CustomPlus = styled(Icon)`
	height: 34px;
	width: 34px;
`;

export const Text = styled(StdText)`
	max-width: 320px;
`;
