import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { MainLayout } from 'layouts/MainLayout';
import { CompedTicketsHeader } from './CompedTicketsHeader';
import { COMPED_FILTER_OPTIONS } from './constants';
import { CompedTicketsTable } from './CompedTicketsTable';
import { Search } from 'features/common/components/Search';
import { ROUTES } from 'features/routes';
import { CancelInviteModal } from './CancelInviteModal';
import { debounce } from 'utils/utils';
import { useCompedTicketsPage } from './hooks/useCompedTicketsPage';
import { IdParamsType } from 'utils/types';

import * as Styled from './CompedTickets.styles';

export const CompedTickets = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { id } = useParams<IdParamsType>();
	const {
		setSearchText,
		setSelectedPeriod,
		compedTicketsQueryObject,
		selectedPeriod,
		showCancelModal,
		closeCancelModal,
		openCancelModal,
		onCancelInvite,
	} = useCompedTicketsPage();
	const searchDebounce = debounce(setSearchText);

	const subtitle = compedTicketsQueryObject?.data?.pages[0]?.data[0]?.events_name[0] ?? '';

	const onSendTicket = () => history.push(ROUTES.SEND_TICKETS.replace(':id', id));

	return (
		<MainLayout>
			<CompedTicketsHeader subtitle={subtitle} />
			<Styled.FiltersContainer>
				<Search
					width="300px"
					onChange={(e) => searchDebounce(e.target.value)}
					placeholder={t('search-email-or-name')}
				/>
				<Styled.ButtonsContainer>
					<Styled.Filter
						onChange={(value) => setSelectedPeriod(value)}
						options={COMPED_FILTER_OPTIONS}
						selectedOption={selectedPeriod}
					/>
					<Styled.SendTicketsButton text={t('comped-tickets-send-tickets')} type="button" onClick={onSendTicket} />
				</Styled.ButtonsContainer>
			</Styled.FiltersContainer>
			<CompedTicketsTable compedTicketsQueryObject={compedTicketsQueryObject} openCancelModal={openCancelModal} />
			{showCancelModal.show && (
				<CancelInviteModal
					showModal={showCancelModal.show}
					closeModal={closeCancelModal}
					modalInfo={{
						modalTitle: t('comped-ticket-cancel-invite-modal-title'),
						modalMessage: t('comped-ticket-cancel-invite-modal-description'),
						onDelete: onCancelInvite,
					}}
				/>
			)}
		</MainLayout>
	);
};
