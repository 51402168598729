import styled from 'styled-components';

import { Form } from 'features/common/components/Form';
import { Icon } from 'features/common/components/Icon';

import { hideScrollbar } from 'styles';

export const CustomIcon = styled(Icon)`
	height: 90px;
	width: 90px;
`;

export const ContentWrap = styled.div`
	display: flex;
	justify-content: center;
	overflow-y: auto;
	height: calc(100vh - 266px);
	width: 100%;

	${hideScrollbar}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: calc(100vh - 375px);
	}
`;

export const FormColumn = styled(Form)`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 72px 0 36px 0;
	max-width: 425px;
	gap: 36px;
	height: fit-content;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: 300px;
	}
`;
