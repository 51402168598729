import { FC, useState } from 'react';

import { Icon } from 'features/common/components/Icon';
import { InfoCardProps } from './types';

import { RoundContainer, SubTitle, Wrapper, InfoWrapper, StyledIcon, IconContainer, Title } from './InfoCard.styles';

const InfoCard: FC<InfoCardProps> = ({
	title,
	subtitle,
	icon,
	children,
	isClosable,
	highlightBackground,
}: InfoCardProps) => {
	const [show, setShow] = useState(true);

	if (isClosable && !show) return null;
	return (
		<Wrapper highlightBackground={highlightBackground} hasIcon={!!icon}>
			{icon && (
				<RoundContainer>
					<StyledIcon iconName={icon} />
				</RoundContainer>
			)}
			<InfoWrapper hasIcon={!!icon}>
				<Title element="h2">{title}</Title>
				<SubTitle element="h3">{subtitle}</SubTitle>
				{children}
			</InfoWrapper>
			{isClosable && (
				<IconContainer>
					<Icon iconName="close" onClick={() => setShow(false)} />
				</IconContainer>
			)}
		</Wrapper>
	);
};

export { InfoCard };
