import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { useResponsiveScreen } from 'hooks/useWindowSize';
import { Modal } from 'features/common/components/Modal';
import { Template1, Template2 } from 'features/common/components/Templates';
import { QUATERNARY } from 'features/common/components/Button/constants';
import { SelectTemplateModalProps } from './types';

import * as Styled from './SelectTemplate.styles';

export const SelectTemplate: FC<SelectTemplateModalProps> = ({ closeModal, ticketImage, display, setIsCancel }) => {
	const { t } = useTranslation();
	const { setValue, watch } = useFormContext();
	const isLargeScreen = useResponsiveScreen();
	const [image, setImage] = useState('');
	const displayType = watch('displayType');

	const setTemplate = (selectedTemplate: number) => setValue('displayType', `template${selectedTemplate}`);

	useEffect(() => {
		setImage(ticketImage instanceof Blob ? URL.createObjectURL(ticketImage) : (ticketImage as string));
	}, [ticketImage]);

	useEffect(() => {
		setValue('displayType', 'template0');
	}, []);

	return (
		<Styled.CustomModal onClose={closeModal}>
			<Modal.Body>
				<Styled.ContainerBody>
					<Styled.CarouselContainer>
						<Styled.CenteredContainer>
							<Styled.Title element="h3">{t('ticketDetails-ticket-selectTemplate')}</Styled.Title>
							<Styled.Carousel
								swipeable
								centerMode
								emulateTouch
								showThumbs={false}
								showArrows={false}
								showStatus={false}
								showIndicators={false}
								centerSlidePercentage={isLargeScreen ? 51 : 90}
								onChange={(template) => setTemplate(template)}
								onClickItem={(template) => setTemplate(template)}
							>
								<Template1 isEdit image={image} name={watch('name')} onClick={() => setTemplate(0)} />
								<Template2 isEdit image={image} name={watch('name')} onClick={() => setTemplate(1)} />
							</Styled.Carousel>
						</Styled.CenteredContainer>
						<Styled.ButtonContainer>
							<Styled.SubmitButton
								type="button"
								category={QUATERNARY}
								text={t('cancel')}
								onClick={() => {
									setValue('displayType', display);
									setIsCancel(true);
									closeModal();
								}}
							/>
							<Styled.SubmitButton
								type="button"
								text={t('Confirm')}
								onClick={() => {
									setValue('displayType', displayType);
									setIsCancel(false);
									closeModal();
								}}
								data-cy="confirm-template"
							/>
						</Styled.ButtonContainer>
					</Styled.CarouselContainer>
				</Styled.ContainerBody>
			</Modal.Body>
		</Styled.CustomModal>
	);
};
