import styled from 'styled-components';

import { Text } from 'features/common/components/Text';

export const CustomFieldCard = styled.div`
	width: 320px;
	display: flex;
	height: 100px;
	cursor: pointer;
	min-width: 320px;
	max-width: 320px;
	width: fit-content;
	border-radius: 8px;
	padding: 16px 24px;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.grey2};
`;

export const CardTitle = styled(Text)`
	font-size: 20px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const CardDescription = styled(Text)`
	opacity: 0.6;
	font-size: 14px;
	font-weight: 500;
	line-height: normal;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;
