import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { TitledHeaderProps } from './types';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { Header } from './Header';
import { Icon } from '../Icon';

import { Title, Subtitle } from './Header.styles';

export const TitledHeader: FC<TitledHeaderProps> = ({ CustomIcon, modal, title, subtitle }) => {
	const { goBack } = useHistory();

	return (
		<Header modal={modal}>
			<FlexContainer alignItems="center" flexDirection="row" gap={'24px'}>
				<Icon iconName="arrowLeft" onClick={goBack} cursor="pointer" />
				{CustomIcon && CustomIcon()}
				<FlexContainer flexDirection="column">
					<Title element="h2">{title}</Title>
					<Subtitle element="h3">{subtitle}</Subtitle>
				</FlexContainer>
			</FlexContainer>
		</Header>
	);
};
