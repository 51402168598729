import { FC } from 'react';
import { Text } from 'features/common/components/Text';
import { NavigationTabsProps } from './types';

import { Container, Line, Tab, TabContainer } from './NavigationTabs.styles';

const NavigationTabs: FC<NavigationTabsProps> = ({
	activeId,
	className,
	onClick,
	tabs,
	hideDivider = false,
	selectedBarColor,
}: NavigationTabsProps) => {
	const handleClick = (id: string) => (event: { preventDefault: () => void }) => {
		event.preventDefault();
		onClick && onClick(id);
	};
	const onlyTab = tabs.length === 1;
	return (
		<>
			<Container className={className}>
				{tabs.map(({ id, name }) => (
					<TabContainer key={id} onClick={handleClick(id)}>
						<Tab active={activeId === id} tabIndex={0} onlyTab={onlyTab} selectedBarColor={selectedBarColor}>
							<Text>{name}</Text>
						</Tab>
					</TabContainer>
				))}
			</Container>
			{!hideDivider && !onlyTab && <Line />}
		</>
	);
};

export { NavigationTabs };
