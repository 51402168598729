import styled from 'styled-components';

import { ColumnProps } from './types';

import { StdText } from 'features/common/components/Text/Text.styles';

const ROW_GAP = '16px';
export const ICON_WIDTH = '45px';

export const Row = styled.div`
	background-color: ${({ theme }) => theme.colors.grey7};
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${ROW_GAP};
	padding: 24px;
`;

export const Column = styled.div<ColumnProps>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: ${({ isPaired }) => (isPaired ? '0px' : '12px')};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: ${({ isLimited }) => (isLimited ? ` calc(100% - ${ICON_WIDTH} - ${ROW_GAP})` : '100%')};
	}
`;

export const HighlightText = styled(StdText)`
	color: ${({ theme }) => theme.colors.green};
`;

export const BoldText = styled(StdText)`
	font-size: 20px;
	font-weight: 700;
	text-transform: capitalize;
	text-align: start;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
`;

export const MaxColumnFlex = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	max-width: 250px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: 100%;
	}
`;

export const Link = styled.a`
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 16px;
	font-weight: 500;
	text-decoration: underline 1px ${({ theme }) => theme.colors.white};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
`;
