import moment from 'moment';
import { camelizeKeys } from 'humps';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { queryClient } from 'index';
import { handleLink } from 'utils/handleLink';
import { Page } from '../..';
import { ButtonContainer, SkipButton, StepTitle, NextButton } from '../Steps.styles';
import { TicketDisplay } from './TicketDisplay';
import { EventTemplateProps } from '../../../../common/components/Templates/types';
import { convertUTCToDate } from 'utils/utils';
import { EventIdParamsType } from 'utils/types';

import * as Styled from './TicketCreated.styles';

export const TicketCreated = () => {
	const { t } = useTranslation();
	const { getValues, reset } = useFormContext();

	const { eventId }: EventIdParamsType = useParams();

	const queryKey: string[] = ['event', eventId];
	const event = camelizeKeys(queryClient.getQueryData(queryKey)) as unknown as EventTemplateProps;

	const selectAnother = () => {
		reset();
		handleLink('#2');
	};

	const skip = () => handleLink('#9');

	return (
		<Page id="8">
			<Styled.CenteredContainer>
				<Styled.Container>
					<StepTitle element="h3">{t('ticketDetails-ticket-created')}</StepTitle>
					<TicketDisplay />
					<Styled.Name>{getValues('name')}</Styled.Name>
					<Styled.Date>
						{moment(convertUTCToDate(`${event?.startDate}`, event?.timeZone)).format('MM/DD/YYYY')}
					</Styled.Date>
				</Styled.Container>
				<ButtonContainer>
					<SkipButton type="button" onClick={skip}>
						{t('createEvent-skip')}
					</SkipButton>
					<NextButton onClick={selectAnother} type="button">
						{t('ticketDetails-ticket-add')}
					</NextButton>
				</ButtonContainer>
			</Styled.CenteredContainer>
		</Page>
	);
};
