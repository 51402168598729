import { useEffect } from 'react';

import { Settings } from 'features/Settings';
import { ROUTES } from 'features/routes';
import { sendGAPage } from 'utils/utils';

const SettingsPage = () => {
	useEffect(() => {
		sendGAPage({ page: ROUTES.SETTINGS_PATH, title: 'Settings Page' });
	}, []);

	return <Settings />;
};

export default SettingsPage;
