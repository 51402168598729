import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: fixed;
	height: 125px;
	width: 100%;
	gap: 20%;
	left: 0;
	top: 0;
	background-color: ${({ theme }) => theme.colors.grey3};
	box-shadow: ${({ theme }) => `0px 8px 16px ${theme.colors.darkGrey3}`};
	z-index: 4;
`;

export const ContainerHamburger = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 27px;
`;

export const ContainerImage = styled.div`
	display: flex;
	align-items: center;
`;

export const Logo = styled.img`
	padding: 35px 65px 30px 40px;
	width: fit-content;
`;
