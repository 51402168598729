import styled from 'styled-components';

import { Text as UnstyledText } from 'features/common/components/Text';

export const Title = styled(UnstyledText)`
	font-size: 20px;
	font-weight: 600;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
	}
`;

export const Subtitle = styled(UnstyledText)`
	opacity: 0.7;
	font-size: 16px;
	font-weight: 500;
	text-transform: none;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;

export const Container = styled.div`
	gap: 24px;
	width: 80%;
	display: flex;
	padding-bottom: 10px;
	flex-direction: column;
	justify-content: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;
