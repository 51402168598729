import styled from 'styled-components';

import { Text as UnstyledText } from 'features/common/components/Text';
import { hideScrollbar } from 'styles';

export const Column = styled.div`
	width: 100%;
	display: flex;
	overflow-y: scroll;
	max-width: 620px;
	max-height: 100vh;
	align-items: center;
	flex-direction: column;

	${hideScrollbar}
`;

export const Subtitle = styled(UnstyledText)`
	font-size: 18px;
`;

export const FieldSet = styled.div`
	gap: 16px;
	width: 100%;
	display: flex;
	overflow: scroll;
	max-width: 620px;
	margin-top: 100px;
	padding-bottom: 24px;
	flex-direction: column;
	height: calc(100vh - 200px);

	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-top: 100px;
		height: calc(100vh - 225px);
	}
`;

export const ScreenTitle = styled.div`
	top: 0;
	left: 0;
	right: 0;
	z-index: 4;
	height: 100px;
	display: flex;
	position: fixed;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background: ${({ theme }) => theme.colors.blackGrey};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		top: 125px;
		background: transparent;
		position: unset;
	}
`;

export const TotalPriceContainer = styled.div`
	width: 100%;
	display: flex;
	padding: 0 15px;
	border-radius: 4px;
	flex-direction: column;
	box-sizing: border-box;
	border: ${({ theme }) => `1px solid ${theme.colors.white}`};
`;

export const TotalPrice = styled(UnstyledText)`
	opacity: 0.7;
	font-size: 20px;
	font-weight: 400;
`;

export const TotalPriceInfo = styled(UnstyledText)`
	font-size: 13px;
	font-weight: 400;
	text-transform: none;
`;
