import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Icon } from '../Icon';
import { L, M } from '../Icon/utils';
import { NEW_KEY, PromoCodeAddCardProps } from './types';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { ROUTES } from 'features/routes';

import {
	CardContainer,
	UpCircle,
	DownCircle,
	Discount,
	CreatePromoCode,
	DiscountContainer,
	Column,
} from './PromoCodeCard.styles';

const PromoCodeAddCard = ({ fromDashboard }: PromoCodeAddCardProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const isLargeScreen = useResponsiveScreen();

	const onClickAddNew = () => push(ROUTES.PROMO_CODES_NEW_PATH);

	return (
		<CardContainer className="new" onClick={onClickAddNew}>
			<UpCircle className="new" />
			<DownCircle className="new" />
			<DiscountContainer className="new" type={NEW_KEY}>
				<Discount type={NEW_KEY}>
					<Icon iconName="plus" size={isLargeScreen ? L : M} />
				</Discount>
			</DiscountContainer>
			<Column className="new">
				<CreatePromoCode>{t(fromDashboard ? 'promoCode-addPromoCode' : 'promoCode-createPromoCode')}</CreatePromoCode>
			</Column>
		</CardContainer>
	);
};

export { PromoCodeAddCard };
