import styled from 'styled-components';
import { Button as UnstyledButton } from 'features/common/components/Button';

export const ContainerBody = styled.div`
	gap: 16px;
	padding: 30px;
	min-height: 250px;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
	align-content: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 30px 20px;
	}
`;

export const ContainerActionButtons = styled.div`
	display: flex;
	gap: 16px;
	justify-content: center;
	padding: 0px;
`;

export const ModalButton = styled(UnstyledButton)`
	padding: 0px;
	width: 90%;
	max-width: 400px;
	height: 44px;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const Option = styled.button`
	all: unset;
	width: 50%;
	display: flex;
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	align-items: center;
	justify-content: center;
	font-family: ${({ theme }) => theme.fonts.primary};
	background: ${({ theme }) => theme.colors.lightGrey5};
`;
