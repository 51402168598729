import { useTranslation } from 'react-i18next';
import { Title } from './PromoCodesHeader.styles';
import { MainHeader } from 'features/common/components/MainHeader';

export const PromoCodesHeader = () => {
	const { t } = useTranslation();

	return (
		<MainHeader lessPadding>
			<Title element="h1">{t('promoCode-title')}</Title>
		</MainHeader>
	);
};
