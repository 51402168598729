import { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { ROUTES } from 'features/routes';
import LoginPage from 'pages/LoginPage';
import CreateAccountPage from 'pages/CreateAccountPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ScannerAccountPage from 'pages/ScannerAccountPage';
import Orchestator from 'pages/Orchestator';

const NotAuthenticatedRoutes = (): JSX.Element => {
	const { LOGIN_PATH, CREATE_ACCOUNT_PATH, ROOT_PATH, FORGOT_PASSWORD, MEMBER_LOGIN, USER_MGMT, SCANNER_ACCOUNT } =
		ROUTES;
	const accessToken = localStorage.getItem('accessToken');
	const { push, location } = useHistory();
	const { pathname } = useLocation();

	useEffect(() => {
		if (
			!accessToken &&
			![CREATE_ACCOUNT_PATH, MEMBER_LOGIN, FORGOT_PASSWORD, USER_MGMT, SCANNER_ACCOUNT].includes(location.pathname)
		)
			push(ROUTES.LOGIN_PATH);
	}, [accessToken]);

	useEffect(() => {
		if (pathname !== MEMBER_LOGIN && pathname !== USER_MGMT) localStorage.clear();
	}, []);

	return (
		<Switch>
			<Redirect exact from={ROOT_PATH} to={LOGIN_PATH} />
			<Route exact path={LOGIN_PATH}>
				<LoginPage />
			</Route>
			<Route exact path={MEMBER_LOGIN}>
				<LoginPage isWelcome />
			</Route>
			<Route exact path={CREATE_ACCOUNT_PATH}>
				<CreateAccountPage />
			</Route>
			<Route exact path={FORGOT_PASSWORD}>
				<ForgotPasswordPage />
			</Route>
			<Route exact path={SCANNER_ACCOUNT}>
				<ScannerAccountPage />
			</Route>
			<Route path={USER_MGMT}>
				<Orchestator />
			</Route>
		</Switch>
	);
};

export default NotAuthenticatedRoutes;
