import { useTranslation } from 'react-i18next';

import { useResponsiveScreen } from 'hooks/useWindowSize';
import { Table } from 'features/common/components/Table';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { RoleSelect } from '../RoleSelect';
import { COLUMN_WIDTH, GRID_COLUMNS, COLUMN_HEADERS } from '../../constants';
import { BooleanDispatch, TableBodyProps } from './types';
import { MemberData } from 'features/Settings/types';
import { useAddMember } from '../../hooks/useAddMember';

import { Span, TableButton } from './MemberTable.styles';

export const MemberTable: React.FC<TableBodyProps> = ({
	membersData = [],
	setShowMobileOptions,
	setShowResetModal,
	setShowDeleteModal,
	setMember,
}: TableBodyProps) => {
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const { addMember } = useAddMember();
	const handleOnClick = (member: MemberData, toggleModal: BooleanDispatch) => {
		setMember(member);
		toggleModal(true);
	};

	return (
		<Table>
			<thead>
				<Table.HeaderRow gridColumns={GRID_COLUMNS}>
					{COLUMN_HEADERS.map(
						(header: string, i: number) =>
							(isLargeScreen || header !== COLUMN_HEADERS[3]) && (
								<Table.Cell key={header} minWidth={COLUMN_WIDTH[i]}>
									<Table.ColumnHeader> {t(header)}</Table.ColumnHeader>
								</Table.Cell>
							)
					)}
				</Table.HeaderRow>
			</thead>
			<tbody>
				{membersData.map((member: MemberData) => {
					const { full_name, email, roles, status } = member;
					return (
						<Table.DataRow
							key={member.member_id}
							gridColumns={GRID_COLUMNS}
							onClick={() => !isLargeScreen && handleOnClick(member, setShowMobileOptions)}
						>
							<Table.Cell minWidth={COLUMN_WIDTH[0]}>{full_name}</Table.Cell>
							<Table.Cell minWidth={COLUMN_WIDTH[1]}>{email}</Table.Cell>
							<Table.Cell minWidth={COLUMN_WIDTH[2]} overflow="unset">
								<RoleSelect secondaryStyle={true} memberData={member} isForm={false} key={roles} />
							</Table.Cell>

							{isLargeScreen && status && (
								<Table.Cell minWidth={COLUMN_WIDTH[3]}>
									<FlexContainer gap="24px">
										<TableButton
											category="outlined"
											text={t('settings-memberTable-reset-password')}
											type="button"
											onClick={() => handleOnClick(member, setShowResetModal)}
										/>
										<TableButton
											category="outlined"
											text={t('settings-tableContainer-delete-member')}
											type="button"
											onClick={() => handleOnClick(member, setShowDeleteModal)}
										/>
									</FlexContainer>
								</Table.Cell>
							)}
							{isLargeScreen && !status && (
								<Table.Cell minWidth={COLUMN_WIDTH[3]}>
									<FlexContainer gap="24px" alignItems="center">
										<TableButton
											category="outlined"
											text={t('settings-memberTable-resend-invite')}
											type="button"
											onClick={() => addMember({ full_name, email, roles })}
										/>
										<Span>{t('settings-memberTable-pending')}</Span>
									</FlexContainer>
								</Table.Cell>
							)}
						</Table.DataRow>
					);
				})}
			</tbody>
		</Table>
	);
};
