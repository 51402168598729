import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, generatePath } from 'react-router-dom';

import { Icon } from 'features/common/components/Icon';
import { XS } from 'features/common/components/Icon/utils';
import { PromoCodeCardProps } from './types';
import { chipText, typeText } from './utils';
import { ROUTES } from 'features/routes';

import {
	CardContainer,
	UpCircle,
	DownCircle,
	Row,
	Discount,
	Name,
	DiscountContainer,
	Column,
	ActiveContainer,
	Uses,
	Chip,
	Event,
} from './PromoCodeCard.styles';

const PromoCodeCard: FC<PromoCodeCardProps> = ({
	name,
	amount,
	isActive,
	used,
	type,
	references,
	background,
	onClick,
	isPercentage,
	id,
}: PromoCodeCardProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const handleOnClick = () => push(generatePath(ROUTES.PROMO_CODES_EDIT_PATH, { id, type: typeText[type] }));

	return (
		<CardContainer onClick={onClick ?? handleOnClick}>
			<UpCircle background={background} />
			<DownCircle background={background} />
			<DiscountContainer type={type}>
				<Discount type={type}>{`${isPercentage ? amount + '%' : '$' + amount}`}</Discount>
			</DiscountContainer>
			<Column>
				<Row>
					<Name>{name}</Name>
					<ActiveContainer>
						<Icon iconName={isActive ? 'activeCircle' : 'inactiveCircle'} size={XS} />
						{t(isActive ? 'dashboard-promoCodes-active' : 'dashboard-promoCodes-inactive')}
					</ActiveContainer>
				</Row>
				<Event>{references.length === 1 ? references[0].name : t('dashboard-promoCodes-multipleEvents')}</Event>
				<Uses>{`${used} ${t('dashboard-promoCodes-uses')}`}</Uses>
				<Chip type={type}>{chipText[type]}</Chip>
			</Column>
		</CardContainer>
	);
};

const memorizedPromoCodeCard: FC<PromoCodeCardProps> = memo(PromoCodeCard);

export { memorizedPromoCodeCard as PromoCodeCard };
