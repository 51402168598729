import styled from 'styled-components';

export const AffiliatesContainer = styled.div`
	gap: 16px;
	display: flex;
	max-width: 100%;
	align-items: center;
	padding: 12px 0;

	::-webkit-scrollbar {
		display: none;
	}
`;
