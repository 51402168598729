import * as yup from 'yup';

import i18n from 'locals/i18n';
import { CustomFieldType, TicketType } from 'utils/types';
import { CustomFieldFormProps } from './types';

export const defaultValues = {
	ticket_types_ids: [],
	custom_field: {
		name: '',
		description: '',
		only_buyer: 'buyerOnly',
		is_required: 'required',
		name_type: 'CUSTOM_QUESTION',
		type: 'STRING',
		choices: [{ name: '' }],
	},
};

export const schema = yup.object().shape({
	custom_field: yup.object().shape({
		name: yup.string().required(i18n.t('customFields-question-required')),
	}),
});

export const createRequestBody = (body: CustomFieldFormProps) => {
	if (body.custom_field.name_type !== 'CUSTOM_QUESTION') {
		body.custom_field.name = body.custom_field.name_type;
		body.custom_field.is_required = 'required';
	}

	return {
		...body,
		custom_field: {
			...body.custom_field,
			is_required: body.custom_field.is_required === 'required',
			only_buyer: body.custom_field.only_buyer === 'buyerOnly',
		},
	};
};

export const editFormValues = (data: CustomFieldType) => {
	const name_type = ['ATTENDEE_NAME', 'ATTENDEE_EMAIL'].includes(data.name) ? data.name : 'CUSTOM_QUESTION';
	const choices = data.choices?.length ? data.choices.map(({ name }) => ({ name })) : [{ name: '' }];

	return {
		ticket_types_ids: data.ticket_types.map(({ ID }: TicketType) => ID) || [],
		custom_field: {
			...defaultValues.custom_field,
			name_type,
			name: name_type === 'CUSTOM_QUESTION' ? data.name : '',
			type: data.type,
			is_required: data.is_required ? 'required' : 'notRequired',
			only_buyer: data.only_buyer ? 'buyerOnly' : 'eachAtendee',
			choices,
		},
	};
};
