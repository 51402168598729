import { MainLayout } from 'layouts/MainLayout';
import { AnalyticsSummary } from './components/AnalyticsSummary/AnalyticsSummary';
import { AnalyticsTitle } from './components/AnalyticsTitle/AnalyticsTitle';
import { EventsAnalytics } from './components/EventsAnalytics/EventsAnalytics';

import * as Styled from './Analytics.styles';

export const Analytics = () => {
	return (
		<MainLayout>
			<AnalyticsTitle />
			<Styled.ContentWrapper>
				<AnalyticsSummary />
				<EventsAnalytics />
			</Styled.ContentWrapper>
		</MainLayout>
	);
};
