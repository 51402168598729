import styled from 'styled-components';

export const SuccessAnimation = styled.div`
	background: ${({ theme }) => theme.colors.greenGradient4};
	height: 200px;
	width: 200px;
	border-radius: 100%;
	display: flex;
    justify-content: center;
	}
`;

export const Img = styled.img`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 50%;
	}
`;

export const Text = styled.div`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	text-align: center;
	color: ${({ theme }) => theme.colors.white};
	width: 268px;
`;
