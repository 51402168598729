import { useTranslation } from 'react-i18next';

import { MobileModal } from 'features/common/components/MobileModal';
import { Icon } from 'features/common/components/Icon';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { CONFIRMED_OPTIONS, PENDING_OPTIONS } from './constants';
import { EDIT_MEMBER } from '../../constants';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { MobileOptionsModalProps } from './types';
import { useAddMember } from '../../hooks/useAddMember';

import { FlexButton } from './MobileOptionsModal.styles';

export const MobileOptionsModal: React.FC<MobileOptionsModalProps> = ({
	closeModal,
	memberData: { full_name, email, roles, status },
	setShowResetModal,
	setShowDeleteModal,
	setMemberModal,
	isOpen,
}: MobileOptionsModalProps) => {
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const { addMember } = useAddMember();
	const showModal = !isLargeScreen && isOpen;

	const onClickArray = status
		? [
				() => setMemberModal({ isOpen: true, type: EDIT_MEMBER }),
				() => setShowResetModal(true),
				() => setShowDeleteModal(true),
		  ]
		: [() => addMember({ full_name, email, roles }), () => setShowDeleteModal(true)];
	const OPTIONS = status ? CONFIRMED_OPTIONS : PENDING_OPTIONS;

	return (
		<MobileModal isOpen={showModal} closeModal={closeModal}>
			<FlexContainer padding="16px 30px">
				<FlexContainer flexDirection="column" width="100%">
					{OPTIONS.map(({ text, icon }, i, array) => {
						const bottomBorder = i != array.length - 1;
						return (
							<FlexButton key={text} bottomBorder={bottomBorder} onClick={onClickArray[i]}>
								<Icon iconName={icon} />
								{t(text)}
							</FlexButton>
						);
					})}
				</FlexContainer>
			</FlexContainer>
		</MobileModal>
	);
};
