import { createSlice } from '@reduxjs/toolkit';

type LoginState = {
	isAlreadyCreated: boolean;
	email: string;
};

const initialState = {
	isAlreadyCreated: false,
	email: '',
} as LoginState;

const loginSlice = createSlice({
	name: 'loginSlice',
	initialState,
	reducers: {
		setIsAlreadyCreated(state, action) {
			state.isAlreadyCreated = action.payload;
		},
		setEmail(state, action) {
			state.email = action.payload;
		},
	},
});

export const { setIsAlreadyCreated, setEmail } = loginSlice.actions;
export default loginSlice.reducer;
