import styled from 'styled-components';

import { Button } from 'features/common/components/Button';

export const WelcomeContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	height: 100vh;
	width: 100%;
	font-family: ${({ theme }) => theme.fonts.primary};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		height: calc(100vh - 125px);
		gap: 20px;
	}
`;

export const LearnMoreContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	height: 100vh;
	width: 100%;
	font-family: ${({ theme }) => theme.fonts.primary};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		height: calc(100vh - 125px);
		gap: 20px;
	}
`;

export const IntroDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 10px 0;
	gap: 10px;
	max-width: 730px;
	min-width: 600px;
	z-index: 2;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		min-width: 100%;
		padding: 0 5px;
	}
`;

export const MoleculesImg = styled.img`
	width: 40%;
	margin: 0 -100px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		position: absolute;
		z-index: 1;
		width: 100%;

		&.hide {
			display: none;
		}
	}
`;

export const H1 = styled.h1`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		text-align: center;
		padding: 0 5px;
	}
`;

export const IntroText = styled.p`
	font-size: 20px;
	text-align: center;
	padding: 0 5px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
	}
`;

export const ButtonsDiv = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 10px;
	}
`;

export const LearnMoreButton = styled(Button)`
	color: ${({ theme }) => theme.colors.green};
	background: ${({ theme }) => theme.colors.grey3};
	border: ${({ theme }) => `1px solid ${theme.colors.green}`};
	margin: 0;
	width: 45%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 90%;
	}
`;

export const GetStartedButton = styled(Button)`
	margin: 0;
	width: 45%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 90%;
	}
`;

export const MarketYourEvents = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 730px;
	min-width: 600px;
	gap: 10px;
`;
