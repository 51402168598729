import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { EventProps } from './types';
import { allPages } from 'features/common/hooks/useFilterButtonClick';

import { NumberDot } from './EventsSelected.styles';

export const EventsSelected: FC<EventProps> = ({ page }) => {
	const eventCount = useSelector((state: RootState) => {
		const sliceName = allPages[page].sliceName;
		return state[sliceName].eventCount;
	});

	return eventCount > 0 ? <NumberDot>{eventCount}</NumberDot> : null;
};
