import { useTranslation } from 'react-i18next';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

import { PalleteProps } from './types';
import { QUATERNARY } from 'features/common/components/Button/constants';
import { Modal } from 'features/common/components/Modal/Modal';

import { Row, ModalButton, ContainerActionButtons } from './Colors.styles';

export const PaletteModal = ({ currentColor = '', handleChange, onClose }: PalleteProps) => {
	const [color, setColor] = useColor('hex', currentColor);
	const { t } = useTranslation();

	return (
		<Modal onClose={onClose}>
			<Modal.Header> {t('website-select-color')} </Modal.Header>
			<Modal.Body>
				<div tabIndex={0}>
					<Row className="centerBorder">
						<ColorPicker width={456} height={228} color={color} onChange={setColor} hideHSV dark />
					</Row>
				</div>
			</Modal.Body>
			<Modal.ActionButtons>
				<ContainerActionButtons>
					<ModalButton category={QUATERNARY} onClick={onClose} text={t('button-cancel')} type="button" />
					<ModalButton
						onClick={() => handleChange(color)}
						text={t('button-save-changes')}
						type="button"
						data-cy="save-color"
					/>
				</ContainerActionButtons>
			</Modal.ActionButtons>
		</Modal>
	);
};
