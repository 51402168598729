import { useEffect, useState } from 'react';

import { UseShowHoverOptions } from './types';

export const useShowHoverOptions = ({ hoverEnabled, elementId }: UseShowHoverOptions) => {
	const [showHoverOptions, setShowHoverOptions] = useState(false);

	const handleHoverOptionsState = (e: Event) => {
		setShowHoverOptions(e.type === 'mouseover');
	};

	const handleShowHoverOptions = () => setShowHoverOptions((prevState) => !prevState);

	useEffect(() => {
		if (hoverEnabled) {
			const el = document.getElementById(elementId);
			el?.addEventListener('mouseover', handleHoverOptionsState);
			el?.addEventListener('mouseleave', handleHoverOptionsState);

			return () => {
				el?.removeEventListener('mouseover', handleHoverOptionsState);
				el?.removeEventListener('mouseleave', handleHoverOptionsState);
			};
		}
	}, [hoverEnabled, elementId]);

	return { showHoverOptions, handleShowHoverOptions };
};
