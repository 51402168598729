import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { PromoCodeTitleProps } from './types';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { Header } from 'features/common/components/Header';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { promoCodeTypes } from 'features/PromoCodesForm/utils';
import { ModalDelete } from '../ModalDelete';

import { StyledIcon, Subtitle, Title } from './PromoCodeFormTitle.styles';

const PromoCodesFormTitle: FC<PromoCodeTitleProps> = ({
	type,
}: PromoCodeTitleProps) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const isEdit = pathname.includes('edit');
	const isLargeScreen = useResponsiveScreen();
	return (
		<Header modal={isEdit && <ModalDelete />}>
			<FlexContainer
				alignItems={isLargeScreen ? 'flex-start' : 'center'}
				flexDirection="row"
				gap={isLargeScreen ? '42px' : '20px'}
				justifyContent="flex-start"
			>
				{promoCodeTypes[type ?? 'isEdit'].icon && (
					<StyledIcon
						src={promoCodeTypes[type ?? 'isEdit'].icon}
						size={isLargeScreen ? undefined : 'M'}
					/>
				)}
				<FlexContainer
					flexDirection="column"
					gap={isLargeScreen ? '9px' : '0px'}
				>
					<Title element="h2">{t('promoCode-Form-title')}</Title>
					<Subtitle element="h3">
						{t(
							`promoCode-Form-subtitle-${
								promoCodeTypes[type ?? 'isEdit'].subtitle
							}`
						)}
					</Subtitle>
				</FlexContainer>
			</FlexContainer>
		</Header>
	);
};

const memorizedPromoCodesFormTitle: FC<PromoCodeTitleProps> =
	memo(PromoCodesFormTitle);

export { memorizedPromoCodesFormTitle as PromoCodesFormTitle };
