import { UseQueryResult } from '@tanstack/react-query';
import { AllPages } from 'features/common/hooks/useFilterButtonClick';
import { Dispatch, SetStateAction } from 'react';

export interface FilterOptionProps {
	className?: string;
}

type AllPagesKeys = keyof AllPages;

export enum DateFilterEnumPage {
	allTime = '',
	pastDay = 'past_day',
	pastWeek = 'past_week',
	pastMonth = 'past_month',
	pastYear = 'past_year',
}

export interface DateOptionProps extends FilterOptionProps {
	option: DateFilterEnumPage;
	selectedPeriod: selectedPeriod;
	page: AllPagesKeys;
}

export interface EventOptionProps extends FilterOptionProps {
	option: { name: string; id: number };
	page: AllPagesKeys;
}

export interface Event {
	id: number;
	name: string;
}

export interface FilterProps {
	eventQueryObj: UseQueryResult<any[]>;
	perPageState: PerPageState;
	selectedPeriod: selectedPeriod;
	selectedEvents?: selectedEvents;
	eventCount: number;
	page: AllPagesKeys;
	filterOption: DateFilterEnumPage[];
}

export interface MobileFilterProps extends FilterProps {
	setSearchText: Dispatch<SetStateAction<string>>;
}

export interface PerPageState {
	perPage: string;
	setPerPage: Dispatch<SetStateAction<string>>;
}

export const AllDateFilters: any = {
	[DateFilterEnumPage.allTime]: {
		translationKey: 'filter-dateOption-all-time',
	},
	[DateFilterEnumPage.pastDay]: {
		translationKey: 'filter-dateOption-past-day',
	},
	[DateFilterEnumPage.pastWeek]: {
		translationKey: 'generic-past-week',
	},
	[DateFilterEnumPage.pastMonth]: {
		translationKey: 'generic-past-month',
	},
	[DateFilterEnumPage.pastYear]: {
		translationKey: 'generic-past-year',
	},
};

export interface FilterCommonProps {
	selectedPeriod: selectedPeriod;
	selectedEvents: selectedEvents;
	eventCount: number;
	page: AllPagesKeys;
	filterOption: DateFilterEnumPage[];
}

export interface selectedPeriod {
	unapplied: DateFilterEnumPage;
	applied: DateFilterEnumPage;
}

interface EventTracker {
	[key: string]: boolean;
}

export interface selectedEvents {
	unapplied: EventTracker;
	applied: EventTracker;
}
