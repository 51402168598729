import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PRIMARY, OUTLINED } from 'features/common/components/Button/constants';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { MobileModal } from 'features/common/components/MobileModal';
import { EventsContainer } from './components/EventsContainer/EventsContainer';
import { DatesContainer } from './components/DatesContainer/DatesContainer';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { MobileFilterProps } from '../../types';
import { addVisibilityClass } from '../../utils';

import { FilterButtonEnum, useFilterButtonClick } from 'features/common/hooks/useFilterButtonClick';

import { FilterButton } from '../../RTKFilter.styles';
import {
	MobileFilterTitle,
	IconFilter,
	FilterAppliedIndicator,
	TestContainer,
	RelativeDiv,
} from './MobileFilter.styles';

export const MobileFilter: FC<MobileFilterProps> = ({
	eventQueryObj,
	perPageState,
	setSearchText,
	selectedPeriod,
	eventCount,
	filterOption,
	page,
}) => {
	const { t } = useTranslation();
	const [dateShow, setDateShow] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);
	const isLargeScreen = useResponsiveScreen();

	const cancelClick = useFilterButtonClick(closeModal, FilterButtonEnum[dateShow ? 'dateCancel' : 'eventCancel'], page);
	const applyClick = useFilterButtonClick(closeModal, FilterButtonEnum[dateShow ? 'dateApply' : 'eventApply'], page);

	return (
		<>
			<RelativeDiv>
				<IconFilter iconName="filter" onClick={openModal} />
				{eventCount > 0 && <FilterAppliedIndicator />}
			</RelativeDiv>

			{showModal && (
				<MobileModal isOpen={showModal} closeModal={closeModal}>
					<FlexContainer flexDirection="column" gap={isLargeScreen ? '24px' : '12px'} padding="23px 30px 16px 30px">
						<FlexContainer flexDirection="row" gap={isLargeScreen ? '32px' : '8px'} justifyContent="space-around">
							<MobileFilterTitle
								element="h3"
								onClick={() => setDateShow(true)}
								className={addVisibilityClass(dateShow)}
							>
								{t('fanDatabase-mobileFilters-filter-by-date')}
							</MobileFilterTitle>
							<MobileFilterTitle
								element="h3"
								onClick={() => setDateShow(false)}
								className={addVisibilityClass(!dateShow)}
							>
								{t('fanDatabase-mobileFilters-filter-by-event')}
							</MobileFilterTitle>
						</FlexContainer>

						{dateShow ? (
							<TestContainer>
								<DatesContainer
									className={addVisibilityClass(dateShow)}
									selectedPeriod={selectedPeriod}
									filterOption={filterOption}
									page={page}
								/>
							</TestContainer>
						) : (
							<EventsContainer
								className={addVisibilityClass(!dateShow)}
								eventQueryObj={eventQueryObj}
								perPageState={perPageState}
								setSearchText={setSearchText}
								page={page}
							/>
						)}

						<FlexContainer gap="16px" justifyContent="center">
							<FilterButton text={t('fanDatabase-cancel')} category={OUTLINED} onClick={cancelClick} type="button" />
							<FilterButton text={t('fanDatabase-apply')} category={PRIMARY} onClick={applyClick} type="button" />
						</FlexContainer>
					</FlexContainer>
				</MobileModal>
			)}
		</>
	);
};
