import { useQuery } from '@tanstack/react-query';

import { getDays } from './utils';
import { fetchAllHighlights, fetchEventHighlights } from './services';
import { EventHighlightsReturn, Highlights, HighlightsReturn, SmoothHighlightsReturn } from './types';

export function useHighlights(id: string, smoothData: true): SmoothHighlightsReturn;
export function useHighlights(id: string, smoothData?: false): EventHighlightsReturn;
export function useHighlights(): HighlightsReturn;
export function useHighlights(id?: string, smoothData?: boolean) {
	const { data: highlights, isLoading: isAllLoading } = useQuery({
		queryKey: ['highlights'],
		queryFn: fetchAllHighlights,
		enabled: id === undefined,
	});

	const { data: eventHighlights, isLoading: isEventLoading } = useQuery({
		queryKey: ['eventHighlights', id],
		queryFn: (): Promise<Highlights<string>> => fetchEventHighlights(id),
		enabled: Boolean(id),
		select: (data) => (smoothData ? (getDays(data) as Highlights<Date>) : data),
	});

	return { highlights, eventHighlights, isLoading: id ? isEventLoading : isAllLoading };
}
