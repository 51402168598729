import styled from 'styled-components';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import { Text as UnstyledText } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';

export const Container = styled.div`
	display: flex;
	width: 100%;
	padding: 40px 80px 20px;
	box-sizing: border-box;
	background-color: ${({ theme }) => theme.colors.grey};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		padding: 10px 10px 10px 24px;
		height: auto;
	}
`;

export const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	cursor: default;
	max-width: 100%;

	> div {
		height: auto;
	}

	> button {
		position: absolute;
		right: 10px;
		top: 10px;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
		max-width: 100%;
	}
`;

export const Icon = styled(UnstyledIcon)`
	width: 30px;
	height: 30px;
	cursor: pointer;
`;

export const SocialMediaLink = styled.a`
	display: inline-block;
	text-decoration: none;
	background-size: contain;
	background-repeat: no-repeat;
	width: 45px;
	height: 45px;
`;

export const SocialNetworkContainer = styled.div`
	margin-top: 14px;
`;

export const Title = styled(UnstyledText)`
	font-weight: 600;
	font-size: 38px;
	line-height: 40px;

	> img {
		margin-left: 20px;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-weight: 600;
		font-size: 20px;
		line-height: 46px;
		width: 70%;
	}
`;

export const Text = styled(UnstyledText)`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	padding-top: 8px;
	max-width: calc(100% - 24px);
`;

export const Image = styled.img`
	width: 186px;
	height: 173px;
	top: 74px;
	object-fit: cover;
	border-radius: 8px;
	gap: 10px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 60px;
		height: 60px;
		border-radius: 4px;
	}
`;

export const OutlinedButtonContainer = styled.div`
	display: flex;
	gap: 10px;
`;

export const OutlinedButton = styled(Button)`
	border-radius: 8px;
	display: flex;
	justify-content: center;
	font-size: small;
	align-items: center;
	width: fit-content;
	padding: 2px 10px;
	margin: 0px;
`;

export const OutlinedLinkButton = styled.a`
	background: transparent;
	border: ${({ theme }) => `1px solid ${theme.colors.green}`};
	color: ${({ theme }) => theme.colors.green};
	cursor: pointer;
	font-size: small;
	border-radius: 8px;
	width: fit-content;
	padding: 2px 10px;
	text-decoration: none;
	text-align: center;
`;
