import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { Toggle } from 'features/common/components/Toggle';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { TicketTagProps } from './types';
import { selectedType } from 'features/PromoCodesForm/types';
import { handleQuantity } from './utils';

import { Input, Text, TicketTagContainer } from './SpecificPromocodes.styles';

export const TicketTag: React.FC<TicketTagProps> = ({
	title,
	isLastElement,
	id,
	unlimitedAll,
	fromEventList,
}: TicketTagProps) => {
	const [unlimited, setUnlimited] = useState<boolean>(false);
	const [quantity, setQuantity] = useState<string>('');
	const [shouldExecuteHandleQuantity, setShouldExecuteHandleQuantity] =
		useState<boolean>(false);
	const { pathname } = useLocation();
	const isEdit = pathname.includes('edit');
	const { t } = useTranslation();

	const { getValues, setValue, watch } = useFormContext();

	const handleToggle = () => setUnlimited(!unlimited);

	useEffect(() => {
		if (unlimitedAll) {
			getValues('selected').forEach((item: selectedType) => {
				if (item.id === id) setUnlimited(true);
			});
		} else setUnlimited(false);
	}, [unlimitedAll]);

	useEffect(() => {
		if (isEdit && getValues('isEdited')) {
			getValues('selected').map((item: selectedType) => {
				if (item.id === id) {
					if (item.quantity === -1) setUnlimited(true);
					else setQuantity(String(item.quantity));
				}
			});
			setShouldExecuteHandleQuantity(true);
		}
	}, [watch('isEdited')]);

	useEffect(() => {
		if (!isEdit || shouldExecuteHandleQuantity) {
			handleQuantity({
				selected: getValues('selected'),
				setValue,
				quantity,
				unlimited,
				id,
			});
		}
	}, [unlimited, quantity]);

	return (
		<TicketTagContainer
			isLastElement={!isLastElement}
			fromEventList={fromEventList}
		>
			<Text className="span">{title}</Text>
			<FlexContainer alignItems="center" gap="10px">
				<Input
					value={quantity}
					onChange={(e) => setQuantity(e.target.value)}
					type="number"
					placeholder="--"
					min={0}
				/>
				<FlexContainer alignItems="center" gap="12px" margin="16px 0px">
					<Text className="span">{t('unlimited')}</Text>
					<Toggle
						isChecked={unlimited}
						name="unlimited"
						id={`${id}`}
						handleClick={handleToggle}
					/>
				</FlexContainer>
			</FlexContainer>
		</TicketTagContainer>
	);
};
