import { useState, useEffect } from 'react';

function getMobileView() {
	const { innerWidth: width } = window;
	return width < 844;
}

export function useMobileView() {
	const [windowDimensions, setWindowDimensions] = useState(getMobileView());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getMobileView());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}
