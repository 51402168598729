import { useTranslation } from 'react-i18next';

import { Icon } from 'features/common/components/Icon';
import { MyFinancesProps } from '../../types';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { useLinkStripeAccount } from 'hooks/useLinkStripeAccount';

import * as Styled from './BankingInfo.styles';

export const BankingInfo = ({ orgData }: MyFinancesProps) => {
	const { t } = useTranslation();
	const { bank_account: bankStatus } = orgData || {};
	const connected = (bankStatus && bankStatus.toLowerCase()) === 'connected';

	const { linkStripeAccount } = useLinkStripeAccount();
	return (
		<>
			<Styled.ResponsiveContainer>
				<Styled.Title>{t('settings-my-finances-banking-info')}</Styled.Title>
			</Styled.ResponsiveContainer>
			<Styled.BankingInfoCard onClick={() => linkStripeAccount()}>
				<Styled.BankInfoTextContainer>
					<FlexContainer width="100%" justifyContent="space-between" alignItems="center">
						<FlexContainer flexDirection="column">
							<Styled.BankInfoText connected={connected}>
								{t(connected ? 'payouts-account-connected-title' : 'payouts-account-not-connected-title')}
							</Styled.BankInfoText>
							<Styled.BankInfoSubTitle>
								{t(connected ? 'payouts-account-connected-subtitle' : 'payouts-account-not-connected-subtitle')}
							</Styled.BankInfoSubTitle>
						</FlexContainer>
						<FlexContainer>
							<Icon iconName="openStripeIntegration" />
						</FlexContainer>
					</FlexContainer>
				</Styled.BankInfoTextContainer>
			</Styled.BankingInfoCard>
		</>
	);
};
