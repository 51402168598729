import { Icon } from 'features/common/components/Icon';
import { SendTicketsTitleSectionProps } from '../../types';

import * as Styled from './SendTicketsTitleSection.styles';

export const SendTicketsTitleSection = ({ title, days, streetAddress }: SendTicketsTitleSectionProps) => (
	<Styled.Wrapper>
		<Styled.Title>{title}</Styled.Title>
		<div>
			{days.map(({ day, time }) => (
				<Styled.Date key={day?.value}>
					{day?.label} {time?.label}
				</Styled.Date>
			))}
			<Styled.Location>
				<Icon iconName="locationPin" />
				{streetAddress}
			</Styled.Location>
		</div>
	</Styled.Wrapper>
);
