import {
	fontStyleWebsettingCallout,
	fontStyleWebsettingHeader,
	fontStyleWebsettingSubtitle,
	fontStyleWebsettingText,
	fontStyleWebsettingTitle,
} from 'assets/images';

export const selectFontStyleOptionsWebsetting = [
	{
		title: 'Header Text',
		text: 'The header text color will be the color on top of your pages and will be on top of your header color. It is pink in the example below.',
		image: fontStyleWebsettingHeader,
	},
	{
		title: 'Title',
		text: 'The title text will be on top of your background color. It will be the title of your event and other main pages.',
		image: fontStyleWebsettingTitle,
	},
	{
		title: 'Subtitle',
		text: 'Subtitles will be on top of the background color and generally explain what a page’s function is. Below the subtitle is grey.',
		image: fontStyleWebsettingSubtitle,
	},
	{
		title: 'Callouts',
		text: 'Callouts text will the text color that appears on top of your theme color. Generally this is for buttons and callouts. Below it is white.',
		image: fontStyleWebsettingCallout,
	},
	{
		title: 'Text',
		text: 'Your event description and most of the body text on your widget or web spin up will be text.',
		image: fontStyleWebsettingText,
	},
];
