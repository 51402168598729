import { useEffect } from 'react';

import { Home as HomeComponent } from 'features/Home';
import { ROUTES } from 'features/routes';
import { sendGAPage } from 'utils/utils';

const Home = (): JSX.Element => {
	useEffect(() => {
		sendGAPage({ page: ROUTES.HOME_PATH, title: 'Home Page' });
	}, []);

	return <HomeComponent />;
};
export default Home;
