import styled from 'styled-components';
import { Carousel as UnstyledCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const CarouselContainer = styled.div`
	gap: 8px;
	width: 80%;
	display: flex;
	align-items: center;
	padding-bottom: 10px;
	flex-direction: column;
	justify-content: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 90%;
	}
`;

export const CenteredContainer = styled.div`
	flex: 1;
	gap: 8px;
	width: 80%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 90%;
	}
`;

export const Carousel = styled(UnstyledCarousel)`
	&.carousel-root {
		width: 600px;
		min-width: 600px;

		@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			min-width: 90%;
			max-width: 90vw;
		}
	}

	li.slide {
		opacity: 0.4;
	}

	li.slide.selected {
		opacity: 1;
	}
`;
