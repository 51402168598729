import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ModalStatus, Modals } from './types';

export enum DateFilterEnumPage {
	allTime = '',
	pastDay = 'past_day',
	pastWeek = 'past_week',
	pastMonth = 'past_month',
	pastYear = 'past_year',
}

interface EventTracker {
	[key: string]: boolean;
}

interface FanDatabaseState {
	selectedPeriod: {
		unapplied: any;
		applied: DateFilterEnumPage;
	};
	selectedEvents: {
		unapplied: EventTracker;
		applied: EventTracker;
	};
	modalStatus: ModalStatus;
	eventCount: number;
}

export const initialState = {
	selectedPeriod: {
		unapplied: DateFilterEnumPage,
		applied: DateFilterEnumPage.allTime,
	},
	selectedEvents: {
		unapplied: { '-1': true },
		applied: { '-1': true },
	},
	modalStatus: {
		openModal: Modals.none,
		fanData: {
			user_id: 0,
			full_name: '',
			email: '',
		},
	},
	eventCount: 0,
} as FanDatabaseState;

const FanDatabaseSlice = createSlice({
	name: 'fanDatabase',
	initialState,
	reducers: {
		alterUnappliedDateFanDatabase: (state, action) => {
			state.selectedPeriod.unapplied = action.payload;
		},
		alterAppliedDateFanDatabase: (
			state,
			action: PayloadAction<DateFilterEnumPage>
		) => {
			state.selectedPeriod.applied = action.payload;
		},
		alterUnappliedEventsFanDatabase: (state, action: PayloadAction<number>) => {
			const id = action.payload;
			const { unapplied } = state.selectedEvents;
			if (id !== -1) {
				delete unapplied[-1];
				unapplied[id]
					? delete state.selectedEvents.unapplied[id]
					: (unapplied[id] = true);
			}
			if (id === -1 || Object.keys(unapplied).length === 0) {
				state.selectedEvents.unapplied = initialState.selectedEvents.unapplied;
			}
		},
		alterAppliedEventsFanDatabase: (state) => {
			state.selectedEvents.applied = state.selectedEvents.unapplied;
			FanDatabaseSlice.caseReducers.updateFanEventCountFanDatabase(state);
		},
		resetUnappliedEventsFanDatabase: (state) => {
			state.selectedEvents.unapplied = state.selectedEvents.applied;
		},
		resetFanFiltersFanDatabase: (state) => {
			state.selectedEvents.applied = initialState.selectedEvents.applied;
			state.selectedPeriod.applied = initialState.selectedPeriod.applied;
		},
		openFanModal: (state, action: PayloadAction<ModalStatus>) => {
			state.modalStatus = action.payload;
		},
		closeFanModal: (state) => {
			state.modalStatus.openModal = Modals.none;
			state.modalStatus.fanData = initialState.modalStatus.fanData;
		},
		updateFanEventCountFanDatabase: (state) => {
			const allEvents = state.selectedEvents.applied['-1'] ? 1 : 0;
			state.eventCount =
				Object.keys(state.selectedEvents.applied).length - allEvents;
		},
		resetFanFiltersToInitialFanDatabase: (state) => {
			state.eventCount = initialState.eventCount;
			state.selectedPeriod = initialState.selectedPeriod;
			state.selectedEvents = initialState.selectedEvents;
		},
	},
});

export const {
	alterUnappliedDateFanDatabase,
	alterAppliedDateFanDatabase,
	alterUnappliedEventsFanDatabase,
	alterAppliedEventsFanDatabase,
	resetUnappliedEventsFanDatabase,
	openFanModal,
	closeFanModal,
	resetFanFiltersToInitialFanDatabase,
} = FanDatabaseSlice.actions;

export default FanDatabaseSlice.reducer;

export const selectedPeriod = (state: RootState) =>
	state.fanDatabase.selectedPeriod;
