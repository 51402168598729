import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { common } from 'theme/common';
import { MainHeaderProps } from './types';

export const TitleContainer = styled.div<MainHeaderProps>`
	gap: ${({ gap }) => gap};
	background: ${({ theme }) => theme.colors.grey2};
	width: 100%;
	display: flex;
	align-items: center;
	// TODO: Delete this prop (and the interface if there are not more props) when other screens's responsiveness are done
	padding: 48px 0 48px ${({ lessPadding }) => (lessPadding ? '6%' : '130px')};
	box-sizing: border-box;
	position: relative;
	z-index: 1;

	@media screen and (max-width: ${common.breakpoints.sm}) {
		padding: 32px 0 32px 24px;
	}
`;

export const Title = styled(Text)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
	}
`;
