import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { FileInputButtonProps } from './types';

const FileInputButton: FC<FileInputButtonProps> = ({
	Button,
	name,
	id,
	inputRef,
	accept,
	...rest
}: FileInputButtonProps) => {
	const { register, setValue } = useFormContext();

	return (
		<>
			<Button />
			<input
				id={id ?? name}
				{...rest}
				{...register(name)}
				ref={(e) => {
					register(name).ref(e);
					inputRef.current = e;
				}}
				type="file"
				accept={accept ?? 'image/*'}
				style={{ display: 'none' }}
				onChange={(event) => setValue(id ?? name, event?.target?.files?.length ? event?.target?.files[0] : null)}
				data-cy={`${name}-input`}
			/>
		</>
	);
};

export { FileInputButton };
