import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MainLayout } from 'layouts/MainLayout';
import { PromoCodeTitle } from './components/PromoCodeTitle';
import { PromoCodeTypeCard } from './components/PromoCodeTypeCard';
import { PROMO_CODES_TYPES, PromoCodesTypes } from './utils';
import { PromoCodesLocationState } from './types';

import { ContentWrapper, Description, PromoCodeTypeCardsContainer } from './PromoCodes.styles';

const PromoCodes: FC = () => {
	const { t } = useTranslation();
	const { codeId } = useParams<any>();
	const { state } = useLocation<PromoCodesLocationState>();
	const fromDashboardFlag = state && state.fromDashboard;

	const isNewPromoCode = !codeId;

	return (
		<MainLayout>
			<PromoCodeTitle isNewPromoCode={isNewPromoCode} />
			<ContentWrapper>
				<Description element="p">{t('promoCode-New-description')}</Description>
				<PromoCodeTypeCardsContainer>
					{PromoCodesTypes.map(({ description, icon, title, type, path }) =>
						type === PROMO_CODES_TYPES.ASSIGN_EXISTING && !fromDashboardFlag ? null : (
							<PromoCodeTypeCard
								description={t(description)}
								icon={icon}
								title={t(title)}
								type={type}
								key={title}
								fromDashboard={fromDashboardFlag}
								path={path}
							/>
						)
					)}
				</PromoCodeTypeCardsContainer>
			</ContentWrapper>
		</MainLayout>
	);
};

export { PromoCodes };
