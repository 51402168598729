import { Draggable } from '@hello-pangea/dnd';

import { DragHandle } from 'assets/customizedIcons';
import { getField } from '../utils';
import { FieldsProps } from './types';
import { canEditCustomField } from 'utils/utils';
import { Icon } from 'features/common/components/Icon';

import * as Styled from './Fields.styles';

export const Fields = ({ rows, customFields, onEdit }: FieldsProps) => {
	return (
		<>
			{rows
				.sort((a, b) => a - b)
				.map((row) => (
					<Draggable key={row} draggableId={row.toString()} index={row}>
						{(provided) => {
							const customField = customFields.find((cf) => cf.row === row);
							return (
								<Styled.FieldContainer ref={provided.innerRef} {...provided.draggableProps}>
									<Styled.CustomFieldButtonsContainer {...provided.dragHandleProps} data-cy="drag-handle">
										<DragHandle />
									</Styled.CustomFieldButtonsContainer>

									<Styled.InputContainer>{customField && getField(customField)}</Styled.InputContainer>

									<Styled.CustomFieldButtonsContainer>
										{customField && canEditCustomField(customField) && (
											<Styled.PencilButton onClick={() => onEdit(customField.ID)}>
												<Icon iconName="pencilBlack" />
											</Styled.PencilButton>
										)}
									</Styled.CustomFieldButtonsContainer>
								</Styled.FieldContainer>
							);
						}}
					</Draggable>
				))}
		</>
	);
};
