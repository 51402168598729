import { Sidebar } from 'features/common/components/Sidebar';
import { MainLayoutProps } from './types';

import { MainPanel, Wrapper } from './MainLayout.styled';
import { useEffect } from 'react';

const MainLayout = ({ children, color, containsStickyEl = false, isFullVh = false }: MainLayoutProps) => {
	useEffect(() => {
		const body = document.querySelector('body');
		if (body && color) {
			body.style.backgroundColor = color;
		}
	}, [color]);

	return (
		<Wrapper>
			<Sidebar />
			<MainPanel color={color} containsStickyEl={containsStickyEl} isFullVh={isFullVh}>
				{children}
			</MainPanel>
		</Wrapper>
	);
};

export { MainLayout };
