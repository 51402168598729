import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { hideScrollbar } from 'styles';

export const CardContainer = styled.div`
	height: 360px;
	overflow: hidden;
	margin-right: 10px;
	border-radius: 8px;
	overflow: hidden;
	background-color: ${({ theme }) => theme.colors.black4};
	max-width: 50%;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		box-sizing: border-box;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 260px;
		height: 260px;
		min-width: 260px;
		min-height: 260px;
		max-width: 260px;
		max-height: 260px;
	}
`;

export const ChartContainer = styled.div`
	width: 109%;
	height: 180px;
	&.isEvent {
		margin-top: 37px;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 112%;
		height: 150px;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	overflow-x: scroll;
	justify-content: space-between;
	width: 91%;
	padding: 0 3% 0 6%;

	${hideScrollbar}
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 94%;
		padding: 0 3%;
	}
`;

export const Column = styled.div`
	height: fit-content;
	display: flex;
	flex-direction: column;
`;

export const InfoColumn = styled.div`
	padding: 0;
	height: 100%;
	display: flex;
	white-space: pre-wrap;
	word-break: break-word;
	flex-direction: column;
	justify-content: center;
	justify-content: flex-start;
`;

export const Row = styled.div`
	height: 16%;
	display: flex;
	align-items: center;
	flex-direction: row;
	padding: 25px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 16px;
	}
`;

export const CardName = styled(Text)`
	font-size: 16px;
	font-weight: 400;
	max-width: 145px;
	line-height: 19px;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 24px;
		max-width: 100%;
		line-height: 36px;
	}
`;

export const CardAmount = styled(Text)`
	font-size: 50px;
	line-height: 56px;
	font-weight: 700;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 40px;
		max-width: 100%;
		line-height: 46px;
	}
`;

export const CardAmountTotal = styled(CardAmount)`
	color: ${({ theme }) => theme.colors.crimson};
`;

export const CardAmountRevenue = styled(CardAmount)`
	color: ${({ theme }) => theme.colors.green5};
`;

export const CardAmountAttendees = styled(CardAmount)`
	color: ${({ theme }) => theme.colors.purple1};
`;

export const TicketInfoItemContainer = styled.div`
	gap: 10px;
	display: flex;
	margin: 5px 0px;
	align-items: center;
`;
