import { UseFormReturn } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

import { uploadImage } from 'services/UploadImage/uploadImage';
import { FieldProps, Media } from './types';

export function useUploadImageMutation(formMethods: UseFormReturn<FieldProps, any>) {
	const { mutateAsync: mutateUploadImage } = useMutation(uploadImage, {
		onSuccess: ({ data }) => {
			if (data.image_type != 'gallery_image') {
				const newValues = [
					{
						url: data.image_url,
						name: data.name,
						position: 1,
						main_image: true,
					},
				];
				formMethods.setValue('mediaContent.eventImage', newValues);
			}

			if (data.image_type === 'gallery_image') {
				const newImageValues: Media = {
					position: data.index,
					type: 'image',
					url: data.image_url,
				};
				const currentMedia = formMethods.getValues('mediaContent.media');
				currentMedia[data.index] = newImageValues;
				formMethods.setValue('mediaContent.media', currentMedia);
			}
		},
		onError: (err) => {
			//TODO: do something
			// eslint-disable-next-line no-console
			console.error('error', err);
		},
	});

	return { mutateUploadImage };
}
