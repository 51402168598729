import { FC, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal } from 'features/common/components/Modal/Modal';
import { QUATERNARY } from 'features/common/components/Button/constants';
import { ButtonGroup } from '../ButtonGroup';
import { ModalReplaceProps } from './types';

import { ContainerActionButtons, ContainerBody, ModalButton, Option } from './ModalReplace.styles';

const ModalReplace: FC<ModalReplaceProps> = ({ setDisplayType, inputRef, ...rest }: ModalReplaceProps) => {
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false);
	const { setValue } = useFormContext();

	const ticketImage = useWatch({
		name: 'ticketImage',
	});

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	useEffect(() => closeModal(), [ticketImage]);

	return showModal ? (
		<Modal onClose={closeModal}>
			<>
				<Modal.Header>{t('ticketDetails-ticket-selectOne')}</Modal.Header>
				<Modal.Body>
					<ContainerBody>
						<ButtonGroup setDisplayType={setDisplayType} inputRef={inputRef} {...rest} />
					</ContainerBody>
				</Modal.Body>
				<Modal.ActionButtons>
					<ContainerActionButtons>
						<ModalButton category={QUATERNARY} onClick={closeModal} text={t('button-cancel')} type="button" />
					</ContainerActionButtons>
				</Modal.ActionButtons>
			</>
		</Modal>
	) : (
		<Option
			type="button"
			onClick={() => {
				openModal();
				setDisplayType('');
				setValue('thumbnail', '');
			}}
			data-cy="replace-img"
		>
			{t('ticketDetails-ticket-replace')}
		</Option>
	);
};

export { ModalReplace };
