import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { EventCollection } from './EventCollection';
import { EventProps } from '../Events/types';
import { SCHEDULED_QUERY } from '../../../common/components/Filters/LocalFilter/constants';
import { getData } from 'services/api';

import { Title, InfoCard, Container, Wrapper } from './UpcomingEvents.styles';

const UpcomingEvents: FC = () => {
	const { t } = useTranslation();

	const fetchUpcomingEvents = (): Promise<EventProps[]> => getData(`/events?date=${SCHEDULED_QUERY}`);

	const { data: upcomingEvents } = useQuery({
		queryKey: ['upcomingEvents'],
		queryFn: fetchUpcomingEvents,
	});
	const hasEvents = !!upcomingEvents?.length;

	return (
		<Wrapper data-cy="upcoming-events-section">
			<Title element="h2">{t('upcomingEvents')}</Title>
			<Container>
				{hasEvents ? (
					<EventCollection events={upcomingEvents || []} />
				) : (
					<InfoCard
						title={t('upcomingEvents-title')}
						subtitle={t('upcomingEvents-subtitle')}
						icon="calendar"
						highlightBackground
					/>
				)}
			</Container>
		</Wrapper>
	);
};

export { UpcomingEvents };
