import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Divider } from 'features/common/components/Divider';
import {
	PromoCodeAddCard,
	PromoCodeCard,
} from 'features/common/components/PromoCodeCard';
import {
	onChangeShowFooter,
	onChangeShowTicketTypes,
} from '../../slice/PromoCodesFormSlice';
import { SearchBar } from './components/SearchBar/SearchBar';
import { PromoCode } from 'utils/types';
import {
	handleOptions,
	handleType,
	initialSelectedValue,
} from 'features/PromoCodesSection/utils';
import { useGetPromoCodes } from 'hooks/useGetPromoCodes';
import { useGetEvents } from 'hooks/useGetEvents';
import { FilterOptionType } from 'features/PromoCodesSection/components/Filter/types';

import { PromoCodeListContainer } from './ExistingPromoCodes.styles';

const ExistingPromoCodes: React.FC = () => {
	const [selectedEvent, setSelectedEvent] =
		useState<FilterOptionType>(initialSelectedValue);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const dispatch = useDispatch();

	const handleTicketTypes = () => {
		dispatch(onChangeShowTicketTypes());
		dispatch(onChangeShowFooter());
	};

	const { promocodes } = useGetPromoCodes({
		selectedEvent,
		searchQuery,
	});

	const { items } = useGetEvents({
		searchQuery: '',
		per_page: 1000,
	});

	const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	const onChangeFilter = (option: FilterOptionType) => {
		setSelectedEvent(option);
	};

	return (
		<>
			{items && (
				<SearchBar
					onChangeFilter={onChangeFilter}
					onChangeSearch={onChangeSearch}
					options={handleOptions(items)}
					selectedOption={selectedEvent}
				/>
			)}
			<Divider />
			<PromoCodeListContainer>
				<PromoCodeAddCard />
				{promocodes.map(
					({
						id,
						code,
						promocode_type,
						total_uses,
						discount,
						references,
						active,
						percentage,
						coupon_type,
					}: PromoCode) => (
						<PromoCodeCard
							key={id}
							id={id}
							name={code}
							type={handleType(promocode_type)}
							used={total_uses}
							amount={discount || percentage}
							references={references || []}
							isActive={active}
							isPercentage={coupon_type === 'percentage'}
							onClick={handleTicketTypes}
						/>
					)
				)}
			</PromoCodeListContainer>
		</>
	);
};

export { ExistingPromoCodes };
