import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
  }

  * {
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  ::-webkit-scrollbar {
      display: none;
  }
`;

export default GlobalStyle;
