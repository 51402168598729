import { CustomFee } from 'features/EditEvent/types';

export const CUSTOM_FEE_TYPES = {
	PERCENTAGE: 'percentage',
	FLAT: 'flat',
};

export const CUSTOM_FEE_TYPES_SYMBOLS = {
	[CUSTOM_FEE_TYPES.PERCENTAGE]: '%',
	[CUSTOM_FEE_TYPES.FLAT]: '$',
};

export const defaultValuesCustomFee: CustomFee = {
	ID: 0,
	name: '',
	amount: 0,
	type: CUSTOM_FEE_TYPES.FLAT,
	taxable: '0',
};
