import styled from 'styled-components';

export const H1 = styled.h1`
	font-weight: 700;
	font-size: 32px;
	line-height: 48px;
	font-family: ${({ theme }) => theme.fonts.primary};
	margin: 0;
`;

export const H2 = styled.h2`
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const H3 = styled.h3`
	margin: 0px;
	font-size: 32px;
	font-weight: 600;
	line-height: 36px;
	font-family: ${({ theme }) => theme.fonts.primary};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 20px;
		line-height: 30px;
	}
`;

export const H4 = styled.h4`
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const Paragraph = styled.p`
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const Span = styled.span`
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const StdText = styled.span`
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 16px;
	font-weight: 500;
	text-align: center;
`;
