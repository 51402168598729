import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Header } from 'features/common/components/Header';
import { Icon } from 'features/common/components/Icon';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { AnalyticsTitleProps } from './types';

import * as Styled from './AnalyticsTitle.styles';

export const AnalyticsTitle = ({ subtitle }: AnalyticsTitleProps) => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const isLargeScreen = useResponsiveScreen();

	return (
		<Header>
			<FlexContainer
				alignItems="flex-start"
				flexDirection="row"
				gap={isLargeScreen ? '42px' : '20px'}
				justifyContent="flex-start"
			>
				<Icon iconName="arrowLeft" onClick={goBack} cursor="pointer" />
				<FlexContainer
					flexDirection="column"
					gap={isLargeScreen ? '9px' : '0px'}
				>
					<Styled.Title element="h2">{t('analytics')}</Styled.Title>
					{subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
				</FlexContainer>
			</FlexContainer>
		</Header>
	);
};
