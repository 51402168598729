import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';

import { InputLabel } from './InputLabel';
import { FormInputProps } from './types';
import { handleNewDates, handleUnlimitedDate } from './utils';

import { IconCalendar, CalendarWrapper, Calendar, Error, InputContainer } from './Form.styles';

const FormInputDate: FC<FormInputProps> = ({ id, name, label, onChange, index, value, formValue }: FormInputProps) => {
	const [startDate, setStartDate] = useState<Date | null>(value);
	const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
	const { pathname } = useLocation();
	const {
		setValue,
		getValues,
		formState: { errors },
		watch,
	} = useFormContext();
	const error = errors[name];
	const isFromPromoCode = pathname.includes('promo-codes');

	const handleOnChangeDate = (event: any) => {
		setStartDate(event);
		setValue(
			formValue as string,
			handleNewDates({
				event,
				currentDates: getValues(formValue as string),
				index: Number(index),
			})
		);
		onChange && onChange(event);
	};

	const handleCalendarStatus = () => {
		setIsCalendarOpen((prevState) => !prevState);
	};

	useEffect(() => {
		if (isFromPromoCode && id?.includes('end')) {
			setValue('endDate', [
				{
					...getValues('endDate')[0],
					start_date: handleUnlimitedDate({
						watch,
						getValues,
						isRealValue: true,
					}),
					end_date: handleUnlimitedDate({
						watch,
						getValues,
						isRealValue: true,
					}),
				},
			]);
			setStartDate(handleUnlimitedDate({ watch, getValues }));
		}
	}, [watch('isEndDate')]);

	useEffect(() => {
		if (isFromPromoCode && getValues('isEdited') && id?.includes('start'))
			setStartDate(getValues('startDate')[0].start_date);
	}, [watch('isEdited')]);

	return (
		<>
			<InputLabel className="SM" htmlFor={id ?? name} label={label} />
			<InputContainer className="relative" hasError={Boolean(error)}>
				<CalendarWrapper isCalendarOpen={isCalendarOpen}>
					<DatePicker
						id={id ?? name}
						selected={startDate}
						onChange={handleOnChangeDate}
						dateFormat="d MMMM yyyy"
						calendarContainer={Calendar}
						onCalendarOpen={handleCalendarStatus}
						onCalendarClose={handleCalendarStatus}
					/>
				</CalendarWrapper>
				<IconCalendar iconName="greenCalendar" isCalendarOpen={isCalendarOpen} />
			</InputContainer>
			<Error>{error?.message}</Error>
		</>
	);
};

export { FormInputDate };
