import { useMemo } from 'react';
import { Query, useIsFetching, useIsMutating } from '@tanstack/react-query';

import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { DASHBOARD_AFFILIATES, EVENTS_SEARCH_AND_FILTER } from 'utils/constants';

const useLoading = (): boolean => {
	const isMutating = useIsMutating();
	const isFetching = useIsFetching({
		predicate: (query: Query) => {
			return ![DASHBOARD_AFFILIATES, EVENTS_SEARCH_AND_FILTER].some((key) =>
				query.options.queryKey?.toString().includes(key)
			);
		},
	});

	const { loading } = useAppSelector((state: RootState) => state.auth);

	const isLoading = useMemo(() => loading || !!isFetching || !!isMutating, [loading, isFetching, isMutating]);

	return isLoading;
};

export default useLoading;
