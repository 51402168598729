import { FC } from 'react';

import { OrgPanel } from './components/OrgPanel';
import { UserPanel } from './components/UserPanel';
import { TableContainer } from './components/TableContainer';
import { MyProfileProps } from './types';

import { ToSSection } from './components/ToSSection';

import { PanelWrapper } from './MyProfile.styles';

export const MyProfile: FC<MyProfileProps> = ({
	orgData,
	userData,
	membersData,
	hasMultiplePartners,
}: MyProfileProps) => {
	return (
		<>
			<PanelWrapper>
				<OrgPanel orgData={orgData} hasMultiplePartners={hasMultiplePartners} />
				<UserPanel userData={userData} />
			</PanelWrapper>
			<TableContainer membersData={membersData} />
			<ToSSection />
		</>
	);
};
