import { useTranslation } from 'react-i18next';
import { Search } from 'features/common/components/Search';
import { Filter } from 'features/PromoCodesSection/components/Filter';

import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { ToolTip } from 'features/common/components/InfoTooltip';
import { Icon } from 'features/common/components/Icon';
import { SearchBarExistingPromoCodesProps } from './types';

import { Container, TitleSearchBar } from '../../ExistingPromoCodes.styles';

const SearchBar: React.FC<SearchBarExistingPromoCodesProps> = ({
	onChangeFilter,
	onChangeSearch,
	options,
	selectedOption,
}: SearchBarExistingPromoCodesProps) => {
	const { t } = useTranslation();
	return (
		<>
			<FlexContainer gap="18px" flexDirection="row" alignItems="center">
				<TitleSearchBar>
					{t('promoCode-Form-existing-search-title')}
				</TitleSearchBar>
				<ToolTip text="">
					<Icon iconName="help" />
				</ToolTip>
			</FlexContainer>
			<Container>
				<Search onChange={onChangeSearch} placeholder={t('promoCode-search')} />
				<Filter
					onChange={onChangeFilter}
					options={options}
					selectedOption={selectedOption}
				/>
			</Container>
		</>
	);
};

export { SearchBar };
