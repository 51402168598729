import { useQuery } from '@tanstack/react-query';

import { getData } from 'services/api';
import { ElementController } from './ElementController';
import { CONTROLLED_PREVIEW_ELEMENTS } from './utils';
import { EmailPreviewProps } from './types';

import { Container, LeftSide, StickyDiv } from './EmailPreview.styles';

export const EmailPreview = ({ partnerImage }: EmailPreviewProps) => {
	const { data } = useQuery({ queryKey: ['preview'], queryFn: () => getData('/marketing/designs/template') });

	return (
		<LeftSide>
			<Container>
				<StickyDiv dangerouslySetInnerHTML={{ __html: data }}></StickyDiv>
			</Container>
			{CONTROLLED_PREVIEW_ELEMENTS.map(({ htmlClass, htmlId, name, type }) => (
				<ElementController
					key={name}
					htmlClass={htmlClass}
					htmlId={htmlId}
					partnerImage={partnerImage}
					name={name}
					type={type}
				/>
			))}
		</LeftSide>
	);
};
