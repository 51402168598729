import styled from 'styled-components';

import { Minus, Plus } from 'assets/customizedIcons';
import { Text } from 'features/common/components/Text';
import { IconProps } from 'utils/types';

import { StyledInput } from 'features/BoxOffice/Counter/Counter.styles';

export const Container = styled.div`
	align-items: center;
	border-radius: 4px;
	display: flex;
	gap: 8px;
	padding: 12px 24px;
	background-color: ${({ theme }) => theme.colors.blackGrey};
`;
export const Label = styled(Text)`
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
`;

export const SelectLabel = styled.select`
	align-items: center;
	border: 1px solid ${({ theme }) => theme.colors.green};
	background-color: ${({ theme }) => theme.colors.blackGrey};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.green};
	display: flex;
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
	height: 40px;
	gap: 4px;
	padding: 4px 8px;
`;

export const PlusIcon = styled(Plus)<IconProps>`
	height: 20px;
	width: 20px;
	stroke: ${({ theme, disabled }) => (disabled ? theme.colors.grey18 : theme.colors.green)};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	user-select: none;
`;

export const MinusIcon = styled(Minus)<IconProps>`
	height: 20px;
	width: 20px;
	stroke: ${({ theme, disabled }) => (disabled ? theme.colors.grey18 : theme.colors.green)};
	fill: ${({ theme, disabled }) => (disabled ? theme.colors.grey18 : theme.colors.green)};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	user-select: none;
`;

export const GreenBox = styled.div`
	align-items: center;
	border: 1px solid ${({ theme }) => theme.colors.green};
	border-radius: 4px;
	display: flex;
	gap: 4px;
	height: 30px;
	padding: 4px 8px;
`;

export const Input = styled(StyledInput)`
	background-color: ${({ theme }) => theme.colors.blackGrey};
	color: ${({ theme }) => theme.colors.white};
	display: flex;
	font-size: 16px;
	font-weight: 400;
`;
