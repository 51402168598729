import { FlexContainer } from 'features/common/components/FlexContainer';
import { TicketType } from '../TicketType';
import { useMobileView } from 'hooks/useMobileView';
import { useGetLastEvent } from '../hooks/useGetLastEvent';

export const SingleDayTickets = () => {
	const { event } = useGetLastEvent();
	const isMobile = useMobileView();

	return (
		<FlexContainer flexDirection="column" gap="32px">
			{event?.ticketTypes.map((value) =>
				TicketType({
					value,
					startDate: event.startDate,
					endDate: event.endDate,
					timezone: event.timeZone,
					isMobile,
				})
			)}
		</FlexContainer>
	);
};
