import { useTranslation } from 'react-i18next';

import { InputProps } from './types';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { Toggle } from 'features/common/components/Toggle';
import { Form } from 'features/common/components/Form';

import { InputContainer, InputText, StyledInputDate, StyledInputTime } from './Input.styles';

const Input: React.FC<InputProps> = ({
	title,
	type,
	name,
	id,
	tooltip,
	toggle,
	toggleDiscount,
	handleToggle,
	toggleValue = false,
	value,
}: InputProps) => {
	const { t } = useTranslation();
	return (
		<InputContainer>
			<FlexContainer flexDirection="row" gap="18px" alignItems="center">
				<InputText>{t(title)}</InputText>
				{toggle && <Toggle isChecked={toggleValue} name={name} id={id} handleChange={handleToggle} />}
				{tooltip && tooltip}
			</FlexContainer>
			{(type === 'text' || type === 'number') && (
				<FlexContainer flexDirection="row" alignItems="center" gap="15px">
					<Form.Input type={type} id={id} name={name} promoCodeForm={true} />
					{type === 'number' && toggleDiscount && toggleDiscount}
				</FlexContainer>
			)}
			{type === 'date' && <StyledInputDate id={id} name={name} index={0} formValue={id} value={value} />}
			{type === 'time' && <StyledInputTime id={id} name={name} index={0} formValue={id} value={value} />}
		</InputContainer>
	);
};

export { Input };
