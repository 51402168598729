import { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';

import { CustomIframe } from './CustomIframe/CustomIframe';
import { getData } from 'services/api';

import * as Styled from './ManageNanos.styles';

const nanosLoginUrl = 'https://tixologi.nanos.ai/api/v1/auth/silent';

export const ManageNanos = () => {
	const formRef = useRef<HTMLFormElement>(null);

	const { data: nanosToken } = useQuery({
		queryKey: ['nanosLogin'],
		queryFn: (): Promise<string> => getData('login/nanos'),
	});

	useEffect(() => {
		if (nanosToken) {
			formRef.current && formRef.current.submit();
		}
	}, [nanosToken]);

	return (
		<Styled.NanosContainer>
			<CustomIframe>
				<form action={nanosLoginUrl} method="POST" ref={formRef} hidden>
					<input name="token" value={nanosToken} readOnly />
					<input type="submit" value="Submit" />
				</form>
			</CustomIframe>
		</Styled.NanosContainer>
	);
};
