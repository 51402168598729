import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { TicketRow } from '../TicketRow';
import { useGetLastEvent } from 'features/BoxOffice/hooks/useGetLastEvent';
import { MultidayRow } from '../MultidayRow/MultidayRow';
import { InformationProps } from 'features/BoxOffice/types';

import { Title, Text, Divider, Total, InnerContainer } from '../OrderPreview.styles';

export const Information = ({ ticketSent }: InformationProps) => {
	const { t } = useTranslation();
	const { watch } = useFormContext();
	const { event, isMultiDay } = useGetLastEvent();
	const { multiday } = useAppSelector(({ lastMultiDay }: RootState) => lastMultiDay);

	const handleTicketRow = () => (isMultiDay ? multiday?.map(MultidayRow) : event?.ticketTypes.map(TicketRow));

	return (
		<InnerContainer>
			<Title className="green">{t('tickets')}</Title>
			{handleTicketRow()}
			<Divider />
			{ticketSent && (
				<>
					<Title>{t('boxOffice-orderNumber')}:</Title>
					<Title>{watch('transactionId')}</Title>
					<Divider />
				</>
			)}
			<Title>{t('boxOffice-sendTo')}:</Title>
			<Text>{watch('email')}</Text>
			<Divider />
			<Title>{t('total')}:</Title>
			<Total element="h1">USD ${watch('total')}</Total>
		</InnerContainer>
	);
};
