import { CustomFields as CustomFieldsComponent } from 'features/CustomFields';

interface CustomFieldsPageProps {
	editMode?: boolean;
}

const CustomFieldsPage = ({ editMode }: CustomFieldsPageProps): JSX.Element => (
	<CustomFieldsComponent editMode={editMode} />
);

export default CustomFieldsPage;
