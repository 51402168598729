import styled from 'styled-components';

export const Root = styled.div`
	margin-top: 45px;
`;

export const CardsWrapper = styled.div`
	display: flex;
	width: 100%;
	margin-top: 16px;
	overflow: hidden;
	flex-wrap: wrap;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		overflow-x: auto;
		flex-wrap: nowrap;
	}
`;
