import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { setTicketsSelected } from 'store/slices/BoxOfficeSlice';
import { resetValues, sendTickets } from './utils/utils';
import { EventData, MultidayType } from 'features/common/types';
import { ButtonsType } from '../../types';
import { useGetLastEvent } from '../../hooks/useGetLastEvent';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { SendTickets, ValidateTickets } from '../services';
import { MultiplePurchaseResponseType } from '../types';
import { camelize } from 'utils/utils';

import { Button } from '../../OrderSummary/OrderSummary.styles';

export const Buttons = ({ ticketSent, setTicketSent }: ButtonsType) => {
	const [ticketScanned, setTicketScanned] = useState<boolean>(false);
	const [finalTickets, setFinalTickets] = useState<string[]>([]);
	const { event, isMultiDay } = useGetLastEvent();
	const { multiday } = useAppSelector(({ lastMultiDay }: RootState) => lastMultiDay);
	const { getValues, watch, reset } = useFormContext();
	const dispatch = useAppDispatch();
	const { goBack } = useHistory();
	const { t } = useTranslation();

	const { mutate: mutateSendTickets } = useMutation(SendTickets, {
		onSuccess: ({ data }) => {
			setTicketSent(true);
			setFinalTickets((camelize(data) as MultiplePurchaseResponseType).tickets.map(({ ticketId }) => String(ticketId)));
		},
	});

	const { mutate: mutateValidateTicket } = useMutation(ValidateTickets, {
		onSuccess: () => setTicketScanned(true),
	});

	const validateTickets = () =>
		mutateValidateTicket({
			eventId: String((event as EventData).id),
			ticketsIds: finalTickets,
		});

	const handleClickTop = () =>
		ticketSent
			? validateTickets()
			: sendTickets({
					event: event as EventData,
					multiday: multiday as MultidayType[],
					isMultiDay,
					getValues,
					mutateSendTickets,
			  });

	const handleClickBottom = () => {
		if (!ticketSent) goBack();
		dispatch(setTicketsSelected(false));
		dispatch(resetValues({ event: event as EventData, multiday: multiday as MultidayType[], isMultiDay, reset }));
	};

	const isDisabled =
		(!watch('transactionId') && watch('total') !== '0') || (watch('total') === '0' && watch('transactionId'));

	return (
		<FlexContainer flexDirection="column" gap="12px">
			{!ticketScanned && (
				<Button
					text={t(ticketSent ? 'boxOffice-markAsScanned' : 'boxOffice-sendTickets')}
					type="button"
					onClick={handleClickTop}
					disabled={isDisabled}
				/>
			)}
			<Button
				text={t(ticketSent ? 'boxOffice-newTransaction' : 'cancel')}
				type="button"
				category="outlined"
				onClick={handleClickBottom}
			/>
		</FlexContainer>
	);
};
