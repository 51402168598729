import { FC, useReducer, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownContainer } from 'features/common/components/DropdownContainer';
import { EventDropdown } from './components/EventDropdown';
import { DateDropdown } from './components/DateDropdown';
import { EventsSelected } from './components/EventsSelected';
import { FilterDispatch } from './types';
import { FilterProps, AllDateFilters } from '../../types';
import { filterReducer } from './utils';

export const DesktopFilters: FC<FilterProps> = ({
	eventQueryObj,
	perPageState,
	selectedPeriod,
	page,
	filterOption,
}) => {
	const [openedFilters, dispatch] = useReducer(filterReducer, [false, false]);
	const [isDateFilterOpen, isEventFilterOpen] = openedFilters;
	const { t } = useTranslation();

	const { translationKey } = AllDateFilters[selectedPeriod.applied];

	return (
		<>
			<DropdownContainer
				clickHandler={(e: MouseEvent) => {
					e.stopPropagation();
					dispatch(isDateFilterOpen ? FilterDispatch.close : FilterDispatch.openDate);
				}}
				isOpened={isDateFilterOpen}
				filterName={t(translationKey)}
				iconName="arrowDown"
				closeFilter={() => dispatch(FilterDispatch.close)}
			>
				<DateDropdown
					isOpened={isDateFilterOpen}
					closeFilter={() => dispatch(FilterDispatch.close)}
					selectedPeriod={selectedPeriod}
					filterOption={filterOption}
					page={page}
				/>
			</DropdownContainer>

			<DropdownContainer
				clickHandler={(e: MouseEvent) => {
					e.stopPropagation();
					dispatch(isEventFilterOpen ? FilterDispatch.close : FilterDispatch.openEvent);
				}}
				isOpened={isEventFilterOpen}
				filterName={t('filter-desktopFilters-events')}
				iconName="arrowDown"
				closeFilter={() => dispatch(FilterDispatch.close)}
			>
				<EventsSelected page={page} />
				<EventDropdown
					isOpened={isEventFilterOpen}
					closeFilter={() => dispatch(FilterDispatch.close)}
					eventQueryObj={eventQueryObj}
					perPageState={perPageState}
					page={page}
				/>
			</DropdownContainer>
		</>
	);
};
