import styled from 'styled-components';

export const ContentWrapper = styled.div`
	padding: 0 0 6% 3%;
	width: calc(100vw - 3%);

	display: flex;
	flex-direction: column;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100vw;
		padding: 0;
	}
`;

export const Padding = styled.div`
	margin-top: 32px;
`;
