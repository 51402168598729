import { FC, memo } from 'react';
import { NewEvent } from './NewEvent';
import { AddEvent } from './AddEvent';
import { CreateEventProps } from './types';

const CreateEvent: FC<CreateEventProps> = ({ hasEvents }: CreateEventProps) => (
	<>{hasEvents ? <NewEvent /> : <AddEvent />}</>
);

const memorizedCreateEvent: FC<CreateEventProps> = memo(CreateEvent);

export { memorizedCreateEvent as CreateEvent };
