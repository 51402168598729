import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectType } from './components/SelectType';
import { MainLayout } from 'layouts/MainLayout';
import { CreateEventForm } from './components/CreateEventForm';
import { Text } from 'features/common/components/Text';
import { ModalCancel } from './components/ModalCancel';
import { EventMode } from 'utils/types';

import { BackIcon, FieldSet, ScreenTitle } from './CreateEvent.styles';

export const CreateEvent = () => {
	const { t } = useTranslation();
	const [eventMode, setEventMode] = useState<EventMode | undefined>();

	return (
		<MainLayout>
			<ScreenTitle>
				<BackIcon iconName="arrowLeft" onClick={() => window.history.back()} />
				<Text data-cy="title">{t('create-event')}</Text>
				<ModalCancel />
			</ScreenTitle>
			<FieldSet>
				<SelectType eventModeState={{ eventMode, setEventMode }} />
				{eventMode && <CreateEventForm eventMode={eventMode} />}
			</FieldSet>
		</MainLayout>
	);
};
