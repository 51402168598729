import { useState } from 'react';
import { createPortal } from 'react-dom';
import { NanosCustomIframeProps } from '../../types';

import * as Styled from './CustomIframe.styles';

export const CustomIframe = ({ children, ...props }: NanosCustomIframeProps) => {
	const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);

	const mountNode = contentRef?.contentWindow?.document?.body;

	return (
		<Styled.Iframe {...props} ref={setContentRef}>
			{mountNode && createPortal(children, mountNode)}
		</Styled.Iframe>
	);
};
