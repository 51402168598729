import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from './Table';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { OUTLINED } from 'features/common/components/Button/constants';
import { LoadMoreProps } from './types';

export const LoadMore: FC<LoadMoreProps> = ({ isDisabled, handleClick }) => {
	const { t } = useTranslation();
	return (
		<FlexContainer justifyContent="center" margin="32px 0">
			<Table.Button
				category={OUTLINED}
				text={t('button-load-more')}
				onClick={handleClick}
				disabled={isDisabled}
				type="button"
			/>
		</FlexContainer>
	);
};
