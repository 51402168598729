import { ExtendedColors } from 'theme/styled';
import { ReferenceType } from 'utils/types';

export const EVENT_KEY = 'EVENT';
export const NEW_KEY = 'NEW';
export const TICKET_KEY = 'TICKET';
export const UNLIMITED_KEY = 'UNLIMITED';

export interface PromoCodeCardProps extends BackgroundCardProps {
	name: string;
	id: number;
	type: string;
	used: number;
	amount: number;
	references: ReferenceType[];
	isActive: boolean;
	onClick?: () => void;
	isPercentage?: boolean;
}

export interface DiscountColorProps {
	type: string;
}

export interface BackgroundCardProps {
	background?: ExtendedColors;
}

export interface PromoCodeAddCardProps {
	fromDashboard?: boolean;
}
