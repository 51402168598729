import { Option } from 'features/common/components/Form/types';
import i18n from 'locals/i18n';

export const gateOptions: Option[] = [
	{ label: i18n.t('createEvent-gateTime-always'), value: '' },
	{ label: i18n.t('createEvent-gateTime-hours', { hours: 3, s: 's' }), value: -3 },
	{ label: i18n.t('createEvent-gateTime-hours', { hours: 2, s: 's' }), value: -2 },
	{ label: i18n.t('createEvent-gateTime-hours', { hours: 1 }), value: -1 },
	{ label: i18n.t('createEvent-gateTime-minutes', { minutes: 45 }), value: -0.75 },
	{ label: i18n.t('createEvent-gateTime-minutes', { minutes: 30 }), value: -0.5 },
	{ label: i18n.t('createEvent-gateTime-minutes', { minutes: 15 }), value: -0.25 },
];

export const ticketingModeOptions: Option[] = [
	{ label: i18n.t('createEvent-ticketMode-dynamic'), value: 'dynamic' },
	{ label: i18n.t('createEvent-ticketMode-static'), value: 'static' },
];
