import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Text as Title } from 'features/common/components/Text';
import { EventTitleProps } from './types';
import { useAppDispatch } from 'store/hooks';
import { updatePartnerName } from 'features/Home/services';
import { getData } from 'services/api';
import { Logo } from '../CompanyLogo/Logo';

import { MainWrapper, TitleInput, Wrapper, Icon } from './EventTitle.styles';

const EventTitle: FC<EventTitleProps> = ({ hasLogo }: EventTitleProps) => {
	const { data } = useQuery(['partner'], () => getData('/partners/me'));
	const dispatch = useAppDispatch();
	const [isOpened, setIsOpened] = useState<boolean>(false);
	const [value, setValue] = useState<string>('initial Title');

	useEffect(() => {
		if (data?.name) setValue(data?.name);
	}, [data]);

	const onClickTitle = () => {
		if (isOpened) {
			dispatch(updatePartnerName(value));
		}
		setIsOpened((prevState) => !prevState);
	};

	const onChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	};

	const title = isOpened ? (
		<TitleInput value={value} onChange={onChangeTitle} />
	) : (
		<div style={{ cursor: 'pointer' }} onClick={onClickTitle}>
			<Title element="h2">{value}</Title>
		</div>
	);

	return (
		<MainWrapper>
			<Wrapper hasLogo={hasLogo}>
				<Logo />
				{title}
				<Icon iconName="pencil" isOpened={isOpened} onClick={onClickTitle} />
			</Wrapper>
		</MainWrapper>
	);
};

const memorizedEventTitle: FC<EventTitleProps> = memo(EventTitle);

export { memorizedEventTitle as EventTitle };
