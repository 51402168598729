import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from 'store';
import { EventOptionProps } from '../../types';
import { allPages } from 'features/common/hooks/useFilterButtonClick';

import { CheckboxButton } from 'features/common/components/CheckboxButton/CheckBoxButton.styles';
import { Option } from '../../RTKFilter.styles';

export const EventOption: FC<EventOptionProps> = ({ option, className, page }) => {
	const { unapplied } = useSelector((state: RootState) => {
		const sliceName = allPages[page].sliceName;
		return state[sliceName].selectedEvents;
	});

	const dispatch = useDispatch();

	return (
		<Option className={className} onClick={(e) => e.stopPropagation()}>
			<CheckboxButton
				type="checkbox"
				checked={unapplied[option.id] === true}
				name={option.name}
				value={option.name}
				onChange={() => dispatch(allPages[page].eventUnapplied(option.id))}
			/>
			{option.name}
		</Option>
	);
};
