import { ControlledElements } from './types';

export const CONTROLLED_PREVIEW_ELEMENTS: ControlledElements[] = [
	{
		name: 'focus',
		htmlId: 'eventButton',
		type: 'link',
	},
	{
		name: 'themeColor',
		htmlClass: 'set-background-color',
		htmlId: '',
		type: 'color',
	},
	{
		name: 'headerText',
		htmlId: 'header',
		type: 'text',
	},
	{
		htmlId: 'title',
		name: 'title',
		type: 'text',
	},
	{
		htmlId: 'companyLogo',
		name: 'logo',
		type: 'toggleOnly',
	},
	{
		htmlId: 'banner',
		name: 'headerImages',
		type: 'image',
	},
	{
		name: 'bodyText',
		htmlId: 'description',
		type: 'text',
	},
	{
		htmlId: 'eventButton',
		name: 'viewEventButton',
		type: 'toggleOnly',
	},
	{
		name: 'customerSupportContact',
		htmlClass: 'hide-support-contact',
		htmlId: 'supportContact',
		type: 'text',
	},
];
