import styled from 'styled-components';

import {
	LOGO_CONTAINER_DIAMETER,
	LOGO_CONTAINER_DIAMETER_MOBILE,
	LOGO_DIAMETER,
	LOGO_DIAMETER_MOBILE,
} from '../../../../constants';

export const Image = styled.img`
	object-fit: contain;
	height: ${LOGO_CONTAINER_DIAMETER - 52}px;
	width: ${LOGO_CONTAINER_DIAMETER - 52}px;

	&:hover {
		cursor: pointer;
		background: ${({ theme }) => theme.colors.blackGrey2};
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: ${LOGO_CONTAINER_DIAMETER_MOBILE - 28}px;
		width: ${LOGO_CONTAINER_DIAMETER_MOBILE - 28}px;
	}
`;

export const HoverImage = styled.img`
	position: absolute;
	height: ${LOGO_DIAMETER}px;
	width: ${LOGO_DIAMETER}px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: ${LOGO_DIAMETER_MOBILE}px;
		width: ${LOGO_DIAMETER_MOBILE}px;
	}
`;

export const Container = styled.div`
	background: ${({ theme }) => theme.colors.greyBlue};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	left: 4px;
	top: 4px;
	height: ${LOGO_DIAMETER}px;
	width: ${LOGO_DIAMETER}px;

	&:hover {
		cursor: pointer;
		background: ${({ theme }) => theme.colors.blackGrey2};
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		position: absolute;
		height: ${LOGO_DIAMETER_MOBILE}px;
		width: ${LOGO_DIAMETER_MOBILE}px;
	}
`;
