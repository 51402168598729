import styled from 'styled-components';

export const ContentWrapper = styled.div`
	padding: 0 6% 6% 6%;
	width: 100vw;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 0;
	}
`;

export const Padding = styled.div`
	padding-left: 6%;
	padding-right: 3%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 3%;
	}
`;

export const ContainerTabs = styled(Padding)`
	padding-bottom: 15px;
	background: ${({ theme }) => theme.colors.black};
	margin-bottom: 32px;
`;

export const AnalyticsContainer = styled(Padding)`
	padding-top: 32px;
	padding-bottom: 15px;
	background: ${({ theme }) => theme.colors.black};
	margin-bottom: 32px;
`;

export const Root = styled(ContainerTabs)`
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 8px;
`;

export const ContainerTabsPieCharts = styled.div`
	display: flex;
	flex-direction: row;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
	}
`;

export const Divider = styled('div')`
	border: 1px solid ${({ theme }) => theme.colors.lightGrey5};
	width: 100%;
	margin-top: 32px;
	margin-bottom: 22px;
`;

export const SummaryCardsContainer = styled.div`
	display: flex;
	flex-direction: row;
	overflow-x: auto;
	overflow-y: hidden;
	justify-content: space-around;
	width: 100%;
	padding: 15px;
	padding-left: 85px;
	padding-right: 25px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		justify-content: flex-start;
	}
`;

export const SmallTicketsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	width: 100%;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		grid-template-columns: 1fr;
	}
`;

export const Title = styled.div`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 24px;
	line-height: 36px;
	margin-left: 23px;
	margin-top: 19px;
`;
