import { FC } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useFormContext } from 'react-hook-form';
// import { useQuery } from '@tanstack/react-query';
// import { useParams } from 'react-router-dom';

// import { Form } from 'features/common/components/Form';
// import { handleTotalPrice } from 'features/CreateTicket/components/Steps/TicketDetails/utils';
// import { TicketDetailsParams } from 'features/TicketDetails/types';
// TODO: Uncomment when fees are ready
// import { ToolTip } from 'features/common/components/InfoTooltip';
// import { feeOptions } from 'utils/constants';
// import { getData } from 'services/api';
import { RequiredFieldsProps } from 'utils/types';
import { ImageSection } from '../ImageSection/ImageSection';

// TODO: Uncomment when fees are ready
// import { Icon } from './RightForm.styles';

const RightForm: FC<RequiredFieldsProps> = ({ isFromRequiredFields, ticket }: RequiredFieldsProps) => {
	// const { t } = useTranslation();
	// const { id }: TicketDetailsParams = useParams();
	// const { watch } = useFormContext();

	// const { data: taxesData } = useQuery({
	// 	queryKey: ['ticketTaxes'],
	// 	queryFn: () => getData(`/public/ticketing/events/taxes/${id}`),
	// });

	return (
		<>
			{/* TODO: Uncomment when fees are ready */}
			{/* <Form.RadioButtonList
				id="buyerAbsorbPaymentFee"
				name="buyerAbsorbPaymentFee"
				label={t('ticketDetails-paymentProcessingFee')}
				options={feeOptions}
				Tooltip={() => (
					<ToolTip
						className="top"
						title={t('ticketDetails-paymentProcessingFee-tooltip-title')}
						text={t('ticketDetails-paymentProcessingFee-tooltip')}
					>
						<Icon iconName="help" />
					</ToolTip>
				)}
			/>
			<Form.RadioButtonList
				id="buyerAbsorbTixologistFee"
				name="buyerAbsorbTixologistFee"
				label={t('ticketDetails-tixologistFee')}
				options={feeOptions}
				Tooltip={() => (
					<ToolTip
						className="left"
						title={t('ticketDetails-tixologistFee-tooltip-title')}
						text={t('ticketDetails-tixologistFee-tooltip')}
					>
						<Icon iconName="help" />
					</ToolTip>
				)}
			/> */}
			{/* <Form.Input
				id="totalPrice"
				name="totalPrice"
				info={t('ticketDetails-priceBuyerPays')}
				disabled
				value={`$${taxesData && handleTotalPrice({ watch, taxesData })}`}
			/> */}
			<ImageSection isFromRequiredFields={isFromRequiredFields} ticket={ticket} />
		</>
	);
};

export { RightForm };
