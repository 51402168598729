import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'features/common/components/Modal/Modal';
import { Icon as Trash } from 'features/common/components/Icon';
import { useParams } from 'react-router-dom';

import {
	QUATERNARY,
	TERTIARY,
} from 'features/common/components/Button/constants';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useEditPromoCodeApi } from 'hooks/useEditPromoCode';
import { IdParamsType } from 'utils/types';

import {
	ContainerActionButtons,
	ContainerBody,
	ModalButton,
	Icon,
	Text,
	Button,
	DeleteButton,
} from './ModalDelete.styles';

const ModalDelete: FC = () => {
	const { id }: IdParamsType = useParams();
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const [showModal, setShowModal] = useState(false);

	const { mutateDeletePromoCode } = useEditPromoCodeApi();

	const closeModal = () => setShowModal(false);
	const deletePromoCode = () => mutateDeletePromoCode({ id });
	const openModal = () => setShowModal(true);

	return showModal ? (
		<Modal onClose={closeModal}>
			<>
				<Modal.Header>{t('promoCode-modal-delete-title')}</Modal.Header>
				<Modal.Body>
					<ContainerBody>
						<Icon iconName="trashGrey" />
						<Text element="p">{t('promoCode-modal-delete-message')}</Text>
					</ContainerBody>
				</Modal.Body>
				<Modal.ActionButtons>
					<ContainerActionButtons>
						<ModalButton
							category={QUATERNARY}
							onClick={closeModal}
							text={t('button-cancel')}
							type="button"
						/>
						<ModalButton
							category={TERTIARY}
							onClick={deletePromoCode}
							text={t('button-delete')}
							type="button"
						/>
					</ContainerActionButtons>
				</Modal.ActionButtons>
			</>
		</Modal>
	) : isLargeScreen ? (
		<Button
			type="button"
			category={TERTIARY}
			onClick={openModal}
			text={t('promoCode-modal-delete-button')}
		/>
	) : (
		<DeleteButton type="button" onClick={openModal}>
			<Trash iconName="trash" />
		</DeleteButton>
	);
};

export { ModalDelete };
