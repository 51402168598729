import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Form } from 'features/common/components/Form';
import { AuthLayout } from 'layouts/AuthLayout';
import { CreateAccountForm } from './CreateAccountForm';
import {
	CREATE_ACCOUNT_ERRORS,
	defaultErrors,
	defaultGoogleValues,
	defaultValues,
	validationGoogleSchema,
	validationSchema,
	validationSingUpSchema,
} from './utils';
import { Canvas } from 'features/common/animations/Canvas';
import { createAccount, signUpRequest } from 'services/auth';
import { CreateAccountErrors, FormProps } from './types';
import { RootState } from 'store';
import { useAppDispatch } from 'store/hooks';
import { ROUTES } from 'features/routes';

import { RightContainer } from 'styles';

export const CreateAccount = () => {
	const { t } = useTranslation();
	const { reset } = useForm();
	const { push } = useHistory();
	const dispatch = useAppDispatch();
	const [errors, setErrors] = useState<CreateAccountErrors>(defaultErrors);

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema(t)),
	});
	const formGoogleMethods = useForm({
		resolver: yupResolver(validationGoogleSchema(t)),
	});

	const formSingUpMethods = useForm({
		resolver: yupResolver(validationSingUpSchema(t)),
	});

	const google = useSelector((state: RootState) => state.google);
	const login = useSelector((state: RootState) => state.login);

	const createAccountResults = (results: any, error: any) => {
		if (results) {
			setErrors(defaultErrors);
			reset(defaultValues);
			return push(ROUTES.HOME_PATH);
		} else if (error) {
			if (error.code === CREATE_ACCOUNT_ERRORS.EMAIL_ALREADY_IN_USE) {
				setErrors({
					partnerAlreadyExist: false,
					emailAlreadyExist: true,
					weakPassword: false,
				});
			}
			if (error.code === CREATE_ACCOUNT_ERRORS.WEAK_PASSWORD) {
				setErrors({
					partnerAlreadyExist: false,
					emailAlreadyExist: false,
					weakPassword: true,
				});
			}
			if (error.data === CREATE_ACCOUNT_ERRORS.PARTNER_ALREADY_EXISTS) {
				setErrors({
					partnerAlreadyExist: true,
					emailAlreadyExist: false,
					weakPassword: false,
				});
			}
		}
	};

	const signUpRequestResults = (results: any, error: any) => {
		if (results) {
			setErrors(defaultErrors);
			reset(defaultGoogleValues);
			push(ROUTES.HOME_PATH);
		} else {
			if (error.data === CREATE_ACCOUNT_ERRORS.PARTNER_ALREADY_EXISTS) {
				setErrors({
					partnerAlreadyExist: true,
					emailAlreadyExist: false,
					weakPassword: false,
				});
			}
		}
	};
	const handleDispatch = (email: string, password: string, name: string, organizationName: string) => {
		if (google.isNewGoogleAccount)
			dispatch(signUpRequest(google.name, google.email, organizationName, signUpRequestResults));
		else if (login.isAlreadyCreated) dispatch(signUpRequest(name, login.email, organizationName, signUpRequestResults));
		else dispatch(createAccount(email, password, name, organizationName, createAccountResults));
	};
	const onSubmit = ({ email, password, name, organizationName }: FormProps) => {
		handleDispatch(email, password, name, organizationName);
	};

	const handleFormMethods = () => {
		if (google.isNewGoogleAccount) return formGoogleMethods;
		else if (login.isAlreadyCreated) return formSingUpMethods;
		else return formMethods;
	};

	return (
		<AuthLayout>
			<Form formMethods={handleFormMethods()} onSubmit={onSubmit}>
				<RightContainer>
					<Canvas />
					<CreateAccountForm errors={errors} />
				</RightContainer>
			</Form>
		</AuthLayout>
	);
};
