import styled, { DefaultTheme } from 'styled-components';
import { Text } from 'features/common/components/Text';
import { StatusStylesProps } from './types';
import { StatusType } from '../types';
import { COMPLETED, LIVE, SCHEDULED } from '../../../../common/components/Filters/LocalFilter/constants';

const calculateColor = (theme: DefaultTheme, status: StatusType) => {
	switch (status) {
		case SCHEDULED:
			return theme.colors.blue;
		case COMPLETED:
			return theme.colors.blue;
		case LIVE:
			return theme.colors.green;
		default:
			return theme.colors.crimson;
	}
};

const calculateLeftPosition = (status: StatusType) => {
	switch (status) {
		case SCHEDULED:
			return '290px';
		case COMPLETED:
			return '288px';
		case LIVE:
			return '340px';
		default:
			return '310px';
	}
};

export const Container = styled.div`
	cursor: pointer;
	width: 420px;
	height: 415px;
	background: ${({ theme }) => theme.colors.grey2};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 340px;
	}
`;

export const ImageContainer = styled.div`
	position: relative;
	height: 270px;
	width: 100%;
	border-radius: 4px 4px 0px 0px;
	overflow: hidden;
`;

export const StatusContainer = styled.div<StatusStylesProps>`
	position: absolute;
	display: flex;
	align-items: center;
	padding: 4px 12px;
	gap: 4px;
	height: 25px;
	left: ${({ status }) => calculateLeftPosition(status)};
	top: 22px;
	background: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.black};
	border-radius: 1000px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		left: unset;
		right: 20px;
	}
`;

export const StatusColor = styled.div<StatusStylesProps>`
	width: 6px;
	height: 6px;
	margin-right: 4px;
	border-radius: 100%;
	background: ${({ theme, status }) => calculateColor(theme, status)};
`;

export const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
`;

export const InfoContainer = styled.div`
	display: flex;
	height: 145px;
	padding: 16px;
	flex-direction: column;
	box-sizing: border-box;
	gap: 4px;
`;

export const Title = styled(Text)`
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

export const Info = styled(Text)`
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
