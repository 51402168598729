import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PromoCodesForm as PromoCodesFormComponent } from '../features/PromoCodesForm';
import { IPromoCodesFormParams } from 'features/PromoCodesForm/types';
import { sendGAPage } from 'utils/utils';

const PromoCodesForm: React.FC = () => {
	const { type } = useParams<IPromoCodesFormParams>();
	const { pathname } = useLocation();

	useEffect(() => {
		sendGAPage({ page: pathname, title: `Promo Codes ${type}` });
	}, []);
	return <PromoCodesFormComponent />;
};

export default PromoCodesForm;
