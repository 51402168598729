import { useState } from 'react';

import { TicketTag } from './TicketTag';
import { Icon } from 'features/common/components/Icon';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { EventTagProps } from './types';

import { Container, Text, Column } from './SpecificPromocodes.styles';

export const EventTag: React.FC<EventTagProps> = ({
	title,
	tickets,
	unlimitedAll,
}: EventTagProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const handleClassName = () => (isOpen ? 'isOpen' : '');

	return (
		<Container className={handleClassName()}>
			<FlexContainer justifyContent="space-between">
				<Text>{title}</Text>
				<Icon
					iconName="arrowDown"
					onClick={() => setIsOpen(!isOpen)}
					className={`${handleClassName()} pointer`}
				/>
			</FlexContainer>
			{isOpen &&
				tickets.map(({ ID, name }, index) => (
					<Column key={ID}>
						<TicketTag
							title={name}
							isLastElement={index === tickets.length - 1}
							id={ID}
							unlimitedAll={unlimitedAll}
						/>
					</Column>
				))}
		</Container>
	);
};
