import { useEffect, useRef } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { EventProps } from 'features/Home/components/Events/types';
import { getResponse } from 'services/api';
import { FilterOptionType } from 'features/common/components/Filters/LocalFilter/types';

interface UseGetEventsProps {
	selectedOption?: FilterOptionType;
	searchQuery: string;
	per_page: number;
}

export const useGetEvents = ({ selectedOption, searchQuery, per_page }: UseGetEventsProps) => {
	const headersRef = useRef(0);
	const fetchSearchEvents = async (pageParam: number): Promise<EventProps[]> => {
		const { data, headers } = await getResponse(
			`events?date=${searchQuery ? '' : selectedOption?.value}&search=${searchQuery}&per_page=${per_page * pageParam}`
		);
		headersRef.current = Number(headers['x-total-count']);
		return data;
	};

	const {
		data: events,
		fetchNextPage,
		isLoading,
	} = useInfiniteQuery(
		[`events-with-search-&-filter-${selectedOption?.value}`, searchQuery],
		({ pageParam = 1 }) => fetchSearchEvents(pageParam),
		{
			getNextPageParam: (_lastPage, allPages) => {
				const maxPages = Math.round(headersRef.current / per_page) + 1;
				const nextPage = allPages.length + 1;
				return nextPage <= maxPages ? nextPage : undefined;
			},
		}
	);

	useEffect(() => {
		let fetching = false;
		const onScroll = async (event: any) => {
			const { scrollHeight, scrollTop, clientHeight } = event.target.scrollingElement;

			if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
				fetching = true;
				await fetchNextPage();
				fetching = false;
			}
		};

		document.addEventListener('scroll', onScroll);
		return () => {
			document.removeEventListener('scroll', onScroll);
		};
	}, []);

	const items = events?.pages[events?.pages?.length - 1];

	return { items, isLoading };
};
