import * as yup from 'yup';
import { camelizeKeys } from 'humps';

import { common } from 'theme';
import { CampaignBody, DataToProcess, FormFields, UseTranslation, IdObject } from './types';

export const schema = yup.object().shape({
	name: yup.string().required('Campaign name is required'),
	audience: yup.boolean().isTrue('Target audience is required'),
	customerSupportContact: yup.string().email('must be valid email').nullable(),
});

export const defaultValues = (t: UseTranslation): FormFields => ({
	allEvents: true,
	totalEmails: 0,
	name: '',
	cohorts: [],
	events: [],
	focus: [],
	themeColor: common.colors.green,
	headerText: t('marketing-campaignForm-defaultHeader'),
	headerTextEnabled: true,
	title: t('marketing-campaignForm-defaultTitle'),
	titleEnabled: true,
	logoEnabled: true,
	headerImages: [],
	headerImagesEnabled: true,
	viewEventButtonEnabled: true,
	bodyText: t('marketing-campaignForm-defaultBody'),
	bodyTextEnabled: true,
	// TODO: use footer enabled form field once we add social media
	// footerEnabled: false,
	customerSupportContact: '',
	customerSupportContactEnabled: false,
});

export const convertResToForm = (t: UseTranslation, res?: CampaignBody): FormFields => {
	if (res) {
		const events = res?.events?.map((event) => event.ID) || [];
		const cohorts = res?.cohorts?.map((cohort) => cohort.ID) || [];
		const focus = res?.focus?.map((focus) => focus.ID) || [];
		return camelizeKeys({ ...res, events, cohorts, focus }) as FormFields;
	}
	return defaultValues(t);
};

export const createArrayOfObjects = (array: number[]): IdObject[] => array.map((value) => ({ ID: value }));

export const processDataForReq = ({ cohortIds, eventIds, focusIds }: DataToProcess) => {
	const cohorts = createArrayOfObjects(cohortIds);
	const events = createArrayOfObjects(eventIds);
	const focus = createArrayOfObjects(focusIds);
	return { cohorts, events, focus };
};
