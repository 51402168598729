import { FlexContainer } from 'features/common/components/FlexContainer';
import { BankingInfo } from './BankingInfo';
import { MyFinancesProps } from '../types';

import { PayoutsTableSection } from './PayoutsTableSection';

const Payouts = ({ orgData }: MyFinancesProps) => (
	<FlexContainer flexDirection="column">
		<BankingInfo orgData={orgData} />
		<PayoutsTableSection />
	</FlexContainer>
);

export { Payouts };
