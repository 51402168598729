import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { FormSide } from 'features/CustomFields/CustomFields.styles';

export const Wrapper = styled(FormSide)`
	padding: 46px 150px;
	overflow: auto;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 18px;
	}
`;

export const SelectsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 20px;
`;

export const Label = styled(Text)`
	font-size: 15px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		line-height: 24px;
	}
`;

export const CustomQuestionContainer = styled.div`
	margin-top: 20px;
`;
