import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'features/routes';
import { CampaignInfoProps } from '../../types';
import { getLabels } from './utils';
import { Icon } from 'features/common/components/Icon';

import { CampaignContainer, InfoFlex, Label, LabeledGroup, InfoText } from './CampaignInfo.styles';

export const CampaignInfo = ({ campaignData: { id, name, emailsSent, focus, status } }: CampaignInfoProps) => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const isDraft = status === 'DRAFT';

	return (
		<CampaignContainer
			isDraft={isDraft}
			onClick={() => {
				if (isDraft) push(ROUTES.EDIT_CAMPAIGN_PATH.replace(':campaignId', `${id}`));
			}}
		>
			<InfoFlex>
				<InfoText extraWidth>{name}</InfoText>
				{getLabels(emailsSent, focus, status, t).map(({ extraWidth, label, data }) => (
					<LabeledGroup key={label} extraWidth={extraWidth}>
						<Label>{t(`marketing-campaignInfo-${label}`)}</Label>
						<InfoText>{data}</InfoText>
					</LabeledGroup>
				))}
			</InfoFlex>
			{isDraft && <Icon iconName="pencil" />}
		</CampaignContainer>
	);
};
