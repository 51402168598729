import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { setLoading } from 'store/slices/AuthSlice';
import { AuthLayout } from 'layouts/AuthLayout';

import { BodyText, Container, DownloadButton, RightContainer, Title } from './ScannerAccount.styles';

const iosLink = 'https://apps.apple.com/uy/app/tixologi-producer/id6443567562';
const androidLink = 'https://play.google.com/store/apps/details?id=com.tixologipartner';

const ScannerAccount = () => {
	const { t } = useTranslation();

	useEffect(() => {
		setLoading(false);
	}, []);

	const navigateTo = (platform: string) => () => {
		window.open(platform === 'ios' ? iosLink : androidLink, '_blank');
	};

	return (
		<AuthLayout>
			<RightContainer>
				<Container>
					<Title>{t('scannerAccount-title')}</Title>

					<BodyText>
						<Trans
							i18nKey="scannerAccount-body"
							components={{
								span: <span></span>,
							}}
						/>
					</BodyText>

					<DownloadButton text={t('scannerAccount-button-ios')} category="primary" onClick={navigateTo('ios')} />

					<DownloadButton
						text={t('scannerAccount-button-android')}
						category="primary"
						onClick={navigateTo('android')}
					/>
				</Container>
			</RightContainer>
		</AuthLayout>
	);
};

export { ScannerAccount };
