import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const Orchestator = () => {
	const history = useHistory();
	const { search } = useLocation();
	const mode = new URLSearchParams(search).get('mode');

	useEffect(() => {
		if (mode === 'resetPassword') {
			const oobCode = new URLSearchParams(search).get('oobCode');
			history.push(`/reset-password?oobCode=${oobCode}`);
		} else {
			const continueURL = new URLSearchParams(search).get('continueUrl') || '';
			const searchParams = new URLSearchParams(new URL(continueURL).search);
			const email = searchParams.get('email') || '';
			const userId = searchParams.get('user_id') || '';
			const partnerId = searchParams.get('partner_id') || '';
			const invitedBy = searchParams.get('invited_by');
			const partnerName = searchParams.get('partner_name');
			localStorage.setItem('partnerId', partnerId);
			localStorage.setItem('userId', userId);
			localStorage.setItem('url', window.location.href);
			history.push(
				`/member-login?user_id=${userId}&partner_id=${partnerId}&email=${email}&invited_by=${invitedBy}&partner_name=${partnerName}`
			);
		}
	}, [history, mode, search]);

	return null;
};

export default Orchestator;
