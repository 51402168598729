import api, { getData } from 'services/api';
import { UpdateWebsiteContentProps } from './types';
import { EventProps } from 'features/Home/components/Events/types';
import { toastError, toastSuccess } from 'hooks/useToastNotification/useToastNotification';

export const updateContent = async ({ attributes, eventId }: UpdateWebsiteContentProps) => {
	try {
		await api.put(`/events/${eventId}`, {
			event_id: eventId,
			images: attributes.mediaContent.eventImage[0]?.url
				? [{ ...attributes.mediaContent.eventImage[0], image: attributes.mediaContent.eventImage[0]?.url }]
				: [],
			media: attributes.mediaContent.media,
		});
		toastSuccess('website-default-eventsite-success');
	} catch {
		toastError('website-default-eventsite-error');
	}
};

export const getContent = async (id: string) => {
	try {
		const event: EventProps = await getData(`public/ticketing/events/${id}`);

		return {
			eventName: event.name,
			mediaContent: {
				eventImage: event.images.length > 0 ? [{ url: event?.images[0]?.image, name: 'event image' }] : [],
				media: Array.isArray(event?.media) ? event?.media : [],
			},
		};
	} catch (error) {
		toastError('generic-error-loading');
	}
};
