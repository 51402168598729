import { useState } from 'react';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getData, post } from 'services/api';
import { QueryResultProps } from 'features/Orders/types';
import { COMPED_FILTER_OPTIONS } from '../constants';
import { IdParamsType } from 'utils/types';
import { useToastNotification } from 'hooks/useToastNotification';
import { queryClient } from 'index';

export const useCompedTicketsPage = () => {
	const [selectedPeriod, setSelectedPeriod] = useState(COMPED_FILTER_OPTIONS[0]);
	const { showNotification, TYPES } = useToastNotification();
	const resultsPerPage = 30;
	const { id } = useParams<IdParamsType>();
	const [searchText, setSearchText] = useState('');

	const initialValueCancelModal = { show: false, id: 0 };
	const [showCancelModal, setShowCancelModal] = useState(initialValueCancelModal);
	const openCancelModal = (id: number) => setShowCancelModal({ show: true, id });
	const closeCancelModal = () => setShowCancelModal(initialValueCancelModal);

	const compedTicketsQueryObject = useInfiniteQuery({
		queryKey: ['orders', selectedPeriod, searchText],
		queryFn: ({ pageParam = 1 }) =>
			getData(
				`/orders?date=${selectedPeriod.value}&page=${pageParam}&per_page=${resultsPerPage}&search=${searchText}&comped=true&event_ids=${id}`
			),
		getNextPageParam: (lastPage, pages) => (lastPage.data.length >= resultsPerPage ? pages.length + 1 : undefined),
	}) as QueryResultProps;

	const { mutate: onCancelInvite } = useMutation(() => post(`/tickets/cancel-comp/${showCancelModal.id}`), {
		onSuccess: () => {
			closeCancelModal();
			queryClient.refetchQueries(['orders']);
		},
		onError: () => {
			showNotification({
				message: 'Error',
				type: TYPES.ERROR,
			});
			closeCancelModal();
		},
	});
	return {
		selectedPeriod,
		setSelectedPeriod,
		setSearchText,
		compedTicketsQueryObject,
		showCancelModal,
		openCancelModal,
		closeCancelModal,
		onCancelInvite,
	};
};
