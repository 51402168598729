import { setLastEvent } from 'store/slices/lastEventSlice';
import { setLastMultiDay } from 'store/slices/lastMultiDayEventSlice';
import { AppDispatch } from 'store';
import { TicketType } from 'features/common/types';
import { UpdateQuantityType, UpdateMultidayQuantityType } from './types';

export const updateQuantityInData =
	({ newQuantity, ticketId, event }: UpdateQuantityType) =>
	(dispatch: AppDispatch) => {
		const newTicketTypes = event.ticketTypes.map((item: TicketType) => {
			if (ticketId === item.ID)
				return {
					...item,
					quantity: newQuantity,
				};

			return item;
		});
		const newData = { ...event, ticketTypes: newTicketTypes };
		dispatch(setLastEvent(newData));
	};

export const updateQuantityInMultiday =
	({ newQuantity, dayId, multiday, ticketId }: UpdateMultidayQuantityType) =>
	(dispatch: AppDispatch) => {
		const newMultiday = multiday.map((item) => {
			if (item.day.id === dayId)
				return {
					...item,
					ticketTypes: item.ticketTypes.map((ticket) => {
						if (ticket.ID === ticketId) {
							return {
								...ticket,
								quantity: newQuantity,
							};
						}
						return ticket;
					}),
				};
			return item;
		});
		dispatch(setLastMultiDay(newMultiday));
	};
