import { FC } from 'react';

import { Form } from 'features/common/components/Form';
import { UploadImageCard } from 'features/common/components/UploadCardContainer';
import { ButtonGroupProps, TicketOptionsProps } from './types';
import { ticketOptions } from '../../utils';

const ButtonGroup: FC<ButtonGroupProps> = ({ inputRef, setDisplayType }: ButtonGroupProps) => (
	<>
		{ticketOptions.map(({ icon, info, text, type }: TicketOptionsProps) => (
			<Form.FileInputButton
				key={text}
				id="ticketImage"
				name="ticketImage"
				accept="image/jpeg, image/png"
				inputRef={inputRef}
				Button={() => (
					<UploadImageCard
						key={type}
						icon={icon}
						info={info}
						inputRef={inputRef}
						setDisplayType={setDisplayType}
						size="S"
						text={text}
						type={type}
					/>
				)}
			/>
		))}
	</>
);

export { ButtonGroup };
