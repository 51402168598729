import { FlexContainer } from 'features/common/components/FlexContainer';
import { PromoCodeTag } from '../PromoCodeTag';

import { Price, Text } from '../OrderSummary.styles';
import { TicketType } from 'features/common/types';

export const TicketRow = ({ name, initialPrice, ID, quantity, promoCodeApplied, priceWithNoDiscount }: TicketType) => (
	<FlexContainer key={ID} justifyContent="space-between">
		<FlexContainer gap="8px">
			<Text>{`${name} (${quantity})`}</Text>
			{promoCodeApplied && <PromoCodeTag />}
		</FlexContainer>
		<FlexContainer flexDirection="column">
			{!!promoCodeApplied && <Price className="promoCodeApplied">${priceWithNoDiscount}</Price>}
			<Price>${initialPrice}</Price>
		</FlexContainer>
	</FlexContainer>
);
