import { DateTime } from 'luxon';

import { GetTemplateImage } from './types';
import fallbackImage from 'assets/images/fallbackTicket.svg';

export const defaultValues = {
	email: '',
	transactionId: '',
	total: '',
	fixedPrice: '',
	nuveiFees: '',
	tixologiFees: '',
	myTaxes: '',
	ticketsWithPC: [],
	PCId: '',
};

export const convertUTCToDate = (date: string, timezone: string, format: string) => {
	const parsedDate = DateTime.fromISO(date).setZone(timezone);
	return parsedDate.toFormat(format);
};

export const getTemplateImage = ({ thumbnail, image }: GetTemplateImage) => thumbnail || image || fallbackImage;

export const termsAndServiceURL = 'https://tixologi.com/terms-of-service/';
