import styled from 'styled-components';

export const TicketsContainer = styled.div`
	display: flex;
	max-height: 500px;
	max-width: 100%;
	align-items: stretch;
	padding: 10px 0;

	::-webkit-scrollbar {
		display: none;
	}
`;
