import styled from 'styled-components';

import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import { common } from 'theme';
import { ButtonContainerProps } from './types';

const backgroundColors = {
	primary: common.colors.greenGradient3,
	secondary: common.colors.grey15,
};

export const ButtonContainer = styled.button<ButtonContainerProps>`
	all: unset;
	width: fit-content;
	height: 40px;
	display: flex;
	cursor: pointer;
	border-radius: 21px;
	align-items: center;
	justify-content: center;
	font-family: ${({ theme }) => theme.fonts.primary};
	background: ${({ category }) => backgroundColors[category]};
	box-shadow: ${({ theme }) => theme.shadows.buttonWithIconShadow};
	gap: 8px;
	padding: 0 16px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-family: ${({ theme }) => theme.fonts.primary};
		font-size: 14px;
		font-weight: 600;
		height: 35px;
		line-height: 21px;
		letter-spacing: 0em;
		text-align: center;
		padding: 0 14px;

		img {
			height: 18px;
			width: 18px;
		}
	}
`;

export const Icon = styled(UnstyledIcon)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 18px;
		width: 18px;
	}
`;
