import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TicketsQuantityProps } from './types';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { InputText } from '../Input/Input.styles';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { ToolTip } from 'features/common/components/InfoTooltip';
import { Icon } from 'features/common/components/Icon';
import { ticketsQuantityTitle } from 'features/PromoCodesForm/utils';
import { onChangeShowTicketTypes } from 'features/PromoCodesForm/slice/PromoCodesFormSlice';
import { EventSpecificList } from '../EventSpecificList/EventSpecificList';
import { TicketsQuantityButton } from './components/TicketsQuantityButton';
import { RootState } from 'store';

import { Container } from './TicketsQuantity.styles';

const TicketsQuantity: React.FC<TicketsQuantityProps> = ({ type }: TicketsQuantityProps) => {
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const dispatch = useDispatch();
	const showTicketTypes = useSelector((state: RootState) => state.promoCodesForm.showTicketTypes);

	const handleOnClickContainer = () => {
		if (type === 'ticketSpecific' && !showTicketTypes) dispatch(onChangeShowTicketTypes());
	};

	return (
		<Container onClick={handleOnClickContainer}>
			{type === 'ticketSpecific' ? (
				<TicketsQuantityButton type={type} />
			) : (
				<>
					{type === 'unlimited' && (
						<>
							<FlexContainer flexDirection="row" gap="12px" alignItems="center">
								<InputText>{t(ticketsQuantityTitle[type])}</InputText>
								<ToolTip title={t('promoCode-Form-quantity-title')} text={t('promoCode-Form-quantity-description')}>
									<Icon iconName="help" />
								</ToolTip>
							</FlexContainer>
							<TicketsQuantityButton type={type} />
						</>
					)}
					{type === 'eventSpecific' && !isLargeScreen && <EventSpecificList />}
				</>
			)}
		</Container>
	);
};

export { TicketsQuantity };
