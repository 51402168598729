import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Color } from 'react-color-palette';

import { Colors } from 'features/common/components/Colors/Colors';
import { PaletteModal } from 'features/common/components/Colors/PaletteModal';
import { WebsiteSectionTitle } from '../../../WebsiteSectionTitle/WebsiteSectionTitle';
import { palette, selectColorStyleOptions } from './utils';
import { PaletteDataProps, PaletteKey } from './types';
import { sendGAEvent } from 'utils/utils';

import { Container, ContentContainer, Row } from './WebsiteColors.styles';

const paletteTitles: { [key in PaletteKey]: string } = {
	theme_color: 'Theme',
	header_color: 'Header',
	background_color: 'Background',
};

const WebsiteColors = () => {
	const { setValue, watch } = useFormContext();
	const { t } = useTranslation();
	const [toChange, setToChange] = useState<PaletteKey>(palette[0]);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const isDefaultId = 'themeColors.isDefault';
	const isDefaultStyles = watch(isDefaultId);
	const data: PaletteDataProps = watch('themeColors');
	const handleOpenModal = (title: PaletteKey) => {
		setToChange(title);
		setIsOpen(true);
		sendGAEvent({ category: 'WebPage', action: `palette ${title}` });
	};

	const handleSaveChanges = (newColor: Color) => {
		const newData = {
			[toChange]: newColor.hex,
		};
		setValue(`themeColors.${toChange.toLowerCase()}`, newData[toChange]);
		setIsOpen(false);
	};

	useEffect(() => {
		setValue('themeColors', data);
	}, []);

	return (
		<Container>
			<WebsiteSectionTitle
				title={t('website-colors-title')}
				setIsDefaultStyles={() => {
					setValue(isDefaultId, !isDefaultStyles);
				}}
				isDefaultStyles={isDefaultStyles}
				showTooltip
			/>
			<ContentContainer>
				<Row>
					{selectColorStyleOptions.map(({ title, text, image }) => (
						<Colors
							color={data?.[title]}
							handleOpenModal={() => handleOpenModal(title)}
							title={`${paletteTitles[title]} Color`}
							text={text}
							key={title}
							image={image}
						/>
					))}
				</Row>
			</ContentContainer>
			{data && isOpen && (
				<PaletteModal
					currentColor={data?.[toChange]}
					handleChange={handleSaveChanges}
					onClose={() => setIsOpen(false)}
				/>
			)}
		</Container>
	);
};

export { WebsiteColors };
