import styled from 'styled-components';

import { Label } from './Logo/Logo.styles';

export const Button = styled(Label)`
	align-items: center;
	background: ${({ theme }) => theme.colors.greenGradient3};
	border-radius: 6px;
	border-width: 0;
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 18px;
	font-weight: 500;
	height: 43px;
	justify-content: center;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: center;
	width: 180px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 11px;
		line-height: 15px;
		height: 27px;
		width: 111px;
	}
`;
