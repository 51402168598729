import { useEffect, useState } from 'react';

import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLastMultiDay } from 'store/slices/lastMultiDayEventSlice';

export const useGetLastEvent = () => {
	const [ticketTypesId, setTicketTypesId] = useState<number[] | undefined>([]);
	const [isMultiUpdated, setIsMultiUpdated] = useState<boolean>(false);
	const [isMultiDay, setIsMultiDay] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const { event } = useAppSelector(({ lastEvent }: RootState) => lastEvent);
	const { ticketsSelected } = useAppSelector(({ boxOffice }: RootState) => boxOffice);

	useEffect(() => {
		if (event) {
			const multiday = event.days.map((day) => ({
				day,
				ticketTypes: event.ticketTypes,
				timezone: event.timeZone,
				eventName: event.name,
				venue: event.streetAddress,
			}));

			const ticketsId = event.ticketTypes.map(({ ID }) => ID);

			setTicketTypesId(ticketsId);
			setIsMultiDay(multiday.length > 1);

			if (!isMultiUpdated && !ticketsSelected) {
				dispatch(setLastMultiDay(multiday));
				setIsMultiUpdated(true);
			}
		}
	}, [event]);

	return { event, ticketTypesId, isMultiDay };
};
