import { createContext, useContext, FC, useState, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import { dark } from './dark';
import { light } from './light';

export const ThemeContext = createContext({
	theme: 'light',
	toggle: () => {},
});

export const useTheme = () => {
	const { theme, toggle } = useContext(ThemeContext);

	return { theme: theme === 'light' ? light : dark, toggle, themeName: theme };
};

export const StyledThemeProvider: FC = ({ children }: any) => {
	const [actualTheme, setTheme] = useState('light');

	const toggle = () => {
		setTheme((theme) => (theme === 'light' ? 'dark' : 'light'));
	};

	const values = useMemo(
		() => ({
			theme: actualTheme,
			toggle,
		}),
		[toggle, actualTheme]
	);

	return (
		<ThemeContext.Provider value={values}>
			<ThemeProvider theme={actualTheme === 'light' ? light : dark}>
				{children}
			</ThemeProvider>
		</ThemeContext.Provider>
	);
};

export * from './common';
export * from './dark';
export * from './light';
