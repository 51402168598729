import styled from 'styled-components';

import { StdText } from 'features/common/components/Text/Text.styles';

export const Button = styled.button<{ selected: boolean }>`
	background-color: ${({ theme, selected }) => (selected ? theme.colors.green : theme.colors.lightGrey)};
	border: none;
	border-radius: 8px;
	cursor: pointer;
	padding: 24px;
	width: 50%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

export const Title = styled(StdText)<{ textAlign?: string }>`
	text-align: ${({ textAlign }) => textAlign || 'left'};
`;

export const Info = styled(StdText)`
	text-align: left;
	font-size: 14px;
	font-weight: 400;
`;

export const RowColumnFlex = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
	width: 100%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
	}
`;
