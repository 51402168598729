import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { ButtonWithIcon } from 'features/common/components/ButtonWithIcon';
import { Button } from 'features/common/components/Button';
import { ModalNeedHelp } from './components/ModalNeedHelp';
import { useAppSelector } from 'store/hooks';
import { generateCode } from './utils';
import { useResponsiveScreenXXS } from 'hooks/useWindowSize';

import { BackgroundSection, Title, Text } from 'features/Embed/Embed.styles';
import * as Styled from './EmbedInfo.styles';

const EmbedInfo = () => {
	const [showInstructions, setShowInstructions] = useState(false);
	const [showNeedHelp, setShowNeedHelp] = useState(false);
	const { t } = useTranslation();
	const { loggedUser } = useAppSelector((state) => state.auth);
	const isMobile = useResponsiveScreenXXS();

	const closeModalNeedHelp = () => setShowNeedHelp(false);
	const openModalNeedHelp = () => setShowNeedHelp(true);

	return (
		<BackgroundSection>
			<FlexContainer flexDirection="column">
				<Title>{t('embed')}</Title>
				<Text>{t('embedInfo-description')}</Text>
			</FlexContainer>
			<FlexContainer gap="12px" flexDirection={isMobile ? 'column' : 'row'}>
				<ButtonWithIcon
					type="button"
					label="Instructions"
					icon="arrowDown"
					category="secondary"
					onClick={() => setShowInstructions(!showInstructions)}
				/>
				<Styled.Anchor
					href={`${process.env.REACT_APP_WIDGET}/buy-tickets/partner/${loggedUser?.currentPartner.ID}`}
					target="_blank"
					rel="noreferrer"
					data-cy="view-embed"
				>
					{t('button-view-embed')} <Styled.Icon iconName="play-circle" />
				</Styled.Anchor>
			</FlexContainer>
			{showInstructions && (
				<FlexContainer gap="12px" flexDirection="column">
					<Text>{t('embedInfo-snippet')}</Text>
					<Styled.Pre>
						<code>{generateCode(String(loggedUser?.currentPartner.ID))}</code>
					</Styled.Pre>
					<Button text="Need help?" category="underlined" type="button" onClick={openModalNeedHelp} />
				</FlexContainer>
			)}
			{showNeedHelp && <ModalNeedHelp onClose={closeModalNeedHelp} />}
		</BackgroundSection>
	);
};

export { EmbedInfo };
