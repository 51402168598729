import { RoleOptionsType } from './types';

export const ADMIN = 'Admin';
export const EDITOR = 'Editor';
export const VIEW_ONLY = 'View Only';
export const SCANNER = 'Scanner';

export const ADMIN_QUERY = 'admin';
export const EDITOR_QUERY = 'editor';
export const VIEW_ONLY_QUERY = 'view_only';
export const SCANNER_QUERY = 'scanner';

export const MY_PROFILE_QUERY_KEY = 'Settings-MyProfile';

export const ROLE_OPTIONS: RoleOptionsType = [
	{ label: ADMIN, value: ADMIN_QUERY },
	{ label: EDITOR, value: EDITOR_QUERY },
	{ label: VIEW_ONLY, value: VIEW_ONLY_QUERY },
	{ label: SCANNER, value: SCANNER_QUERY },
];
