import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { handleLink } from 'utils/handleLink';
import { Page } from '../..';
import {
	ButtonContainer,
	SkipButton,
	StepTitle,
	NextButton,
} from '../Steps.styles';

import * as Styled from './ConfirmUpload.styles';

export const ConfirmUpload = () => {
	const { t } = useTranslation();
	const { watch, setValue } = useFormContext();

	const images = watch('images');

	const selectAnother = () => {
		setValue('images', []);
		handleLink('#6');
	};

	return (
		<Page id="7">
			<Styled.CenteredContainer>
				<StepTitle element="h3">
					{t('ticketDetails-ticket-ticketPreview')}
				</StepTitle>
				<Styled.Ticket src={images[0]?.url} />
				<ButtonContainer>
					<SkipButton type="button" onClick={selectAnother}>
						{t('ticketDetails-ticket-upload-selectOther')}
					</SkipButton>
					<NextButton type="submit">
						{t('ticketDetails-ticket-upload-next')}
					</NextButton>
				</ButtonContainer>
			</Styled.CenteredContainer>
		</Page>
	);
};
