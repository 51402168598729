import styled from 'styled-components';

import { DraggableContainer } from '../Reorder.styles';

export const FieldContainer = styled.div`
	margin: auto;
	display: flex;
	align-items: stretch;
	height: 100%;
	width: 100%;
	background: ${({ theme }) => theme.colors.whitePerl};

	${DraggableContainer}:not(.dragging-over) & {
		&:first-child {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}

		&:last-child {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}
`;

export const InputContainer = styled.div`
	min-height: 48px;
	width: 100%;
	color: ${({ theme }) => theme.colors.black};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0 15px 10px;
	box-sizing: border-box;
`;

export const CustomFieldButtonsContainer = styled.div`
	padding: 0 15px;
	display: flex;
	align-items: center;
`;

export const PencilButton = styled.button`
	all: unset;
	cursor: pointer;
	height: 25px;
`;
