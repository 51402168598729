import { createSlice } from '@reduxjs/toolkit';

import { EventSliceProps } from './types';

const initialLastEvent: EventSliceProps = {
	event: undefined,
};

const lastEvent = createSlice({
	name: 'lastEvent',
	initialState: initialLastEvent,
	reducers: {
		setLastEvent(state, action) {
			state.event = action.payload;
		},
	},
});

export const { setLastEvent } = lastEvent.actions;

export default lastEvent.reducer;
