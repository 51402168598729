import { FC } from 'react';
import { ButtonWithIconProps } from './types';

import { ButtonContainer, Icon } from './ButtonWithIcon.styles';

const ButtonWithIcon: FC<ButtonWithIconProps> = ({ label, icon, category, onClick }: ButtonWithIconProps) => (
	<ButtonContainer category={category} onClick={onClick}>
		{label}
		<Icon iconName={icon} />
	</ButtonContainer>
);

export { ButtonWithIcon };
