import { maxPicturesAllowedConst } from './components/InputWrapper/contants';
import { InputWrapper } from './components/InputWrapper';
import { EventImageProps } from './types';
import { StyledDropzone } from './components/StyledDropzone';

import { ImageInputContainer } from './ImageInputs.styles';

export const DropzoneInput = ({
	maxPicturesAllowed = maxPicturesAllowedConst,
	inputId,
	cyId = '',
}: EventImageProps) => {
	return (
		<InputWrapper maxPicturesAllowed={maxPicturesAllowed} inputId={inputId}>
			<ImageInputContainer>
				<StyledDropzone cyId={cyId} />
			</ImageInputContainer>
		</InputWrapper>
	);
};
