import styled from 'styled-components';

export const WrapperInputs = styled.div`
	display: flex;
	gap: 35px;
`;

export const ToggleContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 25px;
`;
