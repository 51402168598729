import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Form } from 'features/common/components/Form';

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 490px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xxs}) {
		width: 341px;
	}
`;

export const InputText = styled(Text)`
	font-size: 20px;
	line-height: 30px;
	font-weight: 600;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xxs}) {
		font-size: 15px;
		font-weight: 600;
		line-height: 23px;
		letter-spacing: 0em;
		text-align: left;
	}
`;

export const StyledInputDate = styled(Form.InputDate)`
	margin-bottom: 0px;
	width: 100%;
`;

export const StyledInputTime = styled(Form.InputTime)`
	margin-bottom: 0px;
	width: 100%;
`;
