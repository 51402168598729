import styled from 'styled-components';

import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import { Text } from 'features/common/components/Text';
import { WrapperStylesProps } from './types';

export const Wrapper = styled.div<WrapperStylesProps>`
	position: relative;
	background-color: ${({ highlightBackground, theme }) => highlightBackground && theme.colors.grey};
	padding: 36px 46px 36px 46px;
	margin-top: 24px;
	display: flex;
	justify-content: ${({ hasIcon }) => (hasIcon ? 'flex-start' : 'center')};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 18px;
		padding: 14px;
	}
`;

export const IconContainer = styled.div`
	position: absolute;
	top: 7%;
	right: 40px;
	cursor: pointer;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		left: 90%;
	}
`;

export const StyledIcon = styled(UnstyledIcon)`
	width: 60%;
	height: 60%;
`;

export const InfoWrapper = styled.div<WrapperStylesProps>`
	align-items: ${({ hasIcon }) => (hasIcon ? 'flex-start' : 'center')};
	display: flex;
	flex-direction: column;
`;

export const RoundContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 101px;
	min-width: 101px;
	height: 101px;
	border-radius: 100%;
	margin-right: 24px;
	background-color: ${({ theme }) => theme.colors.blackGrey};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 41px;
		min-width: 41px;
		height: 41px;
	}
`;

export const Title = styled(Text)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
	}
`;

export const SubTitle = styled(Text)`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 10px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
	}
`;
