import { FC, memo } from 'react';

import { ProgressBarProps } from './types';
import { calcPercentage } from './utils';

import { ProgressBarBase, Progress, ContainerProgressBar, ProgressText } from './ProgressBar.styles';

const ProgressBar: FC<ProgressBarProps> = ({ progressQty, totalAvailable, className }: ProgressBarProps) => (
	<ContainerProgressBar className={className}>
		<ProgressBarBase className={className}>
			<Progress role="progressbar" percentage={calcPercentage(progressQty, totalAvailable)}>
				<ProgressText>{`${progressQty}/${totalAvailable}`}</ProgressText>
			</Progress>
		</ProgressBarBase>
	</ContainerProgressBar>
);

const memorizedProgressBar: FC<ProgressBarProps> = memo(ProgressBar);

export { memorizedProgressBar as ProgressBar };
