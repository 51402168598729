import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { OUTLINED, PRIMARY } from 'features/common/components/Button/constants';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { useClickAway } from 'features/common/hooks/useClickAway';
import { DropdownInputProps } from './types';
import { Icon } from 'features/common/components/Icon';
import { debounce } from 'utils/utils';

import * as Styled from './DropdownInput.styles';

export const DropdownInput = ({
	children,
	filterName,
	hideViewAll,
	id,
	setPerPage,
	setSearch,
	updateValues,
	validationName,
}: DropdownInputProps) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const debouncedSetSearch = debounce(setSearch);
	const divRef = useRef<HTMLDivElement>(null);
	useClickAway(divRef, () => setIsOpen(false));

	const applyChanges = () => {
		setIsOpen(false);
		updateValues();
	};

	const {
		formState: { errors },
	} = useFormContext();
	const error = errors[validationName || ''];

	return (
		<>
			<Styled.DropdownWrap
				isOpen={isOpen}
				id={id}
				onClick={() => setIsOpen((prev) => !prev)}
				hasError={Boolean(error)}
				ref={divRef}
				tabIndex={0}
			>
				<Styled.InputDisplay>
					<span>{filterName}</span>
					<FlexContainer alignItems="center">
						<Icon className={isOpen ? 'isOpen' : ''} iconName={'arrowDown'} />
					</FlexContainer>
				</Styled.InputDisplay>
				<Styled.Container isOpened={isOpen} onClick={(e) => e.stopPropagation()}>
					<FlexContainer padding="12px">
						<Styled.Search onChange={(e) => debouncedSetSearch(e.target.value)} placeholder={t('search')} />
					</FlexContainer>
					{children}
					<Styled.ButtonContainer>
						<Styled.CustomButton
							text={t('button-view-all')}
							category={OUTLINED}
							onClick={() => setPerPage(-1)}
							disabled={hideViewAll}
						/>
						<FlexContainer gap="16px">
							<Styled.FilterButton text={t('button-cancel')} category={OUTLINED} onClick={() => setIsOpen(false)} />
							<Styled.FilterButton text={t('apply')} category={PRIMARY} onClick={applyChanges} />
						</FlexContainer>
					</Styled.ButtonContainer>
				</Styled.Container>
			</Styled.DropdownWrap>
			<Styled.Error>{error?.message}</Styled.Error>
		</>
	);
};
