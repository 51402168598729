import styled from 'styled-components';

export const ToolTipText = styled.span`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	visibility: hidden;
	width: 400px;
	background: ${({ theme }) => theme.colors.grey6};
	box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.25);
	font-family: ${({ theme }) => theme.fonts.primary};
	border-radius: 8px;
	border-radius: 6px;
	padding: 24px;
	position: absolute;
	z-index: 2;
	bottom: 150%;
	left: -650%;

	&.topForm {
		bottom: -200%;
		@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			bottom: -300%;
		}
	}

	span {
		align-items: flex-start;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		text-transform: uppercase;
	}

	:after: {
		content: '';
		position: absolute;
		top: -100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: black transparent transparent transparent;
	}

	&.width-350 {
		width: 350px;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: 614px;
		width: calc(100vw - 164px);
		bottom: -350%;
	}
`;

export const Wrapper = styled.div`
	position: relative;
	display: inline-block;
	vertical-align: middle;

	&:hover {
		cursor: pointer;
		> span {
			visibility: visible;
		}
	}
`;
