import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UserRoutes } from './UserRoutes';

import { logout } from 'services/auth';
import { ROUTES } from 'features/routes';
import { useAppDispatch } from 'store/hooks';

const AuthenticatedRoutes = (): JSX.Element => {
	const accessToken = localStorage.getItem('accessToken');
	const { pathname } = useLocation();
	const { push } = useHistory();
	const dispatch = useAppDispatch();
	const { CREATE_ACCOUNT_PATH, FORGOT_PASSWORD, USER_MGMT } = ROUTES;

	useEffect(() => {
		if (accessToken && pathname.includes('/login')) push(ROUTES.HOME_PATH);
	}, [accessToken]);

	if ([CREATE_ACCOUNT_PATH, FORGOT_PASSWORD, USER_MGMT].includes(pathname)) {
		dispatch(logout(() => {}));
	}

	return <UserRoutes />;
};

export default AuthenticatedRoutes;
