import { Button } from 'features/common/components/Button';
import { Text } from 'features/common/components/Text';
import { Icon } from 'features/common/components/Icon';
import { Modal } from 'features/common/components/Modal';
import { BodyContainer } from 'features/common/components/Modal/Modal.styles';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: calc(100vw - 48px);
	}
`;

export const ActionsWrapper = styled(Modal.ActionButtons)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;

	padding: 24px 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column-reverse;
		gap: 16px;
	}
`;

export const ModalButton = styled(Button)`
	border-radius: 8px;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	width: 160px;
	padding: 8px 22px;
	margin: 0;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 85%;
		max-width: 320px;
	}
`;

export const IconAlert = styled(Icon)`
	height: 56px;
	width: 56px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 40px;
		width: 40px;
	}
`;

export const StyledButton = styled(Button)`
	max-width: 382px;
	height: 35px;
	font-weight: 500;
	font-size: 16px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
`;

export const ModalMessage = styled(Text)`
	text-transform: none;
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 153%;
	text-align: center;
	max-width: 340px;

	&.title {
		font-weight: 600;
	}
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;

export const Wrapper = styled(BodyContainer)`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	white-space: normal;
	padding: 50px 24px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px;
	}
`;
