import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { ImageType } from 'features/common/types';
import { useClickAway } from 'features/common/hooks/useClickAway';
import cameraLogo from 'assets/images/cameraLogo.svg';
import { handlePictureChange, deletePartnerImage } from 'services/OrgImage';
import { OrgImagePopup } from 'features/common/components/OrgImagePopup';
import { useToastNotification } from 'hooks/useToastNotification';

import { Container, Image, HoverImage } from './Logo.styles';

export const Logo: FC = () => {
	const ref = useRef(null);
	const dispatch = useAppDispatch();
	const { showNotification, TYPES } = useToastNotification();
	const { t } = useTranslation();
	const { loggedUser } = useAppSelector((state: RootState) => state.auth);
	const partnerImage = loggedUser?.currentPartner.image;

	useEffect(() => {
		setCurrentImage(partnerImage);
	}, [loggedUser?.currentPartner]);

	const [currentImage, setCurrentImage] = useState<ImageType>(partnerImage);
	const [showHoverImage, setShowHoverImage] = useState<boolean>(false);
	const [showUploader, setShowUploader] = useState<boolean>(false);
	const isImage = currentImage !== '' && currentImage !== '-';

	const onImageClick = () => setShowUploader((prevState) => !prevState);
	const handleOnMouseEnter = () => setShowHoverImage(true);
	const handleOnMouseLeave = () => setShowHoverImage(false);

	useClickAway(ref, () => {
		setShowUploader(false);
	});

	const handlePictureChangeResults = (results: any, error: any) => {
		if (results) setShowUploader(false);
		if (error)
			showNotification({
				message: t('error-uploading-image'),
				type: TYPES.ERROR,
			});
	};
	const handleInputFile = ({ target }: ChangeEvent<HTMLInputElement>) => {
		dispatch(handlePictureChange(target, handlePictureChangeResults));
		target.value = '';
	};

	const onClickDelete = () => {
		dispatch(deletePartnerImage(handlePictureChangeResults));
	};

	return (
		<>
			<Container ref={ref} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
				{isImage && <Image src={currentImage?.toString()} />}
				{showHoverImage && <HoverImage src={cameraLogo} onClick={onImageClick} />}
			</Container>
			{showUploader && (
				<OrgImagePopup
					innerRef={ref}
					inHomePage={false}
					onChangeUpload={handleInputFile}
					onClickDelete={onClickDelete}
				/>
			)}
		</>
	);
};
