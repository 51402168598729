import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import i18n from 'i18next';

export const resolver = yupResolver(
	object().shape({
		linkName: string().required(i18n.t('dashboard-affiliateCreate-required')),
	})
);

export const defaultValues = { linkName: '' };
