import styled from 'styled-components';

import { ModalButton } from 'features/common/components/ModalDelete/ModalDelete.styles';

export const Button = styled(ModalButton)`
	padding: 0px;
	width: 125px;
	height: 44px;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	font-family: ${({ theme }) => theme.fonts.primary};
	background-color: ${({ theme }) => theme.colors.lightGrey4};

	&:hover {
		background-color: ${({ theme }) => theme.colors.red};
	}
`;

export const DeleteButton = styled.button`
	all: unset;
	width: 25px;
	height: 25px;
`;
