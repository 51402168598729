import styled from 'styled-components';

import { ArrowLeft } from 'assets/customizedIcons';
import { ArrowProps } from '../types';

export const ArrowIcon = styled(ArrowLeft)<ArrowProps>`
	height: 36px;
	width: 36px;
	stroke: ${({ theme, disabled }) => (disabled ? theme.colors.grey18 : theme.colors.green)};
	transform: ${({ isRight }) => (isRight ? 'rotate(180deg)' : 'none')};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	user-select: none;
`;

export const GrayContainer = styled.div`
	align-items: center;
	border-radius: 4px;
	display: flex;
	padding: 14px 12px;
	background-color: ${({ theme }) => theme.colors.blackGrey};
`;
