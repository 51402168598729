import styled from 'styled-components';
import { Icon } from 'features/common/components/Icon';
import { Modal } from 'features/common/components/Modal';
import { Text } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';
import { common } from 'theme/common';

import { BodyContainer } from 'features/common/components/Modal/Modal.styles';

export function getStatusColor(status: string) {
	switch (status) {
		case 'Completed':
			return common.colors.green10;
		case 'Registered':
			return common.colors.green10;
		case 'Refunded':
			return common.colors.orange;
		case 'Voided':
			return common.colors.orange;
		case 'Rejected':
			return common.colors.red;
		case 'Pending':
			return common.colors.orange;
		default:
			return common.colors.white;
	}
}

//following styled component are shared my children components
export const CustomModal = styled(Modal)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: calc(100vw - 48px);
	}
`;

export const ModalMessage = styled(Text)`
	text-transform: none;
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 153%;
	text-align: center;
	max-width: 340px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;

export const UserIcon = styled(Icon)`
	height: 56px;
	width: 56px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 40px;
		width: 40px;
	}
`;

export const MailIcon = styled(Icon)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 20px;
		width: 20px;
	}
`;

export const Email = styled(Text)`
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	word-break: break-word;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;

export const ActionsWrapper = styled(Modal.ActionButtons)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;

	padding: 24px 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column-reverse;
		gap: 16px;
	}
`;

export const ModalButton = styled(Button)`
	border-radius: 8px;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	width: 160px;
	padding: 8px 22px;
	margin: 0;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 85%;
		max-width: 320px;
	}
`;

export const ConfirmationContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.grey13};
	border-radius: 8px;
	width: fit-content;
	height: fit-content;
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;
`;

export const BaseWrapStyle = styled(BodyContainer)`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	white-space: normal;
`;

//used for confirmIdModal1 & 2
export const Wrapper = styled(BaseWrapStyle)`
	padding: 50px 24px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px;
	}
`;

export const IdentityInfoContainer = styled(ConfirmationContainer)`
	padding: 24px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 16px;
	}
`;

export const Name = styled(Text)`
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;
