import { FC } from 'react';
import { Icon } from 'features/common/components/Icon';
import logoSmall from 'assets/images/logoSmall.svg';
import { NavbarProps } from '../../types';

import { ContainerImage, ContainerHamburger, Logo, Wrapper } from './Navbar.styles';

const Navbar: FC<NavbarProps> = ({ onClick }: NavbarProps) => (
	<Wrapper aria-label="nav-bar">
		<ContainerHamburger>
			<Icon iconName="hamburger" onClick={onClick} />
		</ContainerHamburger>
		<ContainerImage>
			<Logo src={logoSmall} alt="Logo" />
		</ContainerImage>
	</Wrapper>
);

export { Navbar };
