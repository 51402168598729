import { UploadImageCardProps } from './types';
import { Icon } from '../Icon';

import * as Styled from './UploadImageCard.styles';

const UploadImageCard: React.FC<UploadImageCardProps> = ({
	icon,
	info,
	inputRef,
	onClick,
	setDisplayType,
	size,
	text,
	type,
}: UploadImageCardProps) => (
	<Styled.CardContainer
		key={type}
		type={type}
		onClick={() => {
			setDisplayType(type);
			if (onClick) onClick();
			if (inputRef) inputRef?.current?.click();
		}}
		size={size}
		data-cy={`${type}-btn`}
	>
		<Styled.Circle type={type}>
			<Icon iconName={icon} />
		</Styled.Circle>
		<Styled.Text>{text}</Styled.Text>
		<Styled.Info size={size}>{info}</Styled.Info>
	</Styled.CardContainer>
);

export { UploadImageCard };
