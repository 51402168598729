import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Modal } from 'features/common/components/Modal';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { RefundIncompleteProps } from './types';
import { getRefundStatus } from './utils';
import { formatNum } from 'features/RefundTicketsSummary/utils';

import { ActionsWrapper, ModalButton, PrimaryIcon } from '../../RefundOutcomes.styles';
import * as Styled from './RefundIncomplete.styles';

export const RefundIncomplete: FC<RefundIncompleteProps> = ({
	setShowIncomplete,
	refundPerTicket,
	refundData,
	ticketData,
}) => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const { ticketDisplayDetails, amountFailed, amountRefunded } = getRefundStatus(
		refundPerTicket,
		ticketData,
		refundData
	);

	const handleCancelClick = () => {
		setShowIncomplete(false);
		goBack();
	};

	return (
		<Modal>
			<Modal.Header>{t('refundTicketsSummary-refundIncomplete-header')}</Modal.Header>
			<Styled.CustomBody>
				<PrimaryIcon iconName="invoice" />
				<Styled.ModalDescription>
					<Styled.Subtitle element="h4">{t('refundTicketsSummary-refundIncomplete-partial-refund')}</Styled.Subtitle>
					<Styled.SubtitleSpan>{t('refundTicketsSummary-refundIncomplete-partial-message')}</Styled.SubtitleSpan>
				</Styled.ModalDescription>
				<Styled.TicketList>
					{ticketDisplayDetails.map(({ id, type, event, amount, refundFailed }) => (
						<FlexContainer key={id} justifyContent="space-between" alignItems="center">
							<Styled.IconTextFlex>
								<Styled.SecondaryIcon iconName={refundFailed ? 'alertCircleRed' : 'checkCircle'} />
								<Styled.Span>{`${type} - ${event}`}</Styled.Span>
							</Styled.IconTextFlex>
							<Styled.Span>{`$${formatNum(amount)}`}</Styled.Span>
						</FlexContainer>
					))}
				</Styled.TicketList>
				<Styled.FundsSummaryContainer>
					<Styled.IconTextFlex>
						<Styled.SecondaryIcon iconName="alertCircleRed" />
						<Styled.Subtitle>{t('refundTicketsSummary-refundIncomplete-total-not-refunded')}</Styled.Subtitle>
					</Styled.IconTextFlex>
					<Styled.Subtitle>{`$${formatNum(amountFailed)}`}</Styled.Subtitle>
				</Styled.FundsSummaryContainer>
				<Styled.FundsSummaryContainer isRefunded>
					<Styled.IconTextFlex>
						<Styled.SecondaryIcon iconName="checkCircle" />
						<Styled.Subtitle>{t('refundTicketsSummary-refundIncomplete-total-refunded')}</Styled.Subtitle>
					</Styled.IconTextFlex>
					<Styled.Subtitle>{`$${formatNum(amountRefunded)}`}</Styled.Subtitle>
				</Styled.FundsSummaryContainer>
			</Styled.CustomBody>
			<ActionsWrapper>
				<ModalButton text={t('dismiss')} type="button" onClick={handleCancelClick} />
			</ActionsWrapper>
		</Modal>
	);
};
