import styled from 'styled-components';
import { Icon } from 'features/common/components/Icon';
import { Modal } from 'features/common/components/Modal';
import { Button } from 'features/common/components/Button';

export const PrimaryIcon = styled(Icon)`
	height: 56px;
	width: 56px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 36px;
		width: 36px;
	}
`;

export const ActionsWrapper = styled(Modal.ActionButtons)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
	padding: 24px 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column-reverse;
		gap: 16px;
	}
`;

export const ModalButton = styled(Button)`
	border-radius: 8px;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	width: 160px;
	padding: 8px 22px;
	margin: 0;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 85%;
		max-width: 320px;
	}
`;
