import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';

import { createCampaign, editCampaign, formatImgForReq, getPartnerInfo } from '../services';
import { ROUTES } from 'features/routes';
import { TYPES } from 'hooks/useToastNotification/constants';
import { CampaignMutationProps, HandleClickProps } from '../types';
import { useToastNotification } from 'hooks/useToastNotification';
import { useUnregisterFormSubs } from './useUnregisterFormSubs';

export const useCampaignMutatation = ({ campaignId, formMethods, setIsOverCapacity }: CampaignMutationProps) => {
	const { push } = useHistory();
	const toastTools = useToastNotification();
	const [isDraft, setIsDraft] = useState(false);
	const [isPartialSend, setIsPartialSend] = useState(false);

	const { setValue, unregister, watch, getValues } = formMethods;
	const totalEmails = watch('totalEmails');
	useUnregisterFormSubs(unregister);

	const onSuccess = () => {
		toastTools.showNotification({
			message: toastTools.t(`marketing-campaignMutation-${isDraft ? 'draftSuccess' : 'createSuccess'}`),
			type: TYPES.SUCCESS,
		});
		push(`${ROUTES.MARKETING_PATH}?tab=email-campaign`);
	};

	const onError = () => {
		toastTools.showNotification({
			message: toastTools.t(`marketing-campaignMutation-${isDraft ? 'draft-failed' : 'send-failed'}`),
			type: TYPES.ERROR,
		});
	};

	const { mutate: editCampaignMutation } = useMutation({
		mutationFn: editCampaign,
		onSuccess,
		onError,
	});

	const { mutate: createCampaignMutation } = useMutation({
		mutationFn: createCampaign,
		onSuccess,
		onError,
	});

	const { data: initialData } = useQuery({
		queryKey: ['partner-info'],
		queryFn: getPartnerInfo,
	});

	const { data: dataBeforeSend, mutate: checkCapacity } = useMutation({
		mutationFn: getPartnerInfo,
		onSuccess: async (partnerInfo) => {
			const formData = getValues();
			const emailsAllowed = (partnerInfo?.emailsAllowance || -1) - (partnerInfo?.emailsSent || 0);
			if (totalEmails >= emailsAllowed && formData.status === 'CREATED' && !isPartialSend) {
				setIsOverCapacity(true);
			} else {
				await formatImgForReq({ formData, toastTools });
				campaignId ? editCampaignMutation({ formData, campaignId }) : createCampaignMutation(formData);
			}
		},
	});

	const handleClick = ({ status, isPartialSend = false }: HandleClickProps) => {
		setValue('status', status);
		setValue('audience', !!watch('cohorts').length || !!watch('events').length || watch('allEvents'));
		setIsDraft(status === 'DRAFT' ? true : false);
		setIsPartialSend(isPartialSend);
	};

	return { partnerInfo: dataBeforeSend || initialData, checkCapacity, handleClick, totalEmails };
};
