import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Search } from 'features/common/components/Search';
import { TitleProps, WrapperProps } from './types';
import { Icon } from 'features/common/components/Icon';

export const MobileFilterTitle = styled(Text)<TitleProps>`
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	padding: 8px;
	margin: 0;
	width: 140px;
	display: flex;
	justify-content: center;
	text-align: center;

	&.show {
		border-bottom: solid ${({ theme }) => theme.colors.green};
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
		padding: 0 8px 8px 8px;
	}
`;

export const OptionsContainer = styled.div<WrapperProps>`
	display: none;

	&.show {
		display: block;
	}
`;

export const FilterSearch = styled(Search)`
	border-radius: 8px;
	width: 100%;
`;

export const IconFilter = styled(Icon)`
	cursor: pointer;
`;

export const FilterAppliedIndicator = styled.div`
	position: absolute;
	height: 10px;
	width: 10px;
	left: 16px;
	top: 0;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.colors.green};
`;

export const TestContainer = styled.div`
	max-height: 30vh;
	overflow-y: scroll;
`;

export const RelativeDiv = styled.div`
	position: relative;
`;
