import { TotalTicketSoldProps } from 'features/Analytics/components/AnalyticsSummary/CardsSummary/types';
import { common } from 'theme';

export const ALL_TIME = 'All Time';
export const ALL_TIME_VALUE = 'all_time';
export const NET_SALES = 'netSales';

export const totalTicketSoldProps: TotalTicketSoldProps = {
	noGraph: false,
	ticketsSold: 0,
	totalPayout: 0,
	totalAttendees: 0,
};

const { colors } = common;

export const COLORS = [
	colors.green,
	colors.crimson,
	colors.lightBlue2,
	colors.purple1,
	colors.lightBlue3,
	colors.orange3,
	colors.green11,
];
