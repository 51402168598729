import { useTranslation } from 'react-i18next';

import createEventTickets from 'assets/images/createEventTickets.svg';
import { Page } from 'features/common/components/Carousel';
import { handleLink } from 'utils/handleLink';
import {
	ButtonContainer,
	Container,
	InputContainer,
	NextButton,
	SkipButton,
	StepTitle,
} from '../Steps.styles';

import { Image } from './AddTickets.styles';

export const AddTickets = () => {
	const { t } = useTranslation();

	return (
		<Page id="1">
			<Container>
				<InputContainer>
					<StepTitle element="h3">{t('createEvent-addTicketsStep')}</StepTitle>
					<Image src={createEventTickets} />
				</InputContainer>
				<ButtonContainer>
					<SkipButton type="button" onClick={() => handleLink('#9')}>
						{t('createEvent-addTicketsStep-skip')}
					</SkipButton>
					<NextButton type="button" onClick={() => handleLink('#2')}>
						{t('createEvent-addTicketsStep-add')}
					</NextButton>
				</ButtonContainer>
			</Container>
		</Page>
	);
};
