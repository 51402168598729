import styled from 'styled-components';

import { Modal } from 'features/common/components/Modal';
import { Icon } from 'features/common/components/Icon';
import { Text as unstyledText } from 'features/common/components/Text';
import { Button as unstyledButton } from 'features/common/components/Button';
import { TextProps } from './types';

export const Body = styled(Modal.Body)`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 16px;
	padding: 24px 0px;
`;

export const AlertIcon = styled(Icon)`
	height: 40px;
	width: 40px;
`;

export const Text = styled(unstyledText)<TextProps>`
	font-size: 14px;
	font-style: normal;
	font-weight: ${({ isBold }) => (isBold ? '600' : '400')};
	line-height: 21px;
	text-align: center;
	max-width: 270px;
`;

export const ButtonContainer = styled(Modal.ActionButtons)`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 16px;
	padding: 24px;
`;

export const Button = styled(unstyledButton)`
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	margin: 0;
	max-width: 400px;
	padding: 8px 16px;
`;
