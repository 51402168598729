import { DateComponentProps } from './types';

import {
	CalendarIcon,
	ClockIcon,
	DateContainer,
	IconContainer,
} from './DateComponent.styles';

const DateComponent = ({ date, time }: DateComponentProps) => (
	<DateContainer>
		<IconContainer>
			<CalendarIcon /> {date}
		</IconContainer>
		<IconContainer>
			<ClockIcon /> {time}
		</IconContainer>
	</DateContainer>
);

export default DateComponent;
