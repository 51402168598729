import { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { MainHeader } from 'features/common/components/MainHeader';
import { MainLayout } from 'layouts/MainLayout';
import { getData } from 'services/api';

import { MyProfile } from './components/MyProfile';
import { MY_PROFILE_QUERY_KEY } from './constants';
import { MyProfileData } from './types';
import { StateTabProps } from 'utils/types';
import { MyFinances } from './components/MyFinances';

import { useAppSelector } from 'store/hooks';
import { SubheaderContainer, TabButton } from 'features/common/components/TabSubheader';
import { MainContentWrap } from './Settings.styles';

export const Settings: FC = () => {
	const { t } = useTranslation();
	const [isShowTab, setShowTab] = useState('profile');

	const { data } = useQuery({
		queryKey: [MY_PROFILE_QUERY_KEY],
		queryFn: (): Promise<MyProfileData> => getData('/partners/settings'),
		refetchOnWindowFocus: false,
	});
	const partners = useAppSelector((state) => state.auth.loggedUser?.Partners) ?? [];
	const hasMultiplePartners = partners?.length > 1;

	const {
		state: { showTab } = {
			showTab: null,
			isFromRequiredFields: false,
		},
	}: StateTabProps = useLocation();

	const [showProfile, setShowProfile] = useState(showTab != 'tax_info' && showTab != 'payouts' ? true : false);

	return (
		<MainLayout>
			<MainHeader>
				<MainHeader.Title element="h1">{t('settings')}</MainHeader.Title>
			</MainHeader>
			<SubheaderContainer>
				<TabButton
					className={showProfile ? 'selected' : ''}
					onClick={() => {
						setShowProfile(true);
						setShowTab('profile');
					}}
				>
					{t('settings-my-profile')}
				</TabButton>
				<TabButton className={!showProfile ? 'selected' : ''} onClick={() => setShowProfile(false)}>
					{t('settings-my-finances')}
				</TabButton>
			</SubheaderContainer>
			<MainContentWrap>
				{showProfile && isShowTab === 'profile' ? (
					<MyProfile
						orgData={data?.partner}
						userData={data?.user}
						membersData={data?.members}
						hasMultiplePartners={hasMultiplePartners}
					/>
				) : (
					<MyFinances
						showTab={showTab}
						orgData={data?.partner}
						userData={data?.user}
						membersData={data?.members}
						hasMultiplePartners={hasMultiplePartners}
					/>
				)}
			</MainContentWrap>
		</MainLayout>
	);
};
