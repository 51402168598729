import styled from 'styled-components/macro';

export const Menu = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	top: 27px;
	left: 20px;
	height: fit-content;
	width: 220px;
	position: absolute;

	> a {
		text-decoration: none;
	}
`;

export const Wrapper = styled.div`
	height: fit-content;
	position: fixed;
	width: 260px;
	z-index: 4;
`;
