import styled from 'styled-components';

import { Button as UnstyledBtn } from 'features/common/components/Button';
import { SendTicketsSection } from 'features/SendTickets/SendTickets.styles';
import { Text } from 'features/common/components/Text';

export const Root = styled(SendTicketsSection)`
	background-color: ${({ theme }) => theme.colors.blackGrey};
	align-items: center;
	width: 100%;
	padding-top: 10%;
`;

export const ConfirmationCard = styled.div`
	padding: 32px;
	width: 50%;
	background-color: ${({ theme }) => theme.colors.lightGrey14};
	border-radius: 8px;
	font-family: ${({ theme }) => theme.fonts.primary};
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 80%;
	}
`;

export const ConfirmationText = styled(Text)`
	width: 100%;
	justify-content: flex-start;
`;

export const Button = styled(UnstyledBtn)`
	width: fit-content;
	height: fit-content;
	margin: 0;
	width: 100%;
	:disabled {
		background: ${({ theme }) => theme.colors.grey9};
	}
`;

export const Price = styled.p`
	display: flex;
	justify-content: space-between;
	border-top: 1px solid ${({ theme }) => theme.colors.white};
	padding: 24px 0;
	width: 100%;
`;
