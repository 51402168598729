import { useInfiniteQuery } from '@tanstack/react-query';

import api from 'services/api';
import { AffiliateReqProps, GetAffiliatesProps, InfiniteAffiliates } from './types';

export const useGetAffiliates = ({ eventId, search = '', perPage, queryKey }: GetAffiliatesProps) => {
	const affiliates = useInfiniteQuery({
		queryKey,
		queryFn: async ({ pageParam = 1 }: AffiliateReqProps): Promise<InfiniteAffiliates> => {
			const res = await api.get(`/events/affiliates/${eventId}?search=${search}&page=${pageParam}&per_page=${perPage}`);
			return {
				data: res.data,
				totalPages: parseInt(res.headers['x-total-count']) / perPage,
			};
		},
		getNextPageParam: ({ totalPages }, allPages) => (allPages.length < totalPages ? allPages.length + 1 : undefined),
		enabled: Boolean(eventId),
	});
	return { affiliates, affiliateCt: affiliates.data?.pages[0].data.length || 0 };
};
