import { camelize, sendGAEvent } from 'utils/utils';
import { HandleValidationProps, ValidationData } from './types';

export const handleValidation = ({
	data,
	setPromoCodeId,
	setTicketsWithPromoCodes,
	setValue,
	setFormValue,
}: HandleValidationProps) => {
	const { ticketTypes, promocodeId } = camelize(data) as ValidationData;
	const isValid = ticketTypes.length > 0;

	if (isValid) {
		setPromoCodeId(promocodeId);
		setFormValue('PCId', promocodeId);
		setTicketsWithPromoCodes(ticketTypes.map(Number));
		setValue('');
	}
	sendGAEvent({ category: 'Promo Code', action: `Promo Code ${isValid ? 'Applied' : 'NotValid'}` });
};
