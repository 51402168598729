import { DayType, EventProps } from 'features/Home/components/Events/types';
import { getData, post } from 'services/api';
import {
	Available,
	NestedTicketObject,
	RecipientProps,
	RecipientTicketType,
	SendTicketsDateProps,
	SendTicketsEventProps,
	SendTicketsFunctionProps,
} from './types';
import { convertObject, formatDate } from './utils';

export const getEvent = async (id: string): Promise<SendTicketsEventProps> => {
	const res: EventProps = await getData(`public/ticketing/events/${id}`);

	const dates: SendTicketsDateProps[] = [];
	const ticketTypes: RecipientTicketType[] = [];
	res.days.forEach(({ start_date, end_date, id }: DayType) => {
		const formatedDates = formatDate({ startDate: start_date, timezone: res.time_zone, endDate: end_date });
		dates.push({
			time: { label: formatedDates.time, value: `${id}` },
			day: { label: formatedDates.day, value: `${id}` },
		});
	});
	res.ticket_types.forEach(({ name, amount, ID, initial_price, sold, sold_out }) =>
		ticketTypes.push({
			type: name,
			quantity: 0,
			amount,
			ticketTypeId: ID,
			ticket_type_id: ID,
			initialPrice: initial_price,
			soldOut: sold_out,
			sold,
		})
	);
	return {
		name: res.name,
		id: res.id,
		ticketTypes,
		days: dates,
		timezone: res.time_zone,
		streetAddress: res.street_address,
	};
};

export const checkTicketsAvailability = (tickets: NestedTicketObject): Promise<Available> => {
	const body = convertObject(tickets);
	return post('/public/ticketing/ticket_types/availability', body);
};

export const sendTickets = ({ recipients }: SendTicketsFunctionProps) => {
	const body = recipients.map((recipient: RecipientProps) => ({
		recipient_name: recipient.firstName,
		recipient_last_name: recipient.lastName,
		email: recipient.email,
		ticket_types: recipient.ticketTypes,
	}));
	return post('tickets/comp-tickets', body);
};
