import styled, { css } from 'styled-components';
import { InputStylesProp, StyledLabelStylesProp, StylesProp } from './types';

const checkedSwitch = ({ disabled }: any) => css`
	+ span {
		opacity: ${disabled && 0.75};
		> span {
			transform: translateX(0px);
		}
	}
`;

const notCheckedSwitch = () => css`
	+ span {
		> span {
			transform: translateX(0);
		}
	}
`;

export const StyledLabel = styled.label<StyledLabelStylesProp>`
	position: relative;
	width: 168px;
	height: 34px;
	box-sizing: border-box;
	cursor: ${({ disabled }) => !disabled && 'pointer'};
`;

export const Input = styled.input<InputStylesProp>`
	transform: scale(0);
	+ span {
		width: 168px;
		height: 34px;
		background: ${({ theme }) => theme.colors.black2};
		bottom: 10px;
		display: flex;
		align-items: center;
		> span {
			opacity: ${({ disabled }) => disabled && 0.75};
		}
	}
	${({ theme, isPercentage, disabled }) =>
		isPercentage ? checkedSwitch({ theme, disabled }) : notCheckedSwitch()};
`;

export const Background = styled.span<StylesProp>`
	border-radius: 2px;
	height: 100%;
	left: 0;
	transition: background 0.4s;
	position: absolute;
	width: 100%;
	opacity: ${({ disabled, isPercentage }) =>
		disabled && (isPercentage ? '0.75' : '0.75')};
	display: flex;
	justify-content: space-between;
	flex-direction: ${({ isPercentage }) =>
		isPercentage ? 'row-reverse' : 'row'};
	transition: transform 0.45s;
`;

export const Circle = styled.span<StylesProp>`
	width: 82px;
	height: 32px;
	background: ${({ theme }) => theme.colors.green};
	border-radius: 2px;
	transition: transform 0.45s;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;

	&:disabled {
		opacity: 0.75;
	}
`;

export const UncheckedIconContainer = styled.div<StylesProp>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 84px;
	height: 34px;
	background: ${({ theme }) => theme.colors.black2};
	border-radius: 2px;
	z-index: 1;
`;
