import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { AuthLayout } from 'layouts/AuthLayout';
import { Form } from 'features/common/components/Form';
import { Canvas } from 'features/common/animations/Canvas';
import { ForgotPasswordForm } from './ForgotPasswordForm.tsx';
import {
	defaultValues,
	validationSchema,
	defaultValuesResetPassword,
	validationSchemaResetPassword,
	resetPassword,
} from './utils';
import { DefaultValues } from './types';
import { requestPassword } from './services';
import { ResetPasswordForm } from './ResetPasswordForm.tsx';
import { sendGAEvent } from 'utils/utils';

import { RightContainer } from 'styles';

export const ForgotPassword = () => {
	const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
	const { search } = useLocation();
	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema()),
	});
	const formMethodsResetPassword = useForm({
		defaultValues: defaultValuesResetPassword,
		resolver: yupResolver(validationSchemaResetPassword()),
	});
	const code = new URLSearchParams(search).get('oobCode');

	const { mutate: sendResetPasswordEmail, isSuccess: emailSent } = useMutation(requestPassword, {
		onSuccess: () => {
			formMethods.reset();
			sendGAEvent({ category: 'Forgot Password', action: 'Forgot password' });
		},
	});

	const handlePasswordReset = () =>
		resetPassword({ code: String(code), password: formMethodsResetPassword.getValues('password'), setIsPasswordReset });

	const onSubmit = (values: DefaultValues) => (code ? handlePasswordReset() : sendResetPasswordEmail(values));

	return (
		<AuthLayout>
			<Form formMethods={code ? formMethodsResetPassword : formMethods} onSubmit={onSubmit}>
				<RightContainer>
					<Canvas />
					{code ? <ResetPasswordForm isSuccess={isPasswordReset} /> : <ForgotPasswordForm emailSent={emailSent} />}
				</RightContainer>
			</Form>
		</AuthLayout>
	);
};
