import styled from 'styled-components';
import { Icon as unStyleIcon } from 'features/common/components/Icon';
import { IconStylesProps, WrapperStylesProps } from './types';

export const Icon = styled(unStyleIcon)<IconStylesProps>`
	cursor: pointer;
	position: relative;
	top: ${({ isOpened }) => isOpened && '16px'};
`;

export const MainWrapper = styled.div`
	height: 150px;
	width: 100%;
	margin-bottom: 0;
	background-color: ${({ theme }) => theme.colors.grey};
	display: flex;
	justify-content: flex-start;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 100px;
		margin-bottom: 0;
		box-shadow: 0px 8px 16px ${({ theme }) => theme.colors.darkGrey3};
	}
`;

export const Wrapper = styled.div<WrapperStylesProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin-left: 8%;
	text-align: left;
	gap: 25px;
	z-index: 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		left: 100px;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		left: 26%;
		top: 20px;
	}
`;

export const TitleInput = styled.input`
	position: relative;
	top: 16px;
	z-index: 2;
	font-weight: 600;
	font-size: 24px;
	text-transform: capitalize;
	background-color: ${({ theme }) => theme.colors.black};
	color: ${({ theme }) => theme.colors.white};
	border-width: 0;
	border-radius: 6px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 20px;
	}
`;
