import { useTranslation } from 'react-i18next';

import { GalleryImages } from './components/GalleryImages';
import { GalleryVideos } from './components/GalleryVideos';
import { EventImage } from './components/EventImage';

import { Root, Title } from './MediaContent.styles';

const MediaContent = () => {
	const { t } = useTranslation();

	return (
		<Root>
			<Title>{t('website-media-content-title')}</Title>
			<EventImage />
			<GalleryImages />
			<GalleryVideos />
		</Root>
	);
};

export { MediaContent };
