import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';

import { InputLabel } from './InputLabel';
import { FormInputProps } from './types';
import { handleNewTimes } from './utils';

import { CalendarWrapper, CalendarTime, Error, InputContainer, InputTimeContainer, IconTime } from './Form.styles';

const FormInputTime = ({ id, name, label, onChange, value, index, formValue, ...rest }: FormInputProps) => {
	const { pathname } = useLocation();
	const {
		setValue,
		getValues,
		formState: { errors },
		watch,
	} = useFormContext();
	const isFromPromoCode = pathname.includes('promo-codes');
	const error = errors[name];

	const [startDate, setStartDate] = useState<Date | null>(value);
	const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

	const handleOnChangeDate = (event: any) => {
		setStartDate(event);
		setValue(
			formValue as string,
			handleNewTimes({
				event,
				currentDates: getValues(formValue as string),
				label: String(label),
				index: Number(index),
			})
		);
		onChange && onChange(event);
	};

	const handleCalendarStatus = () => {
		setIsCalendarOpen((prevState) => !prevState);
	};

	useEffect(() => {
		if (isFromPromoCode && getValues('isEdited') && id?.includes('start')) {
			setStartDate(getValues('startDate')[0].start_date);
		}
	}, [watch('isEdited')]);

	return (
		<InputTimeContainer {...rest}>
			<InputLabel className="SM" htmlFor={id ?? name} label={label} />
			<InputContainer className="relative" hasError={Boolean(error)}>
				<CalendarWrapper isCalendarOpen={isCalendarOpen}>
					<DatePicker
						id={id ?? name}
						selected={startDate}
						onChange={handleOnChangeDate}
						showTimeSelect
						showTimeSelectOnly
						timeIntervals={15}
						timeCaption="Time"
						dateFormat="h:mm aa"
						calendarContainer={CalendarTime}
						onCalendarOpen={handleCalendarStatus}
						onCalendarClose={handleCalendarStatus}
					/>
				</CalendarWrapper>
				<IconTime iconName="greenClock" isCalendarOpen={isCalendarOpen} />
			</InputContainer>
			<Error>{error?.message}</Error>
		</InputTimeContainer>
	);
};

export { FormInputTime };
