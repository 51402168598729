import styled from 'styled-components';

export const CenteredContainer = styled.div`
	width: 60%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 90%;
	}
`;

export const Ticket = styled.img`
	width: 300px;
	height: 500px;
	overflow: hidden;
	object-fit: cover;
	border-radius: 10px;
`;
