import { FilterOptionTypeCollection } from 'features/common/components/Filters/LocalFilter/types';

export const PAST_DAY = 'Past Day';
export const PAST_WEEK = 'Past Week';
export const PAST_MONTH = 'Past Month';
export const PAST_YEAR = 'Past Year';
export const ALL_TIME = 'All Time';

export const PAST_DAY_QUERY = 'past_day';
export const PAST_WEEK_QUERY = 'past_week';
export const PAST_MONTH_QUERY = 'past_month';
export const PAST_YEAR_QUERY = 'past_year';
export const ALL_TIME_QUERY = 'all_time';

export const COMPED_FILTER_OPTIONS: FilterOptionTypeCollection = [
	{ label: ALL_TIME, value: ALL_TIME_QUERY },
	{ label: PAST_WEEK, value: PAST_WEEK_QUERY },
	{ label: PAST_MONTH, value: PAST_MONTH_QUERY },
	{ label: PAST_YEAR, value: PAST_YEAR_QUERY },
];
