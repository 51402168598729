import { FC } from 'react';
import { TextProps } from './types';
import {
	ELEMENT_H1,
	ELEMENT_H2,
	ELEMENT_H3,
	ELEMENT_H4,
	ELEMENT_P,
	ELEMENT_SPAN,
} from './constants';

import { H1, H2, H3, H4, Paragraph, Span } from './Text.styles';

const Text: FC<TextProps> = ({
	className,
	children,
	element,
	...props
}: TextProps) => {
	let Component = Span;

	switch (element) {
		case ELEMENT_H1:
			// @ts-ignore
			Component = H1;
			break;
		case ELEMENT_H2:
			// @ts-ignore
			Component = H2;
			break;
		case ELEMENT_H3:
			// @ts-ignore
			Component = H3;
			break;
		case ELEMENT_H4:
			// @ts-ignore
			Component = H4;
			break;
		case ELEMENT_P:
			// @ts-ignore
			Component = Paragraph;
			break;
		case ELEMENT_SPAN:
		case null:
		case undefined:
			Component = Span;
			break;
		default:
			// @ts-ignore
			Component = null;
	}

	return (
		<Component className={className} {...props}>
			{children}
		</Component>
	);
};

export { Text };
