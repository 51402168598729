import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from 'react-redux-typescript';
import { RootState } from 'store';
import { Todo } from '../types';

type TodosState = {
	todoList: Todo[];
};

const initialState = {
	todoList: [],
} as TodosState;

const todoSlice = createSlice({
	name: 'todos',
	initialState,
	reducers: {
		addTodo(state, action: PayloadAction<string, Todo>) {
			state.todoList.push(action.payload);
		},
		toggleTodo(state, action: PayloadAction<string, Todo>) {
			const index = state.todoList.findIndex(
				({ id }) => id === action.payload.id
			);
			if (index) {
				state.todoList[index].completed = !state.todoList[index].completed;
			}
		},
	},
});

export const { addTodo, toggleTodo } = todoSlice.actions;
export default todoSlice.reducer;

export const selectTodos = (state: RootState) => state.todos.todoList;
