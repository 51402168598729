import styled from 'styled-components';

import { TabButtonProps } from './types';

export const SubheaderContainer = styled.menu`
	margin: 0px;
	box-sizing: border-box;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.blackGrey4};
	display: flex;
	gap: 16px;
	padding: 16px 0 16px 130px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 8px;
		width: calc(100% - 24px - 24px);
		border-radius: 8px;
		padding: 8px;
		margin: 24px;
		justify-content: center;
	}
`;

export const TabButton = styled.button<TabButtonProps>`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.colors.white};
	background-color: transparent;
	cursor: pointer;
	border-radius: 8px;
	border: unset;
	padding: 8px 32px;

	&.selected {
		background-color: ${({ theme }) => theme.colors.lightGrey4};
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
		line-height: 21px;
		padding: 8px 24px;
	}
`;
