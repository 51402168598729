import { FC, memo } from 'react';
import moment from 'moment';

import { CustomTooltipProps } from './types';
import { toUsFormat } from 'utils/utils';

import { ContainerTooltip, TextTooltip } from './NetSales.styles';

const CustomTooltip: FC<CustomTooltipProps> = ({ label, data }: CustomTooltipProps) => {
	const option = data?.find(({ date }) => date.valueOf() === label?.valueOf());

	if (option) {
		return (
			<ContainerTooltip>
				<TextTooltip element="p">
					{moment(option.date).format('MM/DD/YY')}
					<br />
					{toUsFormat(option.payout)}
				</TextTooltip>
			</ContainerTooltip>
		);
	}

	return null;
};

const memorizedCustomTooltip: FC<CustomTooltipProps> = memo(CustomTooltip);

export { memorizedCustomTooltip as CustomTooltip };
