import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-bottom: 20px;
	width: 490px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xxs}) {
		width: 341px;
	}
`;
