import { put, deleteData } from 'services/api';
import { post } from 'services/api';
import {
	MemberData,
	PutRoyaltyRes,
	postFileBody,
	RolePutBody,
	NewMemberData,
} from './types';
import { queryClient } from 'index';
import { MY_PROFILE_QUERY_KEY } from 'features/Settings/components/MyProfile/constants';

export const royaltyPut = (updatedRoyalty: number): Promise<PutRoyaltyRes> =>
	put('/partners/me', {
		partner_secondary_default_Royalty: updatedRoyalty,
	});

export const documentPost = (body: postFileBody) => post('/partners/tos', body);

export const documentDelete = (fileId: number) =>
	deleteData(`/partners/tos/${fileId}`);

export const memberRolePut = async ({
	memberData,
	newRole,
}: RolePutBody): Promise<MemberData> => {
	const promise = await put(`/members/${memberData.member_id}`, {
		...memberData,
		roles: [newRole],
	});

	queryClient.invalidateQueries([MY_PROFILE_QUERY_KEY]);
	return promise;
};

export const addMemberPost = async (
	memberData: NewMemberData
): Promise<MemberData> => {
	const promise = await post('/members/invite', {
		...memberData,
		roles: [memberData.roles],
	});

	queryClient.invalidateQueries([MY_PROFILE_QUERY_KEY]);
	return promise;
};

export const memberDelete = async (memberId: number) => {
	const promise = await deleteData(`/members/${memberId}`);

	queryClient.invalidateQueries([MY_PROFILE_QUERY_KEY]);
	return promise;
};
