import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useResponsiveScreen } from 'hooks/useWindowSize';
import { RootState } from 'store';
import { getResponse } from 'services/api';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { Search } from 'features/common/components/Search';
import { MainHeader } from 'features/common/components/MainHeader';
import { initialState, resetFanFiltersToInitialFanDatabase } from './Slices/FanDatabaseSlice';
import { NoEventsFallback } from 'features/common/components/NoEventsFallback';
import { RTKFilter } from 'features/common/components/Filters';
import { FansTable } from './components/FansTable';
import { debounce } from 'utils/utils';
import { filterOptionFanDatabase } from 'features/common/components/Filters/constants';
import { useAppSelector } from 'store/hooks';

import { CustomFlexContainer } from './FanDatabase.styles';

export const FanDatabase: FC = () => {
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const [searchText, setSearchText] = useState('');
	const dispatch = useDispatch();
	const resultsPerPage = 30;
	const searchDebounce = debounce(setSearchText);
	const { selectedPeriod, selectedEvents, eventCount } = useAppSelector((state: RootState) => state.fanDatabase);
	const headersRef = useRef(0);

	const eventFilterApplied = selectedEvents.applied['-1'] ? '' : Object.keys(selectedEvents.applied).join(',');

	const fanQueryObject = useInfiniteQuery({
		queryKey: ['fans', selectedPeriod.applied, selectedEvents.applied, searchText],
		queryFn: async ({ pageParam = 1 }) => {
			const { data, headers } = await getResponse(
				`/partners/fans?date=${selectedPeriod.applied}
					&page=${pageParam}
					&per_page=${resultsPerPage}
					&search=${searchText}
					${eventFilterApplied === '' ? '' : '&events=' + eventFilterApplied}
					`
			);
			headersRef.current = Number(headers['x-total-count']);

			return data;
		},
		getNextPageParam: (_, pages) => {
			const maxPages = Math.round(headersRef.current / resultsPerPage) + 1;
			const nextPage = pages.length + 1;
			return nextPage <= maxPages ? nextPage : undefined;
		},
	});

	const isNoData = fanQueryObject.data?.pages[0].length === 0;
	const isNotFiltered =
		selectedPeriod.applied === initialState.selectedPeriod.applied &&
		selectedEvents.applied === initialState.selectedEvents.applied;
	const displayFallback = isNoData && isNotFiltered && !fanQueryObject.isError && searchText === '';

	useEffect(() => {
		dispatch(resetFanFiltersToInitialFanDatabase());
	}, []);

	return (
		<MainLayout>
			<MainHeader lessPadding>
				<MainHeader.Title element="h1">{t('fanDatabase-title')}</MainHeader.Title>
			</MainHeader>
			{displayFallback ? (
				<NoEventsFallback isFanDatabase />
			) : (
				<>
					<CustomFlexContainer>
						<Search
							width="300px"
							onChange={(e) => searchDebounce(e.target.value)}
							placeholder={t('search-email-or-name')}
							fontSize={isLargeScreen ? '' : '14px'}
						/>
						<RTKFilter
							selectedPeriod={selectedPeriod}
							selectedEvents={selectedEvents}
							eventCount={eventCount}
							filterOption={filterOptionFanDatabase}
							page="fanDatabase"
						/>
					</CustomFlexContainer>
					<FansTable fanQueryObject={fanQueryObject} />
				</>
			)}
		</MainLayout>
	);
};
