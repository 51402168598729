import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';

import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { Search } from 'features/common/components/Search';
import { MainHeader } from 'features/common/components/MainHeader';
import { NoEventsFallback } from 'features/common/components/NoEventsFallback';
import { RTKFilter } from 'features/common/components/Filters';
import { filterOptionOrdersPage } from 'features/common/components/Filters/constants';
import { debounce, sendGAEvent } from 'utils/utils';
import { getData } from 'services/api';
import { useOrdersPage } from './hooks/useOrdersPage';
import { OrdersTable } from './components/OrdersTable/OrdersTable';
import { QueryResultProps } from './types';

import * as Styled from './Orders.styles';

export const Orders: FC = () => {
	const { t } = useTranslation();
	const resultsPerPage = 30;
	const {
		searchText,
		setSearchText,
		selectedPeriod,
		selectedEvents,
		eventCount,
		eventFilterApplied,
		isNotFiltered,
		downloadOrdersQuery,
	} = useOrdersPage();
	const searchDebounce = debounce(setSearchText);

	const ordersQueryObject = useInfiniteQuery({
		queryKey: ['orders', selectedPeriod.applied, selectedEvents.applied, searchText],
		queryFn: ({ pageParam = 1 }) =>
			getData(
				`/orders?date=${selectedPeriod.applied}
				&page=${pageParam}
				&per_page=${resultsPerPage}
				&search=${searchText}
				${eventFilterApplied === '' ? '' : '&event_ids=' + eventFilterApplied}
				`
			),
		getNextPageParam: (lastPage, pages) => (lastPage.data.length >= resultsPerPage ? pages.length + 1 : undefined),
	}) as QueryResultProps;

	const displayFallback = isNotFiltered && !ordersQueryObject.data?.pages[0].data && searchText === '';

	const downloadClick = () => {
		downloadOrdersQuery.mutate();
		sendGAEvent({ category: 'Orders', action: 'Orders download' });
	};

	useEffect(() => {
		sendGAEvent({ category: 'Orders', action: 'Orders filter' });
	}, [selectedEvents, selectedPeriod]);

	return (
		<MainLayout>
			<MainHeader lessPadding>
				<Styled.Title element="h1">{t('ordersPage-title')}</Styled.Title>
			</MainHeader>
			{displayFallback ? (
				<NoEventsFallback isFanDatabase={false} />
			) : (
				<>
					<Styled.CustomFlexContainer>
						<Search
							width="300px"
							onChange={(e) => searchDebounce(e.target.value)}
							placeholder={t('search-email-or-name')}
						/>
						<Styled.Buttons>
							<RTKFilter
								selectedPeriod={selectedPeriod}
								selectedEvents={selectedEvents}
								eventCount={eventCount}
								page="orders"
								filterOption={filterOptionOrdersPage}
							/>
							<Styled.CsvButton onClick={downloadClick}>{t('ordersPage-download')}</Styled.CsvButton>
						</Styled.Buttons>
					</Styled.CustomFlexContainer>
					<OrdersTable ordersQueryObject={ordersQueryObject} />
				</>
			)}
		</MainLayout>
	);
};
