import { Template1, Template2 } from 'features/common/components/Templates';
import { TicketCardImageProps } from './types';
import { isBlob } from 'utils/utils';
import defaultTicketImage from 'assets/images/defaultTicketImage.svg';

import * as Styled from './TicketCard.styles';

export const TicketImageCard = ({ isCard, isEdit, displayType, image, name, event }: TicketCardImageProps) => {
	const localImgLink = () => {
		if (image) {
			return isBlob(image) ? image : `${image}?alt=media`;
		} else {
			return defaultTicketImage;
		}
	};
	return displayType === 'template0' ? (
		<Template1 isCard={isCard} isEdit={isEdit} image={localImgLink()} name={name} eventInfo={event} />
	) : displayType === 'template1' ? (
		<Template2 isCard={isCard} isEdit={isEdit} image={localImgLink()} name={name} />
	) : (
		<Styled.GradientContainer>
			{isCard && <Styled.GradientBackground />}
			<Styled.Image className="isCard" src={localImgLink()} imageType="image" height="100%" data-cy="ticket-img" />
		</Styled.GradientContainer>
	);
};
