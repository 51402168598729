import { useEffect } from 'react';
import { Background } from './bubble';

import { DotsCanvas } from './Canvas.styles';

export const Canvas = () => {
	useEffect(() => {
		new Background();
	}, []);

	return <DotsCanvas id="floatingbubbles" />;
};
