import styled from 'styled-components';

import { Button } from 'features/common/components/Button';
import { Text } from 'features/common/components/Text';

export const EventSpecificContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
`;

export const Title = styled(Text)`
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: 0em;
	margin-bottom: 16px;
	text-align: left;
`;

export const SearchContainer = styled.div`
	margin: 16px 0px 34px 0px;
`;

export const ViewAllButton = styled(Button)`
	font-family: Poppins;
	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
	letter-spacing: 0em;
	text-align: center;
	width: 336px;
`;
