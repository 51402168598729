const apiUrl = process.env.REACT_APP_API_BASE;

export const generateCode = (partnerId: string) => `<script type="text/javascript">
  (function (t, i, x) {
    const f = i.getElementsByTagName("script")[0],
      js = i.createElement("script");
    js.async = true;
    js.src = \`${apiUrl}/public/users/partners/\${x}/embed-script\`;
    f.parentNode.insertBefore(js, f);
  })(window, document, ${partnerId});
</script>
<div class="tixologi-widget" width="100%"></div>
`;
