import styled from 'styled-components';
import { DropDownProps, OptionProps } from './types';
import { Text } from 'features/common/components/Text';

export const DropDownList = styled.ul<DropDownProps>`
	background-color: ${({ theme }) => theme.colors.blackGrey};
	border: ${({ theme }) => ` 0.5px solid  ${theme.colors.white}`};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	left: ${({ secondaryStyle }) => (secondaryStyle ? '-20px' : '-16px')};
	margin: 0;
	padding: 0;
	position: relative;
	top: ${({ secondaryStyle }) => (secondaryStyle ? '-32px' : '12px')};
	width: ${({ secondaryStyle }) => (secondaryStyle ? '140px' : 'calc(100% + 30px)')};
	z-index: 2;
`;

export const ListItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey3};
	color: ${({ theme }) => theme.colors.white};
	gap: 10px;
	list-style: none;
	padding: 12px;
	cursor: pointer;

	&.last {
		border: none;
	}
`;

export const Span = styled(Text)<OptionProps>`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin-left: ${({ isChecked }) => (isChecked ? '' : '35px')};
`;
