import styled from 'styled-components';
import { Button } from 'features/common/components/Button';
import { EventOption } from '../../../EventOption';

export const CustomButton = styled(Button)`
	margin: 0;
	padding: 7px 0 7px 0;
	font-size: 16px;
	font-weight: 600;

	&:disabled {
		display: none;
	}
`;

export const CustomFilterOption = styled(EventOption)`
	padding: 10px 0 10px 0;
`;

export const ListContainer = styled.div`
	overflow-y: auto;
	height: 224px;

	&.show-all {
		height: 280px;
	}
`;
