import styled, { CSSProperties } from 'styled-components';

export const StyledFlexContainer = styled('div')<CSSProperties>`
	align-items: ${({ alignItems }) => alignItems};
	display: flex;
	flex-direction: ${({ flexDirection }) => flexDirection};
	gap: ${({ gap }) => gap};
	justify-content: ${({ justifyContent }) => justifyContent};
	margin: ${({ margin }) => margin};
	padding: ${({ padding }) => padding};
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	flex-wrap: ${({ flexWrap }) => flexWrap};
	background-color: ${({ backgroundColor }) => backgroundColor};
	border-radius: ${({ borderRadius }) => borderRadius};
	box-sizing: ${({ boxSizing }) => boxSizing};
	margin-right: ${({ marginRight }) => marginRight};
`;
