import api from 'services/api';

import {
	CreatePromoCodeProps,
	DeletePromoCodeType,
	selectedType,
	UpdatePromoCodeProps,
} from './types';

export const createPromoCode = ({ values, type }: CreatePromoCodeProps) => {
	const newReferences = values.selected.map(
		({ id, quantity }: selectedType) => ({ reference_id: id, quantity })
	);

	const isPercentage = values.cuponType === 'percentage';

	return api.post('/promocodes', {
		discount: isPercentage ? 0 : values.discountPercentage,
		percentage: isPercentage ? values.discountPercentage : 0,
		coupon_type: values.cuponType,
		code: values.promocodeName,
		start_date: values.startDate[0].start_date,
		end_date: values.isEndDate
			? values.endDate[0].end_date
			: '1970-01-01T00:00:00.000Z',
		promocode_type: type,
		references: newReferences,
	});
};

export const updatePromoCode = ({ values, type, id }: UpdatePromoCodeProps) => {
	const newReferences = values.selected.map(
		({ id, quantity }: selectedType) => ({ reference_id: id, quantity })
	);

	const isPercentage = values.cuponType === 'percentage';

	return api.put(`/promocodes/${id}`, {
		discount: isPercentage ? 0 : values.discountPercentage,
		percentage: isPercentage ? values.discountPercentage : 0,
		coupon_type: values.cuponType,
		code: values.promocodeName,
		start_date: values.startDate[0].start_date,
		end_date: values.isEndDate
			? values.endDate[0].end_date
			: '1970-01-01T00:00:00.000Z',
		promocode_type: type,
		references: newReferences,
	});
};

export const deletePromoCode = ({ id }: DeletePromoCodeType) =>
	api.delete(`/promocodes/${id}`);
