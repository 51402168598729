import { RecipientProps, RecipientTicketType } from 'features/SendTickets/types';

export function calculateTotalPrice(recipients: RecipientProps[]): number {
	const total: number = recipients.reduce((accumulator: number, recipient: RecipientProps) => {
		const ticketTotal: number = recipient.ticketTypes.reduce((subtotal: number, ticketType: RecipientTicketType) => {
			return subtotal + ticketType.quantity * ticketType.initialPrice;
		}, 0);

		return accumulator + ticketTotal;
	}, 0);

	return total;
}
