import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'features/common/components/Header';
import { ModalDelete } from '../ModalDelete';
import { PromoCodeTitleProps } from './types';

const PromoCodeTitle: FC<PromoCodeTitleProps> = ({ isNewPromoCode }: PromoCodeTitleProps) => {
	const { t } = useTranslation();

	const PromoCodeTitle = isNewPromoCode ? t('dashboard-promoCodes') : t('promoCode-Details-title');

	return (
		<Header.TitledHeader
			modal={!isNewPromoCode && <ModalDelete />}
			title={PromoCodeTitle}
			subtitle={t('dashboard-add')}
		/>
	);
};

const memorizedPromoCodeTitle: FC<PromoCodeTitleProps> = memo(PromoCodeTitle);

export { memorizedPromoCodeTitle as PromoCodeTitle };
