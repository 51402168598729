import { EmptySectionProps } from './types';

import { Container, CustomPlus, Text } from './EmptySection.styles';

export const EmptySection = ({ onClick, text }: EmptySectionProps) => (
	<Container onClick={onClick}>
		<CustomPlus iconName="plus" />
		<Text>{text}</Text>
	</Container>
);
