import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { OUTLINED } from 'features/common/components/Button/constants';
import { EventOption } from '../../../EventOption';
import { EventContainerProps } from '../../types';
import { debounce } from 'utils/utils';
import { addShowAllClass, lastIndicator, getAllEventsOption } from '../../../../utils';

import { FilterButton } from '../../../../RTKFilter.styles';
import { OptionsContainer, FilterSearch, TestContainer } from '../../MobileFilter.styles';
import { ListContainer } from './EventsContainer.styles';
import { Event } from 'features/common/components/Filters/types';

export const EventsContainer: FC<EventContainerProps> = ({
	className,
	eventQueryObj,
	perPageState,
	setSearchText,
	page,
}) => {
	const { t } = useTranslation();
	const { perPage, setPerPage } = perPageState;
	const debounceSearch = debounce(setSearchText);
	const isLargeScreen = useResponsiveScreen();

	return (
		<OptionsContainer className={className}>
			<FlexContainer flexDirection="column" gap={isLargeScreen ? '24px' : '12px'}>
				<FilterSearch
					fontSize={!isLargeScreen ? '14px' : ''}
					onChange={(e) => debounceSearch(e.target.value)}
					placeholder={t('filters-eventsContainer-search')}
				/>
				<TestContainer>
					<EventOption option={getAllEventsOption(t)} page={page} />
					<ListContainer className={addShowAllClass(perPage)}>
						{eventQueryObj?.data?.map((event: Event, i: number, array: Event[]) => (
							<EventOption key={event.id} option={event} className={lastIndicator<any>(i, array)} page={page} />
						))}
					</ListContainer>
				</TestContainer>
				<FilterButton
					text={t('button-view-all')}
					category={OUTLINED}
					onClick={() => setPerPage('-1')}
					disabled={perPage === '-1'}
					type="button"
				/>
			</FlexContainer>
		</OptionsContainer>
	);
};
