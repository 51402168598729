import { EVENT_KEY, TICKET_KEY, UNLIMITED_KEY } from './types';

const EVENT = 'Event specific';
const TICKET = 'Ticket specific';
const UNLIMITED = 'Unlimited';

export const chipText: { [key: string]: string } = {
	[EVENT_KEY]: EVENT,
	[TICKET_KEY]: TICKET,
	[UNLIMITED_KEY]: UNLIMITED,
};

export const typeText: { [key: string]: string } = {
	[EVENT_KEY]: 'eventSpecific',
	[TICKET_KEY]: 'ticketSpecific',
	[UNLIMITED_KEY]: 'unlimited',
};
