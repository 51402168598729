import { v4 as uuid } from 'uuid';
import { DateSchema, array, date, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import i18n from 'locals/i18n';
import { AllDefaultValues } from './types';
import { EventMode } from 'utils/types';
import { EVENT_MODE_NORMAL } from 'utils/constants';

const today = new Date();

export const DefaultValues: AllDefaultValues = {
	mode: EVENT_MODE_NORMAL,
	name: '',
	location: '',
	city: '',
	state: '',
	description: '',
	images: [],
	new_images: [],
	dates: [
		{
			start_date: today,
			end_date: today,
			id: uuid(),
		},
	],
	sale_date: today,
	timezone: '',
	gate_time: '',
	venue: '',
	support: '',
	postal_code: '',
	ticketing_mode: 'static',
	quantity: '',
	approval_required: false,
};

export const getFormDetails = (eventMode: EventMode) => {
	const sharedDetails = {
		name: string().required(i18n.t('editEvent-requiredName')),
		location: string().required(i18n.t('editEvent-requiredLocation')),
		timezone: string().required(i18n.t('editEvent-requiredTimezone')),
		dates: array(
			object({
				start_date: date().required(),
				end_date: date()
					.required()
					.when('start_date', (start_date: Date, schema: DateSchema) =>
						schema.min(start_date, i18n.t('error-endDate'))
					),
			})
		),
	};

	const normalSchema = object().shape({
		...sharedDetails,
		postal_code: string().required(i18n.t('editEvent-requiredZipcode')),
	});

	const registrationSchema = object().shape({
		...sharedDetails,
		quantity: string().required(i18n.t('editEvent-requiredQuantity')),
	});

	if (eventMode === EVENT_MODE_NORMAL) {
		return yupResolver(normalSchema);
	} else {
		return yupResolver(registrationSchema);
	}
};
