import {
	createUserWithEmailAndPassword,
	getAdditionalUserInfo,
	signInWithEmailAndPassword,
	signInWithPopup,
	User,
} from 'firebase/auth';

import { api } from 'services/api';
import { auth, googleAuthProvider } from 'services/firebase';
import {
	changeIsAuthenticated,
	setCurrentPartner,
	setLoggedUser,
	logoutUser,
	setLoading,
} from 'store/slices/AuthSlice';
import { AppDispatch } from 'store';
import { setEmail, setIsNewGoogleAccount, setName } from 'store/slices/GoogleSlice';
import { IAsyncActionCallback, IAsyncActionCallbackLogin, Partner } from './types';
import { CREATE_ACCOUNT_ERRORS } from 'features/CreateAccount/utils';
import { ROUTES } from 'features/routes';
import { isScannerRole, sendGAEvent, setGAUser } from 'utils/utils';
import { LOGIN_ERRORS } from 'features/Login/utils';

export const setUserToken = (user: User) =>
	user.getIdToken().then((token) => localStorage.setItem('accessToken', token));

export const createAccount =
	(email: string, password: string, name: string, organizationName: string, callback: IAsyncActionCallback) =>
	async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const { user } = await createUserWithEmailAndPassword(auth, email, password);
			await setUserToken(user);
			dispatch(signUpRequest(name, email, organizationName, callback));
		} catch (error: any) {
			dispatch(setLoading(false));
			if (error) {
				if (error.code === CREATE_ACCOUNT_ERRORS.EMAIL_ALREADY_IN_USE || CREATE_ACCOUNT_ERRORS.WEAK_PASSWORD) {
					callback(undefined, error);
				}
			}
		}
	};

export const signUpRequest =
	(name: string, email: string, organizationName: string, callback: IAsyncActionCallback) =>
	async (dispatch: AppDispatch) => {
		dispatch(setLoading(true));
		try {
			const body = {
				user: {
					full_name: name,
					email,
				},
				partner: {
					name: organizationName,
				},
			};
			const user = await api.post('/users/signup', body);
			localStorage.setItem('accessToken', user.data.login_token);

			const getLoggedUser = await api.get('/users/me');
			if (getLoggedUser && getLoggedUser.data) {
				callback(user, undefined);
				dispatch(changeIsAuthenticated());
				const loggedUser = {
					...getLoggedUser.data,
					currentPartner: getLoggedUser.data.Partners[0],
				};
				dispatch(setLoggedUser(loggedUser));
				dispatch(setLoading(false));
				setGAUser(getLoggedUser.data.ID);
			}
			sendGAEvent({ category: 'Create Account', action: 'Create account success' });
		} catch (error: any) {
			dispatch(setLoading(false));
			if (error) {
				if (error.response.data === CREATE_ACCOUNT_ERRORS.PARTNER_ALREADY_EXISTS) {
					callback(undefined, error.response);
				}
			}
		}
	};

export const logIn =
	(email: string, password: string, callback: IAsyncActionCallback) => async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const { user } = await signInWithEmailAndPassword(auth, email, password);
			await setUserToken(user);
			dispatch(loginRequest(callback));
		} catch (error) {
			dispatch(setLoading(false));
			if (error && callback) callback(error, undefined);
		}
	};

const loginRequest = (callback: IAsyncActionCallbackLogin) => async (dispatch: AppDispatch) => {
	try {
		const { data: token } = await api.post('/login');
		localStorage.setItem('accessToken', token);
		const user = await api.get('/users/me');
		const hasMultiplePartners = user.data.Partners.length > 1;

		if (!hasMultiplePartners && isScannerRole(token)) {
			callback({ code: LOGIN_ERRORS.SCANNER_ROLE }, undefined, hasMultiplePartners);
			dispatch(setLoading(false));
			return;
		}

		const loggedUser = {
			...user.data,
			currentPartner: user.data.Partners[0],
		};

		dispatch(setLoggedUser(loggedUser));
		callback(undefined, token, hasMultiplePartners);
		dispatch(changeIsAuthenticated());

		dispatch(setLoading(false));
		setGAUser(user.data.ID);
		sendGAEvent({ category: 'Login', action: 'Login success' });
	} catch (error) {
		dispatch(setLoading(false));
		if (error) callback(error);
	}
};

export const googleSignin =
	(callback: IAsyncActionCallback, push: (path: string) => void) => async (dispatch: AppDispatch) => {
		try {
			const userCredential = await signInWithPopup(auth, googleAuthProvider);
			await setUserToken(userCredential.user);
			dispatch(setName(userCredential.user.displayName));
			dispatch(setEmail(userCredential.user.email));
			sendGAEvent({ category: 'Google', action: 'Continue with google' });
			if (getAdditionalUserInfo(userCredential)?.isNewUser) {
				dispatch(setIsNewGoogleAccount());
				push(ROUTES.CREATE_ACCOUNT_PATH);
			} else {
				dispatch(loginRequest(callback));
			}
		} catch (error) {
			// TODO: handle google signin error
		}
	};

export const changeToken = async (partner_id: number) => {
	const { data } = await api.post('login/partner', { partner_id });
	localStorage.setItem('accessToken', data);
	return data;
};

export const setPartner =
	(partner: Partner, callback: IAsyncActionCallbackLogin, push: (path: string) => void) =>
	async (dispatch: AppDispatch) => {
		const token = await changeToken(partner.ID);
		if (isScannerRole(token)) {
			dispatch(setCurrentPartner(undefined));
			push(ROUTES.SCANNER_ACCOUNT);
			return;
		}

		dispatch(setCurrentPartner(partner));
		callback();
	};

export const logout = (callback: IAsyncActionCallback) => (dispatch: AppDispatch) => {
	dispatch(logoutUser());
	localStorage.clear();
	callback();
};
