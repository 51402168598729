import { FlexContainer } from 'features/common/components/FlexContainer';
import { SetTimelineZoom } from './SetTimelineZoom';
import { ShiftTimeline } from './ShiftTimeline';
import { GraphDisplayInfo } from './types';

export const AdjustTimeline = ({ setDisplay, displayWindow }: GraphDisplayInfo) => {
	return (
		<FlexContainer gap="8px" flexWrap="wrap-reverse" justifyContent="center" alignItems="start">
			<ShiftTimeline setDisplay={setDisplay} displayWindow={displayWindow} />
			<SetTimelineZoom setDisplay={setDisplay} displayWindow={displayWindow} />
		</FlexContainer>
	);
};
