import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useToastNotification } from 'hooks/useToastNotification';
import { deleteData, getData, post, put } from 'services/api';
import { ROUTES } from 'features/routes';
import { TermsData } from 'features/Settings/components/MyProfile/types';
import { IdParamsType } from 'utils/types';

export const useTermsData = () => {
	const { id }: IdParamsType = useParams();
	const { push } = useHistory();
	const { t } = useTranslation();
	const { showNotification, TYPES } = useToastNotification();

	const onSuccess = (text: string) => {
		showNotification({ message: text, type: TYPES.SUCCESS });
		push(ROUTES.SETTINGS_PATH);
	};

	const { data: editableTerm } = useQuery({
		queryKey: ['one-term', id],
		queryFn: (): Promise<TermsData> => getData(`/partners/tos/${id}`),
		enabled: Boolean(id) && !isNaN(parseInt(id)),
	});

	const createTerm = useMutation({
		mutationFn: (text: string) => post('/partners/tos', { text }),
		onSuccess: () => onSuccess(t('TermsOfService-save-success')),
		onError: () => showNotification({ message: t('TermsOfService-save-error'), type: TYPES.ERROR }),
	});
	const editTerm = useMutation({
		mutationFn: (text: string) => put(`/partners/tos/${id}`, { text }),
		onSuccess: () => onSuccess(t('TermsOfService-save-success')),
		onError: () => showNotification({ message: t('TermsOfService-save-error'), type: TYPES.ERROR }),
	});

	const deleteTerms = useMutation({
		mutationFn: () => deleteData(`/partners/tos/${id}`),
		onSuccess: () => onSuccess(t('TermsOfService-delete-success')),
		onError: () => showNotification({ message: t('TermsOfService-delete-error'), type: TYPES.ERROR }),
	});

	return { createTerm, deleteTerms, editTerm, editableTerm };
};
