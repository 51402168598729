import { ROLE_OPTIONS } from 'features/Settings/constants';
import { QueryNameType, RoleType } from 'features/Settings/types';

export const convertToObj = (
	backendRole: QueryNameType | undefined
): RoleType => {
	for (let i = 0; i < ROLE_OPTIONS.length; i++) {
		if (ROLE_OPTIONS[i].value === backendRole) return ROLE_OPTIONS[i];
	}
	return { value: '', label: '' };
};
