import { useTranslation } from 'react-i18next';

import { Modal } from 'features/common/components/Modal';
import { QUATERNARY } from 'features/common/components/Button/constants';
import { CapacityModalProps } from './types';

import { Body, Text, ButtonContainer, Button, AlertIcon } from './CapacityModal.styles';

export const CapacityModal = ({ partnerInfo, emailCount, handleClick, toggleModal }: CapacityModalProps) => {
	const { t } = useTranslation();

	const { emailsAllowance, emailsSent } = partnerInfo || { emailsAllowance: 0, emailsSent: 0 };
	const remainingAllowance = emailsAllowance - emailsSent;
	const isLimited = emailsAllowance - emailsSent > 0;

	return (
		<Modal onClose={() => toggleModal(false)}>
			<Modal.Header>{t('marketing-capacityModal-capacityReached')}</Modal.Header>
			<Body>
				<AlertIcon iconName="alertCircleRed" />
				<Text>{t(`marketing-capacityModal-${isLimited ? 'limited' : 'no'}Capacity`)}</Text>
				{isLimited && (
					<Text isBold>
						{t('marketing-capacityModal-capacitySummary', {
							willSend: remainingAllowance,
							selected: emailCount,
						})}
					</Text>
				)}
				<Text>{t('marketing-capacityModal-contactTeam')}</Text>
			</Body>
			<ButtonContainer>
				{isLimited && (
					<Button
						text={t('marketing-capacityModal-send')}
						type="submit"
						onClick={() => handleClick({ status: 'CREATED', isPartialSend: true })}
					></Button>
				)}
				<Button
					text={t('marketing-capacityModal-keep-editing')}
					category={QUATERNARY}
					onClick={() => toggleModal(false)}
				></Button>
				<Button
					text={t('marketing-capacityModal-contactBtn')}
					category={QUATERNARY}
					onClick={() => window.open('https://help.tixologi.com/kb-tickets/new')}
				></Button>
			</ButtonContainer>
		</Modal>
	);
};
