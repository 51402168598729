import styled from 'styled-components';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';

export const ButtonContainer = styled.button`
	gap: 16px;
	all: unset;
	width: 100%;
	height: 45px;
	display: flex;
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	border-radius: 6px;
	align-items: center;
	justify-content: center;
	color: ${({ theme }) => theme.colors.white};
	background: ${({ theme }) => theme.colors.grey3};
	font-family: ${({ theme }) => theme.fonts.primary};
	border: ${({ theme }) => `1px solid ${theme.colors.white}`};
`;

export const Icon = styled(UnstyledIcon)`
	margin-right: 10px;
`;
