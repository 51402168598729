import { decamelizeKeys } from 'humps';
import { deleteData, put } from 'services/api';
import { TicketBody } from './types';
import { keepUpperCase } from 'utils/utils';

export const deleteTicket = (id: number) => {
	return deleteData(`/ticket_types/${id}`);
};

export const updateTicket = (body: TicketBody) => {
	return put(
		`/ticket_types/${body.ID}`,
		decamelizeKeys(body, {
			process: keepUpperCase,
		})
	);
};
