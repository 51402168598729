import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import { MainLayout } from 'layouts/MainLayout';
import { EventProps } from 'features/Home/components/Events/types';
import { TicketsSection } from './components/TicketsSection';
import { EventInfo } from './components/EventInfo';
import { Highlights } from './components/Highlights';
import { PromoCodesSection } from './components/PromoCodesSection';
import { EventWebpageSection } from './components/EventWebpageSection';
import { AffiliatesSection } from './components/AffiliatesSection';
import { ImgContentSection } from './components/ImgContentSection';
import { CustomFieldsSection } from './components/CustomFieldsSection';
import { ROUTES } from 'features/routes';
import { getData } from 'services/api';
import { convertUTCToDate } from 'utils/utils';
import { IdParamsType } from 'utils/types';
import { EVENT_MODE_NORMAL, ZERO_TIME } from 'utils/constants';

import { ContentWrapper, Padding } from './Dashboard.styles';

const Dashboard = () => {
	const { push } = useHistory();
	const { id: eventId }: IdParamsType = useParams();

	const { data: event } = useQuery({
		queryKey: [`event-${eventId}`],
		queryFn: (): Promise<EventProps> => getData(`public/ticketing/events/${eventId}`),
	});

	const onClickTitle = () => push(ROUTES.EDIT_EVENT_PATH.replace(':id', eventId));

	const [onSale, setOnsale] = useState<string>('');
	const [firstDateStart, setFirstDateStart] = useState<string>('');
	const [firstDateEnd, setFirstDateEnd] = useState<string>('');

	useEffect(() => {
		if (event !== undefined) {
			setFirstDateStart(convertUTCToDate(event.days[0]?.start_date, event.time_zone));
			setFirstDateEnd(convertUTCToDate(event.days[0]?.end_date, event.time_zone));
			setOnsale(convertUTCToDate(event.selling_time, event.time_zone));
		}
	}, [event]);

	const zeroTime = event?.selling_time === ZERO_TIME;

	return (
		<MainLayout>
			<ContentWrapper>
				{event && (
					<EventInfo
						eventTitle={event.name}
						date={moment(firstDateStart).format('L')}
						startTime={moment(firstDateStart).format('LT')}
						endTime={moment(firstDateEnd).format('LT')}
						address={event.street_address}
						onSaleDate={zeroTime ? event.selling_time : `${moment(onSale).format('L')} ${moment(onSale).format('LT')}`}
						onClick={onClickTitle}
					/>
				)}
				<Padding>
					{event && <Highlights event={event} />}
					{event && <EventWebpageSection id={event.id} isLive={event.live_on_webpage} />}
					{event && <ImgContentSection eventImgCt={Math.min(1, event.images.length)} media={event.media} />}
					{event?.mode === EVENT_MODE_NORMAL && <TicketsSection />}
					{event?.mode === EVENT_MODE_NORMAL && <PromoCodesSection />}
					{event && <AffiliatesSection event={event} />}
					{/* TODO: remove comment when backend is ready */}
					{/* <AddOnsSection /> */}
					{event && <CustomFieldsSection eventMode={event.mode} />}
				</Padding>
			</ContentWrapper>
		</MainLayout>
	);
};

export { Dashboard };
