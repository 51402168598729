import { useEffect, useState } from 'react';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { TicketTypeProps } from './types';
import { convertUTCToDate } from '../utils';
import { getTemplateImage } from '../utils';
import Counter from '../Counter';

import { Divider } from '../BoxOffice.styles';
import { Container, Text, Price, TicketImage, Title, DateTimeContainer } from './TicketType.styles';

export const TicketType = ({
	value: { name, priceWithNoDiscount, description, ID, quantity, thumbnail, image, amount, sold, soldOut },
	startDate,
	endDate,
	timezone,
	isMobile,
	dayId,
}: TicketTypeProps) => {
	const [max, setMax] = useState<number>(0);

	useEffect(() => {
		setMax(amount - sold);
	}, []);

	return (
		<Container key={ID} active={quantity > 0}>
			{!isMobile && <TicketImage src={getTemplateImage({ thumbnail, image })} />}
			<FlexContainer flexDirection="column" gap="12px" width="100%">
				<FlexContainer justifyContent="space-between" alignItems="center">
					<FlexContainer flexDirection="column">
						<Title>{name}</Title>
						<DateTimeContainer>
							<Text className="selected gradient">{convertUTCToDate(startDate, timezone, 'D')} &nbsp;</Text>
							<Text>
								{convertUTCToDate(startDate, timezone, 't')} - {convertUTCToDate(endDate, timezone, 't')}
							</Text>
						</DateTimeContainer>
					</FlexContainer>
					<Counter className="small" quantity={quantity} ticketId={ID} dayId={dayId} max={max} soldOut={soldOut} />
				</FlexContainer>
				<Divider />
				<Text>{description}</Text>
				<FlexContainer flexDirection="column">
					<Price>${priceWithNoDiscount}</Price>
				</FlexContainer>
			</FlexContainer>
		</Container>
	);
};
