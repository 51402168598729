import { useMutation } from '@tanstack/react-query';
import { toastError } from 'hooks/useToastNotification/useToastNotification';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getData } from 'services/api';
import { RootState } from 'store';
import { initialState } from 'store/slices/OrdersPageSlice';
import { handleDownload } from 'utils/handleLink';

export const useOrdersPage = () => {
	const { selectedPeriod, selectedEvents, eventCount } = useSelector((state: RootState) => state.ordersPage);

	const [searchText, setSearchText] = useState('');

	const eventFilterApplied = selectedEvents.applied['-1'] ? '' : Object.keys(selectedEvents.applied).join(',');

	const isNotFiltered =
		selectedPeriod.applied === initialState.selectedPeriod.applied &&
		selectedEvents.applied === initialState.selectedEvents.applied;

	const downloadOrdersQuery = useMutation(async () => {
		try {
			const res = await getData(`/orders/csv?per_page=-1
			&date=${selectedPeriod.applied}
			&search=${searchText}
			${eventFilterApplied === '' ? '' : '&event_ids=' + eventFilterApplied}`);
			handleDownload(res, 'orders.csv');
		} catch {
			toastError('ordersPage-csv-error');
		}
	});

	return {
		downloadOrdersQuery,
		selectedPeriod,
		selectedEvents,
		eventCount,
		searchText,
		setSearchText,
		eventFilterApplied,
		isNotFiltered,
	};
};
