import { FilterOptionTypeCollection } from 'features/common/components/Filters/LocalFilter/types';
import i18n from 'locals/i18n';

export const ALL = i18n.t('home-filter-all');
export const RECENT = i18n.t('home-filter-recent');
export const COMPLETED = i18n.t('home-filter-completed');
export const SCHEDULED = i18n.t('home-filter-scheduled');
export const LIVE_AND_SCHEDULED = i18n.t('home-filter-liveAndScheduled');
export const LIVE = i18n.t('home-filter-live');
export const ON_SALE = i18n.t('generic-onSale');
export const PAST_WEEK = i18n.t('generic-past-week');
export const PAST_MONTH = i18n.t('generic-past-month');
export const PAST_YEAR = i18n.t('generic-past-year');

export const ALL_QUERY = 'all';
export const RECENT_QUERY = 'recent';
export const COMPLETED_QUERY = 'completed';
export const SCHEDULED_QUERY = 'scheduled';
export const LIVE_AND_SCHEDULED_QUERY = 'live_or_scheduled';
export const LIVE_QUERY = 'live';
export const ON_SALE_QUERY = 'on_sale';
export const PAST_WEEK_QUERY = 'past_week';
export const PAST_MONTH_QUERY = 'past_month';
export const PAST_YEAR_QUERY = 'past_year';

export const FILTER_OPTIONS: FilterOptionTypeCollection = [
	{ label: ALL, value: ALL_QUERY },
	{ label: SCHEDULED, value: SCHEDULED_QUERY },
	{ label: LIVE_AND_SCHEDULED, value: LIVE_AND_SCHEDULED_QUERY },
	{ label: RECENT, value: RECENT_QUERY },
	{ label: COMPLETED, value: COMPLETED_QUERY },
	{ label: LIVE, value: LIVE_QUERY },
	{ label: ON_SALE, value: ON_SALE_QUERY },
	{ label: PAST_WEEK, value: PAST_WEEK_QUERY },
	{ label: PAST_MONTH, value: PAST_MONTH_QUERY },
	{ label: PAST_YEAR, value: PAST_YEAR_QUERY },
];
