import styled from 'styled-components';

import { Icon } from 'features/common/components/Icon';
import { Text } from 'features/common/components/Text';
import { TicketQuantityButtonContainerProps } from './types';

export const TicketQuantityButtonContainer = styled.button<TicketQuantityButtonContainerProps>`
	margin-top: 10px;
	width: 490px;
	height: ${({ ticketType }) => (ticketType === 'unlimited' ? '96px' : '72px')};
	border: 1px solid
		${({ theme, ticketType }) =>
			ticketType === 'unlimited' ? theme.colors.lightGrey6 : 'transparent'};
	border-radius: 8px;
	background-color: ${({ theme, ticketType }) =>
		ticketType === 'unlimited' ? theme.colors.blackGrey : theme.colors.grey10};
	display: flex;
	justify-content: ${({ ticketType }) =>
		ticketType === 'unlimited' ? 'flex-start' : 'space-between'};
	align-items: center;
	gap: 24px;
	padding: ${({ ticketType }) =>
		ticketType === 'unlimited'
			? '27px 81px 27px 24px'
			: '24.5px 30px 24.5px 24px'};
	cursor: ${({ ticketType }) =>
		ticketType === 'unlimited' ? 'default' : 'pointer'};
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xxs}) {
		gap: 16px;
		padding: 27px 23.5px;
		width: 342px;
	}
`;

export const TicketQuantityText = styled(
	Text
)<TicketQuantityButtonContainerProps>`
	color: ${({ theme }) => theme.colors.white};
	font-size: ${({ ticketType }) =>
		ticketType === 'unlimited' ? '14px' : '15px'};
	font-weight: ${({ ticketType }) => (ticketType === 'unlimited' ? 500 : 600)};
	line-height: ${({ ticketType }) =>
		ticketType === 'unlimited' ? '21px' : '22px'};
	letter-spacing: 0em;
	text-align: left;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 23px;
`;

export const TicketQuantityIcon = styled(Icon)`
	height: 48px;
	width: 48px;
`;

export const ConfirmedTicketTypes = styled.div`
	height: 24px;
	width: 24px;
	border-radius: 100%;
	background-color: ${({ theme }) => theme.colors.green};
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Poppins;
	color: ${({ theme }) => theme.colors.white};
	font-size: 15px;
	font-weight: 600;
	line-height: 23px;
	letter-spacing: 0em;
	text-align: left;
`;
