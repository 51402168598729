import { SoldAvailableProps } from './types';

import { ColorPieChart, Number, PieChart, Root } from './CustomPieChart.styles';

const CustomPieChart = ({ sold, available, color, sizes, sm }: SoldAvailableProps) => {
	const percentage = Math.round((sold / (sold + available)) * 100);

	const getPrimaryPercentage = () => {
		if (percentage === 100) return 100;
		if (percentage === 0) return 0;
		if (percentage > 10) return percentage - 7;
		return 4;
	};
	const primayPercentage = getPrimaryPercentage();
	const secondaryPercentage = primayPercentage === 0 ? 100 : 100 - primayPercentage - 7;

	return (
		<Root>
			{primayPercentage > 0 && (
				<ColorPieChart color={color} iconWidth={sizes.iconSize} percentage={primayPercentage} sm={sm} />
			)}
			<PieChart
				color={secondaryPercentage > 0 ? '#F2F2F2' : 'transparent'}
				iconWidth={sizes.iconSize}
				percentage={secondaryPercentage}
				sm={sm}
			>
				<Number fontSize={sizes.fontSize}>{`${sold}/${sold + available}`}</Number>
			</PieChart>
		</Root>
	);
};

export default CustomPieChart;
