import styled from 'styled-components';

import { FlexContainer } from 'features/common/components/FlexContainer';

export const Flex = styled(FlexContainer)`
	gap: 16px;
	padding-bottom: 24px;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 24px;
	}
`;
