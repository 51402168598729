import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import TaxInfoFields from './components/TaxInfoFields';
import { getTaxInfoData, updateTaxInfo } from './services';
import { MyFinancesProps } from '../types';
import { useToastNotification } from 'hooks/useToastNotification';
import { sendGAEvent } from 'utils/utils';

import * as Styled from './TaxInfo.styles';

const TaxInfo = ({ isFromRequiredFields }: MyFinancesProps) => {
	const { t } = useTranslation();
	const { showNotification, TYPES } = useToastNotification();

	const { data: taxInfo } = useQuery({
		queryKey: ['taxInfo'],
		queryFn: getTaxInfoData,
	});

	const { mutate: updateTaxInfoSubmit } = useMutation({
		mutationFn: updateTaxInfo,
		onSuccess: () => {
			showNotification({
				message: t('settings-my-finances-tax-info-updated-success'),
				type: TYPES.SUCCESS,
			});
			sendGAEvent({ category: 'Finances', action: 'Edit my finances' });
		},
		onError: () =>
			showNotification({
				message: t('settings-my-finances-tax-info-updated-error'),
				type: TYPES.ERROR,
			}),
	});

	const handleSubmit = () => {
		const body = formMethods.getValues();
		return updateTaxInfoSubmit(body);
	};

	const formMethods = useForm({
		defaultValues: taxInfo,
	});
	useEffect(() => {
		formMethods.reset({
			...taxInfo,
		});
	}, [taxInfo]);

	return (
		<Styled.Root formMethods={formMethods} onSubmit={handleSubmit}>
			<Styled.Title>{t('settings-my-finances-taxes')}</Styled.Title>
			<TaxInfoFields isFromRequiredFields={isFromRequiredFields} />
			<Styled.FixedButtonContainer>
				<Styled.UpdateButton text="Update" type="submit" />
			</Styled.FixedButtonContainer>
		</Styled.Root>
	);
};

export { TaxInfo };
