import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { camelizeKeys } from 'humps';

import { getData } from 'services/api';
import { convertUTCToDate } from 'utils/utils';
import { EventTemplateProps, KeyProps, TemplateProps } from '../types';

import * as Styled from '../Template.styles';

export const Template1 = ({ onClick, isEdit, image, isCard, name }: TemplateProps) => {
	const { t } = useTranslation();
	const propKey = isEdit ? 'id' : 'eventId';
	const { [propKey]: propValue }: KeyProps = useParams();

	const { data } = useQuery({
		queryKey: ['event', propValue],
		queryFn: () => getData(`/public/ticketing/events/${propValue}`),
	});
	const event = camelizeKeys(data) as unknown as EventTemplateProps;

	const isCardClassName = isCard ? 'isCard' : '';

	return (
		<Styled.TemplateContainer className={isCardClassName} onClick={onClick} id="template1" isLightTemplate>
			{isCard && <Styled.GradientBackground />}
			<Styled.Image src={image} />
			<Styled.InfoContainer className={isCardClassName}>
				<Styled.MainInfoContainer>
					<Styled.Title className={isCardClassName} isLightTemplate>
						{event?.name}
					</Styled.Title>
				</Styled.MainInfoContainer>
				<Styled.InfoRow>
					<Styled.InfoItem>
						<Styled.InfoTitle isLightTemplate>{t('generic-date')}</Styled.InfoTitle>
						<Styled.InfoText isLightTemplate>
							{moment(convertUTCToDate(`${event?.startDate}`, event?.timeZone))?.format('MM.DD.YYYY')}
						</Styled.InfoText>
					</Styled.InfoItem>
					<Styled.InfoItem>
						<Styled.InfoTitle isLightTemplate>{t('generic-time')}</Styled.InfoTitle>
						<Styled.InfoText isLightTemplate>
							{`${moment(convertUTCToDate(`${event?.startDate}`, event?.timeZone))?.format('h:mm A')} - ${moment(
								convertUTCToDate(`${event?.endDate}`, event?.timeZone)
							)?.format('h:mm A')}`}
						</Styled.InfoText>
					</Styled.InfoItem>
				</Styled.InfoRow>
				<Styled.Divider isLightTemplate />
				{event?.venue !== '' && (
					<>
						<Styled.InfoRow>
							<Styled.InfoItem>
								<Styled.InfoTitle isLightTemplate>{t('generic-venue')}</Styled.InfoTitle>
								<Styled.InfoText isLightTemplate> {event?.venue}</Styled.InfoText>
							</Styled.InfoItem>
						</Styled.InfoRow>
						<Styled.Divider isLightTemplate />
					</>
				)}
				<Styled.InfoRow>
					<Styled.InfoItem>
						<Styled.InfoTitle isLightTemplate>{t('generic-address')}</Styled.InfoTitle>
						<Styled.InfoText className={isCardClassName} isLightTemplate isClamp={false}>
							{event?.streetAddress}
						</Styled.InfoText>
					</Styled.InfoItem>
				</Styled.InfoRow>
				<Styled.TicketType className={isCardClassName}>
					<Styled.Ticket iconName="ticket-type-black" />
					<Styled.TicketTypeText isLightTemplate>{name}</Styled.TicketTypeText>
				</Styled.TicketType>
			</Styled.InfoContainer>
			{!isCard && <Styled.BottomLine isLightTemplate />}
		</Styled.TemplateContainer>
	);
};
