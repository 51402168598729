export const myFinancesNavTabs = [
	{
		id: 'payouts',
		name: 'Payouts & Payout Methods',
	},
	{
		id: 'tax_info',
		name: 'Tax Info',
	},
];
