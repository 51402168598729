import styled from 'styled-components';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import { Text as UnstyledText } from 'features/common/components/Text';
import { Button as UnstyledButton } from 'features/common/components/Button';

export const Icon = styled(UnstyledIcon)`
	width: 72px;
	height: 72px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 48px;
		height: 48px;
	}
`;

export const Text = styled(UnstyledText)`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 153%;
	padding-top: 38px;
	text-align: center;
	text-transform: none;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding-top: 18px;
	}
`;

export const ContainerBody = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 64px 0;
`;

export const ContainerActionButtons = styled.div`
	display: flex;
	gap: 16px;
	justify-content: center;
	padding: 0px;
`;

export const ModalButton = styled(UnstyledButton)`
	font-size: 14px;
	font-weight: 500;
	font-family: ${({ theme }) => theme.fonts.primary};
	height: 44px;
	line-height: 14px;
	padding: 0px;
	width: 125px;
`;
