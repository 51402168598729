import { FC } from 'react';

import { Icon } from 'features/common/components/Icon';
import { AddButtonProps } from './types';

import { Text, AddButtonContainer } from './RightForm.styles';

const AddButton: FC<AddButtonProps> = ({ text, onClick }: AddButtonProps) => {
	return (
		<AddButtonContainer type="button" onClick={onClick} data-cy="add-day-btn">
			<Icon iconName="plus" />
			<Text>{text}</Text>
		</AddButtonContainer>
	);
};

export { AddButton };
