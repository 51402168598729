import styled from 'styled-components';

import { Button } from 'features/common/components/Button';
import { Text } from 'features/common/components/Text';
import { Table } from 'features/common/components/Table';

export const MainContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 52px auto;
	padding-left: ${({ theme }) => theme.widths.hiddenSidebar};
	width: 85%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		align-items: center;
		padding-left: 0px;
	}
`;

export const CenterContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-width: 1300px;
	width: 100%;
`;

export const ContentTitle = styled(Text)`
	font-size: 32px;
	font-weight: 500;
	line-height: 150%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		text-align: center;
		text-transform: none;
		width: 80%;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
`;

export const CustomButton = styled(Button)`
	font-size: 18px;
	font-weight: 600;
	height: 45px;
	margin: 0 0 24px 0;
	padding: 0px;
	width: 325px;
`;

export const LoadMoreButton = styled(Table.LoadMore)``;

export const HiddenInput = styled.input`
	display: none;
`;
