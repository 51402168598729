import api, { getData } from 'services/api';
import { UpdateWebsiteDataProps } from '../../../../EventSiteEditStyles/types';
import { PartnerWidgetDefaultStylesForm, PartnerWidgetDefaultStylesRequest } from './types';

export const getPartnerWidgetDefaultStyles = async (): Promise<PartnerWidgetDefaultStylesForm> => {
	const data: PartnerWidgetDefaultStylesRequest = await getData('partners/widget');
	return {
		themeColors: {
			background_color: data?.background_color,
			theme_color: data?.theme_color,
			header_color: data?.header_color,
		},
		typoSection: {
			fontFamily: data?.family_font || 'Poppins',
			fontStyle: {
				title: {
					name: data?.title_font_style.toLowerCase(),
					color: data?.title_brand_color,
				},
				subtitle: {
					name: data?.sub_title_font_style.toLowerCase(),
					color: data?.sub_title_brand_color,
				},
				text: {
					name: data?.text_font_style.toLowerCase(),
					color: data?.text_brand_color,
				},
				callouts: {
					name: data?.callouts_font_style,
					color: data?.callouts_brand_color,
				},
				headerText: {
					name: data?.header_font_style,
					color: data?.header_brand_color,
				},
			},
		},
	};
};

export const updatePartnerWidgetDefaultStyles = async (attributes: PartnerWidgetDefaultStylesForm) => {
	api.put('partners/widget', {
		background_color: attributes.themeColors.background_color,
		theme_color: attributes.themeColors.theme_color,
		header_color: attributes.themeColors.header_color,
		family_font: attributes.typoSection.fontFamily,
		callouts_font_style: attributes.typoSection.fontStyle.callouts.name,
		callouts_brand_color: attributes.typoSection.fontStyle.callouts.color,
		header_font_style: attributes.typoSection.fontStyle.headerText.name,
		header_brand_color: attributes.typoSection.fontStyle.headerText.color,
		title_font_style: attributes.typoSection.fontStyle.title.name,
		title_brand_color: attributes.typoSection.fontStyle.title.color,
		sub_title_font_style: attributes.typoSection.fontStyle.subtitle.name,
		sub_title_brand_color: attributes.typoSection.fontStyle.subtitle.color,
		text_font_style: attributes.typoSection.fontStyle.text.name,
		text_brand_color: attributes.typoSection.fontStyle.text.color,
	});
};

export const updateWebisteData = ({ attributes, eventId }: UpdateWebsiteDataProps) =>
	api.put(`/events/${eventId}/websettings`, {
		event_id: eventId,
		background_color: attributes.themeColors.background_color,
		theme_color: attributes.themeColors.theme_color,
		header_color: attributes.themeColors.header_color,
		family_font: attributes.typoSection.fontFamily,
		callouts_font_style: attributes.typoSection.fontStyle.callouts.name,
		callouts_brand_color: attributes.typoSection.fontStyle.callouts.color,
		header_font_style: attributes.typoSection.fontStyle.headerText.name,
		header_brand_color: attributes.typoSection.fontStyle.headerText.color,
		title_font_style: attributes.typoSection.fontStyle.title.name,
		title_brand_color: attributes.typoSection.fontStyle.title.color,
		sub_title_font_style: attributes.typoSection.fontStyle.subtitle.name,
		sub_title_brand_color: attributes.typoSection.fontStyle.subtitle.color,
		text_font_style: attributes.typoSection.fontStyle.text.name,
		text_brand_color: attributes.typoSection.fontStyle.text.color,
	});
