import styled from 'styled-components';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import { Text as UnstyledText } from 'features/common/components/Text';
import { Button as UnstyledButton } from 'features/common/components/Button';

export const Icon = styled(UnstyledIcon)`
	width: 72px;
	height: 72px;
	margin-top: 36px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 48px;
		height: 48px;
	}
`;

export const Text = styled(UnstyledText)`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 153%;
	padding-top: 38px;
	text-align: center;
	text-transform: none;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding-top: 18px;
	}
`;

export const ContainerBody = styled.div`
	height: 421px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 240px;
	}
`;

export const ContainerActionButtons = styled.div`
	display: flex;
	gap: 16px;
	justify-content: center;
	padding: 0px;
`;

export const ModalButton = styled(UnstyledButton)`
	padding: 0px;
	width: 125px;
	height: 44px;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const Button = styled(ModalButton)`
	padding: 0px;
	width: 125px;
	height: 44px;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	font-family: ${({ theme }) => theme.fonts.primary};
	background-color: ${({ theme }) => theme.colors.lightGrey4};

	&:hover {
		background-color: ${({ theme }) => theme.colors.red};
	}
`;

export const DeleteButton = styled.button`
	all: unset;
	width: 25px;
	height: 25px;
`;

export const ExitIcon = styled(UnstyledIcon)`
	top: 40px;
	z-index: 1;
	right: 30px;
	position: absolute;
`;

export const ExitButton = styled.button`
	all: unset;
	cursor: pointer;
`;
