import styled from 'styled-components';

import { DropdownContainerProps } from './types';

import { OptionsContainer } from './DropdownInput.styles';
import { FilterSearch } from 'features/common/components/Filters/components/MobileFilter/MobileFilter.styles';

export { CustomButton } from 'features/common/components/Filters/components/DesktopFilters/components/EventDropdown/EventDropdown.styles';
export { Error } from 'features/common/components/Form/Form.styles';
export { FilterButton } from 'features/common/components/Filters/RTKFilter.styles';
export { OptionsContainer } from 'features/common/components/Filters/components/DesktopFilters/DesktopFilters.styles';

export const DropdownWrap = styled.div<DropdownContainerProps>`
	background: ${({ theme }) => theme.colors.blackGrey3};
	border: 0.5px solid transparent;
	color: ${({ theme }) => theme.colors.white};
	height: 40px;
	width: 100%;
	padding: 5px 15px;
	border-radius: 2px;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 400;
	font-size: 16px;
	box-sizing: border-box;

	&:focus {
		outline: ${({ hasError }) => hasError && '0'};
		border: ${({ hasError, theme }) =>
			hasError ? `0.5px solid ${theme.colors.red}` : `0.5px solid ${theme.colors.lightGrey4}`};
	}
`;

export const InputDisplay = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	height: 25px;
	top: 2px;
	left: 4px;
	cursor: pointer;
`;

export const Container = styled(OptionsContainer)`
	top: 6px;
	left: -15px;
	width: calc(100% + 30px);
`;

export const Search = styled(FilterSearch)`
	max-width: unset;
`;

export const ButtonContainer = styled.div`
	gap: 16px;
	display: flex;
	flex-direction: column;
	padding: 16px;
	box-sizing: border-box;
	justify-content: center;
`;
