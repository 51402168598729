import { HeaderProps } from './types';
import { TitledHeader } from './TitledHeader';

import { MainWrapper, InfoContainer } from './Header.styles';

const Header = ({ children, modal }: HeaderProps) => {
	return (
		<MainWrapper>
			<InfoContainer>{children}</InfoContainer>
			{modal}
		</MainWrapper>
	);
};

Header.TitledHeader = TitledHeader;
export { Header };
