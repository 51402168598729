import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';

import { WebsiteColors } from 'features/Embed/components/NewSettings/WebsiteSettings';
import { TypographySettings } from 'features/Embed/components/NewSettings/TypographySettings';
import { useToastNotification } from 'hooks/useToastNotification';
import { getPartnerWidgetDefaultStyles, updatePartnerWidgetDefaultStyles } from '../services';
import { defaultValues } from '../utils';
import { sendGAEvent } from 'utils/utils';
import { FlexContainer } from 'features/common/components/FlexContainer';

import { BackgroundSection, Title, Text } from 'features/Embed/Embed.styles';
import * as Styled from './EventSiteDefaultStyles.styles';

const EventSiteDefaultStyles: FC = () => {
	const { t } = useTranslation();
	const { showNotification, TYPES } = useToastNotification();

	const { data: initialValues } = useQuery({
		queryKey: ['partnerWidgetDefaultStyles'],
		queryFn: () => getPartnerWidgetDefaultStyles(),
	});
	const formMethods = useForm({ defaultValues, values: initialValues });

	const { mutate: mutatePartnerWidgetDefaultStyles } = useMutation(updatePartnerWidgetDefaultStyles, {
		onSuccess: () => {
			sendGAEvent({ category: 'WebPage', action: 'Customize partner default' });
			showNotification({ message: t('website-default-eventsite-success'), type: TYPES.SUCCESS });
		},
		onError: () => {
			showNotification({ message: t('website-default-eventsite-error'), type: TYPES.ERROR });
		},
	});
	const handleSubmit = () => {
		const values = formMethods.getValues();
		mutatePartnerWidgetDefaultStyles(values);
	};

	return (
		<BackgroundSection>
			<FlexContainer flexDirection="column">
				<Title>{t('embed-styles-title')}</Title>
				<Text>{t('embed-styles-text')}</Text>
			</FlexContainer>
			<Styled.StyledForm onSubmit={handleSubmit} formMethods={formMethods}>
				<WebsiteColors />
				<Styled.DividerWithSpacing />
				<TypographySettings />
				<Styled.PublishButton text={t('button-save')} type="submit" data-cy="submit-styles" />
			</Styled.StyledForm>
		</BackgroundSection>
	);
};

export { EventSiteDefaultStyles };
