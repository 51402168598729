import styled from 'styled-components';

import { Text } from 'features/common/components/Text';

export const Container = styled.div`
	display: flex;
	padding: 1px 5px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.green};
	height: 25px;
	min-width: 92px;
	margin-right: 8px;
`;

export const Label = styled(Text)`
	color: ${({ theme }) => theme.colors.green};
	font-size: 12px;
`;
