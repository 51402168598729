import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { SECONDARY } from 'features/common/components/Button/constants';
import { Form } from 'features/common/components/Form';
import { AUTH } from 'features/common/components/Form/Input/constants';
import { GoogleButton } from '../GoogleButton';
import { ROUTES } from 'features/routes';
import { LoginFormProps } from './types';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';

import {
	Button,
	CreateAccountButton,
	ErrorText,
	FormContainer,
	GradientContainer,
	Line,
	Link,
	Title,
} from './LoginForm.styles';

export const LoginForm: React.FC<LoginFormProps> = ({ errors }: LoginFormProps) => {
	const { push } = useHistory();
	const { t } = useTranslation();

	const { wrongEmail, wrongPassword, unknownError } = errors;

	return (
		<FormContainer>
			<Title>{t('login-title')}</Title>
			<Form.Input id="email" name="email" label="Email" category={AUTH} data-cy="email" />
			<Form.Input id="password" name="password" label="Password" category={AUTH} type="password" data-cy="password" />
			{(wrongPassword || wrongEmail) && (
				<FlexContainer flexDirection="column">
					<ErrorText>{t(wrongPassword ? 'login-invalidPassword' : 'login-invalidEmail')}</ErrorText>
				</FlexContainer>
			)}
			{unknownError && (
				<FlexContainer flexDirection="column">
					<ErrorText>{t('login-unknownError')}</ErrorText>
				</FlexContainer>
			)}
			<Button text={t('login-logIn')} type="submit" data-cy="login" />
			<GradientContainer>
				<CreateAccountButton
					onClick={() => push(ROUTES.CREATE_ACCOUNT_PATH)}
					text={t('login-createAccount')}
					type="submit"
				/>
			</GradientContainer>
			<Line />
			<GoogleButton />
			<Link text={t('login-forgotPassword')} category={SECONDARY} onClick={() => push(ROUTES.FORGOT_PASSWORD)} />
		</FormContainer>
	);
};
