import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export enum DateFilterEnumPage {
	allTime = '',
	pastDay = 'past_day',
	pastWeek = 'past_week',
	pastMonth = 'past_month',
	pastYear = 'past_year',
}

interface EventTracker {
	[key: string]: boolean;
}

interface OrdersDataState {
	selectedPeriod: {
		unapplied: any;
		applied: DateFilterEnumPage;
	};
	selectedEvents: {
		unapplied: EventTracker;
		applied: EventTracker;
	};
	eventCount: number;
}

export const initialState = {
	selectedPeriod: {
		unapplied: DateFilterEnumPage.allTime,
		applied: DateFilterEnumPage.allTime,
	},
	selectedEvents: {
		unapplied: { '-1': true },
		applied: { '-1': true },
	},
	eventCount: 0,
} as OrdersDataState;

const OrdersPageSlice = createSlice({
	name: 'orders',
	initialState,
	reducers: {
		alterUnappliedDateOrdersPage: (state, action) => {
			state.selectedPeriod.unapplied = action.payload;
		},
		alterAppliedDateOrdersPage: (
			state,
			action: PayloadAction<DateFilterEnumPage>
		) => {
			state.selectedPeriod.applied = action.payload;
		},
		alterUnappliedEventsOrdersPage: (state, action: PayloadAction<number>) => {
			const id = action.payload;
			const { unapplied } = state.selectedEvents;
			if (id !== -1) {
				delete unapplied[-1];
				unapplied[id]
					? delete state.selectedEvents.unapplied[id]
					: (unapplied[id] = true);
			}
			if (id === -1 || Object.keys(unapplied).length === 0) {
				state.selectedEvents.unapplied = initialState.selectedEvents.unapplied;
			}
		},
		alterAppliedEventsOrdersPage: (state) => {
			state.selectedEvents.applied = state.selectedEvents.unapplied;
			OrdersPageSlice.caseReducers.updateFanEventCountOrdersPage(state);
		},
		resetUnappliedEventsOrdersPage: (state) => {
			state.selectedEvents.unapplied = state.selectedEvents.applied;
		},
		resetFanFiltersOrdersPage: (state) => {
			state.selectedEvents.applied = initialState.selectedEvents.applied;
			state.selectedPeriod.applied = initialState.selectedPeriod.applied;
		},
		updateFanEventCountOrdersPage: (state) => {
			const allEvents = state.selectedEvents.applied['-1'] ? 1 : 0;
			state.eventCount =
				Object.keys(state.selectedEvents.applied).length - allEvents;
		},
		resetFanFiltersToInitialOrdersPage: (state) => {
			state.eventCount = initialState.eventCount;
			state.selectedPeriod = initialState.selectedPeriod;
			state.selectedEvents = initialState.selectedEvents;
		},
	},
});

export const {
	alterUnappliedDateOrdersPage,
	alterAppliedDateOrdersPage,
	alterUnappliedEventsOrdersPage,
	alterAppliedEventsOrdersPage,
	updateFanEventCountOrdersPage,
	resetUnappliedEventsOrdersPage,
	resetFanFiltersToInitialOrdersPage,
} = OrdersPageSlice.actions;

export default OrdersPageSlice.reducer;

export const selectedPeriod = (state: RootState) =>
	state.ordersPage.selectedPeriod;
