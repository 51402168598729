import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'features/common/components/Table';
import { COLUMN_MAX_WIDTH, COLUMN_MIN_WIDTH } from './constants';
import { ResponsesData, TableBodyProps } from 'features/CustomFieldsResponses/types';
import { downloadTicketQR, formatDate } from './utils';

import { DataRow } from './ResponsesTable.styles';

export const TableBody: FC<TableBodyProps> = ({
	responsesQueryObject,
	questions,
	isAttendeesList,
	showDownloadQR = false,
	columnsN = 0,
	eventMode,
}) => {
	const { t } = useTranslation();
	const { data: responses } = responsesQueryObject;

	const renderCell = (value: string | number, minWidth: number, maxWidth?: number) => (
		<Table.Cell minWidth={minWidth} maxWidth={maxWidth}>
			{value}
		</Table.Cell>
	);

	const renderDownloadQRCell = (data: ResponsesData, minWidth: number, maxWidth?: number) => (
		<Table.Cell minWidth={minWidth} maxWidth={maxWidth} textAlign="center">
			{data.can_download_qr ? (
				<Table.Button
					onClick={() => downloadTicketQR(data.ticket_id, data.email)}
					text={t('download')}
					category="outlined"
					className="small"
				/>
			) : (
				'-'
			)}
		</Table.Cell>
	);

	if (!responses) {
		return null;
	}

	return (
		<tbody>
			{responses.pages.map((page: ResponsesData[]) =>
				page.map((data: ResponsesData, index: number) => {
					const responses = data.responses.filter(
						(response) => !['ATTENDEE_NAME', 'ATTENDEE_EMAIL'].includes(response.custom_field.name)
					);

					let cols = [];

					if (eventMode === 'normal') {
						cols = [
							<>
								{renderCell(data.amount.toFixed(2), COLUMN_MIN_WIDTH[5])}
								{renderCell(formatDate(data.date), COLUMN_MIN_WIDTH[6])}
								{renderCell(data.status, COLUMN_MIN_WIDTH[7])}
								{renderCell(data.ticket_types_string, COLUMN_MIN_WIDTH[8])}
							</>,
						];
					} else {
						cols = [
							<>
								{renderCell(formatDate(data.date), COLUMN_MIN_WIDTH[6])}
								{renderCell(data.ticket_types_string, COLUMN_MIN_WIDTH[8])}
							</>,
						];
					}

					cols = cols.concat(
						questions.map((question) => {
							const response =
								responses.find((response) => response.custom_field.name === question)?.string_value || '-';
							return renderCell(response, COLUMN_MIN_WIDTH[4], COLUMN_MAX_WIDTH[4]);
						})
					);

					return (
						<DataRow gridColumns={`repeat(${columnsN}, 1fr)`} key={[data.order_id, index].join('_')} textAlign="center">
							{renderCell(data.order_id, COLUMN_MIN_WIDTH[0])}
							{renderCell(data.is_buyer ? 'Buyer' : 'Attendee', COLUMN_MIN_WIDTH[1])}
							{renderCell(data.email || '-', COLUMN_MIN_WIDTH[2])}
							{renderCell(data.full_name || '-', COLUMN_MIN_WIDTH[3])}

							{(isAttendeesList ? cols : cols.reverse()).map((el) => el)}
							{isAttendeesList && showDownloadQR && renderDownloadQRCell(data, COLUMN_MIN_WIDTH[9])}
						</DataRow>
					);
				})
			)}
		</tbody>
	);
};
