import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import emptyHighlightsPhoto from 'assets/images/emptyHighlights.svg';
import highlightsHasLogoEmptyPhoto from 'assets/images/highlightsHasLogoEmpty.svg';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { PRIMARY } from 'features/common/components/Button/constants';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { ROUTES } from 'features/routes';
import { common } from 'theme';
import { EmptyHighlightsProps } from '../types';

import { Image, Button, EmptyHighlightsContainer, Title, SubTitle } from './EmptyHighlights.styles';

const EmptyHighlights: FC<EmptyHighlightsProps> = ({ hasLogo }) => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();

	const createEvent = () => push(ROUTES.CREATE_EVENT_PATH);

	const hasLogoAndIsLargeScreen = hasLogo && isLargeScreen;

	return (
		<EmptyHighlightsContainer hasLogo={hasLogo}>
			<Image src={hasLogoAndIsLargeScreen ? highlightsHasLogoEmptyPhoto : emptyHighlightsPhoto} />
			<FlexContainer
				flexDirection="column"
				alignItems={isLargeScreen ? 'flex-start' : 'center'}
				backgroundColor={isLargeScreen ? 'transparent' : common.colors.grey}
			>
				<Title element="h2">{t(`home-highlights${hasLogoAndIsLargeScreen ? '-hasLogo' : ''}-empty-title`)}</Title>
				<SubTitle element="h3">{t(`home-highlights${!isLargeScreen ? '-mobile' : ''}-empty-subtitle`)}</SubTitle>
				<Button text={t('home-createEvent')} category={PRIMARY} type="button" onClick={createEvent} />
			</FlexContainer>
		</EmptyHighlightsContainer>
	);
};

export { EmptyHighlights };
