import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';
import { OptionInfoProps } from './types';

export const OptionContainer = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 32px;
	height: fit-content;
	padding-top: 72px;
	width: 50%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		padding-top: 0;
		width: 80%;
	}
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 16px;
		width: 100%;
	}
`;

export const SectionTitle = styled(Text)`
	font-size: 24px;
	line-height: 32px;
`;

export const RefundOption = styled.div`
	background-color: ${({ theme }) => theme.colors.blackGrey4};
	border-radius: 8px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 56px;
	width: 80%;

	&.checked {
		border: 1px solid ${({ theme }) => theme.colors.green};
		padding: 55px;
		@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			padding: 23px;
		}
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		width: 100%;
	}
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px;
	}
`;

export const Label = styled.label`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 18px;
	line-height: 20px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
		line-height: 20px;
		width: 100%;
	}
`;
export const OptionInfo = styled(Text)<OptionInfoProps>`
	color: ${({ theme, isSelected }) => !isSelected && theme.colors.lightGrey13};
	display: block;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	padding-left: 36px;
	text-transform: unset;
`;

export const OptionInfoBold = styled.span`
	font-weight: 600;
`;

export const CustomFlex = styled.div`
	display: flex;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xxs}) {
		flex-direction: column;
	}
`;

export const ResolverContainer = styled.div`
	display: flex;
	width: 35%;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xxs}) {
		margin-left: 36px;
		width: 75%;
	}
`;

export const AmountInput = styled.input`
	background-color: ${({ theme }) => theme.colors.black};
	border: none;
	box-sizing: border-box;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};
	height: 34px;
	font-size: 14px;
	margin-left: 36px;
	padding-left: 8px;
	width: 35%;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xxs}) {
		max-width: 168px;
		width: 75%;
	}
`;

export const ResolverBtn = styled(Button)`
	background: ${({ theme }) => theme.colors.green};
	border-radius: 0;
	height: 34px;
	margin: 0;
	padding: 0;
	width: 84px;

	&.unselected {
		background: ${({ theme }) => theme.colors.black2};
	}
`;

export const UsageTipText = styled(Text)`
	color: ${({ theme }) => theme.colors.lightGrey13};
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	margin-left: 36px;
	text-transform: none;
`;

export const ReasonsFlex = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 24px;
	width: 80%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		width: 100%;
	}
`;

export const ReasonTitle = styled(Text)`
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
`;

export const DiminishedText = styled(Text)`
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
`;

export const TextArea = styled.textarea`
	background-color: ${({ theme }) => theme.colors.grey4};
	border-radius: 8px;
	box-sizing: border-box;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 16px;
	height: 110px;
	padding: 8px;
	width: 100%;
`;
