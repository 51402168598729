import { useState, useEffect } from 'react';

import { SMALL_RESOLUTION, LARGE_RESOLUTION, MOBILE_RESOLUTION } from 'features/common/constants';
import { Size } from './types';

const useWindowSize = (): Size => {
	const [windowSize, setWindowSize] = useState<Size>({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSize;
};

const useResponsiveScreen = () => {
	const { width } = useWindowSize();
	return width > SMALL_RESOLUTION;
};

const useResponsiveScreenLG = () => {
	const { width } = useWindowSize();
	return width > LARGE_RESOLUTION;
};

const useResponsiveScreenXXS = () => {
	const { width } = useWindowSize();
	return width < MOBILE_RESOLUTION;
};

export { useWindowSize, useResponsiveScreen, useResponsiveScreenLG, useResponsiveScreenXXS };
