import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Form } from 'features/common/components/Form';
import { FormProps } from '../../../EditEvent/components/RightForm/types';
import { DefaultValuesTypes } from 'features/EditEvent/types';

const DayForm: FC<FormProps> = ({ index }) => {
	const { t } = useTranslation();
	const {
		getValues,
		trigger,
		formState: { errors },
	} = useFormContext<DefaultValuesTypes>();

	const resolveErrs = () => {
		const arrayOfErrs = errors.dates?.[index];
		if (arrayOfErrs?.end_date) {
			return arrayOfErrs?.end_date.message;
		} else if (arrayOfErrs?.start_date) return arrayOfErrs?.start_date.message;
	};

	return (
		<div>
			<Form.InputDateAndTime
				id="eventStartDate"
				name="dates"
				label={t('editEvent-start-date')}
				index={index}
				value={new Date(getValues('dates')[index]?.start_date || null)}
				onChange={() => trigger('dates')}
			/>
			<Form.InputDateAndTime
				id="eventEndDate"
				name="dates"
				label={t('editEvent-end-date')}
				index={index}
				value={new Date(getValues('dates')[index]?.end_date || null)}
				calcErrors={resolveErrs()}
				onChange={() => trigger('dates')}
			/>
		</div>
	);
};

const memorizedDayForm: FC<FormProps> = memo(DayForm);

export { memorizedDayForm as DayForm };
