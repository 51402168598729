import { FlexContainer } from 'features/common/components/FlexContainer';
import { QuantityCard } from '../QuantityCard/QuantityCard';
import { RecipientInfoFormProps } from './types';

import * as Styled from './RecipientInfoForm.styles';

export const RecipientInfoForm = ({ id, ticketTypes, onRemove }: RecipientInfoFormProps) => {
	const currentRecipient = `recipients[${id}]`;
	const lastnameId = `${currentRecipient}.lastName`;
	const firstnameId = `${currentRecipient}.firstName`;
	const emailId = `${currentRecipient}.email`;

	return (
		<Styled.CardContainer>
			<FlexContainer justifyContent="space-between" alignItems="center">
				<Styled.RecipientName>Recipient {id + 1}</Styled.RecipientName>
				<Styled.Icon iconName="close" onClick={() => onRemove(id)} />
			</FlexContainer>
			<Styled.WrapperInputs>
				<Styled.Input label="First Name" name={firstnameId} id={firstnameId} />
				<Styled.Input label="Last Name" name={lastnameId} id={lastnameId} />
			</Styled.WrapperInputs>
			<Styled.Input label="Attendee Email" name={emailId} id={emailId} />
			{ticketTypes?.map(({ type, initialPrice, ticketTypeId, sold, soldOut, amount }, i) => (
				<QuantityCard
					type={type}
					key={ticketTypeId}
					recipientId={id}
					id={i}
					initialPrice={initialPrice}
					ticketTypeId={ticketTypeId}
					sold={sold}
					soldOut={soldOut}
					amount={amount}
				/>
			))}
		</Styled.CardContainer>
	);
};
