import { AppDispatch } from 'store';
import { setLastEvent } from 'store/slices/lastEventSlice';
import { setLastMultiDay } from 'store/slices/lastMultiDayEventSlice';
import { ResetValuesProps, SendTicketsType } from '../../types';
import { handleTickeTypetDic } from './calculations';

export const resetValues =
	({ event, multiday, isMultiDay, reset }: ResetValuesProps) =>
	(dispatch: AppDispatch) => {
		reset();
		if (isMultiDay && multiday) {
			const resetMultiday = multiday.map((day) => ({
				...day,
				ticketTypes: day.ticketTypes.map((ticket) => ({
					...ticket,
					quantity: 0,
					promoCodeId: 0,
					promoCodeApplied: false,
					initialPrice: ticket.priceWithNoDiscount,
				})),
			}));
			dispatch(setLastMultiDay(resetMultiday));
		} else if (event) {
			const resetEvent = {
				...event,
				ticketTypes: event.ticketTypes.map((ticket) => ({
					...ticket,
					quantity: 0,
					promoCodeId: 0,
					promoCodeApplied: false,
					initialPrice: ticket.priceWithNoDiscount,
				})),
			};
			dispatch(setLastEvent(resetEvent));
		}
	};

export const sendTickets = ({ event, multiday, isMultiDay, getValues, mutateSendTickets }: SendTicketsType) => {
	const promocodesIds = [...new Set(event?.ticketTypes.map(({ promoCodeId }) => promoCodeId).filter(Number))];
	return mutateSendTickets({
		promocodesIds,
		email: getValues('email'),
		ticketTypeDic: handleTickeTypetDic({ multiday, event, isMultiDay, getValues }),
	});
};
