import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DropzoneInput } from 'features/common/components/ImageInputs/DropzoneInput';
import { maxPicturesAllowed } from './utils';
import { CardGalleryImageUploaded } from '../CardGalleryImageUploaded';
import { ToolTip } from 'features/common/components/InfoTooltip';
import { Icon } from 'features/common/components/Icon';

import { InfoText, SubTitle } from '../../MediaContent.styles';
import { Root, CardsWrapper } from './GalleryImages.styles';

const GalleryImages = () => {
	const { t } = useTranslation();
	const { watch } = useFormContext();
	const images = watch('mediaContent.media').filter((image: any) => image.type !== 'video');

	return (
		<Root>
			<SubTitle>
				{t('website-media-content-gallery-images', {
					imagesUploaded: images?.length,
					maxPicturesAllowed,
				})}
			</SubTitle>
			<ToolTip
				className="top"
				title={t('website-media-content-gallery-images-tooltip-title')}
				text={t('website-media-content-gallery-images-tooltip-text')}
			>
				<Icon iconName="help" />
			</ToolTip>
			<InfoText>{t('website-media-content-gallery-info')}</InfoText>
			<CardsWrapper>
				{images?.map((image: any) => (
					<CardGalleryImageUploaded image={image} key={image.url} />
				))}
			</CardsWrapper>

			<DropzoneInput maxPicturesAllowed={maxPicturesAllowed} inputId="mediaContent.media" cyId="gallery" />
		</Root>
	);
};
export { GalleryImages };
