import { FC } from 'react';
import { ToggleProps } from './types';
import dolar from 'assets/images/dolar.svg';
import percentage from 'assets/images/percentage.svg';
import dolarBlack from 'assets/images/dolarBlack.svg';
import percentageBlack from 'assets/images/percentageBlack.svg';
import { Icon } from 'features/common/components/Icon';

import {
	Background,
	Circle,
	Input,
	StyledLabel,
	UncheckedIconContainer,
} from './ToggleDiscount.styles';

const ToggleDiscount: FC<ToggleProps> = ({
	id,
	isPercentage,
	disabled,
	name,
	handleChange,
	...rest
}: ToggleProps) => (
	<StyledLabel htmlFor={id ?? name} disabled={disabled}>
		<Input
			{...rest}
			aria-label={id ?? name}
			id={id ?? name}
			name={name}
			type="checkbox"
			onChange={handleChange}
			isPercentage={isPercentage}
			disabled={disabled}
		/>
		<Background disabled={disabled} isPercentage={isPercentage}>
			<UncheckedIconContainer isPercentage={!isPercentage}>
				<Icon src={isPercentage ? dolarBlack : percentageBlack} size="M" />
			</UncheckedIconContainer>
			<Circle disabled={disabled} isPercentage={isPercentage}>
				<Icon src={isPercentage ? percentage : dolar} size="M" />
			</Circle>
		</Background>
	</StyledLabel>
);

export { ToggleDiscount };
