import styled from 'styled-components';

import { Text as UnstyledText } from 'features/common/components/Text';
import { Divider } from 'features/common/components/Divider';
import { Form } from 'features/common/components/Form';
import { Button } from 'features/common/components/Button';

export const Text = styled(UnstyledText)`
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;

export const Title = styled(UnstyledText)`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 28px;
	font-weight: 700;
	line-height: 42px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 18px;
	}
`;

export const ContentWrapper = styled.div`
	margin: 48px 0 40px 0;
	overflow: auto;
	width: calc(100vw - 14%);

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 36px 0 32px 0;
	}
`;

export const Container = styled(Form)`
	display: flex;
	align-items: stretch;
	flex-direction: column;
	padding: 0 70px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 0;
		width: 99%;
	}
`;

export const DividerWithSpacing = styled(Divider)`
	margin: 32px 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 16px 0;
	}
`;

export const PublishButton = styled(Button)`
	width: 200px;
	display: flex;
	justify-content: center;
	margin: 0;
	font-weight: 600;
	font-size: 18px;
	align-self: center;
	padding: 12px 24px;
`;
