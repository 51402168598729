import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import ticketBlackBackground from 'assets/images/ticketBlackBackground.svg';
import { TicketsQuantityButtonProps } from './types';
import { Icon } from 'features/common/components/Icon';

import {
	ConfirmedTicketTypes,
	TicketQuantityButtonContainer as Container,
	TicketQuantityIcon,
	TicketQuantityText,
} from './TicketsQuantityButton.styles';

const TicketsQuantityButton: React.FC<TicketsQuantityButtonProps> = ({
	type,
}: TicketsQuantityButtonProps) => {
	const { t } = useTranslation();

	const { getValues } = useFormContext();

	const ticketQuantityText = {
		ticketSpecific:
			getValues('selected').length > 0
				? 'promoCode-Form-specific-ticketsQuantityConfirmed-description'
				: 'promoCode-Form-specific-ticketsQuantity-description',
		isEdit:
			getValues('selected').length > 0
				? 'promoCode-Form-specific-ticketsQuantityConfirmed-description'
				: 'promoCode-Form-specific-ticketsQuantity-description',
		unlimited: 'promoCode-Form-unlimited-ticketsQuantity-description',
		eventSpecific: '',
		existing: '',
	};

	return (
		<Container ticketType={type} type="button">
			{type === 'unlimited' && (
				<TicketQuantityIcon src={ticketBlackBackground} />
			)}
			<TicketQuantityText ticketType={type}>
				{type === 'ticketSpecific' && getValues('selected').length > 0 && (
					<ConfirmedTicketTypes>
						{getValues('selected').length}
					</ConfirmedTicketTypes>
				)}
				{t(ticketQuantityText[type ?? 'isEdit'])}
			</TicketQuantityText>
			{type === 'ticketSpecific' && <Icon iconName="arrowRight" />}
		</Container>
	);
};

export { TicketsQuantityButton };
