import Decimal from 'decimal.js-light';

import { ResolverEnum } from 'features/RefundTicketsSummary/types';
import { safeParseFloat } from 'features/RefundTicketsSummary/utils';

export const validate = (value: string, resolver: ResolverEnum, minTicketPrice: Decimal) => {
	if (value === '') return true;

	const inputAsDecimal = new Decimal(safeParseFloat(value));
	if (resolver === ResolverEnum.dollars) {
		if (inputAsDecimal.decimalPlaces() >= 3 || minTicketPrice.lessThan(inputAsDecimal)) return false;
	} else if (resolver === ResolverEnum.percentage) {
		if (inputAsDecimal.greaterThan(100)) return false;
	}
	return true;
};
