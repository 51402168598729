import { TFunction } from 'react-i18next';

export const addVisibilityClass = (isVisible: boolean): string => {
	return isVisible ? 'show' : '';
};

export const addShowAllClass = (perPage: string): string =>
	perPage === '-1' ? 'show-all' : '';

export const lastIndicator = <arrayData>(index: number, array: arrayData[]) =>
	index === array.length - 1 && index !== 0 ? 'last' : '';

export const getAllEventsOption = (t: TFunction<'translation', undefined>) => ({
	name: t('fanDatabase-filters-all-events'),
	id: -1,
});
