import { EditEventForm } from './EditEventForm';
import { useEditEventApi } from './hooks';

const EditEvent = () => {
	const { event, editEvent } = useEditEventApi();

	return <>{event && <EditEventForm event={event} editEvent={editEvent} />}</>;
};

export { EditEvent };
