import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Form } from 'features/common/components/Form';
import { Button } from 'features/common/components/Button';
import { ToggleSwitch } from 'features/common/components/ToggleSwitch';

import { CheckboxButton } from 'features/common/components/CheckboxButton/CheckBoxButton.styles';

export const Root = styled(Form)`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const FormContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 25px;
	width: 100%;
	margin-bottom: 250px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 300px;
		margin-bottom: 10px;
	}
`;

export const Title = styled(Text)`
	font-size: 20px;
	align-self: start;
`;

export const StyledForm = styled.div`
	width: 60%;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

export const FixedButtonContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.blackGrey};
	position: fixed;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	left: 0;
	box-shadow: ${({ theme }) => theme.shadows.fixedButtonShadow};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		box-shadow: none;
		position: relative;
	}
`;

export const UpdateButton = styled(Button)`
	width: 350px;
	background-color: ${({ theme }) => theme.colors.blackGrey};
	font-size: 18px;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 600;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 300px;
	}
`;

export const CheckboxStyled = styled(CheckboxButton)`
	margin-left: 18px;
`;

export const FormInputTitle = styled(Title)`
	color: ${({ theme }) => theme.colors.lightGrey2};
`;

export const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 25px;
	margin-top: 10px;
`;

export const ToggleSwitchStyled = styled(ToggleSwitch)`
	cursor: pointer;
	margin-top: 10px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-bottom: 10px;
		margin-top: 20px;
	}
`;

export const SsnOrEinContainer = styled.div`
	display: flex;
	gap: 18px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		align-items: flex-start;
		gap: 0px;
	}
`;

export const FormInputContainer = styled.div`
	margin: 20px 0;
`;
