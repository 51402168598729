import styled from 'styled-components';

import { Text as UnstyledText } from 'features/common/components/Text';

export const Wrapper = styled.div`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: flex;
		padding: 0px 10px;
		align-items: center;
		flex-direction: column;
	}
`;

export const BackgroundSection = styled.div`
	background-color: ${({ theme }) => theme.colors.grey16};
	display: flex;
	padding: 23px 32px;
	border-radius: 8px;
	box-sizing: border-box;
	gap: 16px;
	width: 100%;
	flex-direction: column;
`;

export const Text = styled(UnstyledText)`
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;

export const Title = styled(UnstyledText)`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 28px;
	font-weight: 700;
	line-height: 42px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 18px;
	}
`;
