import { FC, ChangeEvent, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { REFUND_OPTIONS, RESOLVERS } from './constants';
import { OptionsEnum, ResolverEnum } from 'features/RefundTicketsSummary/types';
import { RefundOptionProps } from './types';
import { validate } from './utils';
import { formatNum } from '../../utils';

import { RadioButton } from 'features/common/components/RadioButton/RadioButton.styles';
import * as Styled from './RefundOptions.styles';

export const RefundOptions: FC<RefundOptionProps> = ({
	optionsState: [refundOption, setRefundOption],
	resolverState: [selectedResolver, setSelectedResolver],
	partialAmountState: [partialAmount, setPartialAmount],
	setDescription,
	purchaseTotal,
	minTicketPrice,
	numberOfTickets,
}) => {
	const { t } = useTranslation();
	const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
		validate(e.target.value, selectedResolver, minTicketPrice) && setPartialAmount(e.target.value);

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => (e.key === 'e' || e.key === '-') && e.preventDefault();

	const handleResolverClick = (resolver: ResolverEnum) => {
		setSelectedResolver(resolver);
		setPartialAmount('');
	};

	// const refundOptions = purchaseTotal.equals(0) ? REFUND_OPTIONS.slice(0, 1) : REFUND_OPTIONS;
	const refundOptions = REFUND_OPTIONS.slice(0, 1);
	const isFreePurchase = purchaseTotal.isZero();
	return (
		<Styled.OptionContainer>
			<Styled.SectionTitle>
				{t(
					isFreePurchase
						? 'refundTicketsSummary-refundOptions-void-enter-value'
						: 'refundTicketsSummary-refundOptions-enter-value'
				)}
			</Styled.SectionTitle>
			{refundOptions.map((option) => {
				const isChecked = option === refundOption;
				return (
					<Styled.RefundOption
						key={option}
						onClick={() => setRefundOption(option)}
						className={isChecked ? 'checked' : ''}
					>
						<FlexContainer gap="16px">
							<RadioButton id={option} type="radio" readOnly checked={isChecked} />
							<Styled.Label htmlFor={option}>
								{t(
									isFreePurchase
										? `refundTicketsSummary-refundOptions-${option}-void`
										: `refundTicketsSummary-refundOptions-${option}-refund`
								)}
							</Styled.Label>
						</FlexContainer>
						<Styled.OptionInfo isSelected={isChecked}>
							{!isFreePurchase ? (
								<>
									{t(`refundTicketsSummary-refundOptions-${option}-refund-message`)}
									{option === OptionsEnum.FULL ? (
										formatNum(purchaseTotal)
									) : (
										<Styled.OptionInfoBold>{t('refundTicketsSummary-refundOptions-per-ticket')}</Styled.OptionInfoBold>
									)}
								</>
							) : (
								<>{t(`refundTicketsSummary-refundOptions-${option}-void-message`)}</>
							)}
						</Styled.OptionInfo>
						{option === OptionsEnum.PARTIAL && isChecked && (
							<>
								<Styled.CustomFlex>
									<Styled.AmountInput
										type="number"
										onChange={handleChange}
										placeholder={partialAmount}
										value={partialAmount}
										onKeyDown={handleKeyDown}
									/>
									<Styled.ResolverContainer>
										{RESOLVERS.map((resolver) => (
											<Styled.ResolverBtn
												key={resolver}
												type="button"
												text={resolver}
												className={selectedResolver === resolver ? 'selected' : 'unselected'}
												onClick={() => handleResolverClick(resolver)}
											/>
										))}
									</Styled.ResolverContainer>
								</Styled.CustomFlex>
								{numberOfTickets > 1 && selectedResolver === ResolverEnum.dollars && (
									<Styled.UsageTipText>{`${t('refundTicketsSummary-refundOptions-max-amount')} $${formatNum(
										minTicketPrice
									)}`}</Styled.UsageTipText>
								)}
							</>
						)}
					</Styled.RefundOption>
				);
			})}
			<Styled.ReasonsFlex>
				<Styled.ReasonTitle element="h3">
					{!isFreePurchase
						? t('refundTicketsSummary-refundOptions-reason')
						: t('refundTicketsSummary-refundOptions-void-reason')}
					<Styled.DiminishedText> ({t('optional')}) </Styled.DiminishedText>
				</Styled.ReasonTitle>
				<Styled.TextArea onChange={(e) => setDescription(e.target.value)} />
			</Styled.ReasonsFlex>
		</Styled.OptionContainer>
	);
};
