import styled from 'styled-components';

export const LeftSide = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	box-sizing: border-box;
	padding: 48px;
	width: 100%;
`;

export const Container = styled.div`
	height: 100%;
`;

export const StickyDiv = styled.div`
	position: sticky;
	top: 0px;
`;
