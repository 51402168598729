import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { MainLayout } from 'layouts/MainLayout';
import { useGetWebsiteSettingsData } from './hooks/useGetWebsiteSettingsData';
import { updateWebisteData } from './services';
import { sendGAEvent, urlPreviewPage } from 'utils/utils';
import { useToastNotification } from 'hooks/useToastNotification';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { defaultValues } from './utils';
import { IdParamsType } from 'utils/types';
import { FieldProps } from './types';
import { WebsiteColors } from 'features/Embed/components/NewSettings/WebsiteSettings';
import { TypographySettings } from 'features/Embed/components/NewSettings/TypographySettings';
import { Header } from 'features/common/components/Header';

import * as Styled from './EventSiteEditStyles.styles';

export const EventSiteEditStyles = () => {
	const { t } = useTranslation();
	const { id }: IdParamsType = useParams();
	const eventId = parseInt(id);
	const { data: initialValues } = useGetWebsiteSettingsData(id);
	const { showNotification, TYPES } = useToastNotification();
	const formMethods = useForm({
		defaultValues,
		values: initialValues,
	});

	const { mutate: mutateWebsite } = useMutation(updateWebisteData, {
		onSuccess: () => {
			sendGAEvent({ category: 'WebPage', action: 'Customize webpage' });
			showNotification({ message: t('website-default-eventsite-success'), type: TYPES.SUCCESS });
		},
		onError: () => {
			showNotification({ message: t('website-default-eventsite-error'), type: TYPES.ERROR });
		},
	});
	const handleSubmit = async (attributes: FieldProps) => {
		mutateWebsite({ attributes, eventId });
	};
	const handleLiveButton = () => window.open(urlPreviewPage(eventId), '_blank');

	return (
		<MainLayout>
			<Header.TitledHeader title={t('dashboard-eventWebpage')} subtitle={initialValues?.eventName} />
			<Styled.ContentWrapper>
				<Styled.Container onSubmit={handleSubmit} formMethods={formMethods}>
					<FlexContainer flexDirection="column" margin="0 0 24px 0">
						<Styled.Title>{t('eventSiteStyles-title')}</Styled.Title>
						<Styled.Text>{t('eventSiteStyles-info')}</Styled.Text>
					</FlexContainer>
					<WebsiteColors />
					<Styled.DividerWithSpacing />
					<TypographySettings />
					<Styled.DividerWithSpacing />
					<FlexContainer gap="12px" justifyContent="center">
						<Styled.PublishButton
							text={t('button-preview')}
							type="button"
							onClick={handleLiveButton}
							data-cy="preview-btn"
						/>
						<Styled.PublishButton text={t('button-save')} type="submit" data-cy="submit-styles" />
					</FlexContainer>
				</Styled.Container>
			</Styled.ContentWrapper>
		</MainLayout>
	);
};
