import styled from 'styled-components';

import { ModalPopupProps } from './types';

export const AbsoluteWrap = styled.div`
	position: absolute;
`;

export const ModalPopup = styled.div<ModalPopupProps>`
	background-color: ${({ theme }) => theme.colors.grey11};
	border-radius: 16px 16px 0px 0px;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};
	height: fit-content;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 4;
	width: 100vw;
	transform: translateY(${({ isOpen }) => (isOpen ? '0vh' : '100%')});
	transition: all 0.2s ease;
`;

export const ModalBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 3;
	width: 100vw;
	height: 100vh;
	background-color: ${({ theme }) => theme.colors.backgroundBlur};
	display: none;

	&.show {
		display: block;
	}
`;

export const Line = styled.div`
	width: 32px;
	border: 2px solid ${({ theme }) => theme.colors.lightGrey7};
	align-self: center;
	border-radius: 8px;
	margin-top: 16px;
`;
