// #region Global Imports
import { BaseTheme } from 'styled-components';
// #endregion Global Imports

const common: BaseTheme = {
	colors: {
		black: '#000000',
		black2: '#333333',
		black3: '#212121',
		black4: '#1c1b1b',
		black5: '#11111166',
		black6: '#1A1A1A',
		blackGradient: 'linear-gradient(180deg, #444444 0%, #323232 100%)',
		blackGradient2: 'linear-gradient(0deg, #000000 12.24%, rgba(0, 0, 0, 0.35) 71.47%, rgba(0, 0, 0, 0) 97.34%)',
		blackGradientHover: 'linear-gradient(180deg, #656565 0%, #323232 100%)',
		blackGrey: '#232323',
		blackGrey1: '#121212',
		blackGrey2: 'rgba(32, 31, 31, 0.6)',
		blackGrey3: 'linear-gradient(92.21deg, #1F1F1F 5.21%, #070707 98.35%)',
		blackGrey4: '#292929',
		blackGrey5: '#161616',
		blue: '#2A406C',
		blue2: '#2F80ED',
		greenBlue: '#6cbfbf',
		greenBlue2: '#6DE0A2',
		greenBlue3: '#57EBA4',
		greyBlue: '#2d333c',
		darkGrey: '#282C34',
		darkGrey2: '#8490AB',
		darkGrey3: 'rgba(158, 158, 158, 0.25)',
		darkGrey4: '#0e0e0e',
		darkGrey5: '#313131',
		darkGrey6: '#525252',
		darkGrey7: '#282828',
		green: '#A2C166',
		green2: '#C7ED7C',
		green3: '#63A771',
		green4: '#8FAE53',
		green5: '#54BA5D',
		green6: '#82AA60',
		green7: '#728450',
		green8: '#76A12F',
		green9: '#59CE63',
		green10: '#2EBD85',
		green11: '#1eb357',
		green12: '#27AE60',
		green13: '#2CC671',
		greenSuccess: '#2EBD85',
		verticalGreyGradient: 'linear-gradient(180deg, #121212 61.98%, rgba(18, 18, 18, 0) 100%)',
		verticalUpGreyGradient: 'linear-gradient(0deg, #121212 61.98%, rgba(18, 18, 18, 0) 100%)',
		verticalUpGreyGradient2: 'linear-gradient(0deg, #232323 70%, rgba(18, 18, 18, 0) 100%)',
		grey: '#303030',
		grey2: '#323232',
		grey3: '#202020',
		grey4: '#2B2B2B',
		grey5: '#3d3d3d',
		grey6: '#4B4B4B',
		grey7: '#474747',
		grey8: '#9a9a9a',
		grey9: '#828282',
		grey10: '#474747',
		grey11: '#363636',
		grey12: '#353535',
		grey13: '#4A4A4A',
		grey14: '#3E3E3E',
		grey15: '#BDBDBD',
		grey16: '#444444',
		grey17: '#b0b0b0',
		grey18: '#4F4F4F',
		grey19: '#E7E7E7',
		grey20: '#474747',
		lightGrey: '#535353',
		lightGrey2: 'rgba(255, 255, 255, 0.5)',
		lightGrey3: '#919191',
		lightGrey4: '#7C7C7C',
		lightGrey5: '#606060',
		lightGrey6: '#656565',
		lightGrey7: '#f2f2f2',
		lightGrey8: '#5E5E5E',
		lightGrey9: '#9B9B9B',
		lightGrey10: '#868686',
		lightGrey11: '#949494',
		lightGrey12: '#ACACAC',
		lightGrey13: '#A8A8A8',
		lightGrey14: '#494949',
		lightGrey15: '#C0C0C0',
		purpleGradient: 'linear-gradient(180deg, #9C6EBA 0%, #835D9C 100%)',
		purpleGradientHover: 'linear-gradient(180deg, #BD90DB 0%, #835D9C 100%)',
		purpleGreenGradient: 'linear-gradient(180deg, #D700FF 0%, #6DE0A2 49.48%, #236742 100%)',
		white: '#FFFFFF',
		whiteNac: '#E2E2E2',
		red: '#EB5757',
		redError: '#CA5353',
		crimson: '#CA5370',
		crimson1: '#B04056',
		crimson2: '#B2415C',
		crimsonGradient: 'linear-gradient(180deg, #D55971 0%, #B64057 100%)',
		crimsonGradientHover: 'linear-gradient(180deg, #ef6580 0%, #b64057 100%)',
		orange: '#F2994A',
		orange2: '#FFA500',
		orange3: '#FFA500',
		purple1: '#9C6EBA',
		purple2: ' #8751A9',
		purple3: '#D700FF',
		purple4: '#885CA6',
		white2: '#D9D9D9',
		whitePerl: '#F3F4F4',
		greenGradient: 'linear-gradient(111.69deg, #66AB6D 3.91%, #2D5FA9 114.43%);',
		greenGradient2: 'linear-gradient(180deg, #A2C166 0%, #66A771 100%)',
		greenGradient2Hover: 'linear-gradient(180deg, #BBDE80 0%, #66A771 100%)',
		greenGradient3: 'linear-gradient(111.69deg, #A2C166 3.91%, #2A8C7B 114.43%)',
		greenGradient4: 'linear-gradient(180deg, #9dbf67 0%, #369279 100%)',
		aquamarine: '#529083',
		lightBlue: '#6CBFBF',
		lightBlue1: '#4FB2B2',
		lightBlue2: '#4AC1C0',
		lightBlue3: '#38effd',
		lightBlueGradient: 'linear-gradient(180deg, #60CBCB 0%, #5FABAB 100%)',
		lightBlueGradientHover: 'linear-gradient(180deg, #6CBFBF 0%, #56CECA 100%)',
		imageOverlay: 'linear-gradient(179.73deg, rgba(0, 0, 0, 0.7) -1.81%, rgba(0, 0, 0, 0) 99.76%)',
		backgroundBlur: 'rgba(0, 0, 0, 0.5)',
	},
	breakpoints: {
		xxs: '550px',
		xs: '650px',
		sm: '844px',
		md: '950px',
		lg: '1280px',
		xlg: '1500px',
		xl: '1920px',
		xxl: '2560px',
	},
	fonts: {
		primary: 'Poppins',
	},
	shadows: {
		base: '0px -3.14182px 28.2764px rgba(0, 0, 0, 0.1)',
		menuShadow: '12px 12px 24px rgba(0, 0, 0, 0.15)',
		imageShadow: '0px 2px 11px rgba(0, 0, 0, 0.25)',
		greenGradientShadow: '0px 0px 5px 3px rgba(84, 146, 129, 0.25)',
		fixedButtonShadow: '0px 0px 180px 20px #232323',
		hostingOptionShadow: '0px 0px 24px 0px rgba(103, 148, 15, 0.41)',
		buttonWithIconShadow: '0px 0px 24px 0px #a1c06533',
	},
	widths: {
		hiddenSidebar: '42.88px',
		sidebar: '268px',
	},
};

export { common };
