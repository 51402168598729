import styled from 'styled-components';

import { isCheckedProps } from './types';

export const Card = styled.div`
	width: 100%;
	max-width: 400px;
	height: auto;
	min-height: 100px;
	font-family: ${({ theme }) => theme.fonts.primary};
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.grey};
	position: relative;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
		min-width: 300px;
	}
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 22px;
	min-height: 125px;
`;

export const Title = styled.h4`
	font-size: 24px;
	margin: 0;
	font-weight: 500;
	font-family: ${({ theme }) => theme.fonts.primary};
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
`;

export const EventStatusText = styled.h4`
	font-size: 15px;
	margin: 0;
	line-height: 17px;
	font-weight: 400;
	margin-right: 10px;
	color: ${({ isChecked, theme }: isCheckedProps) => (isChecked ? theme.colors.green : theme.colors.lightGrey4)};
`;

export const UrlText = styled.h4`
	font-size: 12px;
	margin: 0;
	line-height: 17px;
	cursor: pointer;
	font-weight: 500;
	width: fit-content;

	&.kinectair-margin {
		margin: 15px 0;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 11px;
	}
`;

export const CardImage = styled.img`
	border-radius: 0 0 8px 8px;
	padding: 0;
	cursor: pointer;
	height: 100%;
	object-fit: cover;
	width: 100%;
	margin: 0;
`;

export const ToggleContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 15px;
`;

export const ImageContainer = styled.div`
	cursor: pointer;
	width: 100%;
	height: 250px;
	padding: 0;
	position: relative;
`;

export const ImageInfo = styled.div`
	height: 100%;
`;
