import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Search } from 'features/common/components/Search';
import { CardContainer } from './components/CardContainer';
import { EventSiteDefaultStyles } from './components/EventSiteDefaultStyles';
import { EmbedInfo } from './components/EmbedInfo';
import { FILTER_OPTIONS } from 'features/common/components/Filters/LocalFilter/constants';
import { FilterOptionType } from 'features/common/components/Filters/LocalFilter/types';
import { LocalFilter } from 'features/common/components/Filters/LocalFilter';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { debounce } from 'utils/utils';

import { ContentWrapper } from './EventSites.styles';
import { Title } from 'features/Embed/Embed.styles';

const EventSites = (): JSX.Element => {
	const { t } = useTranslation();
	const [searchQuery, setSearch] = useState('');
	const [selectedOption, setSelectedOption] = useState<FilterOptionType>(FILTER_OPTIONS[2]);

	const onChangeSearch = debounce(setSearch);

	const onChangeFilter = (option: FilterOptionType) => {
		setSelectedOption(option);
	};

	return (
		<>
			<ContentWrapper>
				<EmbedInfo />
				<EventSiteDefaultStyles />
				<Title>{t('embedInfo-events')}</Title>
				<FlexContainer justifyContent="space-between" width="100%">
					<Search placeholder="Search" onChange={(e) => onChangeSearch(e.target.value)} data-cy="search-input" />
					<LocalFilter onChange={onChangeFilter} options={FILTER_OPTIONS} selectedOption={selectedOption} />
				</FlexContainer>
			</ContentWrapper>
			<CardContainer searchQuery={searchQuery} selectedOption={selectedOption} />
		</>
	);
};

export default EventSites;
