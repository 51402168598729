import { object, string, number } from 'yup';
import { v4 as uuid } from 'uuid';

import calendarBlackBackground from '../../assets/images/calendarBlackBackground.svg';
import ticketBlackBackground from '../../assets/images/ticketBlackBackground.svg';
import unlimitedBlackBackground from '../../assets/images/unlimitedBlackBackground.svg';
import {
	DefaultValuesTypes,
	PromoCodeTypesRecord,
	selectedType,
} from './types';
import { PromoCode } from 'utils/types';
import { today, unlimitedDate } from 'utils/utils';

export const defaultValues: DefaultValuesTypes = {
	promocodeName: '',
	discountPercentage: 0,
	startDate: [
		{
			start_date: today,
			end_date: today,
			id: uuid(),
		},
	],
	endDate: [
		{
			start_date: unlimitedDate,
			end_date: unlimitedDate,
			id: uuid(),
		},
	],
	selected: [],
	cuponType: 'percentage',
	isEndDate: false,
	quantity: '',
	isEdited: false,
};

export const validationSchema = () =>
	object().shape({
		promocodeName: string().required(),
		discountPercentage: number().required(),
	});

export const promoCodeTypes: PromoCodeTypesRecord = {
	unlimited: {
		icon: unlimitedBlackBackground,
		subtitle: 'unlimited',
	},
	ticketSpecific: {
		icon: ticketBlackBackground,
		subtitle: 'ticketSpecific',
	},
	eventSpecific: {
		icon: calendarBlackBackground,
		subtitle: 'eventSpecific',
	},
	isEdit: {
		icon: ticketBlackBackground,
		subtitle: 'isEdit',
	},
	existing: {
		subtitle: 'existing',
	},
};

export const ticketsQuantityTitle = {
	eventSpecific: 'promoCode-Form-eventSpecific-ticketsQuantity-title',
	unlimited: 'promoCode-Form-unlimited-ticketsQuantity-title',
	ticketSpecific: '',
	existing: '',
};

export const handleType = (type: string) => {
	switch (type) {
		case 'eventSpecific':
			return 'event';
		case 'ticketSpecific':
			return 'ticket_type';
		case 'unlimited':
			return 'partner';
		default:
			return 'event';
	}
};

export const handleInitialValues = (promocode: PromoCode) => {
	const isEndDate = !promocode.end_date.includes('1970');
	const newEndDate = isEndDate
		? new Date(promocode.end_date)
		: new Date('1/1/1970');

	const newSelected: selectedType[] =
		promocode.references === null
			? []
			: promocode.references.map(({ reference_id, quantity }) => ({
					id: reference_id,
					quantity,
			  }));

	const allValues: DefaultValuesTypes = {
		promocodeName: promocode.code,
		discountPercentage:
			promocode.coupon_type === 'flat'
				? promocode.discount
				: promocode.percentage,
		startDate: [
			{
				start_date: new Date(promocode.start_date),
				end_date: new Date(promocode.start_date),
				id: uuid(),
			},
		],
		endDate: [
			{
				start_date: newEndDate,
				end_date: newEndDate,
				id: uuid(),
			},
		],
		selected: newSelected,
		cuponType: promocode.coupon_type,
		isEndDate,
		quantity: String(promocode.quantity),
		isEdited: true,
	};
	return allValues;
};
