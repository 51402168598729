import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { postCohort } from 'features/Marketing/services';
import { useToastNotification } from 'hooks/useToastNotification';
import { InfoModal } from './InfoModal/InfoModal';
import { queryClient } from 'index';

import { CustomButton, HiddenInput } from '../../ViewEmailCampaigns.styles';

export const UploadCohort = () => {
	const { t } = useTranslation();
	const hiddenInputRef = useRef<HTMLInputElement>(null);
	const { showNotification, TYPES } = useToastNotification();
	const [isOpen, setIsOpen] = useState(false);

	const { mutate: mutateUploadCohort } = useMutation({
		mutationFn: postCohort,
		onSuccess: () => {
			showNotification({
				message: t('success'),
				type: TYPES.SUCCESS,
			});
			queryClient.refetchQueries(['cohorts']);
		},
		onError: ({ response }: AxiosError) => {
			const message = response?.data.includes('no valid emails')
				? t('error-no-valid-emails')
				: t('error-uploading-cohort');
			showNotification({
				message,
				type: TYPES.ERROR,
			});
		},
	});

	const handleUpload = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const file = target.files?.[0];
		if (hiddenInputRef.current) hiddenInputRef.current.value = '';
		if (file) mutateUploadCohort({ file, name: file.name });
	};

	const onContinueClick = () => {
		if (hiddenInputRef.current) hiddenInputRef.current.click();
		setIsOpen(false);
	};

	return (
		<>
			<CustomButton
				category="outlined"
				onClick={() => setIsOpen(true)}
				text={t('marketing-viewEmailCampaingns-upload')}
				type="button"
			/>
			{isOpen && <InfoModal closeModal={() => setIsOpen(false)} onContinueClick={onContinueClick} />}
			<HiddenInput type="file" onChange={handleUpload} ref={hiddenInputRef} accept=".xlsx, .xls, .csv" />
		</>
	);
};
