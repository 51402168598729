import styled from 'styled-components';

import { Icon } from 'features/common/components/Icon';
import { Text } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';

export const BodyDescription = styled(Text)`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	margin-top: 16px;
	text-align: center;
	text-transform: none;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
		line-height: 21.5px;
	}
`;

export const StyledIcon = styled(Icon)`
	width: 56px;
	height: 56px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 36px;
		height: 36px;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 16px 24px;
	}
`;

export const StyledButton = styled(Button)`
	align-items: center;
	display: flex;
	justify-content: center;
	height: 40px;
	width: 150px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 0;
		max-width: 280px;
		width: 100%;
	}
`;
