import styled from 'styled-components';

import { Text as UnstyledText } from 'features/common/components/Text';
import { Button as UnstyledButton } from 'features/common/components/Button';

export const Container = styled.div`
	width: 60%;
	padding: 32px;
	background-color: ${({ theme }) => theme.colors.grey18};
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.colors.grey6};
	display: flex;
	flex-direction: column;
	gap: 16px;
	height: fit-content;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

export const Title = styled(UnstyledText)`
	font-size: 20px;
	color: ${({ theme }) => theme.colors.lightGrey7};
`;

export const TotalPrice = styled(UnstyledText)`
	font-weight: 700;
	font-size: 20px;
	color: ${({ theme }) => theme.colors.lightGrey7};
`;

export const Price = styled(UnstyledText)`
	font-weight: 700;
	font-size: 16px;
	text-align: end;
	color: ${({ theme }) => theme.colors.lightGrey7};

	&.promoCodeApplied {
		opacity: 0.5;
		text-decoration: line-through;
	}
`;

export const Currency = styled(UnstyledText)`
	font-size: 14px;
	color: ${({ theme }) => theme.colors.lightGrey7};
`;

export const Label = styled(UnstyledText)`
	font-weight: 600;
	color: ${({ theme }) => theme.colors.lightGrey7};
	font-size: 16px;
`;

export const Button = styled(UnstyledButton)`
	border-radius: 8px;
	padding: 8px 24px;
	width: 100%;
	height: 50px;
	font-size: 16px;
	margin: 0;
	&:disabled {
		cursor: not-allowed;
		background: ${({ theme }) => theme.colors.lightGrey3};
	}
`;

export const Text = styled(UnstyledText)`
	color: ${({ theme }) => theme.colors.lightGrey7};
	font-size: 16px;
`;
