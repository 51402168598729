import { useEffect, useState } from 'react';
import { camelizeKeys } from 'humps';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { getData } from 'services/api';
import { EventType } from './types';
import { EventCanGoLive } from 'features/TicketDetails/types';
import useGetEvent from 'features/common/hooks/useGetEvent';
import { useToastNotification } from 'hooks/useToastNotification';

export const useToggleEventLive = (id: number, platform: EventType, currentStatus: boolean) => {
	const { t } = useTranslation();
	const { showNotification, TYPES } = useToastNotification();
	const { event } = useGetEvent(id);

	const [showReqFields, setShowReqFields] = useState(false);
	const [eventGoLive, setEventGoLive] = useState<EventCanGoLive>();
	const [toggleChecked, setToggleChecked] = useState(currentStatus);

	useEffect(() => {
		setToggleChecked(currentStatus);
	}, [currentStatus]);

	const { mutate: toggleEventLive, isLoading: isLoadingToggle } = useMutation({
		mutationFn: () => {
			const searchParams = new URLSearchParams([['platform', platform]]);
			return getData(`/events/${id}/${toggleChecked ? 'take-offline' : 'go-live'}?` + searchParams.toString());
		},
		onSuccess: (data) => {
			if (toggleChecked) {
				setToggleChecked(false);
				return;
			}

			const canGoLive = camelizeKeys(data) as EventCanGoLive;
			setEventGoLive(canGoLive);

			const dataIsComplete = Object.keys(canGoLive)
				.filter((key) => key !== 'ticketTypeDetails')
				.every((key) => {
					if (key === 'partnerInfo') {
						return true;
					}
					return canGoLive[key as keyof EventCanGoLive];
				});

			const isPaidEvent = event?.ticketTypes.some((ticket) => ticket.initialPrice > 0);
			const missingPartnerInfo = !canGoLive.partnerInfo.taxInfo || !canGoLive.partnerInfo.payoutsInfo;
			if (!dataIsComplete || (isPaidEvent && missingPartnerInfo)) {
				setShowReqFields(true);
			} else {
				setToggleChecked(true);
			}
		},
		onError: () => showNotification({ message: t(''), type: TYPES.ERROR }),
	});

	return {
		toggleChecked,
		toggleEventLive,
		isLoadingToggle,
		showReqFields,
		reqFieldsProps: {
			eventGoLive,
			eventId: id,
			closeModal: () => setShowReqFields(false),
		},
	};
};
