import { IPromoCode } from './components/PromoCodeTypeCard/types';
import calendarIcon from 'assets/images/calendarIcon.svg';
import ticketIcon from 'assets/images/ticketIcon.svg';
import unlimitedIcon from 'assets/images/unlimitedIcon.svg';
import ticketsIcon from 'assets/images/ticketsIcon.svg';

export const PromoCodesTypes: IPromoCode[] = [
	{
		description: 'promoCode-New-eventSpecific-description',
		icon: calendarIcon,
		title: 'promoCode-New-eventSpecific-title',
		type: 'Event Specific',
		path: 'eventSpecific',
	},
	{
		description: 'promoCode-New-ticketSpecific-description',
		icon: ticketIcon,
		title: 'promoCode-New-ticketSpecific-title',
		type: 'Ticket Specific',
		path: 'ticketSpecific',
	},
	{
		description: 'promoCode-New-unlimited-description',
		icon: unlimitedIcon,
		title: 'promoCode-New-unlimited-title',
		type: 'Unlimited',
		path: 'unlimited',
	},
	{
		description: 'promoCode-New-assignExisting-description',
		icon: ticketsIcon,
		title: 'promoCode-New-assignExisting-title',
		type: 'Assign Existing',
		path: 'existing',
	},
];

export const PROMO_CODES_TYPES = {
	EVENT_SPECIFIC: 'Event Specific',
	TICKET: 'Ticket Specific',
	UNLIMITED: 'Unlimited',
	ASSIGN_EXISTING: 'Assign Existing',
};
