import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';

import { TicketTypesListProps } from './types';
import { DragHandle } from 'assets/customizedIcons';
import { TicketCard } from '../TicketCard';
import { formatMoney } from 'utils/utils';
import { IdParamsType } from 'utils/types';

import { DragHandleContainer, DraggableContainer, TicketCardContainer } from './TicketTypesList.styles';

const TicketTypesList: FC<TicketTypesListProps> = ({ ticketTypes, setTicketTypes, showSaveButtons }) => {
	const { id } = useParams<IdParamsType>();

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const newTicketTypes = Array.from(ticketTypes.sort((tt1, tt2) => tt1.orderIndex - tt2.orderIndex));
		const [reorderedItem] = newTicketTypes.splice(result.source.index, 1);
		newTicketTypes.splice(result.destination.index, 0, reorderedItem);
		newTicketTypes.forEach((tt, index) => (tt.orderIndex = index));

		setTicketTypes(newTicketTypes);
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable" direction="horizontal">
				{(provided) => {
					return (
						<DraggableContainer ref={provided.innerRef} {...provided.droppableProps}>
							{ticketTypes.map(({ iD, initialPrice, image, amount, sold, holdsAmount, template, name }, index) => (
								<Draggable key={iD} draggableId={iD.toString()} index={index}>
									{(provided) => {
										return (
											<TicketCardContainer key={iD} ref={provided.innerRef} {...provided.draggableProps}>
												<DragHandleContainer
													{...provided.dragHandleProps}
													hidden={!showSaveButtons}
													data-cy="drag-handle"
												>
													<DragHandle />
												</DragHandleContainer>

												<TicketCard
													price={formatMoney(initialPrice)}
													image={image}
													soldAmount={sold}
													ticketAmount={amount}
													holdAmount={holdsAmount}
													eventId={id}
													ticketId={`${iD}`}
													name={name}
													displayType={template.displayType}
												/>
											</TicketCardContainer>
										);
									}}
								</Draggable>
							))}
							{provided.placeholder}
						</DraggableContainer>
					);
				}}
			</Droppable>
		</DragDropContext>
	);
};

export { TicketTypesList };
