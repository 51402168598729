import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { Modal } from 'features/common/components/Modal';
import { QUATERNARY } from 'features/common/components/Button/constants';
import { RefundFailProps } from './types';

import { ModalButton, PrimaryIcon, ActionsWrapper } from '../../RefundOutcomes.styles';
import { BodyWrapper, AlertMessage, ResetMessage } from './RefundFail.styles';

export const RefundFail: FC<RefundFailProps> = ({ refundTickets, setShowFail }) => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const handleTryAgainClick = () => {
		setShowFail(false);
		refundTickets();
	};
	const handleCancelClick = () => {
		setShowFail(false);
		goBack();
	};

	return (
		<Modal onClose={() => setShowFail(false)}>
			<Modal.Header>{t('refundTicketSummary-refundFail')}</Modal.Header>

			<BodyWrapper>
				<PrimaryIcon iconName="alert-circle" />
				<FlexContainer flexDirection="column" gap="8px" alignItems="center">
					<AlertMessage>{t('refundTicketSummary-refundFail-something-went-wrong')}</AlertMessage>
					<ResetMessage element="p">{t('refundTicketSummary-refundFail-message')}</ResetMessage>
				</FlexContainer>
			</BodyWrapper>

			<ActionsWrapper>
				<ModalButton text={t('button-cancel')} category={QUATERNARY} onClick={handleCancelClick} type="button" />
				<ModalButton text={t('button-try-again')} type="button" onClick={handleTryAgainClick} />
			</ActionsWrapper>
		</Modal>
	);
};
