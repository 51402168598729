import { useMutation } from '@tanstack/react-query';

import { post } from 'services/api';

export const useResetPassword = (email?: string) => {
	const { mutate } = useMutation({
		mutationFn: () => {
			return post('/public/users/forgot-password', { email });
		},
	});
	return mutate;
};
