import { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Icon } from 'features/common/components/Icon';
import { FormImageInputProps, ImagesType } from './types';
import { InputLabel } from './InputLabel';
import { M } from '../Icon/utils';
import { getBase64 } from 'utils/utils';
import { ModalDelete } from '../ModalDelete';
import { ImageType } from 'services/UploadImage/types';

import * as Styled from './Form.styles';

const ImageInput: FC<FormImageInputProps> = ({ id, name, label, maxPicturesAllowed }: FormImageInputProps) => {
	const [showModal, setShowModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState<ImagesType | ImageType>();
	const { t } = useTranslation();

	const {
		setValue,
		getValues,
		watch,
		formState: { errors },
	} = useFormContext();
	const error = errors[name];

	// this will cause immediate re-render after image is uploaded
	watch(id ?? name);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: async (acceptedFiles) => {
			const valuesToAdd = await Promise.all(
				acceptedFiles.map(async (file) => ({
					url: URL.createObjectURL(file),
					base64: await getBase64(file),
					name: file.name,
				}))
			);
			const valuesToSave = [...getValues(id ?? name), ...valuesToAdd];
			setValue(id ?? name, valuesToSave);
		},
		accept: {
			'image/jpeg': [],
			'image/png': [],
		},
		maxFiles: maxPicturesAllowed - getValues(id ?? name)?.length,
		disabled: getValues(id ?? name)?.length >= maxPicturesAllowed,
	});

	const isAnImageType = (obj: ImagesType | ImageType | undefined): obj is ImageType =>
		obj !== undefined && 'url' in obj && 'name' in obj && 'base64' in obj;

	const handleDeleteImage = () => {
		const values: ImagesType[] = getValues(id ?? name);
		const _values = values.filter((image) => {
			if (isAnImageType(selectedImage)) return image.url !== selectedImage.url;
			else return image.ID !== selectedImage?.ID;
		});
		setValue(id ?? name, _values);
		setShowModal(false);
	};

	const modalInfo = {
		modalTitle: t('imageInput-message'),
		modalMessage: t('imageInput-title'),
		onDelete: handleDeleteImage,
	};

	return (
		<div>
			<ModalDelete showModal={showModal} closeModal={() => setShowModal(!showModal)} modalInfo={modalInfo} />
			{label && <InputLabel htmlFor={id ?? name} label={label} />}
			<Styled.Row>
				{getValues(id ?? name)?.map((image: ImagesType, index: number) => (
					<Styled.Image
						key={`${image.image}-${index}`}
						src={image.image || image.url}
						onClick={() => {
							setShowModal(!showModal);
							setSelectedImage(image);
						}}
					/>
				))}

				{getValues(id ?? name)?.length < maxPicturesAllowed && (
					<Styled.ImageInputContainer {...getRootProps()}>
						<input {...getInputProps()} id={id ?? name} />
						<Icon iconName="plus" size={M} />
						<Styled.AddImageText>
							{t('editEvent-images-dropFile')}
							<Styled.AddImageLink>{t('editEvent-images-browse')}</Styled.AddImageLink>
						</Styled.AddImageText>
						<Styled.AddImageLink className="SM">{t('editEvent-images-add')}</Styled.AddImageLink>
					</Styled.ImageInputContainer>
				)}
			</Styled.Row>
			<Styled.Error>{error?.message}</Styled.Error>
		</div>
	);
};

export { ImageInput };
