import styled from 'styled-components';

export const NanosContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	background-color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fonts.primary};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px 3%;
		flex-direction: column;
		gap: 20px;
	}
`;

export const IframeDiv = styled.div`
	margin-left: ${({ theme }) => theme.widths.hiddenSidebar};
	height: 100%;
	width: 100%;
`;
