import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'features/routes';
import { NoEventsFallbackProps } from './types';
import { PRIMARY } from 'features/common/components/Button/constants';
import greenBlob from 'assets/images/greenBlob.svg';
import pinkBlob from 'assets/images/pinkBlob.svg';
import greenBlobMobile from 'assets/images/greenBlobMobile.svg';
import pinkBlobMobile from 'assets/images/pinkBlobMobile.svg';

import * as Styled from './NoEventsFallback.styles';

export const NoEventsFallback = ({ isFanDatabase }: NoEventsFallbackProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { CREATE_EVENT_PATH } = ROUTES;

	return (
		<Styled.Container>
			<Styled.GridWrapper>
				<Styled.CustomIcon iconName={isFanDatabase ? 'user' : 'file'} />
				<Styled.PrimaryText element="h2">
					{t('no-events-fallback', {
						propName: isFanDatabase ? 'Fan Database' : 'Order Page',
					})}
				</Styled.PrimaryText>

				<Styled.SecondaryText element="h3">{t('no-events-fallback-description')}</Styled.SecondaryText>
				<Styled.CustomButton
					text="Create Event"
					category={PRIMARY}
					type="button"
					onClick={() => push(CREATE_EVENT_PATH)}
				/>
			</Styled.GridWrapper>

			<Styled.GreenBlob src={greenBlob} alt="green blob" />
			<Styled.PinkBlob src={pinkBlob} alt="red blob" />
			<Styled.GreenBlobMobile src={greenBlobMobile} alt="blob" />
			<Styled.PinkBlobMobile src={pinkBlobMobile} alt="blob" />
			<Styled.GreenGradient />
			<Styled.PinkGradient />
		</Styled.Container>
	);
};
