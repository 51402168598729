import styled from 'styled-components';

export const Container = styled.div`
	background: ${({ theme }) => theme.colors.blackGrey1};
`;

export const PromoCodesContainer = styled.div`
	display: inline-flex;
	gap: 24px;
	height: 140px;
	margin-left: 6%;
	margin-right: 3%;

	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 0 3%;
	}
`;
