import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'features/common/components/Modal/Modal';
import { QUATERNARY, TERTIARY } from 'features/common/components/Button/constants';
import { CancelInviteModalProps } from '../types';

import * as Styled from './CancelInviteModal.styles';

export const CancelInviteModal: FC<CancelInviteModalProps> = ({ modalInfo, showModal, closeModal }) => {
	const { t } = useTranslation();

	return (
		<>
			{showModal && (
				<Styled.CancelModal onClose={closeModal}>
					<>
						<Modal.Header>{modalInfo.modalTitle}</Modal.Header>
						<Styled.ModalBody>
							<Styled.ContainerBody>
								<Styled.Icon iconName="trashGrey" />
								<Styled.Text element="p">{modalInfo.modalMessage}</Styled.Text>
							</Styled.ContainerBody>
						</Styled.ModalBody>
						<Modal.ActionButtons>
							<Styled.ContainerActionButtons>
								<Styled.ModalButton
									category={QUATERNARY}
									onClick={closeModal}
									text={t('comped-ticket-cancel-invite-modal-dismiss-btn')}
									type="button"
								/>
								<Styled.ModalButton
									category={TERTIARY}
									onClick={modalInfo.onDelete}
									text={t('comped-ticket-cancel-invite-modal-cancel-btn')}
									type="button"
								/>
							</Styled.ContainerActionButtons>
						</Modal.ActionButtons>
					</>
				</Styled.CancelModal>
			)}
		</>
	);
};
