import { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { BoxProps } from 'features/common/components/HighlightsCollection/types';
import { HighlightsCollection } from 'features/common/components/HighlightsCollection';
import { toSigDigits } from 'utils/utils';
import { useHighlights } from 'hooks/useHighlights';
import { EventProps } from 'features/Home/components/Events/types';
import { ROUTES } from 'features/routes';
import {
	NORMAL_DASHBOARD_HIGHLIGHTS,
	REG_DASHBOARD_HIGHLIGHTS,
	TICKETS_COMPED,
	TOTAL_ATTENDEES,
	TICKETS_SOLD,
	TOTAL_PAYOUT,
	PAGE_VIEWS,
} from 'features/common/components/HighlightsCollection/constants';

import { ContentWrapper } from 'features/Home/components/Highlights/Highlights.styles';

const Highlights = ({ event }: { event: EventProps }) => {
	const { id }: { id: string } = useParams();
	const { eventHighlights } = useHighlights(id);
	const initialBoxCollection = event.mode === 'normal' ? NORMAL_DASHBOARD_HIGHLIGHTS : REG_DASHBOARD_HIGHLIGHTS;
	const [boxCollection, setBoxCollection] = useState(initialBoxCollection);
	const { push } = useHistory();

	useEffect(() => {
		if (eventHighlights) {
			const boxCollections: BoxProps[] = [];
			for (let i = 0; i < boxCollection.length; i++) {
				const box = boxCollection[i];
				if (box.property === TOTAL_PAYOUT) {
					box.stat = toSigDigits(eventHighlights.total_payout);
					box.onClick = () => push(ROUTES.ORDERS_PATH);
				} else if (box.property === TICKETS_SOLD) {
					box.stat = eventHighlights.tickets_sold;
					box.onClick = () => push(generatePath(ROUTES.ANALYTICS_BY_EVENT_PATH, { id }));
				} else if (box.property === TOTAL_ATTENDEES) {
					box.stat = eventHighlights.total_attendees;
					box.onClick = () => push(generatePath(ROUTES.ATTENDEES, { id }));
				} else if (box.property === PAGE_VIEWS) {
					box.stat = eventHighlights.page_views;
					box.onClick = () => push(generatePath(ROUTES.ANALYTICS_BY_EVENT_PATH, { id }));
				} else if (box.property === TICKETS_COMPED) {
					box.stat = eventHighlights.tickets_comped || 0;
					box.onClick = () => push(generatePath(ROUTES.COMPED_TICKETS, { id }));
				}
				boxCollections.push(box);
			}
			setBoxCollection(boxCollections);
		}
	}, [eventHighlights]);

	return (
		<ContentWrapper>
			<HighlightsCollection boxCollection={boxCollection} />
		</ContentWrapper>
	);
};

export { Highlights };
