import styled, { DefaultTheme } from 'styled-components';

import { Button } from 'features/common/components/Button';
import { Text } from 'features/common/components/Text';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import { TicketImageProps, TicketImgTypes } from './types';

export const CardContainer = styled.div`
	box-sizing: border-box;
	height: 100%;
	width: 444px;
	min-width: 270px;
	margin-right: 10px;
	padding: 12px 20px;
	border-radius: 12px;
	border: ${({ theme }) => `3px solid ${theme.colors.lightGrey}`};
	background: ${({ theme }) => theme.colors.blackGrey};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 15px;
		max-width: 250px;
	}
`;

export const EmptyCardContainer = styled.div`
	box-sizing: border-box;
	width: 270px;
	height: 236px;
	min-width: 270px;
	margin-right: 10px;
	padding: 12px 20px;
	border-radius: 12px;
	border: ${({ theme }) => `3px solid ${theme.colors.grey}`};
	background: ${({ theme }) => theme.colors.grey};
	cursor: pointer;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 444px;
		padding: 25px;
		min-width: 444px;
	}
`;

export const EmptyCardColumn = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
`;

export const Icon = styled(UnstyledIcon)`
	width: 35px;
	height: 35px;
`;

export const Column = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 20px;
	}
`;

export const InfoRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 10px;
	}
`;

export const InfoColumn = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	gap: 5px;
	padding: 20px 0 0;
	white-space: pre-wrap;
	word-break: break-word;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 20px;
		padding-right: 0px;
	}
`;

export const GradientContainer = styled.div`
	position: relative;
	overflow: hidden;
	aspect-ratio: 3 / 5;
`;

export const GradientBackground = styled.div`
	position: absolute;
	height: 200px;
	width: 335px;
	top: 32px;
	background: linear-gradient(0, ${({ theme }) => theme.colors.blackGrey}, transparent);

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		top: 46px;
	}
`;

export const Information = styled.div`
	max-height: 500px;
	overflow: hidden;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-height: 250px;
	}
`;

const displayBackground = (theme: DefaultTheme, imageType: TicketImgTypes) => {
	if (imageType === 'template0') {
		return theme.colors.white;
	} else if (imageType === 'template1') {
		return theme.colors.black;
	} else {
		return 'transparent';
	}
};

export const Image = styled.img<TicketImageProps>`
	background: ${({ theme, imageType }) => displayBackground(theme, imageType)};
	min-height: 100px;
	height: ${({ height }) => height || '100px'};
	width: 300px;
	object-fit: cover;
	border-radius: 3px;
	overflow: hidden;

	&.isCard {
		border-radius: 0px;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		min-height: 215px;
		height: ${({ height }) => height || '215px'};
		width: 335px;
		font-size: 24px;
		line-height: 36px;
		border-radius: 6px;

		&.isCard {
			border-radius: 0px;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}
	}
`;

export const Price = styled(Text)`
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	align-self: flex-end;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 28px;
		line-height: 42px;
	}
`;

export const TicketName = styled(Text)`
	font-size: 16px;
	font-weight: 400;
	max-width: 145px;
	line-height: 19px;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 24px;
		max-width: 95%;
		line-height: 36px;
	}
`;

export const TicketInfoItemContainer = styled.div`
	gap: 10px;
	display: flex;
	align-items: center;
`;

export const TicketInfoItemText = styled(Text)`
	opacity: 0.7;
	font-size: 13px;
	font-weight: 400;
	line-height: 19px;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 20px;
		line-height: 30px;
	}
`;

export const DetailsButton = styled(Button)`
	padding: 4px;
	width: 104px;
	height: 30px;
	font-size: 13px;
	font-weight: 400;
	text-align: center;
	border-radius: 64px;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};
	background-color: ${({ theme }) => theme.colors.green};
	margin: 0;
	margin-left: auto;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 18px;
		line-height: 28px;
		width: 154px;
		height: 35px;
	}
`;

export const AddTicket = styled(Text)`
	font-size: 22px;
	font-weight: 600;
	line-height: 36px;
	color: ${({ theme }) => theme.colors.white};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
	}
`;
