import { useFormContext } from 'react-hook-form';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { InputProps } from './types';

import { Label, StyledInput } from './Input.styles';

export const Input = ({ id, name, placeholder }: InputProps) => {
	const { register } = useFormContext();

	return (
		<FlexContainer flexDirection="column" gap="8px">
			<Label>{name}</Label>
			<StyledInput placeholder={placeholder} id={id} {...register(id)} />
		</FlexContainer>
	);
};
