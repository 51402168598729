import styled from 'styled-components';

export const StatusContainer = styled.div`
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.white};
	max-width: 90px;
	background-color: ${({ color }) => color};
	font-family: ${({ theme }) => theme.fonts.primary};
	padding: 6px 10px;
	text-align: center;
`;
