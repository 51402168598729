import { FC, useState } from 'react';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { DesktopFilter } from './DesktopFilter';
import { MobileFilter } from './MobileFilter';
import { FilterProps, FilterOptionType } from './types';

const Filter: FC<FilterProps> = ({
	onChange,
	options,
	selectedOption,
	responsiveSecondary,
	secondaryStyle,
}: FilterProps) => {
	const [isOpened, setIsOpened] = useState(false);
	const isLargeScreen = useResponsiveScreen();

	const onClickSelect = () => setIsOpened((prevState) => !prevState);

	const onClickOption = (selectedValue: FilterOptionType) => {
		onChange(selectedValue);
	};

	return (
		<>
			{isLargeScreen ? (
				<DesktopFilter
					selectedOption={selectedOption}
					onClickSelect={onClickSelect}
					onClickOption={onClickOption}
					isOpened={isOpened}
					isLargeScreen={isLargeScreen}
					options={options}
					secondaryStyle={secondaryStyle}
				/>
			) : (
				<MobileFilter
					responsiveSecondary={responsiveSecondary}
					onClickSelect={onClickSelect}
					onClickOption={onClickOption}
					isOpened={isOpened}
					isLargeScreen={isLargeScreen}
					options={options}
					selectedOption={selectedOption}
				/>
			)}
		</>
	);
};

export { Filter };
