import styled from 'styled-components';

import { Form } from 'features/common/components/Form';
import { Button } from 'features/common/components/Button';

export const CustomFieldsForm = styled(Form)`
	display: flex;
	width: 100%;
	height: calc(100vh - 168px - 100px);

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		overflow: scroll;
		flex-direction: column;
		height: calc(100vh - 100px - 128px);
	}
`;

export const FormSide = styled.div`
	width: 50%;
	display: flex;
	height: auto;
	flex-direction: column;
	width: 90vw;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	width: 100%;
	border-top: 0.5px solid ${({ theme }) => theme.colors.white2};
	background: ${({ theme }) => theme.colors.blackGrey};
	justify-content: center;
	gap: 32px;
	position: absolute;
	bottom: 0;
	height: 100px;
	align-items: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 8px;
		bottom: 0;
		border: none;
		position: fixed;
	}
`;

export const StyledButton = styled(Button)`
	width: fit-content;
	min-width: 300px;
	position: relative;
	bottom: 0;
	margin: 0;
	height: 56px;
	font-size: 18px;
	padding: unset;

	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		min-width: 150px;
	}
`;
