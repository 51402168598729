import { FocusEvent, Status } from 'features/Marketing/types';
import { TFunction } from 'react-i18next';

export const getLabels = (
	emailsSent: number,
	focus: FocusEvent[],
	status: Status,
	t: TFunction<'translation', undefined>
) => [
	{ extraWidth: false, label: 'emails', data: emailsSent },
	{ extraWidth: true, label: 'focus', data: focus[0]?.name },
	{
		extraWidth: false,
		label: 'status',
		data: t(`marketing-campaignInfo-${status === 'CONTACTS_PENDING' ? 'SENDING' : status}`),
	},
];
