import { FC } from 'react';
import { FormButtonProps } from './types';
import { Button } from '../Button';

const FormButton: FC<FormButtonProps> = ({
	isDisabled,
	isLoading,
	text,
	...rest
}: FormButtonProps) => (
	<Button
		text={text}
		type="submit"
		disabled={isDisabled || isLoading}
		{...rest}
	>
		{isLoading ? 'is loading ' : text}
	</Button>
);

export { FormButton };
