import styled from 'styled-components';

import { Text as UnstyledText } from 'features/common/components/Text';
import { Icon } from 'features/common/components/Icon';

export const Title = styled(UnstyledText)`
	font-weight: 700;
	font-size: 40px;
	line-height: 30px;
	margin: 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 24px;
		line-height: 20px;
	}
`;

export const Subtitle = styled(UnstyledText)`
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	margin: 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
		line-height: 24px;
	}
`;

export const StyledIcon = styled(Icon)`
	width: 72px;
	height: 72px;
`;
