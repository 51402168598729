import React from 'react';
import { FlexContainerProps } from './types';

import { StyledFlexContainer } from './FlexContainer.styles';

const FlexContainer: React.FC<FlexContainerProps> = ({
	children,
	alignItems,
	flexDirection,
	justifyContent,
	gap,
	margin,
	width,
	flexWrap,
	padding,
	className,
	height,
	id,
	backgroundColor,
	borderRadius,
	boxSizing,
	marginRight,
}) => (
	<StyledFlexContainer
		alignItems={alignItems}
		flexDirection={flexDirection}
		justifyContent={justifyContent}
		gap={gap}
		margin={margin}
		width={width}
		flexWrap={flexWrap}
		padding={padding}
		className={className}
		height={height}
		id={id}
		backgroundColor={backgroundColor}
		borderRadius={borderRadius}
		boxSizing={boxSizing}
		marginRight={marginRight}
	>
		{children}
	</StyledFlexContainer>
);

export { FlexContainer };
export default FlexContainer;
