import { FetchedMedia, FieldProps, NewImg, UploadedMedia } from './types';

export const defaultValues: FieldProps = {
	eventName: '',
	mediaContent: {
		eventImage: [],
		media: [],
	},
};

export const isNewImg = (obj: any): obj is NewImg => {
	return typeof obj?.name === 'string' && typeof obj?.base64 === 'string' && typeof obj?.url === 'string';
};

export const isUploadedImg = (obj: any): obj is UploadedMedia => {
	return typeof obj?.url === 'string' && typeof obj?.position === 'number' && typeof obj?.type === 'string';
};

export const isFetchedImg = (obj: any): obj is FetchedMedia => {
	return (
		typeof obj?.ID === 'number' &&
		typeof obj?.url === 'string' &&
		typeof obj?.position === 'number' &&
		typeof obj?.type === 'string'
	);
};

export const isUploadedOrFetched = (obj: any): obj is UploadedMedia | FetchedMedia => {
	return isUploadedImg(obj) || isFetchedImg(obj);
};
