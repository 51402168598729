import { Button } from 'features/common/components/Button';
import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const Wrapped = styled.div`
	padding: 0 0 12px 0;
`;

export const LargeText = styled(Text)`
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	font-weight: 500;

	&.link {
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
		overflow-wrap: anywhere;
	}
`;

export const GreenText = styled(Text)`
	color: ${({ theme }) => theme.colors.green};
	font-size: 14px;
	line-height: 21px;
	font-weight: 500;
`;

export const OvalBtn = styled(Button)`
	border-radius: 21px;
	font-size: 16px;
	margin: 0;
	padding: 5px 16px;
	width: 150px;
`;
