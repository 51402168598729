import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getData } from 'services/api';
import { PromoCode } from 'utils/types';
import { UseGetPromoCodesProps } from './types';

export const useGetPromoCodes = ({
	selectedEvent,
	searchQuery,
}: UseGetPromoCodesProps) => {
	const [initialPromocodes, setInitialPromocodes] = useState<PromoCode[]>([]);
	const [counter, setCounter] = useState<number>(0);

	const fetchPromocodes = () =>
		getData(
			`/promocodes?event_ids=${selectedEvent.value}&search=${searchQuery}`
		);

	const { data: promocodes, refetch } = useQuery({
		queryKey: ['promocodes'],
		queryFn: fetchPromocodes,
		initialData: [],
		onSuccess: () => setCounter(counter + 1),
	});

	useEffect(() => {
		if (counter <= 1) setInitialPromocodes(promocodes);
	}, [promocodes]);

	useEffect(() => {
		refetch();
	}, [selectedEvent, searchQuery]);

	return { promocodes, initialPromocodes };
};
