import { TaxesType } from '../../../OrderSummary/types';
import { TicketType } from 'features/common/types';
import { HandleDiscountType, HandleTaxesType, HandleTicketTypeDicType, TicketTypeDicItem } from '../../types';

export const handleTickeTypetDic = ({ multiday, event, isMultiDay, getValues }: HandleTicketTypeDicType) => {
	let ticketTypesDic: { [key: string]: TicketTypeDicItem } = {};
	if (multiday && event)
		if (isMultiDay) {
			//This is being made with multiday[0] because we don't have the feature of multidays yet, TODO: change logic when the feature exists
			ticketTypesDic = multiday[0].ticketTypes.reduce(
				(obj: { [key: string]: TicketTypeDicItem }, currentValue: TicketType) => {
					if (currentValue.quantity > 0)
						return {
							...obj,
							[currentValue.ID]: {
								name: currentValue.name,
								quantity: currentValue.quantity,
								ticket_price: currentValue.priceWithNoDiscount,
								net_price_per_ticket: currentValue.initialPrice,
								discount_applied: Number((currentValue.priceWithNoDiscount - currentValue.initialPrice).toFixed(2)),
								number_of_applied_promocodes:
									Number((currentValue.priceWithNoDiscount - currentValue.initialPrice).toFixed(2)) === 0
										? 0
										: currentValue.quantity,
								promocode_id: getValues('ticketsWithPC').find((e: number) => e === currentValue.ID)
									? String(getValues('PCId'))
									: '',
							},
						};
					return obj;
				},
				{}
			);
		} else
			ticketTypesDic = event.ticketTypes.reduce(
				(obj: { [key: string]: TicketTypeDicItem }, currentValue: TicketType) => {
					if (currentValue.quantity > 0)
						return {
							...obj,
							[currentValue.ID]: {
								name: currentValue.name,
								quantity: currentValue.quantity,
								ticket_price: currentValue.priceWithNoDiscount,
								net_price_per_ticket: currentValue.initialPrice,
								discount_applied: Number((currentValue.priceWithNoDiscount - currentValue.initialPrice).toFixed(2)),
								number_of_applied_promocodes:
									Number((currentValue.priceWithNoDiscount - currentValue.initialPrice).toFixed(2)) === 0
										? 0
										: currentValue.quantity,
								promocode_id: getValues('ticketsWithPC').find((e: number) => e === currentValue.ID)
									? String(getValues('PCId'))
									: '',
							},
						};
					return obj;
				},
				{}
			);
	return ticketTypesDic;
};

export const handleOurFees = (amount: string, taxes: TaxesType) => ({
	amount,
	currency: 'USD',
	fees_rate: taxes?.partnerRoyalties?.tixologiPrimaryRoyalty,
	fixed_fee: taxes?.partnerRoyalties?.tixologiFixedPrimaryRoyalty,
});

export const handleTaxes = ({ getValues, taxes, id }: HandleTaxesType) => ({
	rate: taxes?.ticketTaxes[id].taxRate || taxes?.partnerRoyalties?.partnerDefaultTax,
	our_taxes: String(
		Number(getValues('tixologiFees')) * (taxes?.ticketTaxes[id].taxRate || taxes?.partnerRoyalties?.partnerDefaultTax)
	),
	vendor_taxes: String(
		Number(getValues('myTaxes')) -
			Number(getValues('tixologiFees')) * (taxes?.ticketTaxes[id].taxRate || taxes?.partnerRoyalties?.partnerDefaultTax)
	),
});

export const handleDiscount = ({ multiday, event, isMultiDay, getValues }: HandleDiscountType) => ({
	amount: String(
		Object.values(handleTickeTypetDic({ multiday, event, isMultiDay, getValues })).reduce(
			(acc: number, { discount_applied, number_of_applied_promocodes }: any) =>
				acc + discount_applied * number_of_applied_promocodes,
			0
		)
	),
	currency: 'USD',
});
