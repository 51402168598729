import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Icon } from 'features/common/components/Icon';
import { ColorProp, InfoTextProp } from './types';

export const TemplateContainer = styled.div<ColorProp>`
	width: 300px;
	height: 500px;
	cursor: pointer;
	max-width: 300px;
	min-width: 300px;
	overflow: hidden;
	position: relative;
	border-radius: 10px;
	background-color: ${({ theme, isLightTemplate }) => (isLightTemplate ? theme.colors.white : theme.colors.darkGrey4)};
	z-index: 0;

	&.isCard {
		width: 300px;
		height: 540px;
		min-width: 0;
		border-radius: 0px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;

		@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
			width: 335px;
			min-width: 0;
			max-width: 335px;
			min-height: 215px;
		}
	}
`;

export const GradientBackground = styled.div`
	position: absolute;
	height: 200px;
	width: 100%;
	top: 22px;
	background: linear-gradient(0, ${({ theme }) => theme.colors.blackGrey}, transparent);
`;

export const DarkGradientBackground = styled.div`
	position: absolute;
	height: 36px;
	width: 100%;
	bottom: 0px;
	background: linear-gradient(0, ${({ theme }) => theme.colors.blackGrey}, transparent);
`;

export const Image = styled.img`
	width: 100%;
	height: 160px;
	object-fit: cover;
`;

export const InfoContainer = styled.div`
	bottom: 0;
	z-index: 2;
	width: 300px;
	height: 350px;
	display: flex;
	padding: 16px;
	position: absolute;
	flex-direction: column;
	box-sizing: border-box;
	align-items: flex-start;

	&.isCard {
		height: 58px;
		width: 74px;
		padding: 8px;

			@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
			    padding: 8px;
				height: 170px;
				width: 300px;
			}
		}
	}
`;

export const MainInfoContainer = styled.div`
	gap: 8px;
	width: 100%;
	display: flex;
	padding: 8px 0 0 0;
	flex-direction: column;
	align-items: flex-start;
`;

export const Title = styled(Text)<ColorProp>`
	font-size: 24px;
	text-align: left;
	line-height: 30px;
	color: ${({ theme, isLightTemplate }) => (isLightTemplate ? theme.colors.black : theme.colors.white)}};
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

	&.isCard {
		margin-top: 100px;
	}
`;

export const InfoText = styled(Text)<InfoTextProp>`
	font-size: 14px;
	text-align: left;
	font-weight: 400;
	line-height: 18px;
	text-transform: none;
	color: ${({ theme, isLightTemplate }) => (isLightTemplate ? theme.colors.black : theme.colors.white)};
	${({ isClamp = true }) =>
		isClamp &&
		`
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	`}

	&.isCard {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: 180px;
	}
`;

export const InfoTitle = styled(Text)<ColorProp>`
	font-size: 11px;
	font-weight: 500;
	line-height: 18px;
	color: ${({ theme, isLightTemplate }) => (isLightTemplate ? theme.colors.lightGrey3 : theme.colors.greenBlue2)};
`;

export const InfoRow = styled.div`
	width: 100%;
	height: fit-content;
	display: flex;
	padding: 8px 0;
	box-sizing: border-box;
`;

export const InfoItem = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const Divider = styled.div<ColorProp>`
	width: 100%;
	height: 1px;
	opacity: 0.3;
	border-bottom: ${({ theme, isLightTemplate }) =>
		`1.5px dashed ${isLightTemplate ? theme.colors.grey10 : theme.colors.greenBlue2}`};
`;

export const BottomLine = styled.div<ColorProp>`
	left: 0;
	bottom: 0;
	width: 175px;
	height: 12px;
	position: absolute;
	border-radius: 0 0 0 10px;
	background-color: ${({ theme, isLightTemplate }) => (isLightTemplate ? theme.colors.black : theme.colors.greenBlue2)};
`;

export const DecorationTitle = styled(Text)<ColorProp>`
	opacity: 0.1;
	width: 400px;
	rotate: 270deg;
	font-size: 64px;
	line-height: 64px;
	position: absolute;
	left: 90px;
	bottom: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-align: right;
	color: ${({ theme, isLightTemplate }) => (isLightTemplate ? theme.colors.black : theme.colors.greenBlue2)};
`;

export const TicketType = styled.div`
	width: 100%;
	top: 90px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;

	&.isCard {
		position: relative;

		@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
			position: relative;
		}
	}

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		position: absolute;
		bottom: 14px;
		top: 296px;
	}
`;

export const TicketTypeText = styled(Text)<ColorProp>`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;
	font-weight: 400;
	color: ${({ theme, isLightTemplate }) => (isLightTemplate ? theme.colors.black : theme.colors.white)};
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

export const Ticket = styled(Icon)`
	width: unset !important;
`;
