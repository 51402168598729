import { object, string } from 'yup';

import { auth } from 'services/firebase';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { DefaultValues, DefaultValuesResetPassword, ResetPassswordProps } from './types';

export const defaultValues: DefaultValues = {
	email: '',
};

export const defaultValuesResetPassword: DefaultValuesResetPassword = {
	password: '',
	confirmPassword: '',
};

export const validationSchema = () =>
	object().shape({
		email: string().required().email(),
	});

export const validationSchemaResetPassword = () =>
	object().shape({
		password: string().required(),
		confirmPassword: string().required(),
	});

export const resetPassword = ({ code, password, setIsPasswordReset }: ResetPassswordProps) => {
	verifyPasswordResetCode(auth, code).then(() =>
		confirmPasswordReset(auth, code, password).then(() => setIsPasswordReset(true))
	);
};
