import {
	EVENT_KEY,
	TICKET_KEY,
	UNLIMITED_KEY,
} from 'features/common/components/PromoCodeCard/types';
import { EventProps } from 'features/Home/components/Events/types';
import { PromoCode } from 'utils/types';
import { FilterOptionType } from './components/Filter/types';
import { TypesProps } from './types';

export const initialSelectedValue = {
	label: 'All Events',
	value: '',
};

export const handleType = (type: string) => {
	const types: TypesProps = {
		event: EVENT_KEY,
		ticket_type: TICKET_KEY,
		partner: UNLIMITED_KEY,
	};
	return types[type] || EVENT_KEY;
};

export const handleTopUsed = (promocodes: PromoCode[]) => {
	const newPromoCodesArray = promocodes;
	newPromoCodesArray.sort((a, b) => b.total_uses - a.total_uses);
	const topUsed = newPromoCodesArray.slice(0, 3);
	return topUsed;
};

export const handleOptions = (items: EventProps[]) => {
	let options: FilterOptionType[] = [];
	if (items) {
		options = items.map(({ id, name }) => ({
			label: name,
			value: String(id),
		}));
		options.unshift(initialSelectedValue);
	}
	return options;
};
