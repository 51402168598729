import styled from 'styled-components';
import { Text as UnstyledText } from 'features/common/components/Text';

export const Title = styled(UnstyledText)`
	font-weight: 600;
	font-size: 32px;
	line-height: 36px;
	margin: 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 20px;
		line-height: 20px;
	}
`;

export const SubTitle = styled(UnstyledText)`
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	line-height: 30px;
	margin: 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
		line-height: 20px;
	}
`;
