import { useState } from 'react';

import { TicketOptionsProps } from './types';
import { handleLink } from 'utils/handleLink';
import { UploadImageCard } from 'features/common/components/UploadCardContainer';

export const TicketTypes = ({ icon, info, link, text, type }: TicketOptionsProps) => {
	const [displayType, setDisplayType] = useState('');

	return (
		<UploadImageCard
			key={type}
			displayType={displayType}
			icon={icon}
			info={info}
			onClick={() => handleLink(link)}
			setDisplayType={setDisplayType}
			size="L"
			text={text}
			type={type}
		/>
	);
};
