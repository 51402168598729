import styled from 'styled-components';
import { Text as UnstyledText } from 'features/common/components/Text';

export const Text = styled(UnstyledText)`
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 153%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
		line-height: 120%;
	}
`;

export const Container = styled.div`
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	overflow: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 7;
	backdrop-filter: blur(5px);
`;

export const Overlay = styled.div`
	align-items: center;
	height: 100%;
	left: 0;
	opacity: 0.5;
	overflow: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 7;
`;

export const ModalContent = styled.div`
	background-color: ${({ theme }) => theme.colors.lightGrey};
	border-radius: 16px;
	height: fit-content;
	margin: auto;
	width: 614px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: 614px;
		width: calc(100vw - 64px);
	}
`;

export const HeaderContainer = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.lightGrey5};
	border-radius: 16px 16px 0 0;
	color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: row;
	font-family: ${({ theme }) => theme.fonts.primary};
	justify-content: center;
	height: 70px;
	padding: 0 24px;
`;

export const CloseButton = styled.div`
	cursor: pointer;
	height: 22px;
	width: 22px;
`;

export const BodyContainer = styled.div`
	color: ${({ theme }) => theme.colors.white};
	padding: 0 55px 0 55px;
`;

export const ActionsContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.lightGrey5};
	border-radius: 0 0 16px 16px;
`;
