import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Footer } from 'features/common/components/Footer';
import { PromoCodesFormFooterProps } from './types';
import { RootState } from 'store';
import { onChangeShowTicketTypes, onChangeShowFooter } from '../../slice/PromoCodesFormSlice';

const PromoCodesFormFooter: React.FC<PromoCodesFormFooterProps> = ({ type }: PromoCodesFormFooterProps) => {
	const { goBack } = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const isEdit = pathname.includes('edit');

	const { showTicketTypes } = useSelector((state: RootState) => state.promoCodesForm);

	const handleOnCancelExisting = () => {
		dispatch(onChangeShowFooter());
		dispatch(onChangeShowTicketTypes());
	};

	//Finish handleOnCancel
	const handleOnCancel = () => {
		if (type === 'existing') return handleOnCancelExisting();
		if (showTicketTypes) return dispatch(onChangeShowTicketTypes());
		goBack();
	};

	const handleConfirmationText = () => {
		if (showTicketTypes) return 'ticketDetails-confirm';
		else if (isEdit) return 'Save';
		else return 'ticketDetails-create';
	};

	return (
		<Footer>
			<Footer.Button type="button" onClick={handleOnCancel} category={'outlined'} text={t('ticketDetails-cancel')} />
			<Footer.Button
				text={t(handleConfirmationText())}
				type={showTicketTypes ? 'button' : 'submit'}
				onClick={(e) => {
					if (showTicketTypes) {
						e.preventDefault();
						handleOnCancel();
					}
				}}
			/>
		</Footer>
	);
};

export { PromoCodesFormFooter };
