import styled from 'styled-components';

export const RadioButton = styled.input`
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
	width: 1.5em;
	height: 1.5em;
	border: 2px solid ${({ theme }) => theme.colors.grey9};
	border-radius: 50%;
	cursor: pointer;

	::after {
		content: '';
		display: block;
		border-radius: 50%;
		width: 0.75em;
		height: 0.75em;
		margin: 3px;
	}

	:focus {
		outline: none;
	}

	:checked {
		border: 2px solid ${({ theme }) => theme.colors.green};

		::after {
			background-color: ${({ theme }) => theme.colors.green};
		}

		:hover {
			border: 2px solid ${({ theme }) => theme.colors.green};
			::after {
				background-color: ${({ theme }) => theme.colors.green};
			}
		}
	}
`;
