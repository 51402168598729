export const PAID_COLUMN_HEADINGS = [
	'customFields-responses-table-orderId',
	'customFields-responses-table-type',
	'customFields-responses-table-email',
	'customFields-responses-table-fullName',
	'customFields-responses-table-amount',
	'customFields-responses-table-date',
	'customFields-responses-table-status',
	'customFields-responses-table-ticketTypes',
];

export const REGISTRATION_COLUMN_HEADINGS = [
	'customFields-responses-table-orderId',
	'customFields-responses-table-type',
	'customFields-responses-table-email',
	'customFields-responses-table-fullName',
	'customFields-responses-table-date',
	'customFields-responses-table-ticketTypes',
];

export const COLUMN_MIN_WIDTH = [100, 150, 300, 250, 250, 120, 250, 120, 250, 50];
export const COLUMN_MAX_WIDTH = [
	undefined,
	undefined,
	undefined,
	undefined,
	450,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
];

export const BROWSER_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const RESPONSES_INDEX = 4;
