import styled from 'styled-components';

export const List = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 0;
	padding: 0;
`;
export const ListItem = styled.li`
	display: flex;
	justify-content: space-between;
	text-decoration: none;
`;

export const Divider = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey5};
	width: 100%;
`;

export const TotalContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.grey14};
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	padding: 24px;
`;
