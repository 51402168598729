import { FC } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { EventCard } from '../EventCard';
import { EventCollectionProps } from './types';
import { convertUTCToDateBarFormat } from 'utils/utils';
import { WEEKDAYS } from '../../constants';
import { ROUTES } from 'features/routes';
import { CardsWrapper } from './EventCollection.styles';

const EventCollection: FC<EventCollectionProps> = ({ events }: EventCollectionProps) => {
	const { push } = useHistory();

	return (
		<CardsWrapper>
			{events.map(({ name, start_date, id, time_zone }) => {
				const { time, date } = convertUTCToDateBarFormat(start_date, time_zone);
				const _date = new Date(date);
				const weekday: string = _date.toLocaleDateString('en-us', { weekday: 'long' }).toLowerCase();

				return (
					<EventCard
						key={id}
						handleClick={() => push(generatePath(ROUTES.DASHBOARD_PATH, { id }))}
						title={name}
						time={time}
						weekday={WEEKDAYS[weekday]}
						day={_date.getDate()}
					/>
				);
			})}
		</CardsWrapper>
	);
};

export { EventCollection };
