import { useFormContext } from 'react-hook-form';
import { InputContainer } from 'features/common/components/Form/Form.styles';
import { FormInputProps } from '../types';

import { Container, LoginInput as Input, InputLabel } from './Input.styles';

const LoginInput = ({ id, name, label, ...rest }: FormInputProps) => {
	const { register } = useFormContext();

	return (
		<InputContainer hasError={false}>
			<InputLabel htmlFor={id ?? name} label={label} />
			<Container>
				<Input id={id} {...register(name)} {...rest} />
			</Container>
		</InputContainer>
	);
};

export { LoginInput };
