import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Form } from 'features/common/components/Form';
import { Button as UnstyledBtn } from 'features/common/components/Button';

export const Title = styled(Text)``;

export const SendTicketsSection = styled.div`
	width: 50%;
	display: flex;
	height: auto;
	flex-direction: column;
	width: 90vw;
`;

export const FormContainer = styled(Form)`
	display: flex;
	width: 100%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
	}
`;

export const Button = styled(UnstyledBtn)`
	width: fit-content;
	height: fit-content;
`;
