import { useEffect } from 'react';

import { BrandFontsProps } from './types';

import { ContentContainer, MyFontPicker } from './BrandFonts.styles';

export const BrandFonts = ({
	handleSelectedFont,
	initialFont: data,
}: BrandFontsProps) => {
	useEffect(() => {
		const primaryElement = document.querySelector('div[id="font-picker-0"] p');
		primaryElement?.classList.add('apply-font-0');
		handleSelectedFont(primaryElement?.innerHTML || '');
	}, [data]);

	const renderFont = (index: number) => (
		<MyFontPicker
			apiKey={process.env.REACT_APP_GOOGLE_FONTS as string}
			activeFontFamily={data}
			onChange={(nextFont) => {
				handleSelectedFont(nextFont?.family);
			}}
			limit={2000}
			pickerId={index.toString()}
			variants={index ? ['regular'] : ['800', '300', 'regular', '700', '600']}
		/>
	);

	return (
		<ContentContainer id="fontpicker">{data && renderFont(0)}</ContentContainer>
	);
};
