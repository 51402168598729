import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Table } from 'features/common/components/Table';
import { COLUMN_HEADINGS, COLUMN_MIN_WIDTH, GRID_COLUMNS, statusOptions } from './constants';
import { BodyTableProps } from './types';
import { StatusComponent } from '../components/StatusComponent';
import { getData } from 'services/api';
import { convertUTCToDateBarFormat, toUsFormat } from 'utils/utils';

import * as Styled from './PayoutsTable.styles';

export const PayoutsTable: React.FC = () => {
	const { t } = useTranslation();
	const { data: payoutsData } = useQuery({
		queryKey: ['payoutsData'],
		queryFn: () => getData('/payouts/partner'),
	});
	return (
		<Styled.Root>
			<Table>
				<thead>
					<Table.HeaderRow gridColumns={GRID_COLUMNS}>
						{COLUMN_HEADINGS.map((heading, i) => (
							<Table.Cell
								key={heading}
								minWidth={COLUMN_MIN_WIDTH[i]}
								className={i === COLUMN_HEADINGS.length - 1 ? 'desktopOnly' : ''}
							>
								<Table.ColumnHeader>{heading}</Table.ColumnHeader>
							</Table.Cell>
						))}
					</Table.HeaderRow>
				</thead>
				<tbody>
					{payoutsData &&
						payoutsData.map(({ event_name, amount, bank, last4, type, initiated_on, status, ID }: BodyTableProps) => {
							return (
								<Table.DataRow gridColumns={GRID_COLUMNS} key={`${ID}`}>
									<Table.Cell minWidth={COLUMN_MIN_WIDTH[0]}>{event_name}</Table.Cell>
									<Table.Cell minWidth={COLUMN_MIN_WIDTH[1]}>{toUsFormat(amount)}</Table.Cell>
									<Table.Cell minWidth={COLUMN_MIN_WIDTH[2]}>{bank}</Table.Cell>
									<Table.Cell minWidth={COLUMN_MIN_WIDTH[3]}>{last4}</Table.Cell>
									<Table.Cell minWidth={COLUMN_MIN_WIDTH[4]}>
										{type === 'total_remaining_payout' ? t('payouts-total') : t('payouts-partial')}
									</Table.Cell>
									<Table.Cell minWidth={COLUMN_MIN_WIDTH[5]}>{convertUTCToDateBarFormat(initiated_on).date}</Table.Cell>
									<Table.Cell minWidth={COLUMN_MIN_WIDTH[6]}>
										<StatusComponent status={status} options={statusOptions} />
									</Table.Cell>
								</Table.DataRow>
							);
						})}
				</tbody>
			</Table>
		</Styled.Root>
	);
};
