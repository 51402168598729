import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from 'features/common/components/Icon';
import { RecipientInfoForm } from './components/RecipientInfoForm';
import { initialRecipient } from 'features/SendTickets/utils';
import { RecipientProps } from 'features/SendTickets/types';
import { SendTicketsFormProps } from './types';
import { SendTicketsTitleSection } from './components/SendTicketsTitleSection';

import * as Styled from './SendTicketsForm.styles';

export const SendTicketsForm = ({ event }: SendTicketsFormProps) => {
	const { getValues, setValue, watch } = useFormContext();
	const { t } = useTranslation();

	const handleAddRecipient = () => {
		const currentValues = getValues('recipients');
		setValue('recipients', [...currentValues, initialRecipient]);
	};

	const handleRemoveRecipient = (id: number) => {
		const currentValues = getValues('recipients');
		currentValues.splice(id, 1);
		setValue('recipients', [...currentValues]);
	};
	const recipientsArray: RecipientProps[] = watch('recipients');

	if (!event) return null;

	return (
		<Styled.Root>
			<SendTicketsTitleSection title={event.name} days={event.days} streetAddress={event.streetAddress} />
			<Styled.Subtitle>{t('send-tickets-recipient-info')}</Styled.Subtitle>
			{recipientsArray?.map((recipient, i) => (
				<RecipientInfoForm
					key={i}
					id={i}
					{...recipient}
					ticketTypes={event.ticketTypes}
					onRemove={handleRemoveRecipient}
				/>
			))}
			<Styled.AddRecipientBtn onClick={handleAddRecipient}>
				<Icon iconName="plus" />
				{t('send-tickets-add-recipient')}
			</Styled.AddRecipientBtn>
		</Styled.Root>
	);
};
