import { useTranslation } from 'react-i18next';

import { OUTLINED } from 'features/common/components/Button/constants';
import { StickyBtnContainerProps } from '../../types';

import { StickyDiv, FormButton } from '../../ManageEmailCampaign.styles';

export const StickyBtnContainer = ({ handleClick, isLargeScreen }: StickyBtnContainerProps) => {
	const { t } = useTranslation();

	return (
		<>
			{!isLargeScreen && (
				<StickyDiv>
					<FormButton
						category={OUTLINED}
						onClick={() => handleClick({ status: 'DRAFT' })}
						text={t('marketing-campaignForm-saveDraft')}
						type="submit"
					/>
					<FormButton text={t('button-send')} type="submit" onClick={() => handleClick({ status: 'CREATED' })} />
				</StickyDiv>
			)}
		</>
	);
};
