import { DateFilterEnumPage } from 'features/common/hooks/useFilterButtonClick';

export const filterOptionFanDatabase: DateFilterEnumPage[] = [
	DateFilterEnumPage.allTime,
	DateFilterEnumPage.pastDay,
	DateFilterEnumPage.pastWeek,
	DateFilterEnumPage.pastMonth,
	DateFilterEnumPage.pastYear,
];

export const filterOptionOrdersPage: DateFilterEnumPage[] = [
	DateFilterEnumPage.allTime,
	DateFilterEnumPage.pastDay,
	DateFilterEnumPage.pastWeek,
	DateFilterEnumPage.pastMonth,
	DateFilterEnumPage.pastYear,
];

export const filterOptionRefundableTickets: DateFilterEnumPage[] = [
	DateFilterEnumPage.allTime,
	DateFilterEnumPage.pastDay,
	DateFilterEnumPage.pastWeek,
	DateFilterEnumPage.pastMonth,
	DateFilterEnumPage.pastYear,
];

//custom range filter option removed for now as it is not a beta priority
//we have them separately because they want different information depending on the filter
