import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AddTicketCardProps } from './types';

import * as Styled from './TicketCard.styles';

export const AddTicketCard: FC<AddTicketCardProps> = ({ onClick }: AddTicketCardProps) => {
	const { t } = useTranslation();
	return (
		<Styled.EmptyCardContainer onClick={onClick}>
			<Styled.EmptyCardColumn>
				<Styled.Icon iconName="plus" />
				<Styled.AddTicket>{t('ticketDetails-ticket-add-new')} </Styled.AddTicket>
			</Styled.EmptyCardColumn>
		</Styled.EmptyCardContainer>
	);
};
