import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const TableTitle = styled(Text)`
	font-weight: 600;
	font-size: 20px;
`;

export const TableContainer = styled.div`
	width: 100%;
	overflow: auto;
`;

export const CustomFlex = styled.div`
	display: flex;
	align-self: flex-start;
	align-items: center;
	gap: 8px;
`;
