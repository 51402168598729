import { useHistory, useLocation } from 'react-router-dom';

import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { Icon } from 'features/common/components/Icon';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { PromoCodeTypeCardProps } from './types';
import {
	CardContainer,
	CardDescription,
	CardTitle,
	StyledIcon,
} from './PromoCodeTypeCard.styles';

const PromoCodeTypeCard: React.FC<PromoCodeTypeCardProps> = ({
	description,
	icon,
	title,
	type,
	fromDashboard = false,
	path,
}: PromoCodeTypeCardProps) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const isLargeScreen = useResponsiveScreen();

	return (
		<CardContainer
			type={type}
			fromDashboard={fromDashboard}
			onClick={() => history.push(`${pathname}/${path}`)}
		>
			<StyledIcon src={icon} />
			<FlexContainer
				flexDirection="column"
				alignItems={isLargeScreen ? 'center' : 'flex-start'}
			>
				<CardTitle>{title}</CardTitle>
				<CardDescription>{description}</CardDescription>
			</FlexContainer>
			{/* TODO: Add onClick to Icon */}
			{!isLargeScreen && <Icon iconName="arrowRight" />}
		</CardContainer>
	);
};

export { PromoCodeTypeCard };
