import { useEffect } from 'react';

import { UseFormUnregister } from 'react-hook-form';
import { FormFields } from '../types';

export const useUnregisterFormSubs = (unregister: UseFormUnregister<FormFields>) => {
	useEffect(() => {
		return () => {
			unregister('totalEmails');
			unregister('cohorts');
			unregister('events');
			unregister('allEvents');
		};
	}, []);
};
