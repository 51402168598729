import { useTranslation } from 'react-i18next';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';

import * as Styled from './TicketTypeSelector.styles';

export const Title = () => {
	const { t } = useTranslation();

	return (
		<FlexContainer flexDirection="column">
			<Styled.Title>{t('ticketDetails-ticket-ticketDesigner')}</Styled.Title>
			<Styled.Subtitle>
				{t('ticketDetails-ticket-attachDesign')}
			</Styled.Subtitle>
		</FlexContainer>
	);
};
