import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AUTH } from 'features/common/components/Form/Input/constants';
import { Form } from 'features/common/components/Form';
import { ResetPasswordFormProps } from '../types';
import { confirmationCheck } from 'assets/images';
import { FormContainer, Title, Button } from 'features/Login/components/LoginForm/LoginForm.styles';
import { ROUTES } from 'features/routes';
import { logout } from 'services/auth';
import { useAppDispatch } from 'store/hooks';

import { Img, SuccessContainer } from './ResetPasswordForm.styles';

export const ResetPasswordForm = ({ isSuccess }: ResetPasswordFormProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const dispatch = useAppDispatch();

	const handleResetPasswordConfirmation = () => {
		if (isSuccess) {
			const accessToken = localStorage.getItem('accessToken');
			if (accessToken) dispatch(logout(() => push(ROUTES.LOGIN_PATH)));
			else push(ROUTES.LOGIN_PATH);
		}
	};

	return (
		<FormContainer className={isSuccess ? 'success' : ''}>
			<Title>{t(isSuccess ? 'reset-password-title-confirmation' : 'reset-password-title')}</Title>
			{isSuccess ? (
				<SuccessContainer>
					<Img src={confirmationCheck} />
				</SuccessContainer>
			) : (
				<>
					<Form.Input id="password" name="password" label="Password" category={AUTH} />
					<Form.Input id="confirmPassword" name="confirmPassword" label="Confirm Password" category={AUTH} />
				</>
			)}
			<Button
				text={t(isSuccess ? 'reset-password-button-confirmation' : 'reset-password-button')}
				type={isSuccess ? 'button' : 'submit'}
				onClick={handleResetPasswordConfirmation}
			/>
		</FormContainer>
	);
};
