import styled from 'styled-components';

import { FlexButtonProps } from './types';

export const FlexButton = styled.button<FlexButtonProps>`
	padding: 19px 0;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 16px;
	line-height: 24px;
	width: 100%;
	display: flex;
	gap: 16px;
	justify-content: flex-start;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.grey11};
	color: ${({ theme }) => theme.colors.white};
	border: none;
	border-bottom: ${({ theme, bottomBorder }) => (bottomBorder ? `1px solid ${theme.colors.lightGrey8}` : 'unset')};

	&:focus {
		outline: 0;
	}
	&:active {
		background-color: ${({ theme }) => theme.colors.darkGrey3};
	}
`;
