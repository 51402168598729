import { WrapperProps } from './types';

import { StyledLoader } from 'features/common/animations/Loader/Loader.styles';
import { Wrapper } from './LazyLoader.styles';

export const LazyLoader = ({ height, margin }: WrapperProps) => (
	<Wrapper height={height} margin={margin}>
		<StyledLoader />
	</Wrapper>
);
