import styled from 'styled-components';

import { RootProps, SquareColorProps } from './types';

export const Root = styled.div`
	display: grid;
	align-items: center;
	place-content: center;
	grid-template-columns: ${({ sm }: RootProps) =>
		sm ? 'repeat(2, 1fr)' : 'unset'};
`;

export const IconContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-right: 24px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-right: 0px;
	}
`;

export const SquareColor = styled.div`
	min-width: 24px;
	min-height: 24px;
	background-color: ${({ color }: SquareColorProps) => color};
	margin-right: 10px;
	border-radius: 1.5px;
`;

export const RefTitle = styled.div`
	font-family: ${({ theme }) => theme.fonts.primary};
	margin-bottom: 10px;
`;

export const Ref = styled.div`
	display: flex;
	align-items: center;
	font-family: ${({ theme }) => theme.fonts.primary};
	width: fit-content;
`;

export const RefBottom = styled(Ref)`
	margin-top: 10px;
`;

export const RefsContainer = styled.div`
	width: fit-content;
	justify-self: center;
`;
