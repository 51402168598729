import { useMutation, useQuery } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { camelizeKeys } from 'humps';

import { queryClient } from 'index';
import { EventCanGoLive, TicketBody, TicketDetailsParams } from './types';
import { NEW_TICKET } from './utils';
import { getData } from 'services/api';
import { uploadImage } from 'services/UploadImage/uploadImage';
import { createTicket } from 'features/CreateTicket/services';
import { updateTicket } from './services';
import { sendGAEvent } from 'utils/utils';
import { useToastNotification } from 'hooks/useToastNotification';
import { ROUTES } from 'features/routes';

export const useTicketTypeApi = () => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const { id: eventId, ticketId } = useParams<TicketDetailsParams>();
	const { showNotification, TYPES } = useToastNotification();

	const isNewTicket = location.pathname.includes(NEW_TICKET);

	const { data: ticket } = useQuery({
		queryKey: ['ticket', ticketId],
		queryFn: (): Promise<TicketBody> => getData(`/public/ticketing/ticket_types/${ticketId}`),
		enabled: !isNewTicket,
	});

	const { data: canGoLive } = useQuery({
		queryKey: ['canGoLive', eventId],
		queryFn: (): Promise<EventCanGoLive> => {
			const searchParams = new URLSearchParams([['set_live', 'false']]);
			return getData(`/events/${eventId}/go-live?` + searchParams.toString());
		},
		select: (data) => camelizeKeys(data) as EventCanGoLive,
		enabled: !!eventId,
	});

	const { mutateAsync: mutateUploadImage } = useMutation(uploadImage, {
		onError: () => {
			showNotification({
				message: t('error-uploading-image'),
				type: TYPES.ERROR,
			});
		},
	});

	const { mutate: mutateCreateTicket } = useMutation(createTicket, {
		onSuccess: () => {
			sendGAEvent({ category: 'Journeys', action: 'Create ticket' });
			push(ROUTES.DASHBOARD_PATH.replace(':id', eventId));
		},
	});

	const { mutate: mutateUpdateTicket } = useMutation(updateTicket, {
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['ticket', ticketId] });
			queryClient.invalidateQueries({ queryKey: ['event', eventId] });

			sendGAEvent({ category: 'Journeys', action: 'Edit ticket' });
			push(ROUTES.DASHBOARD_PATH.replace(':id', eventId));
		},
	});

	return { ticket, canGoLive, mutateUploadImage, mutateCreateTicket, mutateUpdateTicket };
};
