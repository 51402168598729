import { camelizeKeys, decamelizeKeys } from 'humps';

import api, { getData, post, put } from 'services/api';
import { EditCampaignProps, FormFields, FormatImgProps, PartnerInfo } from './types';
import { processDataForReq } from './utils';

export const createCampaign = async (formData: FormFields) => {
	const processedData = processDataForReq({
		cohortIds: formData.cohorts,
		eventIds: formData.events,
		focusIds: formData.focus,
	});
	return post('/marketing/email-campaigns', { ...decamelizeKeys(formData), ...processedData });
};

export const formatImgForReq = async ({ formData: { headerImages, name }, toastTools }: FormatImgProps) => {
	const { showNotification, t, TYPES } = toastTools;
	if (headerImages[0]?.base64) {
		try {
			const {
				data: { image_url },
			} = await api.post('/images', {
				base64_image: headerImages[0].base64,
				image_type: 'email-campaign',
				prefix: name,
			});
			headerImages[0] = { url: image_url };
		} catch (e) {
			showNotification({ message: t('error-uploading-image'), type: TYPES.ERROR });
		}
	}
};

export const editCampaign = async ({ formData, campaignId }: EditCampaignProps) => {
	const processedData = processDataForReq({
		cohortIds: formData.cohorts,
		eventIds: formData.events,
		focusIds: formData.focus,
	});
	return put(`/marketing/email-campaigns/${campaignId}`, { ...decamelizeKeys(formData), ...processedData });
};

export const getPartnerInfo = async () => {
	const data = await getData('/partners/me');
	return camelizeKeys(data) as PartnerInfo;
};
