import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from 'features/common/components/Button';
import { GroupWrap } from '../GroupWrap';
import { ResetModal } from '../TableContainer/components/ResetModal';
import { UserPanelProps } from './types';
import { convertToObj } from '../../utils';
import { logout } from 'services/auth';
import { useAppDispatch } from 'store/hooks';
import { ROUTES } from 'features/routes';

import { InitialContainer, TextWrap, Initials } from './UserPanel.styles';
import * as Styled from '../../MyProfile.styles';

export const UserPanel: FC<UserPanelProps> = ({ userData }) => {
	const { full_name: userName, email: userEmail, roles } = userData || {};
	const dispatch = useAppDispatch();

	const userRole = convertToObj(roles);
	const { t } = useTranslation();
	const { push } = useHistory();
	const [showResetModal, setshowResetModal] = useState(false);
	const userInitials = userName ? userName[0] + (userName[userName.lastIndexOf(' ') + 1] || '') : '';
	const handleLogout = () => dispatch(logout(() => push(ROUTES.LOGIN_PATH)));

	return (
		<Styled.Panel>
			<Styled.LeftContainer>
				<InitialContainer>
					<Initials element="h2">{userInitials}</Initials>
				</InitialContainer>
				<Styled.NameContainer>
					<Styled.InfoLabel htmlFor="user-name" className="left-panel">
						{t('settings-signed-in-as')}
					</Styled.InfoLabel>
					<Styled.NameHeader id="user-name">{userName}</Styled.NameHeader>
					<Styled.LogoutContainer onClick={handleLogout}>
						<Styled.LogoutIcon iconName="logout" />
						<Styled.LogoutText>{t('settings-log-out')}</Styled.LogoutText>
					</Styled.LogoutContainer>
				</Styled.NameContainer>
			</Styled.LeftContainer>
			<Styled.RightContainer>
				<Styled.CenteredWrap>
					<GroupWrap htmlFor="user-email" label={'settings-email'}>
						<TextWrap id="user-email">{userEmail}</TextWrap>
					</GroupWrap>
					<GroupWrap htmlFor="userRole" label={'settings-role'}>
						<Styled.TextMinContent id="userRole">{userRole.label}</Styled.TextMinContent>
					</GroupWrap>
					<GroupWrap htmlFor="password" label={'settings-account-password'} childrenInRow={true}>
						<Styled.TextMinContent id="password">************</Styled.TextMinContent>
						<Button
							category="underlined"
							text={t('settings-reset-password')}
							onClick={() => setshowResetModal(true)}
							type="button"
							disabled={userEmail === ''}
						/>
						{showResetModal && <ResetModal email={userEmail || ''} closeModal={() => setshowResetModal(false)} />}
					</GroupWrap>
				</Styled.CenteredWrap>
			</Styled.RightContainer>
		</Styled.Panel>
	);
};
