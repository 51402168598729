import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';
import { Icon } from 'features/common/components/Icon';

import { ReactComponent as CalendarBg } from 'assets/images/calendarBlackBackground.svg';

export const CalendarIcon = styled(CalendarBg)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: none;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding-right: 100px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding-right: 20px;
	}
`;

export const InfoContainer = styled.div`
	display: flex;
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 15px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 0;
	}
`;

export const Title = styled(Text)`
	font-size: 32px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 24px;
	}
`;

export const Subtitle = styled(Text)`
	font-size: 24px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 12px;
		line-height: 16px;
	}
`;

export const DeleteBtn = styled(Button)`
	width: fit-content;
	height: fit-content;
	margin: 0;
`;

export const TrashIcon = styled(Icon)`
	cursor: pointer;
`;
