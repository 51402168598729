import { colorStyleWebsetting1, colorStyleWebsetting2, colorStyleWebsetting3 } from 'assets/images';
import { PaletteKey } from './types';

export const palette: PaletteKey[] = ['background_color', 'theme_color', 'header_color'];

export const selectColorStyleOptions = [
	{
		title: palette[0],
		text: 'Your background color will be the color of the majority of your page and what most of the other elements sit on top of. In the below image the background is white.',
		image: colorStyleWebsetting1,
	},
	{
		title: palette[1],
		text: 'Your Theme color will be your secondary complimentary color that will add pops of color to your site.',
		image: colorStyleWebsetting2,
	},
	{
		title: palette[2],
		text: 'Your Header color will be used only as the background color of the header part of your site.',
		image: colorStyleWebsetting3,
	},
];
