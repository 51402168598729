import styled from 'styled-components';
import { Input } from 'features/common/components/Form/Form.styles';
import { InputLabel as UnstyledInputLabel } from 'features/common/components/Form/InputLabel';

export const Container = styled.div`
	padding: 1px;
	border-radius: 2px;
	background: ${({ theme }) => theme.colors.greenGradient};
	box-shadow: ${({ theme }) => theme.shadows.greenGradientShadow};
`;

export const LoginInput = styled(Input)`
	margin: 0px;
	border: 0px;

	&:focus {
		width: 100%;
		height: 35px;
	}
`;

export const InputLabel = styled(UnstyledInputLabel)`
	font-size: 16px;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.white};
`;
