import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { InputProps } from './types';
import { InputToggle } from 'features/common/components/Form/InputToggle';
import { InputLabel } from 'features/common/components/Form/InputLabel';

import { Error, Input as StyledInput, InputContainer } from 'features/common/components/Form/Form.styles';

export const Input: FC<InputProps> = ({ id, name, label, isToggle = true, ...rest }: InputProps) => {
	const {
		register,
		setValue,
		watch,
		formState: { errors },
	} = useFormContext();
	const error = errors[name];

	return (
		<InputContainer hasError={Boolean(error)}>
			{isToggle ? (
				<InputToggle htmlFor={id ?? name} label={label} name={`${name}Enabled`} />
			) : (
				<InputLabel htmlFor={id ?? name} label={label} />
			)}
			{name === 'name' ? (
				<StyledInput id={id ?? name} {...rest} type="text" {...register(name)} />
			) : (
				<StyledInput
					id={id ?? name}
					value={watch(name)}
					onChange={(e) => setValue(name, e.target.value)}
					{...rest}
					type="text"
				/>
			)}
			<Error>{error?.message}</Error>
		</InputContainer>
	);
};
