import styled from 'styled-components';

import { ReactComponent as ClockSVG } from 'assets/images/clock.svg';
import { ReactComponent as CalendarSVG } from 'assets/images/calendar.svg';

export const DateContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const IconContainer = styled.div`
	padding-right: 10px;
	display: flex;
	align-items: center;
	font-size: 12px;
`;

export const ClockIcon = styled(ClockSVG)`
	margin-right: 5px;
`;

export const CalendarIcon = styled(CalendarSVG)`
	margin-right: 5px;
`;
