import styled from 'styled-components';

import { Button } from 'features/common/components/Button';

export const BodyContainer = styled.div`
	display: flex;
	width: 100%;

	box-sizing: border-box;
	padding-left: 144px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		align-items: center;
		flex-direction: column;
		gap: 32px;
		padding: 72px 108px 72px 144px;
	}
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 16px;
		max-width: 600px;
		padding: 32px 24px 64px 24px;
	}
`;
export const LightBackground = styled.div`
	background-color: ${({ theme }) => theme.colors.darkGrey7};
	box-sizing: border-box;
	width: 50%;
	padding: 50px 96px;
`;

export const BackgroundBlur = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ theme }) => theme.colors.blackGradient2};
	height: 140px;
	position: sticky;
	bottom: 0;
	width: 100%;
`;

export const RefundButton = styled(Button)`
	border-radius: 8px;
	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
	margin: 0;
	padding: 15px 75px;
	width: 275px;
`;
