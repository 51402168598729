import { today, unlimitedDate } from 'utils/utils';
import { HandleUnlimitedDate, HandleNewDatesProps, HandleNewTimesProps } from './types';
import { getCurrentDateNoTime, getCurrentTime } from 'utils/utils';

export const handleUnlimitedDate = ({ watch, getValues, isRealValue }: HandleUnlimitedDate) => {
	if (watch('isEndDate')) {
		if (getValues('endDate')[0].start_date.getFullYear() !== 1970) return getValues('endDate')[0].start_date;
		else return today;
	} else if (isRealValue) return unlimitedDate;
	else return null;
};

export const handleNewDates = ({ event, currentDates, index }: HandleNewDatesProps) => {
	const actualDayNoTime = getCurrentDateNoTime(event);

	const newDates = currentDates.map(({ start_date, end_date }, i) => {
		const currentTimeStart = getCurrentTime(start_date);
		const currentTimeEnd = getCurrentTime(end_date);

		return {
			...currentDates[i],
			start_date: index === i ? new Date(`${actualDayNoTime} ${currentTimeStart}`) : start_date,
			end_date: index === i ? new Date(`${actualDayNoTime} ${currentTimeEnd}`) : end_date,
		};
	});

	return newDates;
};

export const handleNewTimes = ({ event, currentDates, label, index }: HandleNewTimesProps) => {
	const newTime = getCurrentTime(event);
	const isEndKey = label.includes('End') ? 'end_date' : 'start_date';

	const newDates = currentDates.map(({ start_date, end_date }, i) => {
		const actualDayNoTime = getCurrentDateNoTime(start_date);
		const isEnd = label.includes('End') ? end_date : start_date;

		return {
			...currentDates[i],
			[isEndKey]: index === i ? new Date(`${actualDayNoTime} ${newTime}`) : isEnd,
		};
	});

	return newDates;
};

export const handleNewDateTime = ({ event, currentDates, label, index }: HandleNewTimesProps) => {
	return currentDates.map(({ start_date, end_date }, i) => {
		if (index === i) {
			return {
				start_date: /start/i.test(label) ? new Date(`${event}`) : start_date,
				end_date: /end/i.test(label) ? new Date(`${event}`) : end_date,
			};
		} else return { start_date, end_date };
	});
};
