import { createSlice } from '@reduxjs/toolkit';

type BoxOfficeState = {
	ticketsSelected: boolean;
	ticketsAvailable: boolean;
};

const initialState = {
	ticketsSelected: false,
	ticketsAvailable: false,
} as BoxOfficeState;

const boxOfficeSlice = createSlice({
	name: 'boxOfficeSlice',
	initialState,
	reducers: {
		setTicketsSelected(state, action) {
			state.ticketsSelected = action.payload;
		},
		setTicketsAvailable(state, action) {
			state.ticketsAvailable = action.payload;
		},
	},
});

export const { setTicketsSelected, setTicketsAvailable } = boxOfficeSlice.actions;
export default boxOfficeSlice.reducer;
