import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { DropzoneInput } from 'features/common/components/ImageInputs';
import { Page } from '../..';
import { handleLink } from 'utils/handleLink';
import { CenteredContainer, StepTitle } from '../Steps.styles';

export const TicketTemplateImage = () => {
	const { t } = useTranslation();
	const { hash } = useLocation();
	const { watch } = useFormContext();

	const images = watch('images');

	useEffect(() => {
		if (images.length > 0 && hash === '#4') handleLink('#5');
	}, [images.length]);

	return (
		<Page id="4">
			<CenteredContainer>
				<StepTitle element="h3">{t('ticketDetails-ticket-addImage')}</StepTitle>
				<DropzoneInput maxPicturesAllowed={1} inputId="images" />
			</CenteredContainer>
		</Page>
	);
};
