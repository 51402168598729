export const taxInfoInputs = [
	{
		name: 'name',
		label: 'Name',
		type: 'text',
	},
	{
		name: 'business_name',
		label: 'Business Name',
		type: 'text',
	},
	{
		name: 'federal_tax_classification',
		label: 'Federal Tax Classification',
		type: 'text',
	},
	{
		name: 'tax_exempt',
		label: 'Tax Exempt',
		type: 'check',
	},
	{
		name: 'address1_address',
		label: 'Address 1',
		type: 'text',
	},
	{
		name: 'address1_city',
		label: 'City',
		type: 'text',
	},
	{
		name: 'address1_zip',
		label: 'Zip/ Postal Code',
		type: 'text',
	},
	{
		name: 'address1_state',
		label: 'State/Province',
		type: 'text',
	},
	{
		name: 'id_number',
		label: 'SSN or EIN',
		type: 'ssnOrEin',
	},
	{
		name: 'name_for_signature',
		label: 'Name for Signature',
		type: 'text',
	},
];

export const defaultValues = {
	name: '',
	business_name: '',
	federal_tax_classification: '',
	tax_exempt: false,
	id_number: '',
	id_type: 'ssn',
	name_for_signature: '',
	address1_address: '',
	address1_city: '',
	address1_state: '',
	address1_zip: '',
	address2_address: '',
	address2_city: '',
	address2_state: '',
	address2_zip: '',
};
