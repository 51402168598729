import { useTranslation } from 'react-i18next';

import { Modal } from 'features/common/components/Modal';
import { QUATERNARY } from 'features/common/components/Button/constants';
import { InfoModalProps } from './types';

import { ModalBody, CustomText, ButtonWrap, ModalButton } from './InfoModal.styles';

export const InfoModal = ({ closeModal, onContinueClick }: InfoModalProps) => {
	const { t } = useTranslation();

	return (
		<Modal onClose={closeModal}>
			<Modal.Header>{t('marketing-infoModal-cohortUpload')}</Modal.Header>
			<ModalBody>
				<CustomText>{t('marketing-infoModal-cohort-explanation')}</CustomText>
				<CustomText>{t('marketing-infoModal-cohort-usage')}</CustomText>
			</ModalBody>
			<ButtonWrap>
				<ModalButton text={t('button-cancel')} category={QUATERNARY} onClick={closeModal} type="button" />
				<ModalButton text={t('button-continue')} onClick={onContinueClick} type="button" />
			</ButtonWrap>
		</Modal>
	);
};
