import styled from 'styled-components';

import { Button } from 'features/common/components/Button';
import { ImgProps } from './types';

export const HoverOptions = styled.div`
	position: absolute;
	display: flex;
	gap: 16px;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
`;

export const EditButton = styled(Button)`
	width: 130px;
	height: 40px;
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
`;

export const RatioImg = styled.img<ImgProps>`
	aspect-ratio: 3/2;
	border: 1px solid ${({ theme }) => theme.colors.darkGrey5};
	box-sizing: border-box;
	border-radius: 8px;
	width: 100%;
	max-width: 525px;
	opacity: ${({ showHoverOptions }) => (showHoverOptions ? 0.3 : 1)};
`;

export const RelativeDiv = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ImageInputContainer = styled.div`
	all: unset;
	width: 100%;
	height: 200px;
	display: flex;
	font-size: 20px;
	cursor: pointer;
	padding: 40px 72px;
	border-radius: 4px;
	text-align: center;
	align-items: center;
	box-sizing: border-box;
	justify-content: center;
	background: ${({ theme }) => theme.colors.grey2};
	font-family: ${({ theme }) => theme.fonts.primary};
	border: ${({ theme }) => `1.5px dashed ${theme.colors.lightBlue}`};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 10px;
		min-width: 90%;
	}
`;

export const Container = styled.div`
	width: 100%;
`;
