import styled from 'styled-components';
import { Button as UnstyledButton } from 'features/common/components/Button';
import { Icon } from 'features/common/components/Icon';

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 20px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		justify-content: flex-start;
		gap: 20px;
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
`;

export const RowContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	width: 100%;
`;

export const Text = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-transform: initial;

	&.isOpen {
		height: 120px;
	}
`;

export interface PaletteProps {
	color: string;
}

export const PaletteContainer = styled.div<PaletteProps>`
	width: 35px;
	height: 35px;
	border-radius: 8px;
	&.large {
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&.data {
			background: ${(props) => props.color};
			border: none;
		}
		&.none {
			background: transparent;
			border: 1.5px dashed ${({ theme }) => theme.colors.white};
		}

		@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			width: 100px;
			height: 100px;
		}
	}
`;

export const ContainerActionButtons = styled.div`
	display: flex;
	gap: 16px;
	justify-content: center;
	padding: 0px;
`;

export const ModalButton = styled(UnstyledButton)`
	padding: 0px;
	width: 125px;
	height: 44px;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const Button = styled(ModalButton)`
	padding: 0px;
	width: 125px;
	height: 44px;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	font-family: ${({ theme }) => theme.fonts.primary};
	background-color: ${({ theme }) => theme.colors.lightGrey4};

	&:hover {
		background-color: ${({ theme }) => theme.colors.red};
	}
`;

export const Option = styled.button`
	all: unset;
	width: 50%;
	display: flex;
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	align-items: center;
	justify-content: center;
	font-family: ${({ theme }) => theme.fonts.primary};
	background: ${({ theme }) => theme.colors.lightGrey5};
`;

export const Container = styled.div`
	height: fit-content;
	object-fit: fill;
	width: 350px;
	display: flex;
	padding: 8px 16px;
	cursor: pointer;
	border-radius: 8px;
	align-items: center;
	box-sizing: border-box;
	gap: 20px;
	background-color: ${({ theme }) => theme.colors.grey12};
	text-transform: capitalize;
	font-family: ${({ theme }) => theme.fonts.primary};
	position: relative;
	flex-direction: column;
	justify-content: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

export const Arrow = styled(Icon)`
	width: 16px;
	height: 16px;

	&.inactive {
		transition: 0.6s ease;
		transform: rotate(0deg);
	}

	&.active {
		transition: 0.6s ease;
		transform: rotate(180deg);
	}
`;

export const Group = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const Image = styled.img`
	width: 100%;
`;
