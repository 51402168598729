import styled, { css } from 'styled-components';

export const RightContainer = styled.div`
	gap: 24px;
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: calc(60vw - 32px);
	height: calc(100vh - 64px);
	background: ${({ theme }) => theme.colors.grey3};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100vw;
		height: calc(100vh - 100px);
		background: ${({ theme }) => theme.colors.blackGrey1};
	}
`;

export const hideScrollbar = css`
	::-webkit-scrollbar {
		background-color: transparent;
	}
	::-webkit-scrollbar-corner {
		background-color: transparent;
	}
`;

export const OverflowX = styled.div`
	overflow-x: scroll;
	${hideScrollbar}
`;
