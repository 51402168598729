import styled from 'styled-components';

import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import { ContainerProps, InputProps } from './types';

export const Container = styled.div<ContainerProps>`
	max-width: ${({ width }) => width ?? '346px'};
	width: 100%;
	height: 38px;
	background: ${({ theme }) => theme.colors.white};
	border-radius: 20px;
`;

export const Icon = styled(UnstyledIcon)`
	position: relative;
	left: 27px;
	top: 8px;
`;

export const Input = styled.input<InputProps>`
	position: relative;
	left: 32px;
	width: 70%;
	height: 70%;
	border-width: 0;
	border-radius: 20px;
	font-weight: 400;
	font-size: ${({ fontSize }) => fontSize || '16px'};

	&:focus-within {
		outline: 0;
	}
`;
