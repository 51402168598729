import { StdText } from 'features/common/components/Text/Text.styles';
import styled from 'styled-components';

export const Container = styled.div`
	gap: 16px;
	display: flex;
	max-width: 100%;
	align-items: center;
	padding: 12px 0;
	overflow-x: auto;

	::-webkit-scrollbar {
		display: none;
	}
`;

export const SummaryWrap = styled.div`
	background-color: ${({ theme }) => theme.colors.grey};
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;
	flex-shrink: 0;
	padding: 16px;
	height: 50px;
	width: 250px;
`;

export const EllipsisText = styled(StdText)`
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;

export const ScrollWrap = styled.div`
	display: flex;
	gap: 16px;
`;
