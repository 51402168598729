import moment from 'moment';
import { FC, memo } from 'react';

import { CustomTooltipProps } from './types';

import { ContainerTooltip, TextTooltip } from './TicketsSold.styles';

const CustomTooltip: FC<CustomTooltipProps> = ({ active, label, data }: CustomTooltipProps) => {
	if (!active) {
		return null;
	}
	const tooltipData = data?.find(({ date }) => date.valueOf() === label?.valueOf());
	const date = moment(tooltipData?.date).format('MM/DD/YYYY');

	return (
		<ContainerTooltip>
			<TextTooltip element="h3">
				{date}
				<br />
				{tooltipData &&
					Object.keys(tooltipData).map((key) => {
						return (
							key !== 'date' && (
								<>
									{`${key}: ${tooltipData[key]}`}
									<br />
								</>
							)
						);
					})}
			</TextTooltip>
		</ContainerTooltip>
	);
};

const memorizedCustomTooltip: FC<CustomTooltipProps> = memo(CustomTooltip);

export { memorizedCustomTooltip as CustomTooltip };
