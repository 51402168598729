import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { RefundFail } from './components/RefundFail';
import { RefundOutcomesProps } from './types';
import { useToastNotification } from 'hooks/useToastNotification';
import { RefundIncomplete } from './components/RefundIncomplete';

export const RefundOutcomes: FC<RefundOutcomesProps> = ({ refundData, refundTickets, refundPerTicket, ticketData }) => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const [showFail, setShowFail] = useState(false);
	const [showIncomplete, setShowIncomplete] = useState(false);
	const { showNotification, TYPES } = useToastNotification();

	useEffect(() => {
		if (refundData) {
			const isMultipleRefunds = refundData.refunds.length > 1 ? 'successes' : 'success';
			if (refundData.errors.length > 0 && refundData.refunds.length > 0) setShowIncomplete(true);
			else if (refundData.errors.length > 0) setShowFail(true);
			else {
				goBack();
				showNotification({
					message: t(`refundTicketsSummary-refundOutcomes-${isMultipleRefunds}`),
					type: TYPES.SUCCESS,
				});
			}
		}
	}, [refundData]);

	return (
		<>
			{showFail && <RefundFail refundTickets={refundTickets} setShowFail={setShowFail} />}
			{showIncomplete && refundData && (
				<RefundIncomplete
					setShowIncomplete={setShowIncomplete}
					refundPerTicket={refundPerTicket}
					refundData={refundData}
					ticketData={ticketData}
				/>
			)}
		</>
	);
};
