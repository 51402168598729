import api from 'services/api';
import { UploadImageProps } from './types';

export const uploadImage = ({ image, prefix, index, type }: UploadImageProps) =>
	api
		.post('/images', {
			base64_image: image.base64,
			image_type: type,
			prefix,
		})
		.then((res) => ({ ...res, data: { ...res.data, index } }));
