import { FlexContainer } from 'features/common/components/FlexContainer';
import { TicketType } from '../TicketType';
import { useMobileView } from 'hooks/useMobileView';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { MultidayType } from 'features/common/types';

export const MultidayTickets = () => {
	const isMobile = useMobileView();
	const { multiday } = useAppSelector(({ lastMultiDay }: RootState) => lastMultiDay);

	return (
		<>
			{/* {multiday?.map(({ day, timezone, ticketTypes }) => (
				<FlexContainer flexDirection="column" key={day.id} gap="12px">
					<MultidayTitle>{convertUTCToDate(day.startDate, timezone, 'EEEE, LLL d')}</MultidayTitle>
					<FlexContainer flexDirection="column" gap="32px">
						{ticketTypes.map((value) =>
							TicketType({
								value,
								startDate: day.startDate,
								endDate: day.endDate,
								timezone: timezone,
								isMobile,
								dayId: day.id,
							})
						)}
					</FlexContainer>
				</FlexContainer>
			))} */}
			{/* TODO: uncomment the lines above and remove the ones at the bottom when multidays feature is ready */}
			<FlexContainer flexDirection="column" gap="32px">
				{(multiday as MultidayType[])[0].ticketTypes.map((value) =>
					TicketType({
						value,
						startDate: (multiday as MultidayType[])[0].day.startDate,
						endDate: (multiday as MultidayType[])[0].day.endDate,
						timezone: (multiday as MultidayType[])[0].timezone,
						isMobile,
						dayId: (multiday as MultidayType[])[0].day.id,
					})
				)}
			</FlexContainer>
		</>
	);
};
