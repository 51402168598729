import { useFormContext } from 'react-hook-form';

import { Icon } from 'features/common/components/Icon';
import { CardGalleryImageUploadedProps } from './types';
import { Media } from 'features/EventImgContent/types';
import { isUploadedImg, isNewImg, isFetchedImg } from 'features/EventImgContent/utils';

import { Button, CardContainer, CardImg } from './CardGalleryImageUploaded.styles';

const CardGalleryImageUploaded = ({ image }: CardGalleryImageUploadedProps) => {
	const { setValue, watch } = useFormContext();
	const images: Media[] = watch('mediaContent.media');

	const deleteImage = (deleteImage: Media) => {
		setValue(
			'mediaContent.media',
			images.filter((currImg) => {
				if (isNewImg(deleteImage) && isNewImg(currImg)) {
					return currImg.url !== deleteImage.url;
				} else if (isUploadedImg(deleteImage) && isUploadedImg(currImg)) {
					return currImg.position !== deleteImage.position;
				} else if (isFetchedImg(deleteImage) && isFetchedImg(currImg)) {
					return currImg.ID !== deleteImage.ID;
				}
			})
		);
	};

	return (
		<CardContainer>
			<CardImg src={image?.url + (isNewImg(image) ? '' : '?alt=media')} data-cy="gallery-img" />
			<Button onClick={() => deleteImage(image)} type="button" data-cy="delete-gallery-img">
				<Icon iconName="roundClose" />
			</Button>
		</CardContainer>
	);
};

export { CardGalleryImageUploaded };
