import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { FontProps } from './types';

export { RadioButton } from 'features/common/components/RadioButton/RadioButton.styles';
export { CheckboxButton } from 'features/common/components/CheckboxButton/CheckBoxButton.styles';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 24px;
	max-width: 850px;
	padding: 48px;
	width: 100%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		max-width: 650px;
	}
`;

export const FormText = styled(Text)<FontProps>`
	color: ${({ theme }) => theme.colors.white};
	font-size: ${({ isSmall }) => (isSmall ? ' 18px' : '24px')};
	font-weight: 400;
	line-height: normal;
	text-transform: none;
`;

export const StrongText = styled(FormText)`
	color: ${({ theme }) => theme.colors.green};
`;

export const ListContainer = styled.div`
	max-height: 300px;
	overflow-y: auto;
`;

export const Option = styled.label`
	border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGrey3}`};
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 16px;
	height: 35px;
	justify-content: flex-start;
	margin: 0 16px;
	padding: 8px;
`;
