export const handleLink = (href: string) => {
	const link = document.createElement('a');
	link.href = href;
	setTimeout(() => {
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}, 200);
};

export const handleDownload = (file: BlobPart, fileName: string) => {
	const url = URL.createObjectURL(new Blob([file]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	link.remove();
};
