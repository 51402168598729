import styled from 'styled-components';

import { Text as UnstyledText } from 'features/common/components/Text';
import { Button as UnstyledButton } from 'features/common/components/Button';
import { Divider as UnstyledDivider } from 'features/common/components/Divider';
import { FlexContainer } from 'features/common/components/FlexContainer';

export const Wrapper = styled(FlexContainer)`
	justify-content: center;
	align-items: center;
	width: 100%;
	height: Calc(100vh - 144px);
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: auto;
		margin: 30px 0px 30px 0px;
	}
`;

export const InnerContainer = styled(FlexContainer)`
	flex-direction: column;
	margin: 50px 0px 50px 0px;
`;

export const Title = styled(UnstyledText)`
	font-size: 16px;
	line-height: 24px;
	&.green {
		color: ${({ theme }) => theme.colors.green12};
	}
	&.center {
		text-align: center;
	}
	&.ticketsSent {
		text-align: center;
		font-size: 24px;
		font-weight: 600;
	}
`;

export const Text = styled(UnstyledText)`
	font-size: 16px;
	font-weight: 700;
`;

export const Total = styled(UnstyledText)``;

export const Button = styled(UnstyledButton)`
	border-radius: 8px;
	padding: 8px 24px;
	width: 100%;
	height: 50px;
	font-size: 16px;
	margin: 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 300px;
	}
`;

export const Divider = styled(UnstyledDivider)`
	margin-top: 16px;
	margin-bottom: 16px;
`;

export const Img = styled.img`
	width: 72px;
	height: 72px;
	margin-bottom: 16px;
`;
