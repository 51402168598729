import { useTranslation } from 'react-i18next';

import { ImgWithHover } from 'features/common/components/ImageInputs';
import { FlexContainer } from 'features/common/components/FlexContainer';

import { SubTitle, InfoText } from '../../MediaContent.styles';
import * as Styled from './EventImage.styles';

const EventImage = () => {
	const { t } = useTranslation();

	return (
		<Styled.Container>
			<FlexContainer flexDirection="column" width="100%">
				<SubTitle>{t('generic-event-image')}</SubTitle>
				<InfoText>{t('website-media-content-eventImg-sizing')}</InfoText>
			</FlexContainer>
			<ImgWithHover inputId="mediaContent.eventImage" inputDataCy="event-img-input" />
		</Styled.Container>
	);
};

export { EventImage };
