import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { defaultValues } from '../utils';
import { getWebSettingsData } from '../services';
import { FieldProps } from '../types';

export const useGetWebsiteSettingsData = (id: string): { isLoading: boolean; data: FieldProps } => {
	const { data, isLoading } = useQuery({
		queryKey: ['websettings'],
		queryFn: () => getWebSettingsData(id),
	});
	return {
		data: useMemo(() => {
			return data || defaultValues;
		}, [data]) as FieldProps,
		isLoading,
	};
};
