import { TFunction } from 'react-i18next';
import { object, ref, string } from 'yup';
import { WelcomeFormProps } from './hooks/types';

export const defaultValues = { email: '', password: '' };

export const defaultErrors = {
	wrongPassword: false,
	wrongEmail: false,
	unknownError: false,
};

export const welcomeDefaultValues = { password: '', currentPassword: '' };

export const validationSchema = (t: TFunction<'translation', undefined>) =>
	object().shape({
		email: string().required(t('login-requiredEmail')).email(t('login-invalidEmail')),
		password: string().required(t('login-requiredPassword')),
	});

export const LOGIN_ERRORS = {
	WRONG_PASSWORD: 'auth/wrong-password',
	EMAIL_NOT_FOUND: 'auth/user-not-found',
	SCANNER_ROLE: 'auth/is-scanner-role',
};

export const welcomeValidationSchema = (t: TFunction<'translation', undefined>) =>
	object().shape({
		password: string().required(t('login-welcome-confirm-password')),
		confirmPassword: string().oneOf([ref('password'), null]),
	});

export const throttle = (callback: ({ partnerId, userId, password }: WelcomeFormProps) => Promise<void>) => {
	let isReady = true;

	return ({ partnerId, userId, password }: WelcomeFormProps) => {
		if (isReady) {
			isReady = false;
			window.setTimeout(() => (isReady = true), 5000);
			callback({ partnerId, userId, password });
		}
	};
};
