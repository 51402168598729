import api from 'services/api';
import { SendTicketsProps, ValidateTicketsProps } from './types';

export const SendTickets = ({ ticketTypeDic, promocodesIds, email }: SendTicketsProps) =>
	api.post('/tickets/multiple_purchase_bluesnap', {
		email,
		payment_type: 'box',
		ticket_types: ticketTypeDic,
		promocodes_ids: promocodesIds,
	});

export const ValidateTickets = ({ ticketsIds, eventId }: ValidateTicketsProps) =>
	api.post('/tickets/validate', {
		ticket_ids: ticketsIds,
		event_id: eventId,
	});
