import styled from 'styled-components';

import { ButtonContainerProps } from './types';
import { Text } from 'features/common/components/Text';

export const Container = styled.div`
	margin-bottom: 12px;
	width: 100%;

	&.relative {
		position: relative;
	}
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
	gap: 16px;
	width: 100%;
	padding: ${({ hasValidImage }) => (hasValidImage ? 24 : 0)}px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 10px;
	border-radius: 4px;
	box-sizing: border-box;
	border: ${({ theme, hasValidImage }) => (hasValidImage ? `1px dashed ${theme.colors.green}` : 'none')};

	&.with-image {
		overflow: hidden;
		justify-content: center;
		padding: 24px 0px 0px 0px;
	}

	&.relative {
		position: relative;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		justify-content: center;
	}

	&.requiredFields {
		border: 1px solid ${({ theme }) => theme.colors.greenBlue3};
		padding: 10px;
	}
`;

export const Image = styled.img`
	width: 156px;
	height: 340px;
	object-fit: cover;
	border-radius: 3px;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 24px;
		line-height: 36px;
		border-radius: 6px;
	}
`;

export const ImageOptions = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
`;

export const StyledText = styled(Text)`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
`;
