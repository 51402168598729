import styled from 'styled-components';

export const StyledInput = styled.input`
	all: unset;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.whiteNac};
	background: ${({ theme }) => theme.colors.white};
	padding: 8px 16px;
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const Label = styled.div`
	font-family: ${({ theme }) => theme.fonts.primary};
	color: ${({ theme }) => theme.colors.lightGrey7};
`;
