import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Form } from 'features/common/components/Form';

export const Title = styled(Text)`
	font-size: 20px;
	align-self: start;
`;

export const BankingInfoCard = styled.div`
	height: auto;
	background-color: ${({ theme }) => theme.colors.blackGrey4};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	padding: 48px;
	margin-bottom: 32px;
	cursor: pointer;
`;

export const BankInfoTextContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const BankInfoText = styled.text<{ connected?: boolean }>`
	font-size: 16px;
	white-space: pre-line;
	margin: 0;
	color: ${({ connected, theme }) => (connected ? theme.colors.green : theme.colors.orange)};
	line-height: 24px;
	margin-top: 20px;
`;

export const BankInfoSubTitle = styled(Text)`
	font-size: 12px;
	margin: 0;
	line-height: 24px;
	margin-bottom: 20px;
	padding-top: 0px;
	white-space: pre-line;
`;

export const FormContainer = styled(Form)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
`;

export const ResponsiveContainer = styled.div`
	margin-bottom: 25px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

export const ViewButton = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 4px;
`;
