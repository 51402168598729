import { useEffect } from 'react';

import { PromoCodesSection as PromoCodesSectionComponent } from 'features/PromoCodesSection';
import { ROUTES } from 'features/routes';
import { sendGAPage } from 'utils/utils';

const PromoCodesSection = (): JSX.Element => {
	useEffect(() => {
		sendGAPage({ page: ROUTES.PROMO_CODES_PATH, title: 'Promo Codes Page' });
	}, []);

	return <PromoCodesSectionComponent />;
};
export default PromoCodesSection;
