import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'features/common/components/Modal/Modal';
import {
	QUATERNARY,
	TERTIARY,
} from 'features/common/components/Button/constants';
import { ROUTES } from 'features/routes';

import {
	ContainerActionButtons,
	ContainerBody,
	ModalButton,
	Icon,
	Text,
	ExitIcon,
	ExitButton,
} from './ModalCancel.styles';

const ModalCancel: FC = () => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false);

	const cancel = () => push(ROUTES.HOME_PATH);

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	return showModal ? (
		<Modal onClose={closeModal}>
			<>
				<Modal.Header>{t('createEvent-cancel-title')}</Modal.Header>
				<Modal.Body>
					<ContainerBody>
						<Icon iconName="alert" />
						<Text element="p">{t('createEvent-cancel-subtitle')}</Text>
					</ContainerBody>
				</Modal.Body>
				<Modal.ActionButtons>
					<ContainerActionButtons>
						<ModalButton
							category={QUATERNARY}
							onClick={closeModal}
							text={t('button-cancel')}
							type="button"
						/>
						<ModalButton
							category={TERTIARY}
							onClick={cancel}
							text={t('button-delete')}
							type="button"
						/>
					</ContainerActionButtons>
				</Modal.ActionButtons>
			</>
		</Modal>
	) : (
		<ExitButton onClick={openModal} type="button">
			<ExitIcon iconName="close" />
		</ExitButton>
	);
};

export { ModalCancel };
