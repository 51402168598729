import { FC } from 'react';
import { FocusOn } from 'react-focus-on';
import { useSwipeable } from 'react-swipeable';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { MobileModalProps } from './types';

import { AbsoluteWrap, ModalBackground, ModalPopup, Line } from './MobileModal.styles';

const MobileModal: FC<MobileModalProps> = ({ isOpen, children, closeModal }) => {
	const className = isOpen ? 'show' : '';
	const handlers = useSwipeable({
		onSwipedDown: closeModal,
		delta: 10,
	});

	return (
		<AbsoluteWrap>
			<FocusOn enabled={isOpen}>
				<ModalBackground onClick={closeModal} className={className} />
				<ModalPopup onClick={(e) => e.stopPropagation()} role="dialog" aria-modal="true" isOpen={isOpen} {...handlers}>
					<FlexContainer justifyContent="center">
						<Line />
					</FlexContainer>
					{children}
				</ModalPopup>
			</FocusOn>
		</AbsoluteWrap>
	);
};

export { MobileModal };
