import { useTranslation } from 'react-i18next';

import { BrandFonts } from 'features/common/components/BrandFonts/BrandFonts';
import { ByGoogleFontsComponentProps } from './types';

import { FontFamilyCard, FontFamilySection, SelectedFontText, SubTitle, Title } from '../../TypographySettings.styles';

const ByGoogleFonts = ({ handleSelectedFont, selectedFont }: ByGoogleFontsComponentProps) => {
	const { t } = useTranslation();
	return (
		<FontFamilySection>
			<SubTitle>{t('website-typography-font-family')}</SubTitle>
			<BrandFonts handleSelectedFont={handleSelectedFont} initialFont={selectedFont} />
			<FontFamilyCard>
				<SelectedFontText className="apply-font-0">{selectedFont}</SelectedFontText>
				<Title>{t('website-typography-by-google-fonts')}</Title>
			</FontFamilyCard>
		</FontFamilySection>
	);
};
export { ByGoogleFonts };
