import styled from 'styled-components';
import { Text as UnstyledText } from 'features/common/components/Text';

export const ToggleContainer = styled.div`
	gap: 15px;
	width: 100%;
	display: flex;
	margin-bottom: 25px;
	align-items: center;
`;

export const Text = styled(UnstyledText)`
	font-size: 20px;
`;
