import styled from 'styled-components';

import { Button as UnstyledButton } from 'features/common/components/Button';
import { Text as UnstyledText } from 'features/common/components/Text';

export const ContentWrapper = styled.div`
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		overflow-y: scroll;
		padding: 24px 20px 0px 20px;
	}
`;

export const Button = styled(UnstyledButton)`
	width: 180px;
	height: 45px;
	display: flex;
	font-size: 16px;
	font-weight: 500;
	line-height: 28px;
	align-items: center;
	justify-content: center;
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const Description = styled(UnstyledText)`
	font-size: 24px;
	font-weight: 600;
	line-height: 37px;
	letter-spacing: 0px;
	margin: 88px 0px 72px 0px;
	max-width: 355px;
	text-align: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
		line-height: 24.5px;
		margin: 24px 23px;
		max-width: 100%;
	}
`;

export const PromoCodeTypeCardsContainer = styled('div')`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 24px;
	justify-content: center;
	margin-bottom: 40px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 16px;
	}
`;
