import { useFormContext } from 'react-hook-form';
import { InputLabel } from './InputLabel';
import { FormInputTextProps } from './types';
import { FlexContainer } from '../FlexContainer';

import { Error, InputContainer, TextArea } from './Form.styles';
import { StdText } from '../Text/Text.styles';

const FormInputText = ({ id, name, label, subtitle, value, isCharCt, ...rest }: FormInputTextProps) => {
	const {
		register,
		watch,
		formState: { errors },
	} = useFormContext();
	const error = errors[name];

	return (
		<InputContainer hasError={Boolean(error)}>
			<InputLabel htmlFor={id ?? name} label={label} subtitle={subtitle} />
			<TextArea id={id ?? name} value={value} {...register(name)} {...rest} />
			{isCharCt && watch(name) && (
				<FlexContainer justifyContent="flex-end">
					<StdText>{`${watch(name).length}`}</StdText>
				</FlexContainer>
			)}
			<Error>{error?.message}</Error>
		</InputContainer>
	);
};

export { FormInputText };
