import { FC, memo } from 'react';
import { Header } from 'features/common/components/Header';
import { ModalDelete } from '../ModalDelete';
import { TicketTitleProps } from './types';

import { Title } from './TicketTitle.styles';
import { useTranslation } from 'react-i18next';

const TicketTitle: FC<TicketTitleProps> = ({
	isNewTicket,
}: TicketTitleProps) => {
	const { t } = useTranslation();

	const ticketTitle = isNewTicket
		? t('ticketNew-title')
		: t('ticketDetails-title');

	return (
		<Header modal={!isNewTicket && <ModalDelete />}>
			<Title element="h2">{ticketTitle}</Title>
		</Header>
	);
};

const memorizedTicketTitle: FC<TicketTitleProps> = memo(TicketTitle);

export { memorizedTicketTitle as TicketTitle };
