import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Page } from '../..';
import { ROUTES } from 'features/routes';
import { confirmationCheck } from 'assets/images';
import { ButtonContainer, SkipButton, NextButton } from '../Steps.styles';
import { EventIdParamsType } from 'utils/types';

import * as Styled from '../TicketCreated/TicketCreated.styles';
import { SuccessAnimation, Img, Text } from './Success.styles';

export const Success = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { eventId }: EventIdParamsType = useParams();

	const pushHomePage = () => {
		push(ROUTES.HOME_PATH);
	};

	const pushEvent = () => {
		push(ROUTES.DASHBOARD_PATH.replace(':id', eventId));
	};

	return (
		<Page id="9">
			<Styled.CenteredContainer>
				<Styled.Container>
					<SuccessAnimation>
						<Img src={confirmationCheck} />
					</SuccessAnimation>
					<Text>{t('event-creation-success')}</Text>
				</Styled.Container>
				<ButtonContainer>
					<SkipButton type="button" onClick={pushHomePage}>
						{t('view-homepage')}
					</SkipButton>
					<NextButton onClick={pushEvent} type="button">
						{t('view-event')}
					</NextButton>
				</ButtonContainer>
			</Styled.CenteredContainer>
		</Page>
	);
};
