import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';
import { RightContainer as RightContainerCommon } from 'styles/index';

export const RightContainer = styled(RightContainerCommon)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding-top: 100px;
		justify-content: flex-start;
	}
`;

export const Container = styled.div`
	gap: 24px;
	width: 60%;
	z-index: 1;
	display: flex;
	position: absolute;
	flex-direction: column;
	justify-content: center;
	background: transparent;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 90%;
	}
`;

export const Title = styled(Text)`
	font-size: 24px;
	font-weight: 400;
	text-transform: none;
	color: ${({ theme }) => theme.colors.white};
`;

export const BodyText = styled(Text)`
	font-size: 16px;
	line-height: 24px;
	font-weight: 300;
	text-transform: none;
	color: ${({ theme }) => theme.colors.white};

	span {
		color: ${({ theme }) => theme.colors.green};
	}
`;

export const DownloadButton = styled(Button)`
	font-size: 16px;
	font-weight: 500;
	margin: 0;
	padding: 14px;
`;
