import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorWithPayload, ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { RootState } from 'store';

import * as FanDatabaseSlice from 'features/FanDatabase/Slices/FanDatabaseSlice';
import * as OrdersSlice from 'store/slices/OrdersPageSlice';
import * as RefundableSlice from 'store/slices/RefundableTicketsSlice';

export enum FilterButtonEnum {
	eventApply = 'eventApply',
	eventCancel = 'eventCancel',
	dateApply = 'dateApply',
	dateCancel = 'dateCancel',
	eventUnapplied = 'eventUnapplied',
}

export enum DateFilterEnumPage {
	allTime = '',
	pastDay = 'past_day',
	pastWeek = 'past_week',
	pastMonth = 'past_month',
	pastYear = 'past_year',
}

interface Page {
	sliceName: 'ordersPage' | 'fanDatabase' | 'refundableTickets';
	dateCancel: ActionCreatorWithPayload<DateFilterEnumPage, string>;
	dateApply: ActionCreatorWithPayload<DateFilterEnumPage, string>;
	eventCancel: ActionCreatorWithoutPayload<string>;
	eventApply: ActionCreatorWithoutPayload<string>;
	eventUnapplied: ActionCreatorWithPayload<number>;
}

export interface AllPages {
	orders: Page;
	fanDatabase: Page;
	refundableTickets: Page;
}

export const allPages: AllPages = {
	orders: {
		sliceName: 'ordersPage',
		dateCancel: OrdersSlice.alterUnappliedDateOrdersPage,
		dateApply: OrdersSlice.alterAppliedDateOrdersPage,
		eventCancel: OrdersSlice.resetUnappliedEventsOrdersPage,
		eventApply: OrdersSlice.alterAppliedEventsOrdersPage,
		eventUnapplied: OrdersSlice.alterUnappliedEventsOrdersPage,
	},
	fanDatabase: {
		sliceName: 'fanDatabase',
		dateCancel: FanDatabaseSlice.alterUnappliedDateFanDatabase,
		dateApply: FanDatabaseSlice.alterAppliedDateFanDatabase,
		eventCancel: FanDatabaseSlice.resetUnappliedEventsFanDatabase,
		eventApply: FanDatabaseSlice.alterAppliedEventsFanDatabase,
		eventUnapplied: FanDatabaseSlice.alterUnappliedEventsFanDatabase,
	},
	refundableTickets: {
		sliceName: 'refundableTickets',
		dateCancel: RefundableSlice.alterUnappliedDateRefundableTickets,
		dateApply: RefundableSlice.alterAppliedDateRefundableTickets,
		eventCancel: RefundableSlice.resetUnappliedEventsRefundableTickets,
		eventApply: RefundableSlice.alterAppliedEventsRefundableTickets,
		eventUnapplied: RefundableSlice.alterUnappliedEventsRefundableTickets,
	},
};

export const useFilterButtonClick = (onClose: () => void, scenario: FilterButtonEnum, page: keyof AllPages) => {
	const dispatch = useDispatch();
	const { applied: appliedDates, unapplied: unappliedDates } = useSelector((state: RootState) => {
		const sliceName = allPages[page].sliceName;
		return state[sliceName].selectedPeriod;
	});

	let setState: () => void = () => {};
	switch (scenario) {
		case FilterButtonEnum.dateApply:
			setState = () => dispatch(allPages[page].dateApply(unappliedDates));
			break;
		case FilterButtonEnum.dateCancel:
			setState = () => dispatch(allPages[page].dateCancel(appliedDates));
			break;
		case FilterButtonEnum.eventApply:
			setState = () => dispatch(allPages[page].eventApply());
			break;
		case FilterButtonEnum.eventCancel:
			setState = () => dispatch(allPages[page].eventCancel());
			break;
	}

	return (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		setState();
		onClose();
	};
};
