import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { post } from 'services/api';
import { useToastNotification } from './useToastNotification';

export const useLinkStripeAccount = () => {
	const { showNotification, TYPES } = useToastNotification();
	const { t } = useTranslation();

	const { mutate } = useMutation({
		mutationFn: () => post('/accounts/create', {}),
		onSuccess: (link) => {
			window.open(link, '_blank');
		},
		onError: () => showNotification({ message: t('payouts-link-failure'), type: TYPES.ERROR }),
	});
	return { linkStripeAccount: mutate };
};
