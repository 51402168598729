import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { RecipientInfo } from '../RecipientInfo';
import { RecipientInfoProps } from '../RecipientInfo/types';
import { calculateTotalPrice } from './utils';
import { toUsFormat } from 'utils/utils';

import * as Styled from './SendTicketsConfirmation.styles';

export const SendTicketsConfirmation = () => {
	const { t } = useTranslation();
	const { watch, setValue } = useFormContext();

	const recipientData = watch('recipients');

	const totalPrice = calculateTotalPrice(recipientData);

	useEffect(() => {
		setValue('totalPrice', totalPrice);
	}, [totalPrice]);

	return (
		<Styled.Root>
			<Styled.ConfirmationCard>
				<Styled.ConfirmationText>Confirmation</Styled.ConfirmationText>
				{recipientData?.map((el: JSX.IntrinsicAttributes & RecipientInfoProps, i: number) => (
					<RecipientInfo key={i} {...el} recipientId={i} />
				))}
				<Styled.Price>
					{t('send-tickets-price-label')}
					<strong>{toUsFormat(totalPrice) || 0}</strong>
				</Styled.Price>
				<Styled.Button text="Send Ticket" type="submit" disabled={recipientData.length === 0} />
			</Styled.ConfirmationCard>
		</Styled.Root>
	);
};
