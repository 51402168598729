import styled from 'styled-components';

export const Image = styled.img`
	width: 50%;
	object-fit: contain;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 150%;
	}
`;
