import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { auth } from 'services/firebase';
import { isSignInWithEmailLink, signInWithEmailLink, updatePassword } from 'firebase/auth';
import { changeToken } from 'services/auth';
import { getData, post } from 'services/api';
import { changeIsAuthenticated, setLoading, setLoggedUser } from 'store/slices/AuthSlice';
import { useAppDispatch } from 'store/hooks';
import { ROUTES } from 'features/routes';
import { useToastNotification } from 'hooks/useToastNotification';
import { AccountSetupErrors } from '../types';
import { WelcomeFormProps } from './types';
import { isScannerRole } from 'utils/utils';

export const useCreateAccount = () => {
	const dispatch = useAppDispatch();
	const { push } = useHistory();
	const initialState = {
		oobCodeError: false,
		linkToFirebaseError: false,
	};
	const [accountSetupErrors, setAccountSetupErrors] = useState<AccountSetupErrors>(initialState);

	const { showNotification, TYPES } = useToastNotification();
	const showError = (error: any) =>
		showNotification({
			message: error.message || 'Error',
			type: TYPES.ERROR,
		});

	const handleDispatch = async ({ password, partnerId }: WelcomeFormProps) => {
		if (!auth.currentUser) {
			return;
		}

		try {
			await updatePassword(auth.currentUser, password);

			const token = await getData('users/set-claims');
			localStorage.setItem('accessToken', token);

			const tokenWithPartner = await changeToken(Number(partnerId));
			if (isScannerRole(tokenWithPartner)) {
				dispatch(setLoading(false));
				push(ROUTES.SCANNER_ACCOUNT);
				return;
			}

			const user = await getData('/users/me');
			const loggedUser = {
				...user.data,
				currentPartner: user.Partners[0],
			};
			dispatch(setLoggedUser(loggedUser));

			dispatch(changeIsAuthenticated());

			dispatch(setLoading(false));
			push(ROUTES.HOME_PATH);
		} catch (error) {
			dispatch(setLoading(false));
			showError(error);
		}
	};

	const onCreateAccount = async ({ password, userId, partnerId }: WelcomeFormProps) => {
		const url = localStorage.getItem('url') || '';
		const continueURL = new URLSearchParams(url).get('continueUrl') || '';
		const searchParams = new URLSearchParams(new URL(continueURL).search);
		const email = searchParams.get('email') || '';
		if (isSignInWithEmailLink(auth, url)) {
			dispatch(setLoading(true));
			try {
				const userCredential = await signInWithEmailLink(auth, email, url);
				const user = userCredential.user;
				localStorage.setItem('accessToken', (user as any).accessToken);
				localStorage.setItem('uid', (user as any).uid);
				localStorage.removeItem('url');

				await post(`/users/set-uid/${userId}`, {
					status: true,
					firebase_id: localStorage.getItem('uid'),
				});

				handleDispatch({ password, userId, partnerId });
			} catch (e: any) {
				dispatch(setLoading(false));
				setAccountSetupErrors((prev) =>
					e.isAxiosError ? { ...prev, linkToFirebaseError: true } : { ...prev, oobCodeError: true }
				);
			}
		}
	};

	return {
		onCreateAccount,
		accountSetupErrors,
	};
};
