import styled from 'styled-components';
import { Text } from 'features/common/components/Text';

export const EventContainer = styled.div`
	box-sizing: border-box;
	display: flex;
	align-items: center;
	width: 442px;
	height: 79px;
	background: ${({ theme }) => theme.colors.grey2};
	border-radius: 6px;
	padding: 0 9px 0 9px;
	gap: 20px;
	overflow: hidden;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 311px;
	}
`;

export const EventDate = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 61px;
	color: ${({ theme }) => theme.colors.white};
	background: ${({ theme }) => theme.colors.lightGrey4};
	box-shadow: ${({ theme }) => theme.shadows.base};
	border-radius: 21px;
	flex: 0 0 61px;
`;

export const WeekDay = styled(Text)`
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: ${({ theme }) => theme.colors.white};
`;

export const Date = styled(Text)`
	text-align: center;
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	color: ${({ theme }) => theme.colors.white};
`;

export const InfoEvent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 1 0 75px;
	overflow: hidden;
`;

export const Title = styled(Text)`
	font-size: 16px;
	line-height: 24px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
`;

export const Time = styled(Text)`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
`;
