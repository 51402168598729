import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';

import { maxPicturesAllowedConst } from './contants';
import { getBase64 } from 'utils/utils';
import { InputWrapperProps } from './types';

import { Container } from '../../ImageInputs.styles';

export const InputWrapper = ({
	children,
	dataCy,
	maxPicturesAllowed = maxPicturesAllowedConst,
	inputId,
	areMultipleImg = true,
}: InputWrapperProps) => {
	const { setValue, getValues } = useFormContext();

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: async (acceptedFiles) => {
			const valuesToAdd = await Promise.all(
				acceptedFiles.map(async (file) => ({
					url: URL.createObjectURL(file),
					base64: await getBase64(file),
					name: file.name,
				}))
			);
			if (areMultipleImg) {
				const valuesToSave = [...getValues(inputId), ...valuesToAdd];
				setValue(inputId, valuesToSave);
			} else {
				setValue(inputId, valuesToAdd);
			}
		},
		accept: {
			'image/jpeg': [],
			'image/png': [],
		},
		maxFiles: maxPicturesAllowed - getValues(inputId).length,
		disabled: getValues(inputId).length >= maxPicturesAllowed && areMultipleImg,
	});

	return (
		<Container {...getRootProps()}>
			<input {...getInputProps()} id={inputId} data-cy={dataCy} />
			{children}
		</Container>
	);
};
