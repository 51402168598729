import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROUTES } from 'features/routes';

import { ContainerAddEvent, Icon, Title, Subtitle } from './CreateEvent.styles';

const AddEvent: FC = () => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const { CREATE_EVENT_PATH } = ROUTES;

	return (
		<ContainerAddEvent type="button" onClick={() => push(CREATE_EVENT_PATH)}>
			<Icon iconName="plus" />
			<Title>{t('createEvent-add-title')}</Title>
			<Subtitle>{t('createEvent-add-subtitle')}</Subtitle>
		</ContainerAddEvent>
	);
};

export { AddEvent };
