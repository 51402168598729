import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Form } from 'features/common/components/Form';
import { FormProps } from './types';
import { InputLabel } from 'features/common/components/Form/InputLabel';
import { DefaultValuesTypes } from 'features/EditEvent/types';
import { CUSTOM_FEE_TYPES, CUSTOM_FEE_TYPES_SYMBOLS } from './constants';

import {
	AmountContainer,
	CheckboxContainer,
	CustomFeeContainer,
	CustomFeeType,
	CustomFeeTypeContainer,
	TaxableContainer,
	TaxableDescription,
	Text,
	ToggleText,
} from './RightForm.styles';

const CustomFeeForm: FC<FormProps> = ({ index }) => {
	const { t } = useTranslation();
	const {
		setValue,
		watch,
		formState: { errors },
	} = useFormContext<DefaultValuesTypes>();

	const customFee = watch('custom_fees')?.[index];

	const onChangeType = (type: string) => setValue(`custom_fees.${index}.type`, type);

	if (!customFee) {
		return null;
	}

	return (
		<CustomFeeContainer>
			<Form.Input
				id={`${index}.name`}
				name={`${index}.name`}
				label={t('editEvent-customFee-name')}
				index={index}
				value={customFee.name}
				formValue="custom_fees"
				errorProp={errors.custom_fees?.[index]?.name?.message}
				onChange={(e) => setValue(`custom_fees.${index}.name`, e.target.value)}
			/>

			<div>
				<InputLabel htmlFor={`${index}.amount`} label={t('editEvent-customFee-amount')} />
				<AmountContainer>
					<Form.Input
						id={`${index}.amount`}
						name={`${index}.amount`}
						index={index}
						value={customFee.amount}
						type="number"
						formValue="custom_fees"
						errorProp={errors.custom_fees?.[index]?.amount?.message}
						onChange={(e) => setValue(`custom_fees.${index}.amount`, Number(e.target.value))}
					/>
					<CustomFeeTypeContainer>
						{Object.values(CUSTOM_FEE_TYPES).map((item) => (
							<CustomFeeType key={item} isSelected={customFee.type === item} onClick={() => onChangeType(item)}>
								<Text>{CUSTOM_FEE_TYPES_SYMBOLS[item]}</Text>
							</CustomFeeType>
						))}
					</CustomFeeTypeContainer>
				</AmountContainer>
			</div>

			<TaxableContainer>
				<ToggleText>{t('editEvent-customFee-taxable')}</ToggleText>
				<TaxableDescription>{t('editEvent-customFee-taxable-description')}</TaxableDescription>

				<CheckboxContainer>
					<Form.RadioButtonList
						id={`custom_fees.${index}.taxable`}
						name={`custom_fees.${index}.taxable`}
						variant="secondary"
						background="transparent"
						gap="0"
						options={[
							{ value: '1', optionLabel: t('editEvent-customFee-taxable-yes') },
							{ value: '0', optionLabel: t('editEvent-customFee-taxable-no') },
						]}
					/>
				</CheckboxContainer>
			</TaxableContainer>
		</CustomFeeContainer>
	);
};

const memorizedDayForm: FC<FormProps> = memo(CustomFeeForm);

export { memorizedDayForm as CustomFeeForm };
