import React from 'react';

import { ExistingPromoCodes } from '../ExistingPromoCodes/ExistingPromoCodes';
import { LeftForm } from '../LeftForm';
import { EventSpecificList } from '../EventSpecificList';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { PromoCodesFormContentProps } from './types';

import { ContentWrapper, LeftContainer, RightContainer } from '../../PromoCodesForm.styles';

const PromoCodesFormContent: React.FC<PromoCodesFormContentProps> = ({ type }: PromoCodesFormContentProps) => {
	const isLargeScreen = useResponsiveScreen();
	return (
		<ContentWrapper type={type}>
			{type === 'existing' && <ExistingPromoCodes />}
			{type !== 'existing' && (
				<LeftContainer>
					<LeftForm type={type} />
				</LeftContainer>
			)}
			{type === 'eventSpecific' && isLargeScreen && (
				<RightContainer>
					<EventSpecificList />
				</RightContainer>
			)}
		</ContentWrapper>
	);
};

export { PromoCodesFormContent };
