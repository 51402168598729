import styled from 'styled-components';

import { Modal } from 'features/common/components/Modal';
import { Text } from 'features/common/components/Text';
import { Icon } from 'features/common/components/Icon';
import { FundsSummaryContainerProps } from './types';

export const CustomBody = styled(Modal.Body)`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 16px;
	padding: 24px 48px;
	max-height: 60vh;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px 32px;
		max-height: 50vh;
	}
`;

export const ModalDescription = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 8px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 8px;
	}
`;

export const Subtitle = styled(Text)`
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin: 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
		line-height: 21px;
	}
`;

export const SubtitleSpan = styled(Text)`
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-transform: none;
`;

export const TicketList = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin: 0;
	padding: 18px 15px;
	text-decoration: none;
	width: 400px;
	overflow-y: scroll;
	border-top: 1px solid ${({ theme }) => theme.colors.lightGrey5};
	border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey5};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

export const SecondaryIcon = styled(Icon)`
	height: 24px;
	width: 24px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 16px;
		width: 16px;
	}
`;

export const Span = styled(Text)`
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-transform: none;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 12px;
		line-height: 18px;
	}
`;

export const FundsSummaryContainer = styled.div<FundsSummaryContainerProps>`
	${({ theme, isRefunded }) => isRefunded && `background-color: ${theme.colors.grey14}`};
	${({ theme, isRefunded }) => !isRefunded && `border: 1px solid ${theme.colors.red}`};
	border-radius: 8px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 18px;
	width: 400px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
		padding: 12px;
	}
`;

export const IconTextFlex = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
