import styled from 'styled-components';

export const Img = styled.img``;

export const SuccessContainer = styled.div`
	background: ${({ theme }) => theme.colors.greenGradient4};
	height: 150px;
	width: 150px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
`;
