import styled from 'styled-components';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import { WrapperOptionProps } from './types';

export const Icon = styled(UnstyledIcon)`
	margin-right: 15px;
`;

export const Label = styled.label`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const InputFile = styled.input`
	cursor: pointer;
	display: none;

	&:hover {
		cursor: pointer;
	}
`;

export const WrapperOptions = styled.div<WrapperOptionProps>`
	position: absolute;
	z-index: 1;
	width: 238px;
	height: 130px;
	left: ${({ inHomePage }) => (inHomePage ? 606 : 0)}px;
	top: ${({ inHomePage }) => (inHomePage ? 220 : 175)}px;
	background: ${({ theme }) => theme.colors.black};
	mix-blend-mode: normal;
	border-radius: 4px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		left: ${({ inHomePage }) => (inHomePage ? 24 : 0)}px;
		top: ${({ inHomePage }) => (inHomePage ? 190 : 90)}px;
	}
`;

export const Option = styled.div`
	cursor: pointer;
	display: flex;
	height: 64px;
	align-items: center;
	padding-left: 27px;
`;
