import { CircleWithIconProps } from './types';

import { CustomIcon, Ellipse } from './styles';

export const CircleWithIcon = ({ color, height, width, iconName, ...rest }: CircleWithIconProps) => {
	return (
		<Ellipse color={color} height={height} width={width} {...rest}>
			<CustomIcon iconName={iconName} />
		</Ellipse>
	);
};
