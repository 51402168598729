import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Button as UnstyledButton } from 'features/common/components/Button';

export const FormContainer = styled.div`
	gap: 24px;
	width: 60%;
	z-index: 1;
	display: flex;
	position: absolute;
	flex-direction: column;
	justify-content: center;
	background: transparent;

	&.success {
		align-items: center;
		gap: 75px;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 90%;
		padding: 50px 0px;
	}
`;

export const Title = styled(Text)`
	font-size: 24px;
	font-weight: 400;
	text-transform: none;
	color: ${({ theme }) => theme.colors.white};
`;

export const Button = styled(UnstyledButton)`
	margin: 0px;
	padding: 10px;
	font-size: 16px;
	font-weight: 500;
	font-family: ${({ theme }) => theme.fonts.primary};
	background: ${({ theme }) => theme.colors.greenGradient};
	box-shadow: ${({ theme }) => theme.shadows.greenGradientShadow};

	&:disabled {
		opacity: 0.5;
	}
`;

export const GradientContainer = styled.div`
	padding: 1px;
	border-radius: 6px;
	background: ${({ theme }) => theme.colors.greenGradient};
	box-shadow: ${({ theme }) => theme.shadows.greenGradientShadow};
`;

export const CreateAccountButton = styled(Button)`
	margin: 0px;
	background: transparent;
	height: 100%;
	width: 100%;
	color: ${({ theme }) => theme.colors.aquamarine};
	background: ${({ theme }) => theme.colors.grey3};
`;

export const Line = styled.div`
	height: 1px;
	width: 100%;
	background: ${({ theme }) => theme.colors.green3};
`;

export const Link = styled(UnstyledButton)`
	padding: 0;
	cursor: pointer;
	font-size: 16px;
	align-self: flex-start;
	text-decoration: underline;
	color: ${({ theme }) => theme.colors.green3};
`;

export const ErrorText = styled(Text)`
	color: ${({ theme }) => theme.colors.red};
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0px;
	text-align: left;
	text-transform: none;
`;

export const SupportLink = styled.a`
	color: ${({ theme }) => theme.colors.red};
	display: block;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0px;
	text-align: left;
	text-transform: none;
`;
