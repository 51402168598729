import styled from 'styled-components';

import { Divider } from 'features/common/components/Divider';
import { Form } from 'features/common/components/Form';
import { Button } from 'features/common/components/Button';

export const ContentWrapper = styled.div`
	width: calc(100vw - 14%);
	margin-bottom: 40px;
	overflow: auto;
`;

export const Container = styled(Form)`
	display: flex;
	align-items: stretch;
	flex-direction: column;
	padding: 64px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 0;
		width: 99%;
	}
`;

export const DividerWithSpacing = styled(Divider)`
	margin: 48px 0;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 32px 0;
	}
`;

export const PublishButton = styled(Button)`
	width: 250px;
	display: flex;
	justify-content: center;
	align-self: center;
	margin: 0;
	font-weight: 600;
	font-size: 18px;
	padding: 12px 24px;
`;
