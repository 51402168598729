import styled from 'styled-components';

import { Icon } from 'features/common/components/Icon';
import { Modal } from 'features/common/components/Modal';
import { Text } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';

import { BodyContainer } from 'features/common/components/Modal/Modal.styles';

export const BodyWrapper = styled(BodyContainer)`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	white-space: normal;
	padding: 75px 25px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px;
	}
`;

export const ResetInfoContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.grey13};
	border-radius: 8px;
	width: fit-content;
	height: fit-content;
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	flex-direction: column;
`;

export const CustomModal = styled(Modal)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: calc(100vw - 48px);
	}
`;

export const ModalMessage = styled(Text)`
	text-transform: none;
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 153%;
	text-align: center;
	max-width: 340px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;

export const UserIcon = styled(Icon)`
	height: 56px;
	width: 56px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 40px;
		width: 40px;
	}
`;

export const MailIcon = styled(Icon)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 20px;
		width: 20px;
	}
`;

export const Email = styled(Text)`
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	word-break: break-word;
	text-transform: lowercase;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;

export const ActionsWrapper = styled(Modal.ActionButtons)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;

	padding: 24px 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column-reverse;
		gap: 16px;
	}
`;

export const ModalButton = styled(Button)`
	border-radius: 8px;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	width: 160px;
	padding: 8px 22px;
	margin: 0;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 85%;
		max-width: 320px;
	}
`;
