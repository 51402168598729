import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OUTLINED } from 'features/common/components/Button/constants';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { Table } from 'features/common/components/Table';
import { OrderProps } from 'features/Orders/types';
import { COLUMN_HEADINGS, COLUMN_MIN_WIDTH } from './constants';
import { TableBody } from './TableBody';

export const OrdersTable: FC<OrderProps> = ({ ordersQueryObject }) => {
	const { t } = useTranslation();
	const { fetchNextPage, hasNextPage } = ordersQueryObject;

	return (
		<>
			<Table.TableContainer>
				<Table>
					<thead>
						<Table.HeaderRow gridColumns="repeat(8, 1fr)" textAlign="center">
							{COLUMN_HEADINGS.map((heading, i) => {
								return (
									<Table.Cell key={heading} minWidth={COLUMN_MIN_WIDTH[i]}>
										<Table.ColumnHeader>{t(heading)}</Table.ColumnHeader>
									</Table.Cell>
								);
							})}
						</Table.HeaderRow>
					</thead>
					<TableBody ordersQueryObject={ordersQueryObject} />
				</Table>
			</Table.TableContainer>
			<FlexContainer justifyContent="center" margin="32px 0">
				<Table.Button
					category={OUTLINED}
					text={t('button-load-more')}
					onClick={() => fetchNextPage()}
					disabled={!hasNextPage}
					type="button"
				/>
			</FlexContainer>
		</>
	);
};
