import styled from 'styled-components';

import { OptionsContainerStylesProps } from './types';

export const OptionsContainer = styled.div<OptionsContainerStylesProps>`
	width: 320px;
	z-index: 1;
	background-color: ${({ theme }) => theme.colors.blackGrey};
	border: 0.5px solid ${({ theme }) => theme.colors.white};
	position: relative;
	top: 24px;
	left: -195px;
	border-radius: 8px;
	display: ${({ isOpened }) => (isOpened ? 'block' : 'none')};
`;
