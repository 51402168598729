import styled from 'styled-components';

import { Text } from 'features/common/components/Text';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Title = styled(Text)`
	width: fit-content;
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 12px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		justify-content: center;
	}
`;

export const SelectedFontText = styled(Text)`
	line-height: 48px;
`;

export const SubTitle = styled(Text)`
	font-size: 16px;
	line-height: 24px;
`;

export const FontFamilyCard = styled.div`
	width: 100%;
	max-width: 250px;
	background-color: ${({ theme }) => theme.colors.blackGrey4};
	border-radius: 8px;
	margin-top: 12px;
	padding: 24px;
	display: flex;
	flex-direction: column;
`;

export const Description = styled(Text)`
	font-weight: 400;
	font-size: 16px;
	width: 220px;
	line-height: 24px;
`;

export const FontFamilySection = styled.div`
	width: fit-content;
`;

export const FontStyleSection = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 20px;
	margin-bottom: 20px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-top: 32px;
		flex-direction: column;
	}
`;

export const SelectContainer = styled.div``;
