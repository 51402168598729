import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { selectFontStyleOptionsWebsetting } from './utils';
import { SelectFontStyle } from './components/SelectFontStyle';
import { ByGoogleFonts } from './components/ByGoogleFonts';
import { WebsiteSectionTitle } from 'features/Embed/WebsiteSectionTitle/WebsiteSectionTitle';

import { Container, FontStyleSection, Wrapper } from './TypographySettings.styles';

const TypographySettings = () => {
	const { setValue, watch } = useFormContext();
	const isDefaultId = 'typoSection.isDefault';
	const isDefaultStyles = watch(isDefaultId);
	const fontFamilyId = 'typoSection.fontFamily';
	const initialFont = watch(fontFamilyId);
	const { t } = useTranslation();

	const handleSelectedFont = (f: string) => {
		setValue(fontFamilyId, f);
	};

	const handleChangeFontStyle = (name: string, value: string) => {
		setValue(`typoSection.fontStyle.${name}`, value);
	};

	return (
		<Container>
			<WebsiteSectionTitle
				title={t('website-typography')}
				setIsDefaultStyles={() => {
					setValue(isDefaultId, !isDefaultStyles);
				}}
				isDefaultStyles={isDefaultStyles}
			/>
			<Wrapper>
				<FontStyleSection>
					{selectFontStyleOptionsWebsetting.map(({ title, text, image }) => (
						<SelectFontStyle title={title} text={text} image={image} key={title} onChange={handleChangeFontStyle} />
					))}
				</FontStyleSection>
				<ByGoogleFonts handleSelectedFont={handleSelectedFont} selectedFont={initialFont} />
			</Wrapper>
		</Container>
	);
};

export { TypographySettings };
