import { useEffect } from 'react';

import { Analytics as AnalyticsComponent } from 'features/Analytics';
import { ROUTES } from 'features/routes';
import { sendGAPage } from 'utils/utils';

const Analytics = (): JSX.Element => {
	useEffect(() => {
		sendGAPage({ page: ROUTES.ANALYTICS_PATH, title: 'Analytics Page' });
	}, []);

	return <AnalyticsComponent />;
};
export default Analytics;
