import styled from 'styled-components';

import { Text } from 'features/common/components/Text';

export const Root = styled.div`
	margin-top: 22px;
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

export const CardsWrapper = styled.div`
	display: flex;
	width: 100%;
	margin-top: 16px;
	overflow: hidden;
	flex-wrap: wrap;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		overflow-x: auto;
		flex-wrap: nowrap;
	}
`;

export const Input = styled.input`
	all: unset;
	width: 100%;
	height: 48px;
	padding: 10px;
	font-size: 20px;
	margin-top: 10px;
	padding-right: 50px;
	border-radius: 4px;
	text-align: left;
	box-sizing: border-box;
	background: ${({ theme }) => theme.colors.grey2};
	font-family: ${({ theme }) => theme.fonts.primary};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		min-width: 90%;
	}
`;

export const Error = styled(Text)`
	display: block;
	margin-top: 5px;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	width: 100%;
	color: ${({ theme }) => theme.colors.redError};
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 10px;
	}
`;

export const Span = styled(Text)`
	max-width: 620px;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: ${({ theme }) => theme.colors.white};
	opacity: 0.5;
`;
