import styled from 'styled-components';
import { Text } from 'features/common/components/Text';

export const CenteredContainer = styled.div`
	gap: 8px;
	width: 60%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 90%;
	}
`;

export const Container = styled.div`
	gap: 10px;
	flex: 1;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;

export const Ticket = styled.img`
	width: 300px;
	height: 500px;
	overflow: hidden;
	object-fit: cover;
	border-radius: 10px;
`;

export const Name = styled(Text)`
	font-size: 28px;
	font-weight: 600;
	line-height: 36px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 22px;
		line-height: 24px;
	}
`;

export const Date = styled(Text)`
	font-size: 22px;
	font-weight: 400;
	line-height: 32px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
		line-height: 16px;
	}
`;
