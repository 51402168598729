import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'react-toastify/dist/ReactToastify.css';

import UseScrollToTop from 'hooks/useScrollToTop';
import { persistor, store } from 'store';
import { StyledThemeProvider } from 'theme';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import './locals/i18n';

export const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<StyledThemeProvider>
				<BrowserRouter>
					<Provider store={store}>
						<PersistGate loading={null} persistor={persistor}>
							<UseScrollToTop />
							<App />
							<ToastContainer className="toast-container" />
						</PersistGate>
					</Provider>
				</BrowserRouter>
			</StyledThemeProvider>
			{/* <ReactQueryDevtools initialIsOpen={true} /> */}
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
