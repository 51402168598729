import { useFormContext } from 'react-hook-form';

import { ControlledElementProps } from '../types';
import { FALLBACK_IMG } from './constants';

export const ElementController = ({ name, htmlClass, htmlId, partnerImage, type }: ControlledElementProps) => {
	const { getValues, watch } = useFormContext();

	const values = getValues();
	const toggleValue = watch(`${name}Enabled`);
	const element = htmlId ? (document.querySelector(`#${htmlId}`) as HTMLElement) : '';
	const nodeList = document.querySelectorAll(`.${htmlClass}`) as NodeListOf<HTMLElement>;

	if (type === 'text' && element) element.textContent = values[name];

	if (type === 'link' && element)
		element.setAttribute('href', `${process.env.REACT_APP_WEBSITE_URL}/event/${values.focus[0]}`);

	if (type === 'image' && element) {
		const imageElement = element.querySelector('img');
		imageElement?.setAttribute('src', values.headerImages[0] ? values.headerImages[0]?.url : FALLBACK_IMG);
	}

	if (name === 'logo' && element) {
		const imageElement = element.querySelector('img');
		imageElement?.setAttribute('src', partnerImage);
	}

	if (type === 'color' && nodeList.length > 0)
		for (let i = 0; i < nodeList.length; i++) nodeList[i].style.background = values.themeColor;

	if (name === 'customerSupportContact' && nodeList.length > 0)
		for (let i = 0; i < nodeList.length; i++) nodeList[i].style.display = toggleValue ? 'revert-layer' : 'none';

	if (toggleValue !== undefined && element) element.style.display = toggleValue ? 'revert-layer' : 'none';

	return null;
};
