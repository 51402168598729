import styled from 'styled-components';

import { Button } from 'features/common/components/Button';
import { Text } from 'features/common/components/Text';
import { CustomTextProps, PaymentInfoProps } from './types';

export const InfoContainer = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.lightGrey5};
	border-radius: 8px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 32px;
	min-width: 425px;
	padding: 56px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		min-width: unset;
		width: 80%;
	}
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		border: none;
		gap: 16px;
		padding: 0;
		width: 100%;
	}
`;

export const HeaderBar = styled.div`
	background-color: ${({ theme }) => theme.colors.darkGrey5};
	border-radius: 8px;
	padding: 16px;
`;
export const SummaryTitle = styled(Text)`
	font-size: 20px;
	font-weight: 600;
`;

export const PaymentInfo = styled.div<PaymentInfoProps>`
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 8px;
	display: flex;
	align-items: ${({ isMultipleCards }) => (isMultipleCards ? 'flex-start' : 'center')};
	${({ isMultipleCards }) => isMultipleCards && 'flex-direction: column;'};
	justify-content: space-between;
	padding: 14px 24px;
`;

export const RefundButton = styled(Button)`
	border-radius: 8px;
	margin: 0;
	padding: 15px;
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
`;

export const CustomText = styled(Text)<CustomTextProps>`
	color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.white)};
	font-size: 16px;
	font-weight: ${({ isBold }) => (isBold ? '600' : '400')};
	line-height: 20px;
`;

export const CardNumberFlex = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
	align-self: flex-end;
`;

export const HiddenText = styled(Text)`
	color: ${({ theme }) => theme.colors.lightGrey12};
	font-weight: 600;
	letter-spacing: 4px;
	margin-left: 8px;
	position: relative;
	top: -6px;
`;

export const SmallBoldText = styled(Text)`
	color: ${({ theme }) => theme.colors.black3};
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
`;
