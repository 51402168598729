import { useTranslation } from 'react-i18next';

import { Icon } from 'features/common/components/Icon';
import { ToolTip } from 'features/common/components/InfoTooltip';
import { useResponsiveScreen } from 'hooks/useWindowSize';

export const HelpIcon = () => {
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();

	return (
		<ToolTip className={isLargeScreen ? 'width-350' : ''} text={t('settings-helpIcon-termsInfo')}>
			<Icon iconName="help" />
		</ToolTip>
	);
};
