import styled from 'styled-components';

import { TicketTagContainerProps } from './types';

export const Wrapper = styled.div`
	width: 490px;
	padding-top: 16px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xxs}) {
		width: 342px;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px 24px;
	background: ${({ theme }) => theme.colors.darkGrey4};
	border-radius: 8px;
	margin-bottom: 8px;
	&.isOpen {
		background: ${({ theme }) => theme.colors.darkGrey5};
	}
`;

export const Title = styled.div`
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	color: ${({ theme }) => theme.colors.white};
	margin-bottom: 16px;
`;

export const TicketTagContainer = styled.div<TicketTagContainerProps>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: ${({ theme, isLastElement }) =>
		isLastElement ? `1px solid ${theme.colors.darkGrey4}` : 'none'};
	background: ${({ fromEventList, theme }) =>
		fromEventList ? theme.colors.darkGrey4 : 'none'};
	padding: ${({ fromEventList }) => (fromEventList ? '0px 12px' : 'unset')};
	margin-bottom: ${({ fromEventList }) => (fromEventList ? '8px' : '0px')};
	border-radius: 8px;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Input = styled.input`
	all: unset;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	width: 48px;
	height: 34px;
	background: ${({ theme }) => theme.colors.black};
	border-radius: 2px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	opacity: 0.7;
	text-align: center;
	::-webkit-inner-spin-button,
	::-webkit-outer-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
`;

export const Text = styled.div`
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: ${({ theme }) => theme.colors.white};
	&.span {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
	}
`;
