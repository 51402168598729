import { FlexContainer } from 'features/common/components/FlexContainer';
import { OrderSummary } from '../OrderSummary';
import { Tickets } from '../Tickets';
import { useMobileView } from 'hooks/useMobileView';
import { Input } from '../Input';

import { Flex } from './SelectTicketTypesBody.styles';

export const SelectTicketTypesBody = () => {
	const isMobile = useMobileView();

	return (
		<Flex>
			<FlexContainer flexDirection="column" width={isMobile ? '100%' : '50%'} gap="32px">
				<Input id="email" name="Email" />
				<Tickets />
			</FlexContainer>
			<OrderSummary />
		</Flex>
	);
};
