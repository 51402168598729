import styled from 'styled-components';

import { StyledMainLayoutProps } from './types';

export const Wrapper = styled.div`
	margin: 0;
	min-height: 100vh;
	min-width: 100%;
`;

export const MainPanel = styled.main<StyledMainLayoutProps>`
	position: relative;
	overflow: ${({ containsStickyEl }) => (containsStickyEl ? 'revert' : 'hidden')};
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	min-width: 100%;
	background-color: ${({ theme, color }) => color || theme.colors.blackGrey};
	color: ${({ theme }) => theme.colors.white};
	right: 0;
	left: 0;
	${({ isFullVh }) => isFullVh && 'height:100vh;'}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		position: absolute;
		top: 125px;
		min-height: calc(100% - 125px);
	}
`;
