import { useSelector } from 'react-redux';

import { RootState } from 'store';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import NotAuthenticatedRoutes from './NotAuthenticatedRoutes';
import { Loader } from 'features/common/animations/Loader';
import useLoading from 'hooks/useLoading';
import { useSetApp } from 'hooks/useSetApp';
import { AppDomainContext } from 'contexts/appDomain';

export const Routes = (): JSX.Element => {
	const { isAuthenticated } = useSelector((state: RootState) => state.auth);
	const isLoading = useLoading();
	const { appDomain } = useSetApp();

	if (appDomain === null) return <Loader />;

	return (
		<AppDomainContext.Provider value={appDomain}>
			{!!isLoading && <Loader />}
			{isAuthenticated ? <AuthenticatedRoutes /> : <NotAuthenticatedRoutes />}
		</AppDomainContext.Provider>
	);
};
