import { FC } from 'react';
import { ToggleProps } from './types';

import * as Styled from './ToggleSwitch.styles';

const ToggleSwitch: FC<ToggleProps> = ({
	name,
	handleChange,
	options,
	value,
	isFromRequiredFields,
	...rest
}: ToggleProps) => {
	return (
		<Styled.StyledLabel htmlFor={name} {...rest}>
			<Styled.Input
				aria-label={name}
				id={name}
				name={name}
				type="checkbox"
				className={isFromRequiredFields ? 'requiredFields' : ''}
			/>
			<Styled.Background>
				<>
					{options.map((option) => (
						<div key={option}>
							{option === value ? (
								<Styled.Circle>{option}</Styled.Circle>
							) : (
								<Styled.UncheckedIconContainer id={option} onClick={handleChange}>
									{option}
								</Styled.UncheckedIconContainer>
							)}
						</div>
					))}
				</>
			</Styled.Background>
		</Styled.StyledLabel>
	);
};

export { ToggleSwitch };
