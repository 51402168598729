import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getData } from 'services/api';
import { customFieldNames, IdParamsType } from 'utils/types';
import { getCustomFieldName } from 'utils/utils';
import { CustomField } from 'features/CustomFields/types';

export const useGetCustomFields = (transform = false) => {
	const { id: eventId } = useParams<IdParamsType>();

	const { data } = useQuery({
		queryKey: ['custom-fields', eventId],
		queryFn: (): Promise<CustomField[]> => getData(`/public/ticketing/events/${eventId}/custom-fields`),
		enabled: !!eventId,
		select: (data) => {
			if (!transform) {
				return data;
			}

			return data.map((cf) => ({
				...cf,
				name: getCustomFieldName(cf.name),
				name_type: Object.values(customFieldNames).includes(cf.name) ? cf.name : 'custom',
			}));
		},
	});

	return { customFields: data };
};
