import { ToastPosition } from 'react-toastify';

export const options = {
	position: 'bottom-left' as ToastPosition,
	autoClose: 3000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'colored' as const,
};

export const TYPES = Object.freeze({
	SUCCESS: 'SUCCESS' as const,
	ERROR: 'ERROR' as const,
	INFO: 'INFO' as const,
});
