import { convertUTCToDateBarFormat } from 'utils/utils';
import { BROWSER_TZ } from './constants';
import { getData } from 'services/api';

export const formatDate = (orderDate: string) => {
	const { date, time } = convertUTCToDateBarFormat(orderDate, BROWSER_TZ);
	return `${date} | ${time}`;
};

// Column width at index `responsesIdx` will be used for custom fields responses
export const getCellIndex = (index: number, headings: string[], responsesIdx: number, customHeadings: string[]) => {
	// If the table has no responses or index is less than `responsesIdx`, return same index
	if (headings.length === customHeadings.length || index <= responsesIdx) return index;
	// Last three columns are amount, date and ticket type
	if (index >= headings.length - 3) return index - (headings.length - customHeadings.length - 1);
	// For responses use always index `responsesIdx`
	return responsesIdx;
};

export const downloadTicketQR = async (id: number, email: string) => {
	const response = await getData(`/tickets/${id}/qr`, {
		headers: { 'Content-Type': 'image/png' },
		responseType: 'blob',
	});

	const url = window.URL.createObjectURL(response);
	const a = document.createElement('a');
	a.href = url;
	a.download = (email || 'ticket') + '.png';
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
};
