import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { googleSignin } from 'services/auth';
import { useAppDispatch } from 'store/hooks';
import { ROUTES } from 'features/routes';
import { setIsNewGoogleAccount } from 'store/slices/GoogleSlice';

import { ButtonContainer, Icon } from './GoogleButton.styles';

export const GoogleButton = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const dispatch = useAppDispatch();

	const myCallback = (error: any, results: any) => {
		if (results) {
			push(ROUTES.HOME_PATH);
		} else if (error) {
			dispatch(setIsNewGoogleAccount());
			push(ROUTES.CREATE_ACCOUNT_PATH);
		}
	};

	return (
		<ButtonContainer onClick={() => dispatch(googleSignin(myCallback, push))} type="button">
			<Icon iconName="google" />
			{t('login-google')}
		</ButtonContainer>
	);
};
