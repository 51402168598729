import api, { getData } from 'services/api';
import { TaxInfoDataProps } from './types';

const taxInfoUrl = '/partners/tax-information';

export const updateTaxInfo = async (body: TaxInfoDataProps) => {
	await api.put(taxInfoUrl, body);
};

export const getTaxInfoData = async () => {
	return await getData(taxInfoUrl);
};
