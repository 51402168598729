import { useState } from 'react';

import { TaxInfo } from './TaxInfo';
import { Payouts } from './Payouts';
import { NavigationTabs } from './NavigationTabs';
import { MyFinancesProps } from './types';
import { myFinancesNavTabs } from './utils';
import { common } from 'theme';

const MyFinances = ({ showTab, orgData, userData, membersData, hasMultiplePartners }: MyFinancesProps) => {
	const [selectedTab, setSelectedTab] = useState<string>(showTab ?? 'payouts');

	return (
		<>
			<NavigationTabs
				activeId={selectedTab}
				onClick={(id) => setSelectedTab(id)}
				tabs={myFinancesNavTabs}
				hideDivider
				selectedBarColor={common.colors.green}
			/>
			{selectedTab === 'payouts' ? (
				<Payouts
					orgData={orgData}
					userData={userData}
					membersData={membersData}
					hasMultiplePartners={hasMultiplePartners}
				/>
			) : (
				<TaxInfo
					orgData={orgData}
					userData={userData}
					membersData={membersData}
					hasMultiplePartners={hasMultiplePartners}
				/>
			)}
		</>
	);
};

export { MyFinances };
