import { FC } from 'react';

import { DateOption } from '../../../DateOption';
import { DateContainerProps } from '../../types';
import { lastIndicator } from 'features/common/components/Filters/utils';
import { DateFilterEnumPage } from 'features/common/hooks/useFilterButtonClick';

import { OptionsContainer } from '../../MobileFilter.styles';

export const DatesContainer: FC<DateContainerProps> = ({ className, selectedPeriod, filterOption, page }) => (
	<OptionsContainer className={className}>
		{filterOption?.map((option, i, array) => (
			<DateOption
				page={page}
				key={option}
				selectedPeriod={selectedPeriod}
				option={option}
				className={lastIndicator<DateFilterEnumPage>(i, array)}
			/>
		))}
	</OptionsContainer>
);
