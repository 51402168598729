import styled from 'styled-components';

import { Button } from 'features/common/components/Button';

export { Row, SectionTitleContainer, Title } from 'features/common/components/SectionTitle/SectionTitle.styles';

export const SButton = styled(Button)`
	width: fit-content;
	margin: 0;
	margin-bottom: 11px;
	padding: 8px 41px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 5px 11px;
		margin-bottom: 0px;
	}
`;

export const ButtonContainer = styled.div`
	gap: 16px;
	display: flex;
`;
