import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { handleLink } from 'utils/handleLink';
import { DropzoneInput } from 'features/common/components/ImageInputs';
import { Page } from '../..';
import { CenteredContainer, StepTitle } from '../Steps.styles';

export const TicketImageUpload = () => {
	const { t } = useTranslation();
	const { hash } = useLocation();
	const { watch, setValue } = useFormContext();

	const images = watch('images');

	useEffect(() => {
		if (images.length > 0 && hash === '#6') {
			handleLink('#7');
			setValue('displayType', 'image');
		}
	}, [images.length]);

	return (
		<Page id="6">
			<CenteredContainer>
				<StepTitle element="h3">{t('ticketDetails-ticket-addImage')}</StepTitle>
				<DropzoneInput maxPicturesAllowed={1} inputId="images" />
			</CenteredContainer>
		</Page>
	);
};
