import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';

import { Form } from 'features/common/components/Form';
import { taxInfoInputs } from '../utils';
import { TaxInfoProps } from '../types';

import * as Styled from '../TaxInfo.styles';

const TaxInfoFields = ({ isFromRequiredFields }: TaxInfoProps) => {
	const { setValue, watch } = useFormContext();
	const handleChange = (property: string, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
		setValue(property, e.target.value);

	const handleChangeCheckbox = (property: string, e: ChangeEvent<HTMLInputElement>) =>
		setValue(property, e.target.checked);

	return (
		<Styled.FormContainer>
			<Styled.StyledForm>
				{taxInfoInputs.map(({ name, label, type }) => {
					const value = watch(name);
					switch (type) {
						case 'check':
							return (
								<Styled.CheckboxContainer key={name}>
									<Styled.FormInputTitle>{label}</Styled.FormInputTitle>
									<Styled.CheckboxStyled
										type="checkbox"
										id={name}
										value={value}
										onChange={(e) => handleChangeCheckbox(name, e)}
									/>
								</Styled.CheckboxContainer>
							);
						case 'ssnOrEin': {
							const checkboxValue = watch('id_type');
							const handleChangeIdType = (property: string, e: ChangeEvent<HTMLInputElement>) =>
								setValue(property, e.target.id);
							return (
								<Styled.SsnOrEinContainer key={name}>
									<Form.Input
										id={name}
										name={name}
										label={label}
										value={value}
										type="number"
										onChange={(e) => handleChange(name, e)}
										isFromRequiredFields={isFromRequiredFields && !value}
									/>
									<Styled.ToggleSwitchStyled
										name="id_type"
										options={['SSN', 'EIN']}
										value={checkboxValue}
										handleChange={(e) => handleChangeIdType('id_type', e)}
										isFromRequiredFields={isFromRequiredFields && !value}
									/>
								</Styled.SsnOrEinContainer>
							);
						}
						default:
							return (
								<Styled.FormInputContainer key={name}>
									<Form.Input
										id={name}
										name={name}
										label={label}
										key={name}
										value={value}
										onChange={(e) => handleChange(name, e)}
										isFromRequiredFields={isFromRequiredFields && !value}
									/>
								</Styled.FormInputContainer>
							);
					}
				})}
			</Styled.StyledForm>
		</Styled.FormContainer>
	);
};

export default TaxInfoFields;
