import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';

import { Modal } from 'features/common/components/Modal/Modal';
import { Icon as Trash } from 'features/common/components/Icon';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import {
	QUATERNARY,
	TERTIARY,
} from 'features/common/components/Button/constants';
import { deleteEvent } from 'features/EditEvent/services';
import { ROUTES } from 'features/routes';
import { IdParamsType } from 'utils/types';

import {
	ContainerActionButtons,
	ContainerBody,
	ModalButton,
	Icon,
	Text,
	Button,
	DeleteButton,
} from './ModalDelete.styles';

const ModalDelete: FC = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { HOME_PATH } = ROUTES;
	const { id }: IdParamsType = useParams();
	const isLargeScreen = useResponsiveScreen();
	const [showModal, setShowModal] = useState(false);

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	const { mutate: mutateDeleteEvent } = useMutation(deleteEvent, {
		onSuccess: () => push(HOME_PATH),
	});

	return showModal ? (
		<Modal onClose={closeModal}>
			<>
				<Modal.Header>{t('editEvent-modal-delete-title')}</Modal.Header>
				<Modal.Body>
					<ContainerBody>
						<Icon iconName="trashGrey" />
						<Text element="p">{t('editEvent-modal-delete-message')}</Text>
					</ContainerBody>
				</Modal.Body>
				<Modal.ActionButtons>
					<ContainerActionButtons>
						<ModalButton
							category={QUATERNARY}
							onClick={closeModal}
							text={t('button-cancel')}
							type="button"
						/>
						<ModalButton
							category={TERTIARY}
							onClick={() => mutateDeleteEvent({ id })}
							text={t('button-delete')}
							type="button"
						/>
					</ContainerActionButtons>
				</Modal.ActionButtons>
			</>
		</Modal>
	) : isLargeScreen ? (
		<Button
			type="button"
			category={TERTIARY}
			onClick={openModal}
			text={t('editEvent-modal-delete-button')}
		/>
	) : (
		<DeleteButton type="button" onClick={openModal}>
			<Trash iconName="trash" />
		</DeleteButton>
	);
};

export { ModalDelete };
