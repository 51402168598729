import { useEffect } from 'react';

import { FanDatabase } from 'features/FanDatabase';
import { ROUTES } from 'features/routes';
import { sendGAPage } from 'utils/utils';

const FanDatabasePage = (): JSX.Element => {
	useEffect(() => {
		sendGAPage({ page: ROUTES.FAN_DATABASE_PATH, title: 'Fan Page' });
	}, []);

	return <FanDatabase />;
};
export default FanDatabasePage;
