import styled from 'styled-components';

export const RecipientInfo = styled.div`
	width: 100%;
`;

export const Info = styled.p`
	display: flex;
	justify-content: space-between;
`;
