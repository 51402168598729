import { useMutation, useQuery } from '@tanstack/react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { createPromoCode, deletePromoCode, updatePromoCode } from 'features/PromoCodesForm/services';
import { getData } from 'services/api';
import { IdParamsType } from 'utils/types';
import { ROUTES } from 'features/routes';
import { useToastNotification } from './useToastNotification';
import { sendGAEvent } from 'utils/utils';


export const useEditPromoCodeApi = () => {
	const { id }: IdParamsType = useParams();
	const { pathname } = useLocation();
	const { push } = useHistory();
	const { showNotification, TYPES } = useToastNotification();
	const { t } = useTranslation();
	const isEdit = pathname.includes('edit');

	const { data: promocode } = useQuery({
		queryKey: ['promocode', id],
		queryFn: () => getData(`/public/ticketing/promocodes/${id}`),
		enabled: isEdit,
	});

	const { mutate: mutateCreatePromoCode } = useMutation(createPromoCode, {
		onSuccess: () => {
			sendGAEvent({ category: 'Journeys', action: 'Create promo code' });
			push(ROUTES.PROMO_CODES_PATH);
		},
		onError: () =>
			showNotification({
				message: t('error-creating-promocode'),
				type: TYPES.ERROR,
			}),
	});

	const { mutate: mutateEditPromoCode } = useMutation(updatePromoCode, {
		onSuccess: () => {
			sendGAEvent({ category: 'Journeys', action: 'Edit promo code' });
			push(ROUTES.PROMO_CODES_PATH);
		},
		onError: () =>
			showNotification({
				message: t('error-editing-promocode'),
				type: TYPES.ERROR,
			}),
	});

	const { mutate: mutateDeletePromoCode } = useMutation(deletePromoCode, {
		onSuccess: () => {
			push(ROUTES.PROMO_CODES_PATH);
		},
	});

	return {
		promocode,
		mutateCreatePromoCode,
		mutateDeletePromoCode,
		mutateEditPromoCode,
	};
};
