import { selectedType } from 'features/PromoCodesForm/types';
import { HandleQuantityProps } from './types';

export const handleQuantity = ({
	selected,
	setValue,
	quantity,
	unlimited,
	id,
}: HandleQuantityProps) => {
	if (unlimited || quantity != '') {
		const found = selected.some((item: selectedType) => item.id === id);
		if (found) {
			const newValues = selected.map((item: selectedType) => {
				if (item.id === id) {
					return {
						id,
						quantity: quantity === '' || unlimited ? -1 : Number(quantity),
					};
				} else return item;
			});
			setValue('selected', newValues);
		} else
			setValue('selected', [
				...selected,
				{
					id,
					quantity: quantity === '' || unlimited ? -1 : Number(quantity),
				},
			]);
	} else {
		const newValues = selected.filter((item: selectedType) => id != item.id);
		setValue('selected', newValues);
	}
};
