import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { common } from 'theme';
import { fileGrayBackground } from 'assets/images';
import { Header } from 'features/common/components/Header';
import { MainLayout } from 'layouts/MainLayout';
import { CircleWithIcon } from 'features/common/components/CircleWithIcon';
import { Form } from 'features/common/components/Form';
import { OUTLINED } from 'features/common/components/Button/constants';
import { defaultValues, resolver } from './utils';
import { FormData } from './types';
import { DeleteModal } from './DeleteModal';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { useTermsData } from './hooks/useTermsData';

import { StdText } from 'features/common/components/Text/Text.styles';
import { CustomIcon, FormColumn, FormButton, StickyDiv, ContentWrap } from './TermsOfService.styles';

export const TermsOfService = () => {
	const { t } = useTranslation();
	const { goBack } = useHistory();

	const { createTerm, editTerm, editableTerm } = useTermsData();
	const formMethods = useForm<FormData>({
		defaultValues,
		resolver,
		values: { terms: editableTerm?.text || defaultValues.terms },
	});

	const onSubmit = (data: FormData) => {
		if (editableTerm) editTerm.mutate(data.terms);
		else createTerm.mutate(data.terms);
	};

	return (
		<MainLayout color={common.colors.grey4} containsStickyEl isFullVh>
			<FlexContainer flexDirection="column" width="100%" height="100%">
				<Header.TitledHeader
					CustomIcon={() => <CustomIcon src={fileGrayBackground} />}
					modal={<DeleteModal />}
					title={t('Settings')}
					subtitle={t('termsOfService-subtitle')}
				/>
				<FormColumn onSubmit={onSubmit} formMethods={formMethods}>
					<ContentWrap>
						<CircleWithIcon height="90px" width="90px" iconName="fileWhite" />
						<StdText>{t('termOfService-description')} </StdText>
						<Form.InputText
							id="terms"
							name="terms"
							label={t('termsOfService-label')}
							placeholder={t('termsOfService-placeholder')}
							isCharCt
						/>
					</ContentWrap>
					<StickyDiv>
						<FormButton type="button" onClick={goBack} category={OUTLINED} text={t('button-cancel')} />
						<FormButton text={t('button-save')} type="submit" />
					</StickyDiv>
				</FormColumn>
			</FlexContainer>
		</MainLayout>
	);
};
