import styled from 'styled-components';

export const Label = styled.label`
	display: block;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 8px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
		line-height: 20px;
	}
`;

export const Span = styled.span`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
		line-height: 20px;
	}
`;
