import { createSlice } from '@reduxjs/toolkit';

import { MultiDayProps } from './types';

const initialLastMultiDay: MultiDayProps = {
	multiday: undefined,
};

const lastMultiDay = createSlice({
	name: 'lastMultiDay',
	initialState: initialLastMultiDay,
	reducers: {
		setLastMultiDay(state, action) {
			state.multiday = action.payload;
		},
	},
});

export const { setLastMultiDay } = lastMultiDay.actions;

export default lastMultiDay.reducer;
