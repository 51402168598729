import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { gateOptions } from 'features/CreateEvent/components/CreateEventForm/EventSummary/constants';
import { Form } from 'features/common/components/Form';
import { AddButton } from './AddButton';
import { DeleteButton } from './DeleteButton';
import { CustomFeeForm } from './CustomFeeForm';
import { defaultValuesCustomFee } from './constants';
import { CustomFee } from 'features/EditEvent/types';
import { EventSchedule } from 'features/common/components/EventSchedule';

import { Container, Title } from './RightForm.styles';
import { DayContainer } from 'features/common/components/EventSchedule/EventSchedule.styles';

const RightForm: FC = () => {
	const { t } = useTranslation();
	const { setValue, getValues, watch } = useFormContext();

	const customFees = watch('custom_fees');

	const onClickAddCustomFee = () => {
		const newCollection = [...getValues('custom_fees'), defaultValuesCustomFee];
		setValue('custom_fees', newCollection);
	};

	const onClickDeleteCustomFee = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
		event?.preventDefault();
		const cleanCollection = getValues('custom_fees').filter((_: CustomFee, i: number) => i !== index);
		setValue('custom_fees', cleanCollection);
	};

	return (
		<Container>
			<div>
				<Title>{t('editEvent-event-customFees')}</Title>
				{customFees.map((_: CustomFee, index: number) => (
					<DayContainer key={index}>
						<DeleteButton onClick={(event) => onClickDeleteCustomFee(event, index)} />
						<CustomFeeForm index={index} />
					</DayContainer>
				))}
				<AddButton text={t('editEvent-add-customFee')} onClick={onClickAddCustomFee} />
			</div>
			<Form.Select
				id="gate_time"
				name="gate_time"
				label={t('event-gateTime')}
				options={gateOptions}
				withDefaultValue={false}
			/>
			<EventSchedule />
		</Container>
	);
};

export { RightForm };
