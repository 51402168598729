import { ADMIN_QUERY } from 'features/Settings/constants';
import { TFunction } from 'react-i18next';
import { object, string } from 'yup';

import { MemberData } from 'features/Settings/types';
import { DefaultValuesTypes } from './types';

export const getDefaultValues = (
	isAddMember: boolean,
	memberData: MemberData
): DefaultValuesTypes =>
	isAddMember
		? {
				email: '',
				full_name: '',
				roles: ADMIN_QUERY,
		  }
		: {
				email: memberData.email,
				full_name: memberData.full_name,
				roles: memberData.roles,
		  };

export const validationSchema = (t: TFunction<'translation', undefined>) =>
	object().shape({
		email: string()
			.required(t('settings-memberModal-requiredEmail'))
			.email(t('settings-memberModal-invalidEmail')),
		full_name: string().required(t('settings-memberModal-requiredName')),
	});
