import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { useResponsiveScreen } from 'hooks/useWindowSize';
import { Page } from '../..';
import { Template1, Template2 } from 'features/common/components/Templates';

import { ButtonContainer, StepTitle, SubmitButton } from '../Steps.styles';

import * as Styled from './SelectTemplate.styles';

export const SelectTemplate = () => {
	const { t } = useTranslation();
	const { setValue } = useFormContext();
	const { watch } = useFormContext();
	const isLargeScreen = useResponsiveScreen();

	const setTemplate = (selectedTemplate: number) => {
		setValue('displayType', `template${selectedTemplate}`);
	};

	return (
		<Page id="5">
			<Styled.CarouselContainer>
				<Styled.CenteredContainer>
					<StepTitle element="h3">{t('ticketDetails-ticket-selectTemplate')}</StepTitle>
					<Styled.Carousel
						swipeable
						centerMode
						emulateTouch
						showThumbs={false}
						showArrows={false}
						showStatus={false}
						showIndicators={false}
						centerSlidePercentage={isLargeScreen ? 51 : 90}
						onChange={(template) => setTemplate(template)}
						onClickItem={(template) => setTemplate(template)}
					>
						<Template1 image={watch('images')[0]?.url} name={watch('name')} onClick={() => setTemplate(0)} />
						<Template2 image={watch('images')[0]?.url} name={watch('name')} onClick={() => setTemplate(1)} />
					</Styled.Carousel>
				</Styled.CenteredContainer>
				<ButtonContainer>
					<SubmitButton type="submit" text={t('ticketDetails-ticket-selectTemplate-button')} />
				</ButtonContainer>
			</Styled.CarouselContainer>
		</Page>
	);
};
