import { useEffect } from 'react';

import { CreateEvent as CreateEventComponent } from 'features/CreateEvent';
import { ROUTES } from 'features/routes';
import { sendGAPage } from 'utils/utils';

const CreateEvent = (): JSX.Element => {
	useEffect(() => {
		sendGAPage({ page: ROUTES.CREATE_EVENT_PATH, title: 'Create Event Page' });
	}, []);

	return <CreateEventComponent />;
};
export default CreateEvent;
