import api from 'services/api';
import { AppDispatch, store } from 'store';
import { setPartners } from 'store/slices/AuthSlice';

export const updatePartnerName = (name: string) => async (dispatch: AppDispatch) => {
	try {
		const _partner = {
			name,
		};
		const putPartnerResponse = await api.put('/partners/me', _partner);
		if (putPartnerResponse && putPartnerResponse.data) {
			const {
				auth: { loggedUser },
			} = store.getState();
			const partners = loggedUser?.Partners;
			const _partners = [];
			if (partners) {
				for (let i = 0; i < partners.length; i++) {
					const partner = partners[i];
					const _partner = { ...partner };
					if (partner.ID === putPartnerResponse.data.id) {
						_partner.name = putPartnerResponse.data.name;
					}
					_partners.push(_partner);
				}
			}

			dispatch(setPartners(_partners));
		}
	} catch (error) {
		throw new Error(`Error ${error}`);
	}
};
