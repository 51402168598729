import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { ROUTES } from 'features/routes';
import { IdParamsType } from 'utils/types';
import { getCustomFieldName } from 'utils/utils';
import { CustomField as CustomFieldType } from 'features/CustomFields/types';

import { CardDescription, CardTitle, CustomFieldCard } from './CustomField.styles';

export const CustomField = ({ name, only_buyer, is_required, ticket_types, ID }: CustomFieldType) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { id: eventId } = useParams<IdParamsType>();
	const onClick = (id: number) => push(generatePath(ROUTES.DASHBOARD_EDIT_CUSTOM_FIELDS, { id, eventId }));

	return (
		<CustomFieldCard onClick={() => onClick(ID)}>
			<CardTitle>{getCustomFieldName(name)}</CardTitle>
			<CardDescription>{only_buyer ? t('customFields-buyerOnly') : t('customFields-eachAttendee')}</CardDescription>
			<CardDescription>{ticket_types?.map(({ name }) => name).join(', ')}</CardDescription>
			<CardDescription>{is_required ? t('customFields-required') : t('customFields-notRequired')}</CardDescription>
		</CustomFieldCard>
	);
};
