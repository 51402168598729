import styled from 'styled-components';
import { TabStylesProps } from './types';

export const Container = styled.nav`
	display: flex;
	width: 100%;
	padding-top: 32px;
`;

export const TabContainer = styled.div`
	margin-right: 30px;

	&:hover {
		button {
			color: ${({ theme }) => theme.colors.white};
		}
	}
`;

export const Tab = styled.button<TabStylesProps>`
	border: 0;
	border-bottom: ${({ active, onlyTab }) =>
		active && !onlyTab ? '2px solid' : 'none'};
	border-color: ${({ theme, active, selectedBarColor }) =>
		active
			? selectedBarColor
				? selectedBarColor
				: theme.colors.white
			: theme.colors.lightGrey2};
	background: transparent;
	cursor: pointer;
	outline: none;
	padding: 0;
	padding-bottom: 8px;

	span {
		color: ${({ theme, active }) =>
			active ? theme.colors.white : theme.colors.lightGrey2};

		@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
		}
	}

	&:focus {
		span {
			color: ${({ theme }) => theme.colors.white};
		}
	}
`;

export const Line = styled.hr`
	border: 0.5px solid;
	border-color: ${({ theme }) => theme.colors.lightGrey2};
	margin-top: -1px;
	width: 100%;
`;
