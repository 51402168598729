import { MainHeaderProps } from './types';

import { Title, TitleContainer } from './MainHeader.styles';

export const MainHeader = ({ lessPadding, gap, children }: MainHeaderProps) => (
	<TitleContainer lessPadding={lessPadding} gap={gap}>
		{children}
	</TitleContainer>
);

MainHeader.Title = Title;
