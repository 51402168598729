import { FlexContainer } from 'features/common/components/FlexContainer';
import { useGetLastEvent } from '../hooks/useGetLastEvent';
import { MultidayTickets } from './MultidayTickets';
import { SingleDayTickets } from './SingleDayTickets';

export const Tickets = () => {
	const { isMultiDay } = useGetLastEvent();

	return (
		<FlexContainer flexDirection="column" gap="32px" width="100%">
			{isMultiDay ? <MultidayTickets /> : <SingleDayTickets />}
		</FlexContainer>
	);
};
