import { useTranslation } from 'react-i18next';

import { NetSales } from 'features/Dashboard/components/NetSales';

import * as Styled from '../AnalyticByEvent.styles';

const NetSalesSection: React.FC = () => {
	const { t } = useTranslation();
	return (
		<Styled.AnalyticsContainer>
			<NetSales
				description={`${t('netSales-sellout-potential-revenue')} $15,000`}
			/>
		</Styled.AnalyticsContainer>
	);
};

export default NetSalesSection;
