import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { camelizeKeys } from 'humps';

import { TicketTypeProps, TicketTypesResponse } from './types';
import { IdParamsType } from 'utils/types';
import { getData } from 'services/api';

export const useTicketTypesApi = () => {
	const { id } = useParams<IdParamsType>();

	const { data: ticketTypes } = useQuery({
		queryKey: ['ticketTypes', id],
		queryFn: (): Promise<TicketTypesResponse[]> => getData(`/public/ticketing/ticket_types?event_id=${id}`),
		select: (data) => camelizeKeys(data) as unknown as TicketTypeProps[],
		enabled: !!id,
	});

	return { ticketTypes };
};
