import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HighlightsCollection } from 'features/common/components/HighlightsCollection';
import { BoxProps } from 'features/common/components/HighlightsCollection/types';
import { EmptyHighlights } from './EmptyHighlights';
import { HighlightsProps } from './types';
import {
	HOME_HIGHLIGHTS,
	TOTAL_ATTENDEES,
	TOTAL_EVENTS,
} from 'features/common/components/HighlightsCollection/constants';
import { useHighlights } from 'hooks/useHighlights';

import * as Styled from './Highlights.styles';

const Highlights: FC<HighlightsProps> = ({ hasLogo }: HighlightsProps) => {
	const { t } = useTranslation();
	const [boxCollection, setBoxCollection] = useState(HOME_HIGHLIGHTS);
	const { highlights, isLoading } = useHighlights();
	const hasHighlights = highlights !== undefined && !!Object.values(highlights).reduce((t, n) => t + n);

	useEffect(() => {
		if (highlights) {
			const boxCollections: BoxProps[] = [];
			for (let i = 0; i < boxCollection.length; i++) {
				const box = boxCollection[i];
				if (box.property === TOTAL_EVENTS) {
					box.stat = highlights.total_events;
				} else if (box.property === TOTAL_ATTENDEES) {
					box.stat = highlights.total_attendees;
					delete box.onClick;
				}
				boxCollections.push(box);
			}
			setBoxCollection(boxCollections);
		}
	}, [highlights]);

	return (
		<Styled.MainWrapper hasHighlights={hasHighlights} hasLogo={hasLogo} data-cy="highlights-section">
			{!isLoading ? (
				<>
					<Styled.Title element="h2">{t('highlights-title')}</Styled.Title>
					{hasHighlights ? (
						<Styled.HighlightsBox>
							<HighlightsCollection boxCollection={boxCollection} />
						</Styled.HighlightsBox>
					) : (
						<EmptyHighlights hasLogo={hasLogo} />
					)}
				</>
			) : null}
		</Styled.MainWrapper>
	);
};

const memorizedHighlights: FC<HighlightsProps> = memo(Highlights);

export { memorizedHighlights as Highlights };
