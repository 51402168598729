import { EventWebpageSettings } from 'features/Home/components/Events/types';
import { TicketBody } from 'features/TicketDetails/types';
import { Event } from 'features/common/types';
import { EVENT_MODE_NORMAL, EVENT_MODE_REGISTRATION } from './constants';

export interface IdParamsType {
	id: string;
}
export interface EventIdParamsType {
	eventId: string;
}

export interface GAPageType {
	page: string;
	title: string;
}

export interface GAEventType {
	category: string;
	action: string;
}

export interface ReferenceType {
	reference_id: number;
	quantity: number;
	name: string;
}

export interface TicketType {
	ID: number;
	amount: number;
	buyer_absorb_payment_fee: boolean;
	buyer_absorb_tixologist_fee: boolean;
	holds_amount: number;
	image: string;
	initial_price: number;
	name: string;
	selling_time: string;
	sold: number;
	sold_out: boolean;
}

export interface PromoCode {
	active: boolean;
	code: string;
	coupon_type: string;
	discount: number;
	draft: boolean;
	end_date: string;
	id: number;
	percentage: number;
	promocode_type: string;
	quantity: number;
	references: ReferenceType[];
	start_date: string;
	total_uses: number;
	Events: EventType[];
}

export interface AffiliateInfo {
	id: number;
	CreatedAt: Date;
	UpdatedAt: Date;
	DeletedAt: Date | null;
	event_id: number;
	event: Event | null;
	full_name: string;
	code: string;
	revenue: number;
	quantity: number;
}

export interface EventType {
	ID: number;
	name: string;
	image: string;
	images: any;
	start_date: string;
	end_date: string;
	description: string;
	ticket_types: TicketType[];
	tickets_sold: number;
	total_event_budget: string;
	selling_time: string;
	recurrence_id: number;
	start_date_id: number;
	end_date_id: number;
	is_slot: boolean;
	facebook_link: string;
	twitter_link: string;
	instagram_link: string;
	event_webpage_settings: EventWebpageSettings;
	time_zone: string;
	street_address: string;
	mode: EventMode;
}

export interface StateProps {
	state: {
		isFromRequiredFields?: boolean;
	};
}

export interface StateTabProps {
	state: {
		showTab: string | null;
		isFromRequiredFields: boolean;
	};
}

export interface RequiredFieldsProps {
	isFromRequiredFields?: boolean;
	ticket?: TicketBody;
	eventId?: string;
}

export interface ErrorBoundaryType {
	children: JSX.Element;
}

export interface INoticeFrame {
	function: string;
	file: string;
	line: number;
	column: number;
}
export interface INoticeError {
	type: string;
	message: string;
	backtrace: INoticeFrame[];
}
export interface INotice {
	id?: string;
	url?: string;
	error?: Error;
	errors?: INoticeError[];
}

export interface CustomFieldType {
	ID: number;
	name: string;
	only_buyer: boolean;
	description: string;
	is_required: boolean;
	type: string;
	ticket_types: TicketType[];
	choices: { name: string }[] | null;
}

export type HostingOption = 'widget' | 'webspinup' | '';

export interface UserType {
	id: number;
	email: string;
	full_name: string;
}

export interface CustomFieldResponsesType {
	ID: number;
	string_value: string;
	user: UserType;
	ticket: {
		TicketType: TicketType;
	};
}

export interface CustomToken {
	claims?: {
		memberRoles?: {
			[key: string]: string;
		};
	};
}

export interface IconProps {
	disabled: boolean;
}

export type EventMode = typeof EVENT_MODE_REGISTRATION | typeof EVENT_MODE_NORMAL;

export const customFieldNames = {
	name: 'ATTENDEE_NAME',
	email: 'ATTENDEE_EMAIL',
};

export const customFieldTypes = {
	TEXT: 'TEXT',
	NUMBER: 'NUMBER',
	EMAIL: 'EMAIL',
	DATE: 'DATE',
	DROPDOWN: 'DROPDOWN',
	MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
};
