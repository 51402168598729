import { TFunction } from 'react-i18next';
import { array, object, string } from 'yup';
import { UseMutateFunction } from '@tanstack/react-query';

import { convertUTCToDateBarFormat } from 'utils/utils';
import {
	Available,
	FormData,
	FormatDateProps,
	NestedTicketObject,
	NumberObject,
	SendTicketsFunctionProps,
} from './types';

export const initialRecipient = {
	firstName: '',
	lastName: '',
	email: '',
	ticketTypes: [],
};

export const initialFormData: FormData = {
	recipients: [initialRecipient],
};

export const formatDate = ({ startDate, timezone, endDate }: FormatDateProps) => ({
	day: convertUTCToDateBarFormat(startDate, timezone, 'MMMM D, YYYY').date,
	time: `${convertUTCToDateBarFormat(startDate, timezone).time} - ${convertUTCToDateBarFormat(endDate, timezone).time}`,
});

export function convertObject(originalObject: NestedTicketObject): NumberObject {
	return Object.keys(originalObject).reduce(
		(newObj, key) => ({ ...newObj, [key]: originalObject[key].quantity }),
		{} as NumberObject
	);
}

export const validationSchema = (t: TFunction<'translation', undefined>) =>
	object().shape({
		recipients: array().of(
			object().shape({
				email: string().required(t('createAccount-requiredEmail')).email(t('createAccount-invalidEmail')),
				firstName: string().required(t('createAccount-requiredName')),
				lastName: string().required(t('createAccount-requiredLastName')),
			})
		),
	});

export const handleSubmit = (
	data: SendTicketsFunctionProps,
	mutateCheckAvailability: UseMutateFunction<Available | undefined, unknown, NestedTicketObject, unknown>
) => {
	const nestedTickets: NestedTicketObject = {};
	data.recipients.forEach((recipient) => {
		recipient.ticketTypes.forEach((ticket) => {
			nestedTickets[ticket.ticket_type_id || 0] = { quantity: ticket.quantity, promocode_id: '' };
		});
	});
	mutateCheckAvailability(nestedTickets);
};
