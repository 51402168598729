import { useTranslation } from 'react-i18next';
import { useToastNotification } from './useToastNotification';

export const useCopyToClipboard = () => {
	const { showNotification, TYPES } = useToastNotification();
	const { t } = useTranslation();

	return async (text: string, content: string) => {
		try {
			await navigator.clipboard.writeText(text);
			showNotification({ message: t('copyToClipboard-success', { content }), type: TYPES.SUCCESS });
		} catch {
			showNotification({ message: t('copyToClipboard-error'), type: TYPES.ERROR });
		}
	};
};
