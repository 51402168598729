import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Form } from 'features/common/components/Form';
import { Footer } from 'features/common/components/Footer';
import { OUTLINED } from 'features/common/components/Button/constants';
import { MainLayout } from 'layouts/MainLayout';
import { LeftForm } from './components/LeftForm';
import { RightForm } from './components/RightForm';
import { EventTitle } from './components/EventTitle';
import { defaultValues, getFormDetails, handleInitialValues } from './utils';
import { IdParamsType, StateProps } from 'utils/types';
import { EditEventFormProps } from './types';

import { ContentWrapper, LeftContainer, RightContainer } from './EditEvent.styles';

export const EditEventForm = ({ event, editEvent }: EditEventFormProps) => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const { id }: IdParamsType = useParams();
	const { state: { isFromRequiredFields } = { isFromRequiredFields: false } }: StateProps = useLocation();

	const formMethods = useForm({
		defaultValues,
		resolver: getFormDetails(event.mode),
	});

	const onEditEvent = (values: any) => {
		editEvent({ values, id });
	};

	useMemo(() => {
		if (event) {
			const allValues = handleInitialValues(event);
			formMethods.reset(allValues);
		}
	}, [event]);

	return (
		<Form formMethods={formMethods} onSubmit={onEditEvent}>
			<MainLayout>
				<EventTitle title={event?.name} />
				<ContentWrapper>
					<LeftContainer>
						<LeftForm isFromRequiredFields={isFromRequiredFields} />
					</LeftContainer>
					<RightContainer>
						<RightForm />
					</RightContainer>
				</ContentWrapper>
				<Footer>
					<Footer.Button type="button" onClick={goBack} category={OUTLINED} text={t('editEvent-cancel')} />
					<Footer.Button text={t('editEvent-save')} type="submit" />
				</Footer>
			</MainLayout>
		</Form>
	);
};
