import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MainHeader } from 'features/common/components/MainHeader';
import { MainLayout } from 'layouts/MainLayout';
import { Search } from 'features/common/components/Search';
import { OrganizationCard } from './OrganizationCard';
import { StateProps } from './types';

import * as Styled from './SwitchTeam.styles';

export const SwitchTeam = () => {
	const { t } = useTranslation();
	const partners = useSelector((state: StateProps) => state.auth.loggedUser.Partners);
	const [searchedPartner, setSearchedPartner] = useState(partners);

	const filterPartners = (search: string) => {
		const partFiltered = partners.filter((p) => p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
		setSearchedPartner(partFiltered);
	};

	const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
		filterPartners(e.target.value);
	};

	return (
		<MainLayout>
			<MainHeader>
				<Styled.Title element="h1">{t('settings')}</Styled.Title>
			</MainHeader>
			<Styled.SwitchTeamRoot>
				<Styled.SearchLabel>{t('settings-switch-team-select-organization')}</Styled.SearchLabel>
				<Search onChange={handleSearch} placeholder={t('settings-switch-team-search-label')} />
				<Styled.OrganizationsContainer>
					{searchedPartner.map((partner) => (
						<OrganizationCard {...partner} key={partner.ID} />
					))}
				</Styled.OrganizationsContainer>
			</Styled.SwitchTeamRoot>
		</MainLayout>
	);
};
