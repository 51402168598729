import styled from 'styled-components';

import { Text } from '../Text';
import { ProgressStylesProps } from './types';

export const ContainerProgressBar = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;

	&.analytics {
		width: 90%;
	}
`;

export const ProgressBarBase = styled.div`
	height: 32px;
	width: 100%;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.colors.grey3};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 245px;
	}
`;

export const Progress = styled.div<ProgressStylesProps>`
	width: ${({ percentage }) => `${percentage}%`};
	height: 32px;
	display: flex;
	border-radius: 4px;
	position: relative;
	align-items: center;
	box-sizing: border-box;
	background-color: ${({ theme }) => theme.colors.green};
`;

export const ProgressText = styled(Text)`
	font-size: 14px;
	font-weight: 500;
	padding: 0px 12px;
	line-height: 150%;
	color: ${({ theme }) => theme.colors.white};
`;
