import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlusIcon, LessIcon } from './assets';
import { CounterProps } from './types';
import { updateQuantityInData, updateQuantityInMultiday } from './utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGetLastEvent } from '../hooks/useGetLastEvent';
import { RootState } from 'store';
import { EventData, MultidayType } from 'features/common/types';

import { Container, CounterImage, StyledInput, Text } from './Counter.styles';

const Counter = ({ className, quantity, ticketId, dayId, max, soldOut }: CounterProps) => {
	const [counterQuantity, setCounterQuantity] = useState(quantity);
	const { event, isMultiDay } = useGetLastEvent();
	const { multiday } = useAppSelector(({ lastMultiDay }: RootState) => lastMultiDay);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const handleSelector = (value: number) =>
		dispatch(
			isMultiDay
				? updateQuantityInMultiday({ newQuantity: value, dayId, ticketId, multiday: multiday as MultidayType[] })
				: updateQuantityInData({ newQuantity: value, ticketId, event: event as EventData })
		);

	const handlePlus = () => handleOnChange(counterQuantity + 1);

	const handleLess = () => {
		if (counterQuantity > 0) handleOnChange(counterQuantity - 1);
	};

	const handleOnChange = (value: number) => {
		if (value <= max) {
			setCounterQuantity(value);
			handleSelector(value);
		}
	};

	return (
		<Container>
			{soldOut ? (
				<Text>{t('sold-out')}</Text>
			) : (
				<>
					<CounterImage src={LessIcon} onClick={handleLess} />
					<StyledInput
						value={counterQuantity.toString()}
						onChange={(e) => handleOnChange(Number(e.target.value))}
						type="number"
						min={0}
						className={className}
					/>
					<CounterImage src={PlusIcon} onClick={handlePlus} />
				</>
			)}
		</Container>
	);
};

export default Counter;
