import { TFunction } from 'react-i18next';
import { object, string } from 'yup';

export const fields = [
	{ id: 'email', label: 'Email', type: 'text' },
	{ id: 'password', label: 'Password', type: 'password' },
	{ id: 'name', label: 'Name', type: 'text' },
	{ id: 'organizationName', label: 'Organization Name', type: 'text' },
];

export const fieldsSingUp = [
	{ id: 'name', label: 'Name', type: 'text' },
	{ id: 'organizationName', label: 'Organization Name', type: 'text' },
];

export const defaultValues = {
	email: '',
	password: '',
	name: '',
	organizationName: '',
};

// TODO: add required
export const validationSchema = (t: TFunction<'translation', undefined>) =>
	object().shape({
		email: string().required(t('createAccount-requiredEmail')).email(t('createAccount-invalidEmail')),
		password: string().required(t('createAccount-requiredPassword')),
		name: string().required(t('createAccount-requiredName')),
		organizationName: string().required(t('createAccount-requiredOrgName')),
	});

export const defaultGoogleValues = {
	organizationName: '',
};

// TODO: add required
export const validationGoogleSchema = (t: TFunction<'translation', undefined>) =>
	object().shape({
		organizationName: string().required(t('createAccount-requiredOrgName')),
	});

export const validationSingUpSchema = (t: TFunction<'translation', undefined>) =>
	object().shape({
		name: string().required(t('createAccount-requiredName')),
		organizationName: string().required(t('createAccount-requiredOrgName')),
	});

export const CREATE_ACCOUNT_ERRORS = {
	EMAIL_ALREADY_IN_USE: 'auth/email-already-in-use',
	WEAK_PASSWORD: 'auth/weak-password',
	PARTNER_ALREADY_EXISTS: 'partner already exists',
};

export const defaultErrors = {
	partnerAlreadyExist: false,
	emailAlreadyExist: false,
	weakPassword: false,
};
