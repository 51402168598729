import { FC } from 'react';
import { SearchProps } from '../../../Home/components/Events/types';

import { Container, Icon, Input } from './Search.styles';

const Search: FC<SearchProps> = ({ onChange, placeholder, width, fontSize, className, ...rest }: SearchProps) => (
	<Container width={width} className={className}>
		<Icon iconName="search" />
		<Input type="input" placeholder={placeholder} onChange={onChange} fontSize={fontSize} {...rest} />
	</Container>
);

export { Search };
