import { Dispatch, SetStateAction } from 'react';

import {
	CalculateEmailsProps,
	ControlVisProps,
	EventAttendees,
	GetContentNameProps,
	InputObject,
	SetNumArrState,
	getFilterNameProps,
} from './types';
import { TextInput } from './components/TextInput';
import { Input } from './components/Input';
import { ColorInput } from './components/ColorInput';
import { ImageInput } from './components/ImageInput';
import { TargetAudience } from './components/TargetAudience';
import { InputToggle } from 'features/common/components/Form/InputToggle';
import { Content } from './components/Content';
import { post } from 'services/api';

export const INPUT_ARRAY: InputObject[] = [
	{
		Component: Input,
		id: 'campgain-name',
		isToggle: false,
		label: 'marketing-campaignForm-name',
		name: 'name',
	},
	{
		Component: TargetAudience,
		label: 'marketing-campaignForm-audience',
	},
	{
		Component: Content,
		label: 'marketing-campaignForm-content',
	},
	{
		Component: ColorInput,
		label: 'marketing-campaignForm-colors',
		name: 'themeColor',
	},
	{
		Component: TextInput,
		id: 'campaign-headerText',
		label: 'marketing-campaignForm-headerText',
		name: 'headerText',
	},
	{
		Component: Input,
		id: 'campaign-title',
		label: 'marketing-campaignForm-title',
		name: 'title',
	},
	{
		Component: InputToggle,
		htmlFor: 'logoEnabled',
		label: 'marketing-campaignForm-logo',
		name: 'logoEnabled',
	},
	{
		Component: ImageInput,
		label: 'marketing-campaignForm-headerImages',
		name: 'headerImages',
	},
	{
		Component: InputToggle,
		htmlFor: 'viewEventButtonEnabled',
		label: 'marketing-campaignForm-viewEventButton',
		name: 'viewEventButtonEnabled',
	},
	{
		Component: TextInput,
		id: 'campaign-bodyText',
		label: 'marketing-campaignForm-bodyText',
		name: 'bodyText',
	},
	// TODO: use footer enabled form field once we add social media
	// {
	// 	Component: InputToggle,
	// 	htmlFor: 'footerEnabled',
	// 	label: 'marketing-campaignForm-footer',
	// 	name: 'footerEnabled',
	// },
	{
		Component: Input,
		id: 'campaign-customerSupportContact',
		label: 'marketing-campaignForm-customerSupportContact',
		name: 'customerSupportContact',
	},
];

// In case we decide to switch back to using the names of selected events as filter name
// export const getSelectedNames = ({
// 	cohortsData,
// 	cohortFormValue,
// 	eventsData,
// 	eventFormValue,
// }: GetAudienceNameProps) => {
// 	const names = (cohortsData || []).reduce((selectedNames, { iD, name }) => {
// 		if (cohortFormValue?.includes(iD)) {
// 			return selectedNames === '' ? name : `${selectedNames}, ${name}`;
// 		} else return selectedNames;
// 	}, '');

// 	return (eventsData || []).reduce((selectedNames, { id, name }) => {
// 		if (eventFormValue?.includes(id)) {
// 			return selectedNames === '' ? name : `${selectedNames}, ${name}`;
// 		}
// 		return selectedNames;
// 	}, names);
// };

export const getSelectedName = ({ eventsData, eventFormValue }: GetContentNameProps) =>
	eventsData?.filter(({ id }) => eventFormValue?.includes(id))[0]?.name;

export const changeValue = (
	setState: SetNumArrState,
	newId: number,
	setAllGroups: Dispatch<SetStateAction<boolean>>
) => {
	setState((prev) => (prev.includes(newId) ? prev.filter((id: number) => id !== newId) : [...prev, newId]));
	setAllGroups(false);
};

export const getFilterName = ({ t, allGroups, selectedCt }: getFilterNameProps) => {
	if (allGroups) return t('marketing-campaignForm-allGroupsSelected');
	const s = selectedCt > 1 ? 's' : '';
	return selectedCt > 0
		? t('marketing-campaignForm-selectedGroups', { selectedCt, s })
		: t('marketing-campaignForm-selectGroups');
};

export const calculateTotalEmails = async ({
	allGroups,
	cohortsData,
	cohortIds,
	eventIds,
	setValue,
}: CalculateEmailsProps) => {
	const eventAttendees: EventAttendees[] =
		!allGroups && eventIds.length === 0 ? [] : await post('/events/attendees', { ids: eventIds });

	const eventEmails = eventAttendees?.map((person) => person.email);

	const selectedCohorts = allGroups ? cohortsData : cohortsData?.filter((cohort) => cohortIds.includes(cohort.iD));
	const cohortEmails =
		selectedCohorts?.reduce((total: string[], cohort) => [...total, ...(cohort?.emails || [])], []) || [];
	setValue('totalEmails', new Set([...eventEmails, ...cohortEmails]).size);
};

export const controlVisibility = ({ search, name, i, totalPerPage }: ControlVisProps) => {
	const isInName = name.toLowerCase().includes(search.toLowerCase());
	const isSearched = search && isInName;
	const isFirstPage = i <= 3 && !search;
	const isViewAll = totalPerPage === -1 && isInName;
	return isSearched || isFirstPage || isViewAll;
};
