import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';

export const Container = styled.div`
	cursor: pointer;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	gap: 22px;
	background: ${({ theme }) => theme.colors.grey2};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 340px;
	}
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xxs}) {
		width: 100%;
	}
`;

export const ImageContainer = styled.div`
	position: relative;
	height: 271px;
	width: 100%;
	border-radius: 4px 4px 0px 0px;
	overflow: hidden;
`;

export const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px 16px;
`;
export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Title = styled(Text)`
	font-weight: 600;
	font-size: 22px;
`;

export const Info = styled(Text)`
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
`;

export const TicketsSold = styled(Info)`
	color: ${({ theme }) => theme.colors.green};
`;

export const Icon = styled(UnstyledIcon)`
	width: 72px;
	height: 72px;
	margin-top: 36px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 48px;
		height: 48px;
	}
`;
