import styled from 'styled-components';

export const WhiteBox = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	max-width: 50px;
	max-height: 60px;
	width: 10vw;
	height: 13vw;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NumberSpan = styled.span`
	font-size: 32px;
	font-family: ${({ theme }) => theme.fonts.primary};
	color: ${({ theme }) => theme.colors.black};
`;
