import { HeaderProps } from './types';

import { HeaderContainer, Text } from './Modal.styles';

function Header({ children, className }: HeaderProps) {
	return (
		<HeaderContainer className={className}>
			<Text>{children}</Text>
		</HeaderContainer>
	);
}

export { Header };
