import { common } from 'theme';
import { FieldProps } from './types';

export const defaultValues: FieldProps = {
	eventName: '',
	themeColors: {
		background_color: '',
		theme_color: '',
		header_color: '',
	},
	typoSection: {
		fontFamily: '',
		fontStyle: {
			title: { name: 'Select', color: common.colors.white },
			subtitle: { name: 'Select', color: common.colors.white },
			text: { name: 'Select', color: common.colors.white },
			headerText: { name: 'Select', color: common.colors.white },
			callouts: { name: 'Select', color: common.colors.white },
		},
	},
};
