import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { MainLayout } from 'layouts/MainLayout';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { MediaContent } from './MediaContent';
import { useUploadImageMutation } from './useUploadImageMutation';
import { FieldProps } from './types';
import { IdParamsType } from 'utils/types';
import { getContent, updateContent } from './services';
import { defaultValues, isNewImg, isUploadedOrFetched } from './utils';
import { Header } from 'features/common/components/Header';

import * as Styled from './EventImgContent.styles';

export const EventImgContent = () => {
	const { t } = useTranslation();
	const { id }: IdParamsType = useParams();
	const eventId = parseInt(id);
	const { data: initialValues } = useQuery({
		queryKey: ['event-content', id],
		queryFn: () => getContent(id),
	});

	const formMethods = useForm({
		defaultValues,
		values: initialValues,
	});

	const { mutate: mutateWebsite } = useMutation(updateContent);

	const { mutateUploadImage } = useUploadImageMutation(formMethods);

	const handleSubmit = async (attributes: FieldProps) => {
		const [image] = attributes.mediaContent.eventImage;
		if (image && initialValues && isNewImg(image)) {
			await mutateUploadImage({
				image,
				prefix: image.name,
				index: 1,
				type: 'event_image',
			});
		}

		const allMedia = attributes.mediaContent.media;
		if (allMedia) {
			await Promise.all(
				allMedia.map(async (image, index) => {
					if (isNewImg(image)) {
						await mutateUploadImage({
							image,
							prefix: image.name,
							index: index,
							type: 'gallery_image',
						});
					}
				})
			);
		}

		const values = formMethods.getValues();
		values.mediaContent.media.forEach((media, index) => {
			if (isUploadedOrFetched(media)) media.position = index;
		});

		mutateWebsite({ attributes: values, eventId });
	};

	return (
		<MainLayout>
			<Header.TitledHeader title={t('eventImgContent-header')} subtitle={initialValues?.eventName || ''} />
			<Styled.ContentWrapper>
				<Styled.Container onSubmit={handleSubmit} formMethods={formMethods}>
					<MediaContent />
					<Styled.DividerWithSpacing />
					<FlexContainer gap="12px" justifyContent="center">
						<Styled.PublishButton text={t('button-save')} type="submit" data-cy="save-btn" />
					</FlexContainer>
				</Styled.Container>
			</Styled.ContentWrapper>
		</MainLayout>
	);
};
