import styled from 'styled-components';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { Text as UnstyledText } from 'features/common/components/Text';
import { MainHeader } from 'features/common/components/MainHeader';

export const SubTitle = styled(UnstyledText)`
	color: ${({ theme }) => theme.colors.white};
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	display: flex;
	align-items: center;
`;

export const Divider = styled.div`
	width: 100%;
	border: 1px solid ${({ theme }) => theme.colors.whiteNac};
`;

export const Img = styled.img`
	margin-right: 12px;
`;

export const Wrapper = styled(FlexContainer)`
	width: 100vw;
	max-width: 100vw;
	overflow-y: scroll;
	padding: 16px 16px 16px 60px;
	box-sizing: border-box;
	max-height: calc(100vh - 144px);
	margin-left: 5%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 16px 3% 16px 3%;
		max-height: calc(100vh - 125px - 88px);
		margin-left: 0;
	}
`;

export const Header = styled(MainHeader)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		position: fixed;
	}
`;
