import { createSlice } from '@reduxjs/toolkit';

type GoogleState = {
	isNewGoogleAccount: boolean;
	name: string;
	email: string;
};

const initialState = {
	isNewGoogleAccount: false,
	name: '',
	email: '',
} as GoogleState;

const googleSlice = createSlice({
	name: 'googleSlice',
	initialState,
	reducers: {
		setIsNewGoogleAccount(state) {
			state.isNewGoogleAccount = true;
		},
		setName(state, action) {
			state.name = action.payload;
		},
		setEmail(state, action) {
			state.email = action.payload;
		},
	},
});

export const { setIsNewGoogleAccount, setName, setEmail } = googleSlice.actions;
export default googleSlice.reducer;
