import { FC, memo } from 'react';

import { BoxProps, HighlightsCollectionProps } from './types';
import { HighlightCard } from './HighlightCard';

import { HighlightsContainer } from './HighlightsCollection.styles';

const HighlightsCollection = ({ boxCollection }: HighlightsCollectionProps) => (
	<HighlightsContainer>
		{boxCollection.map(({ ...props }: BoxProps) => (
			<HighlightCard key={props.property} {...props} />
		))}
	</HighlightsContainer>
);

const memorizedHighlightsCollection: FC<HighlightsCollectionProps> = memo(HighlightsCollection);

export { memorizedHighlightsCollection as HighlightsCollection };
