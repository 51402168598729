import { createSlice } from '@reduxjs/toolkit';

type PromoCodesFormState = {
	showTicketTypes: boolean;
	showFooter: boolean;
};

const initialState = {
	showTicketTypes: false,
	showFooter: true,
} as PromoCodesFormState;

const promoCodesFormSlice = createSlice({
	name: 'PromoCodesFormState',
	initialState,
	reducers: {
		onChangeShowTicketTypes(state) {
			state.showTicketTypes = !state.showTicketTypes;
		},
		onChangeShowFooter(state) {
			state.showFooter = !state.showFooter;
		},
	},
});

export const { onChangeShowTicketTypes, onChangeShowFooter } =
	promoCodesFormSlice.actions;
export default promoCodesFormSlice.reducer;
