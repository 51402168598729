import styled from 'styled-components';

import { Text as UnstyledText } from 'features/common/components/Text';
import { Divider } from 'features/common/components/Divider';

export const ContentWrapper = styled.div`
	width: 100%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 0 3% 40px 3%;
	}
`;

export const Title = styled(UnstyledText)`
	font-weight: 700;
	font-size: 40px;
	line-height: 30px;
	padding: 30px 0 30px 6%;
	margin: 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 24px;
		line-height: 20px;
		padding-left: 3%;
	}
`;

export const ContainerEvent = styled.div`
	display: flex;
	margin-bottom: 24px;
	padding-left: 6%;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 94%;
		padding: 0 3%;
		justify-content: space-around;
	}
`;

export const EventsContainer = styled.div`
	margin-top: 17px;
	display: flex;
	flex-direction: column;
	justify-content: start;
	min-height: 156px;
	gap: 22px;
	border-radius: 8px;
	width: 91%;
	padding: 0 3% 0 6%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 94%;
		padding: 0 3%;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 94%;
		padding: 0 3%;
	}
`;

export const Wrapper = styled.div`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
`;

export const EventAnalyticsDivider = styled(Divider)`
	margin: 0 3% 0 6%;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 0 3%;
	}
`;
