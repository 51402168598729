import styled from 'styled-components';

import { Button } from 'features/common/components/Button';
import { Form } from 'features/common/components/Form';
import { Icon } from 'features/common/components/Icon';

export const CustomIcon = styled(Icon)`
	height: 90px;
	width: 90px;
`;

export const FormColumn = styled(Form)`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
`;

export const ContentWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 72px auto;
	max-width: 425px;
	flex-grow: 1;
	gap: 36px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: 300px;
		height: fit-content;
	}
`;

export const StickyDiv = styled.div`
	box-sizing: border-box;
	background: ${({ theme }) => theme.colors.grey4};
	border-top: ${({ theme }) => `0.5px solid ${theme.colors.white2}`};
	display: flex;
	gap: 16px;
	justify-content: center;
	padding: 48px 24px;
	position: sticky;
	bottom: 0;
	width: 100%;
	flex-wrap: wrap-reverse;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px;
	}
`;

export const FormButton = styled(Button)`
	background-color: ${({ theme }) => theme.colors.blackGrey};
	font-size: 18px;
	font-weight: 600;
	line-height: normal;
	margin: 0;
	padding: 8px 16px;
	max-width: 225px;
`;
