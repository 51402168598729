import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from 'store';
import { Modals } from 'features/FanDatabase/Slices/types';
import { ROUTES } from 'features/routes';
import { MobileModal } from 'features/common/components/MobileModal';
import { Icon } from 'features/common/components/Icon';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { OPTIONS, ICONS } from './constants';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { closeFanModal } from 'features/FanDatabase/Slices/FanDatabaseSlice';
import { useTableClick } from 'features/FanDatabase/hooks/useTableClick';

import { FlexButton } from './OptionsModal.styles';

export const OptionsModal: FC = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const isLargeScreen = useResponsiveScreen();
	const { openModal, fanData } = useSelector((state: RootState) => state.fanDatabase.modalStatus);
	const dispatch = useDispatch();
	const isOpen = openModal === Modals.optionsModal && !isLargeScreen;

	const onClickArray = [
		// 	/TODO: add function that will reroute to the fan profile page! */
		useTableClick(Modals.reset, fanData),
		() => push(ROUTES.REFUNDABLE_TICKETS_PATH.replace(':id', String(fanData.user_id))),
		useTableClick(Modals.confirmContact, fanData),
	];

	return (
		<MobileModal isOpen={isOpen} closeModal={() => dispatch(closeFanModal())}>
			<FlexContainer padding="16px 30px">
				<FlexContainer flexDirection="column" width="100%">
					{OPTIONS.map((option, i, array) => {
						const bottomBorder = i != array.length - 1;
						return (
							<FlexButton key={option} bottomBorder={bottomBorder} onClick={onClickArray[i]}>
								<Icon iconName={ICONS[i]} />
								{t(option)}
							</FlexButton>
						);
					})}
				</FlexContainer>
			</FlexContainer>
		</MobileModal>
	);
};
