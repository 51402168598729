import styled from 'styled-components';

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(100vw - 12%);
	align-items: flex-start;
	margin-top: 56px;
	gap: 24px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-top: 15px;
	}
`;
