import {
	ApplyPCTicketTypeProps,
	ApplyPromoCodesMultiDayProps,
	ApplyPromoCodesProps,
	ApplyPromoCodesSingleDayProps,
	CustomFeesMap,
	PromoCodeDataType,
	QuantityProps,
	SubTotalProps,
	TaxesAndFeesProps,
	TaxesType,
	TotalFees,
	TotalProps,
} from './types';
import { setLastEvent } from 'store/slices/lastEventSlice';
import { AppDispatch } from 'store';
import { EventData, MultidayType } from 'features/common/types';
import { nuveiTax1, nuveiTax2 } from 'utils/utils';
import { setLastMultiDay } from 'store/slices/lastMultiDayEventSlice';

const handleApplyPCTicketType = ({ item, ticketsWithPromoCodes, promoCodeData }: ApplyPCTicketTypeProps) =>
	item.ticketTypes.map((ticket) => {
		if (ticketsWithPromoCodes.includes(ticket.ID)) {
			if (promoCodeData && !ticket.promoCodeApplied) {
				ticket = {
					...ticket,
					promoCodeApplied: true,
					promoCodeId: promoCodeData.id,
					initialPrice: calculateDiscountedPrice(ticket.initialPrice, promoCodeData),
				};
			} else if (promoCodeData && ticket.promoCodeApplied) {
				const fakePrice = calculateFakePrice(ticket.priceWithNoDiscount, promoCodeData);
				if (fakePrice < ticket.initialPrice && fakePrice < 0) {
					ticket = { ...ticket, initialPrice: 0 };
				} else if (fakePrice < ticket.initialPrice) {
					ticket = { ...ticket, initialPrice: fakePrice };
				}
			}
		}
		return ticket;
	});

const handleApplyPromoCodesSingleDay =
	({ event, ticketsWithPromoCodes, promoCodeData, setPromoCodeApplied }: ApplyPromoCodesSingleDayProps) =>
	(dispatch: AppDispatch) => {
		const newTicketTypes = handleApplyPCTicketType({ item: event, ticketsWithPromoCodes, promoCodeData });

		setPromoCodeApplied(true);

		const updatedEvent = { ...event, ticketTypes: newTicketTypes };
		dispatch(setLastEvent(updatedEvent));
	};

const handleApplyPromoCodesMultiDay =
	({ multiday, ticketsWithPromoCodes, promoCodeData, setPromoCodeApplied }: ApplyPromoCodesMultiDayProps) =>
	(dispatch: AppDispatch) => {
		const newMultiday = multiday.map((item) => ({
			...item,
			ticketTypes: handleApplyPCTicketType({ item, ticketsWithPromoCodes, promoCodeData }),
		}));

		setPromoCodeApplied(true);
		dispatch(setLastMultiDay(newMultiday));
	};

export const handleApplyPromoCodes =
	({ event, ticketsWithPromoCodes, promoCodeData, setPromoCodeApplied, multiday, isMultiDay }: ApplyPromoCodesProps) =>
	(dispatch: AppDispatch) =>
		dispatch(
			isMultiDay
				? handleApplyPromoCodesMultiDay({ multiday, ticketsWithPromoCodes, promoCodeData, setPromoCodeApplied })
				: handleApplyPromoCodesSingleDay({ event, ticketsWithPromoCodes, promoCodeData, setPromoCodeApplied })
		);

const calculateDiscountedPrice = (initialPrice: number, promoCodeData: PromoCodeDataType): number => {
	if (promoCodeData.couponType === 'flat') {
		return Math.max(initialPrice - promoCodeData.discount, 0);
	} else {
		return Math.max((initialPrice * (100 - promoCodeData.percentage)) / 100, 0);
	}
};

const calculateFakePrice = (priceWithNoDiscount: number, promoCodeData: PromoCodeDataType): number => {
	if (promoCodeData.couponType === 'flat') {
		return priceWithNoDiscount - promoCodeData.discount;
	} else {
		return (priceWithNoDiscount * (100 - promoCodeData.percentage)) / 100;
	}
};

const handleTotalSingleDay = (myTaxes: number, myFees: number, customFees: number, event: EventData) =>
	handleSubtotalSingleDay(event) + myTaxes + myFees + customFees;

const handleTotalMultiDay = (myTaxes: number, myFees: number, multiday: MultidayType[]) =>
	handleSubtotalMultiDay(multiday) + myTaxes + myFees;

export const handleTotal = ({ myTaxes, myFees, customFees, event, isMultiDay, multiday }: TotalProps) =>
	isMultiDay
		? handleTotalMultiDay(myTaxes, myFees, multiday)
		: handleTotalSingleDay(myTaxes, myFees, customFees, event);

const handleSubtotalSingleDay = (event: EventData) =>
	event.ticketTypes.reduce((acc, { initialPrice, quantity }) => {
		return (acc += round(initialPrice * quantity));
	}, 0) || 0;

const handleSubtotalMultiDay = (multiday: MultidayType[]) =>
	multiday.reduce(
		(accu, { ticketTypes }) =>
			accu + ticketTypes.reduce((innerAccu, { quantity, initialPrice }) => (innerAccu += initialPrice * quantity), 0),
		0
	);

export const handleSubtotal = ({ event, multiday, isMultiDay }: SubTotalProps) =>
	isMultiDay ? handleSubtotalMultiDay(multiday) : handleSubtotalSingleDay(event);

const handleFeesSingleDay = (event: EventData, taxes: TaxesType, customFees: CustomFeesMap) =>
	event.ticketTypes.map(({ initialPrice, ID, quantity }, index) => {
		const tixologiFixedRoyalty =
			taxes.ticketTaxes[ID]?.tixologiFixedPrimaryRoyalty || taxes.partnerRoyalties?.tixologiFixedPrimaryRoyalty;
		return round(
			(quantity * initialPrice + customFees[index]) * (taxes.partnerRoyalties?.tixologiPrimaryRoyalty / 100) +
				tixologiFixedRoyalty * quantity
		);
	});

const handleFeesMultiDay = (multiday: MultidayType[], taxes: TaxesType) =>
	multiday.reduce((accumulator, item) => {
		const ticketTypeFees = item.ticketTypes.map(
			({ initialPrice, quantity }) =>
				quantity &&
				initialPrice * (taxes.partnerRoyalties?.tixologiPrimaryRoyalty / 100) +
					taxes.partnerRoyalties?.tixologiFixedPrimaryRoyalty * quantity
		);

		return [...accumulator, ...ticketTypeFees];
	}, [] as number[]);

const handleFeesSum = (myFees: number[]) => myFees.reduce((acc, current) => (acc += current), 0);

const handleTaxesSingleDay = (
	event: EventData,
	myFees: number[],
	taxableCustomFees: CustomFeesMap,
	taxes: TaxesType
) => {
	return event.ticketTypes.reduce((acc, { initialPrice, ID, quantity }, index: number) => {
		return (
			acc + round((quantity * initialPrice + taxableCustomFees[index] + myFees[index]) * taxes.ticketTaxes[ID]?.taxRate)
		);
	}, 0);
};

const handleTaxesMultiDay = (multiday: MultidayType[], myFees: number[], taxes: TaxesType) =>
	multiday.reduce((accumulator, item, index) => {
		const ticketTypeTaxes = item.ticketTypes.reduce(
			(acc, { initialPrice, ID, quantity }) =>
				(acc += quantity && (initialPrice + myFees[index]) * taxes.ticketTaxes[ID].taxRate),
			0
		);

		return accumulator + ticketTypeTaxes;
	}, 0);

const handleTotalFees = ({ myFeesSum, event, customFeesSum, myTaxes, multiday, isMultiDay }: TotalFees) => {
	const subTotal = isMultiDay ? handleSubtotalMultiDay(multiday) : handleSubtotalSingleDay(event);
	const currentNuveiFees = (subTotal + myFeesSum + customFeesSum + myTaxes) * nuveiTax1 + nuveiTax2;
	return myFeesSum + currentNuveiFees;
};

export const round = (float: number) => Math.round(float * 100) / 100;

const handleTaxableCustomFees = (event: EventData, excludeTaxable = false) =>
	event.ticketTypes.reduce((acc, { quantity, initialPrice }) => {
		const fee = event.customFees.reduce((customFees, { type, amount, taxable }) => {
			if (!taxable && !excludeTaxable) {
				return 0;
			}

			if (type === 'flat') {
				return customFees + round(quantity * amount);
			} else {
				return customFees + round(quantity * initialPrice * (amount / 100));
			}
		}, 0);

		return [...acc, fee];
	}, [] as number[]);

const handleCustomFees = (event: EventData) =>
	event.customFees.reduce(
		(customFees, { ID, type, amount }) => ({
			...customFees,
			[ID]: event.ticketTypes.reduce((acc, { initialPrice, quantity }) => {
				if (type === 'flat') {
					return acc + round(quantity * amount);
				} else {
					return acc + round(quantity * initialPrice * (amount / 100));
				}
			}, 0),
		}),
		{} as CustomFeesMap
	);

export const handleTaxesAndFees = ({
	event,
	taxes,
	setMyFees,
	setMyTaxes,
	setCustomFees,
	isMultiDay,
	multiday,
}: TaxesAndFeesProps) => {
	const taxableCustomFees = handleTaxableCustomFees(event);
	const customFees = handleTaxableCustomFees(event, true);

	const myFees =
		isMultiDay && multiday ? handleFeesMultiDay(multiday, taxes) : handleFeesSingleDay(event, taxes, customFees);
	const myFeesSum = handleFeesSum(myFees);

	const myTaxes =
		isMultiDay && multiday
			? handleTaxesMultiDay(multiday, myFees, taxes)
			: handleTaxesSingleDay(event, myFees, taxableCustomFees, taxes);

	const customFeesSum = handleFeesSum(customFees);
	setMyFees(handleTotalFees({ myFeesSum, event, customFeesSum, myTaxes, multiday, isMultiDay }));
	setCustomFees(handleCustomFees(event));

	setMyTaxes(myTaxes);
};

// returns total quantity of ticket types selected
export const handleQuantity = ({ multiday, event, isMultiDay }: QuantityProps) =>
	isMultiDay
		? multiday?.reduce(
				(accu, { ticketTypes }) => accu + ticketTypes.reduce((innerAccu, { quantity }) => innerAccu + quantity, 0),
				0
		  )
		: event?.ticketTypes.reduce((accu, { quantity }) => (accu += quantity), 0);
