import styled from 'styled-components';
import { Text } from 'features/common/components/Text';

export const Title = styled(Text)`
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: ${({ theme }) => theme.colors.lightGrey15};
`;

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 20px;
	}
`;

export const ContainerRow = styled.div`
	display: flex;
	gap: 20px;
	align-items: flex-end;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 5px;
	}
`;
