import styled from 'styled-components';

import { Text } from 'features/common/components/Text';

export const Root = styled.div``;

export const Title = styled(Text)``;

export const SubTitle = styled(Text)`
	font-size: 16px;
	font-weight: 600;
	line-height: 32px;
	margin-right: 10px;
`;

export const InfoText = styled(Text)`
	font-size: 12px;
	font-weight: 400;
	line-height: 24px;
	display: block;
`;
