import { useTranslation } from 'react-i18next';

import { PayoutsTable } from './PayoutsTable';

import * as Styled from './PayoutsTableSection.styles';

const PayoutsTableSection = () => {
	const { t } = useTranslation();
	return (
		<>
			<Styled.Title>{t('settings-my-finances-payouts')}</Styled.Title>
			<PayoutsTable />
		</>
	);
};

export { PayoutsTableSection };
