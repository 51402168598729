import { useFormContext } from 'react-hook-form';
import { InputLabel } from './InputLabel';
import { FormInputWithIconProps } from './types';
import { Icon } from '../Icon';
import { L } from '../Icon/utils';

import { Error, Input, InputContainer, IconInputContainer } from './Form.styles';

const InputWithIcon = ({ id, name, label, iconName, showLabel, ...rest }: FormInputWithIconProps) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	const error = errors[name];

	return (
		<InputContainer hasError={Boolean(error)}>
			{showLabel && <InputLabel htmlFor={id ?? name} label={label} />}
			<IconInputContainer>
				<Icon size={L} iconName={iconName} />
				<Input {...rest} id={id ?? name} {...register(name)} />
			</IconInputContainer>
			<Error className="icon">{error?.message}</Error>
		</InputContainer>
	);
};

export { InputWithIcon };
