import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { getData } from 'services/api';
import { EventTemplateProps, KeyProps, TemplateProps } from '../types';
import { convertUTCToDate } from 'utils/utils';

import * as Styled from '../Template.styles';

export const Template2 = ({ onClick, isEdit, image, isCard, name }: TemplateProps) => {
	const { t } = useTranslation();
	const propKey = isEdit ? 'id' : 'eventId';
	const { [propKey]: propValue }: KeyProps = useParams();

	const { data } = useQuery({
		queryKey: ['event', propValue],
		queryFn: () => getData(`/public/ticketing/events/${propValue}`),
		initialData: [],
	});
	const event = camelizeKeys(data) as unknown as EventTemplateProps;

	const isCardClassName = isCard ? 'isCard' : '';

	return (
		<Styled.TemplateContainer className={isCardClassName} onClick={onClick} id="template2">
			{isCard && <Styled.DarkGradientBackground />}
			<Styled.Image src={image} />
			<Styled.InfoContainer className={isCardClassName}>
				<Styled.MainInfoContainer>
					<Styled.Title className={isCardClassName}>{event?.name}</Styled.Title>
				</Styled.MainInfoContainer>
				<Styled.InfoRow>
					<Styled.InfoItem>
						<Styled.InfoTitle>{t('generic-date')}</Styled.InfoTitle>
						<Styled.InfoText>
							{moment(convertUTCToDate(`${event?.startDate}`, event?.timeZone))?.format('MM.DD.YYYY')}
						</Styled.InfoText>
					</Styled.InfoItem>
					<Styled.InfoItem>
						<Styled.InfoTitle>{t('generic-time')}</Styled.InfoTitle>
						<Styled.InfoText>{`${moment(convertUTCToDate(`${event?.startDate}`, event?.timeZone))?.format(
							'h:mm A'
						)} - ${moment(convertUTCToDate(`${event?.endDate}`, event?.timeZone))?.format('h:mm A')}`}</Styled.InfoText>
					</Styled.InfoItem>
				</Styled.InfoRow>
				<Styled.Divider />
				{event?.venue !== '' && (
					<>
						<Styled.InfoRow>
							<Styled.InfoItem>
								<Styled.InfoTitle>{t('generic-venue')}</Styled.InfoTitle>
								<Styled.InfoText>{event?.venue}</Styled.InfoText>
							</Styled.InfoItem>
						</Styled.InfoRow>
						<Styled.Divider />
					</>
				)}
				<Styled.InfoRow>
					<Styled.InfoItem>
						<Styled.InfoTitle>{t('generic-address')}</Styled.InfoTitle>
						<Styled.InfoText isClamp={false}>{event?.streetAddress}</Styled.InfoText>
					</Styled.InfoItem>
				</Styled.InfoRow>
				<Styled.TicketType className={isCardClassName}>
					<Styled.Ticket iconName="ticket-type" />
					<Styled.TicketTypeText>{name}</Styled.TicketTypeText>
				</Styled.TicketType>
			</Styled.InfoContainer>
			{!isCard && <Styled.BottomLine />}
		</Styled.TemplateContainer>
	);
};
