import styled from 'styled-components';

import { Button as UnstyledButton } from 'features/common/components/Button';

export const Container = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	margin-left: ${({ theme }) => theme.widths.hiddenSidebar};
	gap: 24px;
	padding: 24px 48px;
	max-width: 1500px;
	width: calc(100% - ${({ theme }) => theme.widths.hiddenSidebar});

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-left: 0;
		width: 100%;
	}
`;

export const Button = styled(UnstyledButton)`
	margin: 0;
	padding: 8px 24px;
	width: 150px;
`;

export const DynamicFlex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
		flex-direction: column;
		gap: 12px;
	}
`;
