import { useFormContext } from 'react-hook-form';

import { TextInputProps } from './types';

import { Label, Span } from './TextInput.styles';
import {
	Error,
	Input,
	InputContainer,
} from 'features/common/components/Form/Form.styles';

export const TextInput: React.FC<TextInputProps> = ({
	id,
	name,
	label,
	type,
	staticValue,
	...rest
}: TextInputProps) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	const error = errors[name];

	return (
		<InputContainer hasError={Boolean(error)} type={type}>
			<Label htmlFor={id ?? name}>{label}</Label>

			{staticValue === '' ? (
				<Input id={id ?? name} {...register(name)} {...rest} />
			) : (
				<Span id={`add-member-${staticValue}`}>{staticValue}</Span>
			)}
			<Error>{error?.message}</Error>
		</InputContainer>
	);
};
