import styled from 'styled-components';

import { Modal } from 'features/common/components/Modal';
import { Button } from 'features/common/components/Button';

import { BodyContainer } from 'features/common/components/Modal/Modal.styles';

export const BodyWrapper = styled(BodyContainer)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	margin: auto;
	max-width: 320px;
	padding: 48px 25px 200px 25px;
	white-space: normal;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px 24px 160px 24px;
		gap: 12px;
	}
`;

export const ActionsWrapper = styled(Modal.ActionButtons)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;

	padding: 24px 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column-reverse;
		gap: 16px;
	}
`;

export const ModalButton = styled(Button)`
	border-radius: 8px;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin: 0;
	padding: 8px 22px;
	width: 160px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: 320px;
		width: 85%;
	}
`;
