import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'features/common/components/Header';
import { ModalDelete } from '../ModalDelete';
import { EventTitleProps } from './types';

import { SubTitle, Title } from './EventTitle.styles';

const EventTitle: FC<EventTitleProps> = ({ title }: EventTitleProps) => {
	const { t } = useTranslation();

	return (
		<Header modal={<ModalDelete />}>
			<Title element="h2">{title}</Title>
			<SubTitle element="p">{t('editEvent-subtitle')}</SubTitle>
		</Header>
	);
};

const memorizedEventTitle: FC<EventTitleProps> = memo(EventTitle);

export { memorizedEventTitle as EventTitle };
