import { FC } from 'react';

import { DropdownContainerProps } from './types';
import { Container, DesktopContainer, Icon } from './DropdownContainer.styles';

export const DropdownContainer: FC<DropdownContainerProps> = ({
	isOpened,
	clickHandler,
	filterName,
	secondaryStyle = false,
	iconName = secondaryStyle ? 'arrowDownGrey' : 'arrowDown',
	width,
	refProp,
	children,
}) => {
	return (
		<DesktopContainer
			isOpened={isOpened}
			onClick={clickHandler}
			ref={refProp}
			width={width}
			secondaryStyle={secondaryStyle}
		>
			<Container>
				<span>{filterName}</span>
				{!isOpened && <Icon iconName={iconName} />}
			</Container>
			{children}
		</DesktopContainer>
	);
};
