import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { ButtonsProps } from './types';
import { setTicketsSelected } from 'store/slices/BoxOfficeSlice';
import { useAppDispatch } from 'store/hooks';

import { Button } from '../OrderSummary.styles';

export const Buttons = ({ atLeastOne, isFree }: ButtonsProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { watch } = useFormContext();

	return (
		<FlexContainer flexDirection="column" gap="16px">
			<FlexContainer justifyContent="center">
				<Button
					text={t(isFree && atLeastOne ? 'boxOffice-freeTicket' : 'button-continue')}
					onClick={() => dispatch(setTicketsSelected(true))}
					disabled={!atLeastOne || !watch('email')}
					type="button"
				/>
			</FlexContainer>
		</FlexContainer>
	);
};
