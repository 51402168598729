import styled from 'styled-components';
import { Text } from 'features/common/components/Text';
import { isCheckedProps } from 'features/common/components/EventCardComponent/types';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Title = styled(Text)`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: ${({ theme }) => theme.colors.white};
`;

export const Subtitle = styled(Text)`
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	color: ${({ theme, isChecked }: isCheckedProps) => (isChecked ? theme.colors.green : theme.colors.lightGrey4)};
`;
export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 20px;
	}
`;

export const ContainerRow = styled.div`
	display: flex;
	gap: 20px;
	align-items: flex-end;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 5px;
	}
`;

export const Row = styled.div`
	display: flex;
	gap: 20px;
	flex-wrap: wrap;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		justify-content: flex-start;
		gap: 20px;
		flex-direction: column;
	}
`;

export const ContentContainer = styled.div`
	border-radius: 4px;
	margin-top: 12px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 20px 0px;
	}
`;
