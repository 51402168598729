import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Input } from '../Input';
import { ToolTip } from 'features/common/components/InfoTooltip';
import { Icon } from 'features/common/components/Icon';
import { ToggleDiscount } from 'features/common/components/ToggleDiscount';
import { TicketsQuantity } from '../TicketsQuantity';
import { LeftFormProps } from './types';

import { Wrapper } from './LeftForm.styles';

const LeftForm: React.FC<LeftFormProps> = ({ type }: LeftFormProps) => {
	const { t } = useTranslation();
	const { setValue, getValues, watch } = useFormContext();
	const onChangeEndDate = () => setValue('isEndDate', !getValues('isEndDate'));
	const onChangeDiscount = () => setValue('cuponType', getValues('cuponType') === 'percentage' ? 'flat' : 'percentage');

	return (
		<>
			<Wrapper>
				<Input
					title="promoCode-Form-input-name"
					type="text"
					tooltip={
						<ToolTip
							title={t('promoCode-Form-name-tooltip-title')}
							text={t('promoCode-Form-name-tooltip-description')}
							className="topForm"
						>
							<Icon iconName="help" />
						</ToolTip>
					}
					name="promocodeName"
					id="promocodeName"
				/>
				<Input
					title="promoCode-Form-input-discount"
					type="number"
					name="discountPercentage"
					id="discountPercentage"
					toggleDiscount={
						<ToggleDiscount
							isPercentage={watch('cuponType') === 'percentage'}
							handleChange={onChangeDiscount}
							name="toggle"
						/>
					}
				/>
				<Input
					title="ticketDetails-ticket-active-date"
					type="date"
					tooltip={
						<ToolTip title={t('promoCode-Form-activeDate-title')} text={t('promoCode-Form-activeDate-description')}>
							<Icon iconName="help" />
						</ToolTip>
					}
					name="startDate"
					id="startDate"
					value={getValues('startDate')[0].start_date}
				/>
				<Input
					title="ticketDetails-ticket-active-time"
					type="time"
					name="startDate"
					id="startDate"
					value={getValues('startDate')[0].start_date}
				/>
				<Input
					title="ticketDetails-ticket-stop-date"
					type="date"
					toggle
					toggleValue={watch('isEndDate')}
					handleToggle={onChangeEndDate}
					tooltip={
						<ToolTip title={t('promoCode-Form-endDate-title')} text={t('promoCode-Form-endDate-description')}>
							<Icon iconName="help" />
						</ToolTip>
					}
					name="endDate"
					id="endDate"
					value={getValues('endDate')[0].end_date.getFullYear() === 1970 ? null : getValues('endDate')[0].end_date}
				/>
			</Wrapper>
			<TicketsQuantity type={type} />
		</>
	);
};

export { LeftForm };
