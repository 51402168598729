import FontPicker from 'font-picker-react';

import styled from 'styled-components';

export const ContentContainer = styled.div`
	div {
		box-shadow: unset !important;
		width: 100%;
		> button {
			all: unset;
			display: flex;
			cursor: pointer;
			border-radius: 8px;
			border: ${({ theme }) => `1px solid ${theme.colors.grey8}`};
			height: 40px !important;
			background-color: transparent !important;
			padding-right: 16px !important;
			> p {
				&.dropdown-icon {
					all: unset !important;
					&:before {
						border: solid ${({ theme }) => theme.colors.white} !important;
						border-width: 0 2px 2px 0 !important;
						padding: 2px !important;
						transform: rotate(45deg);
						-webkit-transform: rotate(45deg);
					}
				}
			}
		}
		> ul {
			width: 100%;
			background-color: ${({ theme }) => theme.colors.blackGrey} !important;
			border-radius: 8px;

			> li {
				> button {
					&:hover {
						background: none;
					}
					&.active-font {
						background: transparent;
						opacity: 0.5;
					}
				}
			}
		}
	}
	margin-top: 12px;
`;

export const MyFontPicker = styled(FontPicker)``;
