import styled from 'styled-components';

import { Form } from 'features/common/components/Form';
import { Text } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';

export const Subtitle = styled(Text)`
	font-size: 24px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 12px;
		line-height: 16px;
	}
`;

export const FormContainer = styled(Form)`
	display: flex;
	margin-left: ${({ theme }) => theme.widths.hiddenSidebar};
	width: calc(100% - ${({ theme }) => theme.widths.hiddenSidebar});

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		flex-direction: column;
		align-items: center;
	}
`;

export const StickyDiv = styled.div`
	background: ${({ theme }) => theme.colors.verticalUpGreyGradient2};
	display: flex;
	gap: 16px;
	justify-content: center;
	padding: 48px 0;
	position: sticky;
	bottom: 0;
	width: 100%;
`;

export const FormButton = styled(Button)`
	background-color: ${({ theme }) => theme.colors.blackGrey};
	font-size: 18px;
	font-weight: 600;
	line-height: normal;
	margin: 0;
	padding: 8px 16px;
	max-width: 300px;
`;
