import styled from 'styled-components';
import { Text as UnstyledText } from 'features/common/components/Text';
import { MenuItemStylesProps } from '../../types';

export const StyledMenuItem = styled.div<MenuItemStylesProps>`
	align-items: center;
	cursor: pointer;
	display: flex;
	gap: 29px;
	font-size: 14px;
	width: 100%;
	padding: 16px;
	box-sizing: border-box;
	justify-content: center;
	color: ${({ isActive, theme }) => (isActive ? theme.colors.white : theme.colors.darkGrey2)};
	font-weight: 500;
`;

export const Text = styled(UnstyledText)`
	font-weight: 600;
	font-size: 16px;
	line-height: 26px;
`;
