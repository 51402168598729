import { useEffect } from 'react';

import { Embed as EmbedComponent } from 'features/Embed';
import { ROUTES } from 'features/routes';
import { sendGAPage } from 'utils/utils';

const Embed = (): JSX.Element => {
	useEffect(() => {
		sendGAPage({ page: ROUTES.EMBED_PATH, title: 'Website Page' });
	}, []);

	return <EmbedComponent />;
};
export default Embed;
