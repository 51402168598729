import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { MainHeader } from 'features/common/components/MainHeader';
import { MainLayout } from 'layouts/MainLayout';
import { ErrorModal } from './components/ErrorModal/ErrorModal';
import { SendTicketsForm } from './components/SendTicketsForm';
import { SendTicketsConfirmation } from './components/SendTicketsConfirmation';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { initialFormData, validationSchema, handleSubmit } from './utils';
import { IdParamsType } from 'utils/types';
import { checkTicketsAvailability, getEvent, sendTickets } from './services';
import { useToastNotification } from 'hooks/useToastNotification';
import { ROUTES } from 'features/routes';
import { Icon } from 'features/common/components/Icon';
import { NestedTicketObject } from './types';

import * as Styled from './SendTickets.styles';

export const SendTickets = () => {
	const { t } = useTranslation();
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

	const { showNotification, TYPES } = useToastNotification();
	const { push } = useHistory();
	const formMethods = useForm({
		defaultValues: initialFormData,
		resolver: yupResolver(validationSchema(t)),
	});
	const isLargeScreen = useResponsiveScreen();
	const { id } = useParams<IdParamsType>();

	const { data: event, refetch } = useQuery({
		queryKey: ['event', id],
		queryFn: () => getEvent(id),
		enabled: !!id,
	});

	const closeModal = () => {
		setIsErrorModalOpen(false);
		refetch();
	};

	const { mutate: mutateCheckAvailability } = useMutation({
		mutationFn: async (tickets: NestedTicketObject) => {
			try {
				const data = await checkTicketsAvailability(tickets);
				mutateSendTickets(formMethods.getValues());
				return data;
			} catch {
				setIsErrorModalOpen(true);
			}
		},
	});

	const { mutate: mutateSendTickets } = useMutation({
		mutationFn: sendTickets,
		onSuccess: () => {
			push(ROUTES.COMPED_TICKETS.replace(':id', id));
			showNotification({
				message: t('send-tickets-success'),
				type: TYPES.SUCCESS,
			});
		},
		onError: () =>
			showNotification({
				message: t('send-tickets-error'),
				type: TYPES.ERROR,
			}),
	});

	return (
		<MainLayout>
			{isLargeScreen && (
				<MainHeader gap="12px">
					<Icon
						iconName="arrowLeft"
						onClick={() => push(generatePath(ROUTES.DASHBOARD_PATH, { id }))}
						cursor="pointer"
					/>
					<Styled.Title element="h1"> {t('send-tickets-title')} </Styled.Title>
				</MainHeader>
			)}
			<Styled.FormContainer onSubmit={(data) => handleSubmit(data, mutateCheckAvailability)} formMethods={formMethods}>
				<SendTicketsForm event={event} />
				<SendTicketsConfirmation />
			</Styled.FormContainer>
			{isErrorModalOpen && <ErrorModal closeModal={closeModal} />}
		</MainLayout>
	);
};
