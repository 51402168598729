import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from 'features/common/components/Divider';
import { OrgImagePopupProps } from './types';

import { WrapperOptions, Option, Icon, InputFile, Label } from './OrgImagePopup.styles';

export const OrgImagePopup: FC<OrgImagePopupProps> = ({
	onClickDelete,
	onChangeUpload,
	innerRef,
	inHomePage = true,
}) => {
	const { t } = useTranslation();
	return (
		<WrapperOptions ref={innerRef} inHomePage={inHomePage}>
			<Option onClick={onClickDelete}>
				<Icon iconName="trash" />
				{t('companyLogo-delete')}
			</Option>
			<Divider />
			<Option>
				<Label htmlFor="input-file">
					<Icon iconName="image" />
					{t('companyLogo-replace')}
					<InputFile
						id="input-file"
						name="image"
						type="file"
						accept="image/jpeg, image/png"
						onChange={onChangeUpload}
					/>
				</Label>
			</Option>
		</WrapperOptions>
	);
};
