import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon as Trash } from 'features/common/components/Icon';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { TERTIARY } from 'features/common/components/Button/constants';
import { ModalDelete } from 'features/common/components/ModalDelete';
import { useTermsData } from 'TermsOfSerice/hooks/useTermsData';

import { Button, DeleteButton } from './DeleteModal.styles';

export const DeleteModal = () => {
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const { deleteTerms } = useTermsData();

	const [showModal, setShowModal] = useState(false);

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	return (
		<>
			{showModal && (
				<ModalDelete
					modalInfo={{
						modalTitle: t('termOfService-deleteModal-title'),
						modalMessage: t('termOfService-deleteModal-info'),
						onDelete: () => {
							closeModal();
							deleteTerms.mutate();
						},
					}}
					showModal={showModal}
					closeModal={closeModal}
				/>
			)}
			{isLargeScreen ? (
				<Button category={TERTIARY} onClick={openModal} text={t('button-delete')} />
			) : (
				<DeleteButton onClick={openModal}>
					<Trash iconName="trash" />
				</DeleteButton>
			)}
		</>
	);
};
