import i18n from 'locals/i18n';

import { TicketOptionsProps } from './components/ButtonGroup/types';

export const ticketOptions: TicketOptionsProps[] = [
	//TODO: uncomment when they ask for ticket library
	{
		icon: 'templates',
		info: i18n.t('generic-imgRatio-template'),
		text: i18n.t('generic-templates'),
		type: 'template',
	},
	// { text: 'Ticket library', type: 'library', icon: 'library' },
	{
		icon: 'upload',
		info: i18n.t('generic-imgRatio-upload'),
		text: i18n.t('generic-upload'),
		type: 'upload',
	},
];
