import styled from 'styled-components';
import { Text } from 'features/common/components/Text';
import { TextWrapProps } from './types';

import { LogoContainer } from '../../MyProfile.styles';

export const InitialContainer = styled(LogoContainer)`
	align-items: center;
	background: ${({ theme }) => theme.colors.black};
	display: flex;
	justify-content: center;
`;

export const Initials = styled(Text)`
	font-weight: 600;
	font-size: 48px;
	line-height: 72px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 23.4px;
		line-height: 35px;
	}
`;

export const TextWrap = styled(Text)<TextWrapProps>`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	width: 100%;
	word-wrap: break-word;
	text-transform: lowercase;
`;
