import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Form } from 'features/common/components/Form';
import { ROUTES } from 'features/routes';
import { DefaultValues, getFormDetails } from './utils';
import { uploadImage } from 'services/UploadImage/uploadImage';
import { createEvent } from './services';
import { EventModeProp, ImageBackType, NewImageType } from './types';
import { sendGAEvent } from 'utils/utils';
import { EventSummary } from './EventSummary';
import { toastError } from 'hooks/useToastNotification/useToastNotification';
import { EVENT_MODE_NORMAL } from 'utils/constants';

export const CreateEventForm = ({ eventMode }: EventModeProp) => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const { CREATE_TICKET_PATH } = ROUTES;

	const formMethods = useForm({
		defaultValues: DefaultValues,
		resolver: getFormDetails(eventMode),
		values: {
			...DefaultValues,
			mode: eventMode,
		},
	});

	useEffect(() => {
		formMethods.clearErrors();
	}, [eventMode]);

	const { mutate: mutateCreateEvent } = useMutation(createEvent, {
		onSuccess: ({ data: { ID } }) => {
			sendGAEvent({ category: 'Event Creation', action: 'Create event success' });
			push(
				eventMode === EVENT_MODE_NORMAL
					? generatePath(CREATE_TICKET_PATH, { eventId: ID })
					: generatePath(ROUTES.CREATE_TICKET_PATH, { eventId: ID }) + '#19'
			);
		},
		onError: () => {}, //TODO: do something
	});

	const { mutateAsync: mutateUploadImage } = useMutation(uploadImage, {
		onSuccess: ({ data }) => {
			const newValues: NewImageType[] = [
				...formMethods.getValues('new_images'),
				{
					image: data.image_url,
					position: data.index + 1,
					main_image: !data.index,
				},
			];
			formMethods.setValue('new_images', newValues);
		},
		onError: () => toastError(t('error-uploading-image')),
	});

	const submitForm = (currentValues: { images: ImageBackType[]; name: string }) => {
		Promise.all(
			currentValues.images.map((image, index) =>
				mutateUploadImage({
					image,
					prefix: currentValues.name,
					index,
					type: 'event',
				})
			)
		).then(() => {
			const currentValuesAfter = formMethods.getValues();
			mutateCreateEvent({ values: currentValuesAfter });
		});
	};

	return (
		<Form formMethods={formMethods} onSubmit={submitForm} width="100%">
			<EventSummary eventMode={eventMode} />
		</Form>
	);
};
