import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import { EllipseProps } from './types';
import { Icon } from 'features/common/components/Icon';

export const Ellipse = styled.div<EllipseProps>`
	background-color: ${({ color, theme }) => color || theme.colors.grey6};
	height: ${({ height }) => height};
	width: ${({ width }) => width};
	border-radius: 50%;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;

	&.hover {
		&:hover {
			background-color: ${({ color, theme }) =>
				color ? tinycolor(color).lighten(50).toString() : tinycolor(theme.colors.grey6).lighten(50).toString()};
		}
	}
`;

export const CustomIcon = styled(Icon)`
	height: 60%;
	width: 60%;
`;
