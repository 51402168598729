import styled from 'styled-components';

import { common } from 'theme';
import { Text as UnstyledText } from 'features/common/components/Text';
import { UploadImageCardContainerProps, StyledCircleProps, TicketTypeOptionsProps, SizeProps } from './types';

const palette = common.colors;

const getUploadImageCardBackground = (type: TicketTypeOptionsProps, hover = false) => {
	const colors = {
		upload: hover ? palette.crimsonGradientHover : palette.crimsonGradient,
		designer: hover ? palette.greenGradient2Hover : palette.greenGradient2,
		template: hover ? palette.purpleGradientHover : palette.purpleGradient,
		library: hover ? palette.lightBlueGradientHover : palette.lightBlueGradient,
	};
	return colors[type];
};

const getCircleBackground = (type: TicketTypeOptionsProps) => {
	const colors = {
		upload: palette.crimson1,
		designer: palette.green6,
		template: palette.purple4,
		library: palette.lightBlue1,
	};
	return colors[type];
};

export const CardContainer = styled.div<UploadImageCardContainerProps>`
	box-sizing: border-box;
	gap: 8px;
	height: ${({ size }) => (size === 'S' ? '170px' : '200px')};
	display: flex;
	cursor: pointer;
	min-width: 225px;
	max-width: 500px;
	min-height: ${({ size }) => (size === 'S' ? '170px' : '200px')};
	position: relative;
	border-radius: 8px;
	align-items: center;
	flex-direction: column;
	box-sizing: border-box;
	justify-content: center;
	background: ${({ type }) => getUploadImageCardBackground(type)};
	flex: 1 1 auto;
	padding: 0px 24px;

	&:after {
		background: ${({ type }) => getUploadImageCardBackground(type, true)};
		border-radius: 8px;
		content: '';
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		opacity: 0;
		transition: 300ms ease-out;
		position: absolute;
		z-index: -1;
	}

	&:hover:after {
		opacity: 1;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 170px;
		min-height: 170px;
	}
`;

export const Circle = styled.div<StyledCircleProps>`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	border-radius: 100px;
	justify-content: center;
	background: ${({ type }) => getCircleBackground(type)};
`;

export const Text = styled(UnstyledText)`
	font-size: 20px;
	font-weight: 400;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
	}
`;

export const Info = styled(UnstyledText)<SizeProps>`
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	text-align: center;
	width: ${({ size }) => (size === 'S' ? '175px' : '100%')};
`;
