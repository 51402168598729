import { ShortWeekDayType, WeekDayType } from './UpcomingEvents/types';

export const MONDAY = 'monday';
export const TUESDAY = 'tuesday';
export const WEDNESDAY = 'wednesday';
export const THURSDAY = 'thursday';
export const FRIDAY = 'friday';
export const SATURDAY = 'saturday';
export const SUNDAY = 'sunday';
export const SHORT_MONDAY = 'mon';
export const SHORT_TUESDAY = 'tues';
export const SHORT_WEDNESDAY = 'wed';
export const SHORT_THURSDAY = 'thur';
export const SHORT_FRIDAY = 'fri';
export const SHORT_SATURDAY = 'sat';
export const SHORT_SUNDAY = 'sun';

export const WEEKDAYS: { [key: string]: WeekDayType } = {
	monday: MONDAY,
	tuesday: TUESDAY,
	wednesday: WEDNESDAY,
	thursday: THURSDAY,
	friday: FRIDAY,
	saturday: SATURDAY,
	sunday: SUNDAY,
};

export const SHORT_WEEKDAYS: { [key: string]: ShortWeekDayType } = {
	monday: SHORT_MONDAY,
	tuesday: SHORT_TUESDAY,
	wednesday: SHORT_WEDNESDAY,
	thursday: SHORT_THURSDAY,
	friday: SHORT_FRIDAY,
	saturday: SHORT_SATURDAY,
	sunday: SHORT_SUNDAY,
};
