import { useFormContext } from 'react-hook-form';

import { InputLabel } from './InputLabel';
import { RadioButtonListProps } from './types';
import { RadioButton } from '../RadioButton/RadioButton.styles';

import { Error, ListContainer, OptionContainer, RadioButtonLabel, RadioButtonListContainer } from './Form.styles';

const RadioButtonList = ({
	id,
	name,
	label,
	options,
	Tooltip,
	className,
	variant = 'primary',
	background,
	gap,
	...rest
}: RadioButtonListProps) => {
	const {
		formState: { errors },
		register,
		setValue,
	} = useFormContext();

	const error = errors[name];

	return (
		<RadioButtonListContainer variant={variant}>
			{label && variant !== 'tertiary' && (
				<InputLabel htmlFor={id ?? name} label={label} Tooltip={Tooltip} className={className} variant={variant} />
			)}
			<ListContainer variant={variant} background={background} gap={gap}>
				{options.map(({ value, optionLabel }) => (
					<OptionContainer key={optionLabel} variant={variant}>
						<RadioButton type="radio" id={value} value={value} {...rest} {...register(id ?? name)} />
						<RadioButtonLabel htmlFor={id ?? name} onClick={() => setValue(id, value)}>
							{optionLabel}
						</RadioButtonLabel>
					</OptionContainer>
				))}
			</ListContainer>
			<Error>{error?.message}</Error>
		</RadioButtonListContainer>
	);
};

export { RadioButtonList };
