import { FC } from 'react';
import { Icon } from 'features/common/components/Icon';
import { DeleteButtonProps } from './types';

import { DeleteButtonContainer } from './RightForm.styles';

const DeleteButton: FC<DeleteButtonProps> = ({ onClick }: DeleteButtonProps) => {
	return (
		<DeleteButtonContainer type="button" onClick={onClick} data-cy="trash-btn">
			<Icon iconName="trashGrey" />
		</DeleteButtonContainer>
	);
};

export { DeleteButton };
