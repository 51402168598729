export const COLUMN_HEADINGS = [
	'ordersPage-table-name',
	'ordersPage-table-email',
	'ordersPage-table-event',
	'ordersPage-table-ticket-type',
	'ordersPage-table-quantity',
	'ordersPage-table-value',
	'ordersPage-table-date',
	'ordersPage-table-status',
];

export const COLUMN_MIN_WIDTH = [200, 200, 160, 160, 160, 160, 250, 160];

export const BROWSER_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
