import { FC, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { useResponsiveScreen } from 'hooks/useWindowSize';
import { ConditionalWrapper } from 'features/common/components/ConditionalWrapper';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { memberRolePut } from 'features/Settings/services';
import { useClickAway } from 'features/common/hooks/useClickAway';
import { Icon } from 'features/common/components/Icon';
import { DropdownContainer } from 'features/common/components/DropdownContainer';
import { ROLE_OPTIONS } from 'features/Settings/constants';
import { convertToObj } from '../../../../utils';
import { RoleSelectProps } from './types';
import { RoleType } from 'features/Settings/types';

import { DropDownList, ListItem, Span } from './RoleSelect.styles';
import { Label } from '../MemberModal/TextInput/TextInput.styles';

export const RoleSelect: FC<RoleSelectProps> = ({ memberData, secondaryStyle = false, isForm = true }) => {
	const { roles, member_id: memberId } = memberData;
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedRole, setSelectedRole] = useState(convertToObj(roles));
	const container = useRef(null);
	useClickAway(container, () => setIsOpen(false));
	const { setValue } = useFormContext() || {};
	const { mutate: changeRole } = useMutation({
		mutationFn: memberRolePut,
	});

	const handleOptionClick = (newRole: RoleType) => {
		if (isForm) {
			setSelectedRole(newRole);
			setValue('roles', newRole.value);
		} else changeRole({ memberData, newRole: newRole.value });
	};
	const toggleDropdown = isLargeScreen || isForm ? () => setIsOpen((prev) => !prev) : () => {};
	return (
		<ConditionalWrapper
			condition={isForm}
			wrapper={(children) => (
				<FlexContainer flexDirection="column" width="100%">
					<Label htmlFor={`role-${memberId}`}>{t('settings-tableContainer-role')}</Label>
					{children}
				</FlexContainer>
			)}
		>
			<DropdownContainer
				id={`role-${memberId}`}
				refProp={container}
				isOpened={isOpen}
				clickHandler={toggleDropdown}
				filterName={selectedRole.label}
				secondaryStyle={secondaryStyle}
				width={secondaryStyle ? '105px' : '100%'}
			>
				{isOpen && (
					<DropDownList secondaryStyle={secondaryStyle}>
						{ROLE_OPTIONS.map((role: RoleType, i: number, array: RoleType[]) => {
							const lastIndicator = i === array.length - 1 ? 'last' : '';
							const isChecked = selectedRole.label === role.label;
							return (
								<ListItem key={role.label} className={lastIndicator} onClick={() => handleOptionClick(role)}>
									{isChecked && <Icon iconName="checkGreen" />}
									<Span isChecked={isChecked}>{role.label}</Span>
								</ListItem>
							);
						})}
					</DropDownList>
				)}
				{isForm && <input type="hidden" value={selectedRole.value} />}
			</DropdownContainer>
		</ConditionalWrapper>
	);
};
