import { UseFormReset } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CustomFieldType, EventType } from 'utils/types';
import { deleteData, getData, post, put } from 'services/api';
import { createRequestBody, editFormValues } from './utils';
import { CustomFieldFormProps, ParamsTypes } from './types';
import { useToastNotification } from 'hooks/useToastNotification';

export const useCustomFieldsApi = (resetForm: UseFormReset<CustomFieldFormProps>) => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const { id, eventId } = useParams<ParamsTypes>();
	const { showNotification, TYPES } = useToastNotification();

	const onSuccess = () => {
		showNotification({ message: t('success'), type: TYPES.SUCCESS });
		goBack();
	};

	const onError = () => showNotification({ message: t('error'), type: TYPES.ERROR });

	const { data: event } = useQuery({
		queryKey: ['event', eventId],
		queryFn: (): Promise<EventType> => getData(`public/ticketing/events/${eventId}`),
		enabled: !!eventId,
	});

	const { mutate: createCustomField } = useMutation({
		mutationFn: (reqBody: CustomFieldFormProps) => post(`/events/${eventId}/custom-fields`, createRequestBody(reqBody)),
		onSuccess,
		onError,
	});

	const { mutate: updateCustomField } = useMutation({
		mutationFn: (reqBody: CustomFieldFormProps) => put(`/custom-fields/${id}`, createRequestBody(reqBody)),
		onSuccess,
		onError,
	});

	const { mutate: deleteCustomField } = useMutation({
		mutationFn: () => deleteData(`/custom-fields/${id}`),
		onSuccess,
		onError,
	});

	const { data: customField } = useQuery({
		queryKey: ['custom-field', id],
		queryFn: () => getData(`/public/ticketing/custom-fields/${id}`),
		enabled: !!id,
		onSuccess: (data: CustomFieldType) => resetForm(editFormValues(data)),
	});

	return { event, createCustomField, customField, updateCustomField, deleteCustomField };
};
