import styled from 'styled-components';
import { Text } from 'features/common/components/Text';

export const UploadImageContainer = styled.div`
	gap: 16px;
	display: flex;
	flex-direction: column;
	padding: 10px 0;
`;

export const AddImageText = styled(Text)`
	font-size: 20px;
	font-weight: 600;
	line-height: 16px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
	}
`;

export const AddImageDescription = styled(Text)`
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	text-transform: none;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
		line-height: 16px;
	}
`;

export const SizeRecommendation = styled(Text)`
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	text-transform: none;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 12px;
		line-height: 16px;
	}
`;

export const Row = styled.div`
	gap: 16px;
	display: flex;
	align-items: center;
`;
