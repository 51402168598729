export const CONFIRMED_OPTIONS = [
	{ text: 'settings-mobileOptionsModal-edit', icon: 'pencilGreen' },
	{ text: 'settings-mobileOptionsModal-reset', icon: 'lock' },
	{ text: 'settings-mobileOptionsModal-delete', icon: 'trashRed' },
];

export const PENDING_OPTIONS = [
	{ text: 'settings-memberTable-resend-invite', icon: 'user' },
	{ text: 'settings-mobileOptionsModal-delete', icon: 'trashRed' },
];
