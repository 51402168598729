import styled from 'styled-components';

export const Iframe = styled.iframe`
	height: 100%;
	width: 100%;
	padding-left: ${({ theme }) => theme.widths.hiddenSidebar};
	border: none;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding-left: 0;
	}
`;
