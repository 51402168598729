import styled from 'styled-components';

export const DotsCanvas = styled.canvas`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	position: absolute;
`;
