import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Divider } from 'features/common/components/Divider';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { OUTLINED } from 'features/common/components/Button/constants';
import { INPUT_ARRAY } from './utils';
import { CampaignFormProps } from './types';

import { Container, FormText, StrongText } from './CampaignForm.styles';
import { FormButton } from '../../ManageEmailCampaign.styles';

export const CampaignForm = ({ emailsSent, monthlyAllowance, handleClick, isLargeScreen }: CampaignFormProps) => {
	const { t } = useTranslation();

	return (
		<Container>
			{INPUT_ARRAY.map(({ Component, label, ...rest }) => (
				<Fragment key={label}>
					<Component label={t(label)} {...rest} />
					<Divider />
				</Fragment>
			))}
			<FormText> {t('marketing-campaignForm-schedule')}</FormText>
			<FormText isSmall>
				<Trans>
					We will start this campaign immediately. Tixologi allows you to send {{ monthlyAllowance }} emails per month.
					<br />
					You have sent{' '}
					<StrongText isSmall>
						{{ emailsSent }}/{{ monthlyAllowance }}{' '}
					</StrongText>
					emails so far this month.
				</Trans>
			</FormText>
			{isLargeScreen && (
				<FlexContainer gap="16px" justifyContent="center" margin="100px 0 56px 0">
					<FormButton
						category={OUTLINED}
						onClick={() => handleClick({ status: 'DRAFT' })}
						text={t('marketing-campaignForm-saveDraft')}
						type="submit"
					/>
					<FormButton text={t('button-send')} type="submit" onClick={() => handleClick({ status: 'CREATED' })} />
				</FlexContainer>
			)}
		</Container>
	);
};
