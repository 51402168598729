import styled from 'styled-components';

import { Button } from 'features/common/components/Button';
import { Modal } from 'features/common/components/Modal';
import { Text } from 'features/common/components/Text';

export const ModalBody = styled(Modal.Body)`
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: center;
	padding: 48px 96px;
`;

export const CustomText = styled(Text)`
	font-weight: 400;
	font-size: 16px;
	line-height: normal;
	text-align: center;
	text-transform: none;
`;

export const ButtonWrap = styled(Modal.ActionButtons)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
	padding: 24px;
`;

export const ModalButton = styled(Button)`
	font-size: 16px;
	font-weight: 600;
	margin: 0;
	width: 175px;
`;
