import { UseQueryResult } from '@tanstack/react-query';
import { DateFilterEnumPage } from 'features/common/hooks/useFilterButtonClick';
import { AllPages } from 'features/common/hooks/useFilterButtonClick';
import { PerPageState, selectedPeriod } from '../../types';

export const enum FilterDispatch {
	openDate = 'openDate',
	openEvent = 'openEvent',
	close = 'close',
}
type AllPagesKeys = keyof AllPages;

export interface DropdownProps {
	isOpened: boolean;
	closeFilter: () => void;
	page: AllPagesKeys;
}

export interface DateDropdownProps extends DropdownProps {
	selectedPeriod: selectedPeriod;
	filterOption: DateFilterEnumPage[];
}

export interface EventDropdownProps extends DropdownProps {
	eventQueryObj: UseQueryResult<any[]>;
	perPageState: PerPageState;
}

export interface OptionsContainerStylesProps {
	isOpened: boolean;
}
