import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { GraphDisplayInfo } from '../types';
import { XAxisOption } from 'hooks/useChart/types';

import { Container, Input, Label, SelectLabel, GreenBox, MinusIcon, PlusIcon } from './styles';

export const SetTimelineZoom = ({ setDisplay, displayWindow }: GraphDisplayInfo) => {
	const { t } = useTranslation();

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		setDisplay({
			type: 'zoom_input',
			payload: parseInt(e.target.value),
		});
	};

	return (
		<Container>
			<Label>{t('analytics-show')} </Label>&nbsp;
			<GreenBox>
				<MinusIcon disabled={displayWindow.windowSize <= 5} onClick={() => setDisplay({ type: 'zoom_in' })} />
				<Input type="number" value={displayWindow.windowSizeShadow} onChange={onChange} />
				<PlusIcon
					disabled={displayWindow.dataPoints == displayWindow.windowSize}
					onClick={() => setDisplay({ type: 'zoom_out' })}
				/>
			</GreenBox>
			<SelectLabel onChange={(e) => setDisplay({ type: 'set_timeline', payload: e.target.value as XAxisOption })}>
				<option value="days">{t('days')}</option>
				<option value="weeks">{t('weeks')}</option>
			</SelectLabel>
		</Container>
	);
};
