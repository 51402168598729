import styled from 'styled-components';

import { Text } from 'features/common/components/Text';

export { Button, FormContainer, Line, Title } from '../LoginForm/LoginForm.styles';

export const Description = styled(Text)`
	color: ${({ theme }) => theme.colors.white};
	font-weight: 400;
	font-size: 17px;
	line-height: 25.5px;
	text-transform: initial;
`;

export const RelativeWrap = styled.div`
	position: relative;
`;

export const ErrorWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
`;
