import { FC, memo } from 'react';
import { Icon } from 'features/common/components/Icon';
import { TicketInfoItemProps } from './types';

import {
	TicketInfoItemContainer,
	TicketInfoItemText,
} from './TicketCard.styles';

const TicketInfoItem: FC<TicketInfoItemProps> = ({
	icon,
	label,
}: TicketInfoItemProps) => (
	<TicketInfoItemContainer>
		<Icon iconName={icon} />
		<TicketInfoItemText>{label}</TicketInfoItemText>
	</TicketInfoItemContainer>
);

const memorizedTicketInfoItem: FC<TicketInfoItemProps> = memo(TicketInfoItem);

export { memorizedTicketInfoItem as TicketInfoItem };
