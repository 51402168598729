import { ADMIN_QUERY } from 'features/Settings/constants';
import { MemberData } from 'features/Settings/types';
import { MemberModalState } from './types';

export const COLUMN_HEADERS = [
	'settings-tableContainer-name',
	'settings-tableContainer-email',
	'settings-tableContainer-role',
	'settings-tableContainer-options',
];

export const COLUMN_WIDTH = [225, 265, 125, 400];

export const GRID_COLUMNS = '2fr 2fr 1fr 2fr';

export const INITIAL_MEMBER: MemberData = {
	member_id: 0,
	email: '',
	full_name: '',
	roles: ADMIN_QUERY,
	status: false,
};

export const EDIT_MEMBER = 'edit';
export const ADD_MEMBER = 'add';
export const INITIAL_MEMBER_MODAL: MemberModalState = {
	isOpen: false,
	type: '',
};
