import { FC, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { MenuOptionsProps, MenuOptionProps } from '../../types';
import { MenuItem } from '../MenuItem';

import { Menu, Wrapper } from './MenuOptions.styles';

const MenuOptions: FC<MenuOptionsProps> = ({ options }: MenuOptionsProps) => {
	const { pathname } = useLocation();

	return (
		<Wrapper>
			<Menu>
				{options.map(({ path, name, icon, external }: MenuOptionProps) => {
					const isActive = pathname.includes(path);
					return external ? (
						<a target="_blank" rel="noreferrer" href={path} key={`${name} ${path}`}>
							<MenuItem isActive={isActive} icon={icon} name={name} />
						</a>
					) : (
						<Link key={`${name} ${path}`} to={{ pathname: path }}>
							<MenuItem isActive={isActive} icon={icon} name={name} />
						</Link>
					);
				})}
			</Menu>
		</Wrapper>
	);
};

const memorizedMenuOptions: FC<MenuOptionsProps> = memo(MenuOptions);

export { memorizedMenuOptions as MenuOptions };
