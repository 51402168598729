import styled from 'styled-components';
import { Icon } from 'features/common/components/Icon';

export const BackgroundImage = styled.img`
	width: 100vw;
	height: 100vh;
	display: flex;
	object-fit: cover;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		object-position: left;
		min-height: calc(100vh - 125px);
		max-height: calc(100vh - 125px);
	}
`;

export const BackIcon = styled(Icon)`
	top: 40px;
	left: 30px;
	z-index: 3;
	position: absolute;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		left: 70px;
	}
`;

export const ExitIcon = styled(Icon)`
	top: 40px;
	z-index: 3;
	right: 30px;
	position: absolute;
`;

export const ExitButton = styled.button`
	all: unset;
	cursor: pointer;
`;
