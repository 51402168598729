import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Input } from '../RecipientInfoForm/RecipientInfoForm.styles';
import { QuantityCardProps } from './types';

import { Card, QuantityButton, QuantityText, QuantityIcon, SoldOut } from './QuantityCard.styles';

export const QuantityCard = ({
	type,
	recipientId,
	id,
	initialPrice,
	ticketTypeId,
	sold,
	soldOut,
	amount,
}: QuantityCardProps) => {
	const { t } = useTranslation();
	const { setValue, watch } = useFormContext();
	const name = `recipients[${recipientId}].ticketTypes[${id}]`;
	const quantity = watch(`${name}.quantity`);

	const setQuantity = (newValue: number) => {
		if (newValue + sold > amount) return;
		newValue >= 0 && setValue(`${name}.quantity`, newValue);
	};

	useEffect(() => {
		setValue(name, { type, quantity: 0, initialPrice, ticket_type_id: ticketTypeId });
	}, []);
	return (
		<Card>
			<Input label="Ticket Type" name={`${name}.type`} id={type} value={type} disabled />
			<QuantityText>{t('ticketDetails-quantity')}</QuantityText>
			{soldOut || sold >= amount ? (
				<SoldOut>{t('sold-out')}</SoldOut>
			) : (
				<QuantityButton>
					<QuantityIcon onClick={() => setQuantity(quantity - 1)}>-</QuantityIcon> <div>{quantity}</div>
					<QuantityIcon onClick={() => setQuantity(quantity + 1)}>+</QuantityIcon>
				</QuantityButton>
			)}
		</Card>
	);
};
