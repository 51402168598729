import { useEffect } from 'react';

import { Orders as OrdersComponent } from 'features/Orders';
import { ROUTES } from 'features/routes';
import { sendGAPage } from 'utils/utils';

const Orders = (): JSX.Element => {
	useEffect(() => {
		sendGAPage({ page: ROUTES.ORDERS_PATH, title: 'Orders Page' });
	}, []);

	return <OrdersComponent />;
};
export default Orders;
