import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { ROUTES } from 'features/routes';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { useAudienceData } from './useAudienceData';
import { DropdownInput } from '../DropdownInput';
import { UploadCohort } from 'features/Marketing/ViewEmailCampaigns/components/UploadCohort';
import { InputLabel } from 'features/common/components/Form/InputLabel';
import { calculateTotalEmails, changeValue, controlVisibility, getFilterName } from '../../utils';
import { TargetAudienceProps } from './types';

import { FormText, ListContainer, Option, CheckboxButton } from '../../CampaignForm.styles';

export const TargetAudience = ({ label }: TargetAudienceProps) => {
	const { t } = useTranslation();
	const firstRender = useRef(true);
	const { pathname } = useLocation();
	const isNewCampaign = pathname === ROUTES.CREATE_CAMPAIGN_PATH;
	const { cohortsData, eventsData, search, setSearch, totalPerPage, setPerPage } = useAudienceData();
	const { watch, setValue } = useFormContext();
	const isNotFullPage = cohortsData?.length + eventsData?.length < 5 && totalPerPage > 0;
	const cohortIds: number[] = watch('cohorts');
	const eventIds: number[] = watch('events');
	const allGroupsInit: boolean = watch('allEvents');
	const [selectedEvents, setEvents] = useState(eventIds);
	const [selectedCohorts, setCohorts] = useState(cohortIds);
	const [allGroups, setAllGroups] = useState<boolean>(allGroupsInit);
	const selectedCt = (cohortIds?.length || 0) + eventIds?.length;
	const updateValues = async () => {
		setValue('events', selectedEvents);
		setValue('cohorts', selectedCohorts);
		setValue('allEvents', allGroups);
		calculateTotalEmails({ allGroups, cohortsData, cohortIds: selectedCohorts, eventIds: selectedEvents, setValue });
	};

	useEffect(() => {
		setAllGroups(allGroupsInit);
		setEvents(allGroupsInit ? [] : eventIds);
		setCohorts(allGroupsInit ? [] : cohortIds);
	}, [allGroupsInit, eventIds, cohortIds]);

	useEffect(() => {
		if (firstRender.current && cohortsData && isNewCampaign) {
			calculateTotalEmails({ allGroups, cohortsData, cohortIds: selectedCohorts, eventIds: selectedEvents, setValue });
			firstRender.current = false;
		}
	}, [cohortsData]);

	return (
		<FlexContainer flexDirection="column" gap="16px">
			<FormText>{label}</FormText>
			<FlexContainer flexDirection="column" gap="8px">
				<InputLabel label={t('marketing-campaignForm-audienceInfo')} htmlFor={label} />
				<DropdownInput
					filterName={getFilterName({ allGroups, t, selectedCt })}
					hideViewAll={totalPerPage === -1 || isNotFullPage || !!search}
					id={label}
					setPerPage={setPerPage}
					setSearch={setSearch}
					updateValues={updateValues}
					validationName="audience"
				>
					<ListContainer>
						<Option>
							<CheckboxButton
								type="checkbox"
								checked={allGroups}
								value={t('marketing-campaignForm-allGroups')}
								onChange={() => {
									setAllGroups((prev) => !prev);
									[setEvents, setCohorts].forEach((set) => set([]));
								}}
							/>
							{t('marketing-campaignForm-allGroups')}
						</Option>
						{cohortsData?.map(({ iD, name }, i) => {
							return (
								controlVisibility({ name, i, search, totalPerPage }) && (
									<Option key={iD}>
										<CheckboxButton
											type="checkbox"
											checked={selectedCohorts?.includes(iD)}
											name={name}
											value={name}
											onChange={() => changeValue(setCohorts, iD, setAllGroups)}
										/>
										{name}
									</Option>
								)
							);
						})}
						{eventsData?.map(({ id, name }) => (
							<Option key={id}>
								<CheckboxButton
									type="checkbox"
									checked={selectedEvents?.includes(id)}
									name={name}
									value={name}
									onChange={() => changeValue(setEvents, id, setAllGroups)}
								/>
								{name}
							</Option>
						))}
					</ListContainer>
				</DropdownInput>
			</FlexContainer>
			<UploadCohort />
		</FlexContainer>
	);
};
