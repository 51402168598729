import loginBackground from 'assets/images/loginBackground.svg';
import tixologiLogo from 'assets/images/tixologiLogo.svg';

import {
	Container,
	LeftContainer,
	Logo,
	TicketBackground,
} from './AuthLayout.styles';

export const AuthLayout = ({ children }: { children: React.ReactNode }) => (
	<Container>
		<LeftContainer>
			<TicketBackground src={loginBackground} />
			<Logo src={tixologiLogo} />
		</LeftContainer>
		{children}
	</Container>
);
