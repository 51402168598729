import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { OUTLINED } from 'features/common/components/Button/constants';
import { Modals } from 'features/FanDatabase/Slices/types';
import { OptionsButtonsProps } from './types';
import { Table } from 'features/common/components/Table';
import { useTableClick } from 'features/FanDatabase/hooks/useTableClick';

import { ButtonFlex } from './OptionsButtons.styles';
import { ROUTES } from 'features/routes';

export const OptionsButtons: FC<OptionsButtonsProps> = ({ fanData }) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const buttonInfo = [
		{
			text: 'fanDatabase-refund',
			onClick: () =>
				push(
					ROUTES.REFUNDABLE_TICKETS_PATH.replace(':id', String(fanData.user_id))
				),
		},
		{
			text: 'fanDatabase-identity',
			onClick: useTableClick(Modals.confirmContact, fanData),
		},
		{
			text: 'fanDatabase-reset-password',
			onClick: useTableClick(Modals.reset, fanData),
		},
	];

	return (
		<ButtonFlex>
			{buttonInfo.map((button) => (
				<Table.Button
					key={button.text}
					category={OUTLINED}
					text={t(button.text)}
					onClick={button.onClick}
					type="button"
				/>
			))}
		</ButtonFlex>
	);
};
