import styled from 'styled-components';

import { ConfirmationContainer, BaseWrapStyle } from '../../FansTable.styles';

export const BodyWrapper = styled(BaseWrapStyle)`
	padding: 75px 25px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px;
	}
`;

export const ResetInfoContainer = styled(ConfirmationContainer)`
	padding: 8px 16px;
	flex-direction: column;
`;
