import styled from 'styled-components';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import { ContainerProps } from '../types';

export const Icon = styled(UnstyledIcon)`
	margin-right: 15px;
`;

export const Label = styled.label`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Image = styled.img`
	width: 88px;
	height: 88px;
	border: 1px solid ${({ theme }) => theme.colors.lightGrey2};
	border-radius: 4px;
	object-fit: contain;

	&:hover {
		cursor: pointer;
		background: ${({ theme }) => theme.colors.blackGrey2};
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 60px;
		height: 63px;
	}
`;

export const HoverImage = styled.img`
	position: absolute;
	width: 60px;
	height: 63px;
`;

export const InputFile = styled.input`
	cursor: pointer;
	display: none;

	&:hover {
		cursor: pointer;
	}
`;

export const Container = styled.div<ContainerProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	position: unset;
	width: 88px;
	min-width: 88px;
	height: 88px;
	background: ${({ theme }) => theme.colors.grey2};
	border: 1px solid ${({ theme }) => theme.colors.lightGrey2};
	border-radius: 4px;
	overflow: hidden;

	&:hover {
		cursor: pointer;
		background: ${({ theme }) => theme.colors.blackGrey2};
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		position: unset;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 60px;
		min-width: unset;
		height: 63px;
	}
`;

export const Root = styled.div`
	width: 88px;
	min-width: 88px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: unset;
		min-width: unset;
	}
`;

export const WrapperOptions = styled.div`
	position: absolute;
	z-index: 1;
	width: 238px;
	height: 130px;
	left: 100px;
	top: 124px;
	background: ${({ theme }) => theme.colors.black};
	mix-blend-mode: normal;
	border-radius: 4px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		left: 24px;
		top: 90px;
	}
`;

export const Option = styled.div`
	cursor: pointer;
	display: flex;
	height: 64px;
	align-items: center;
	padding-left: 27px;
	font-family: ${({ theme }) => theme.fonts.primary};
`;
