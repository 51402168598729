import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { queryClient } from 'index';
import { post } from 'services/api';
import moleculesSvg from 'assets/images/Marketing/molecules.svg';
import easySetUpSvg from 'assets/images/Marketing/easySetUp.svg';
import socialMediaSvg from 'assets/images/Marketing/socialMediaLogos.svg';
import marketYourEventsSvg from 'assets/images/Marketing/marketYourEvents.svg';
import { SHOW_NANOS_SCREEN } from '../constants';

import * as Styled from './WelcomeToNanos.styles';

export const WelcomeToNanos = () => {
	const { t } = useTranslation();
	const learnMoreRef = useRef<null | HTMLDivElement>(null);

	const { mutate: postHasVisitedMarketing } = useMutation(() => {
		return post('/partners/marketing', { hasVisitedMarketing: true });
	});

	useEffect(() => {
		postHasVisitedMarketing();
	}, []);

	const onGetStarted = () => {
		queryClient.refetchQueries(SHOW_NANOS_SCREEN);
	};

	const executeScroll = () =>
		learnMoreRef.current && learnMoreRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });

	return (
		<>
			<Styled.WelcomeContainer>
				<Styled.MoleculesImg src={moleculesSvg} alt="Molecules" />
				<Styled.IntroDiv>
					<Styled.H1>{t('marketing-welcome-intro-title')}</Styled.H1>

					<img src={socialMediaSvg} alt="Marketing" />
					<Styled.IntroText>{t('marketing-welcome-intro-text')}</Styled.IntroText>
					<Styled.ButtonsDiv>
						<Styled.LearnMoreButton text={t('marketing-welcome-learn-more')} type="button" onClick={executeScroll} />
						<Styled.GetStartedButton text={t('marketing-welcome-get-started')} type="button" onClick={onGetStarted} />
					</Styled.ButtonsDiv>
				</Styled.IntroDiv>
				<Styled.MoleculesImg src={moleculesSvg} alt="Molecules" className="hide" />
			</Styled.WelcomeContainer>

			<Styled.LearnMoreContainer ref={learnMoreRef}>
				<Styled.IntroDiv>
					<img src={marketYourEventsSvg} alt="Market your events" />
					<Styled.H1>{t('marketing-welcome-events-title')}</Styled.H1>
					<Styled.IntroText>{t('marketing-welcome-events-text')}</Styled.IntroText>
					<Styled.LearnMoreButton text={t('marketing-welcome-get-started')} type="button" onClick={onGetStarted} />
				</Styled.IntroDiv>
			</Styled.LearnMoreContainer>

			<Styled.LearnMoreContainer>
				<Styled.IntroDiv>
					<img src={easySetUpSvg} alt="Easy set up" />
					<Styled.H1>{t('marketing-welcome-setup-title')}</Styled.H1>
					<Styled.IntroText>{t('marketing-welcome-setup-text')}</Styled.IntroText>
					<Styled.LearnMoreButton text={t('marketing-welcome-get-started')} type="button" onClick={onGetStarted} />
				</Styled.IntroDiv>
			</Styled.LearnMoreContainer>
		</>
	);
};
