import { useInfiniteQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { getData } from 'services/api';
import { IdParamsType } from 'utils/types';
import { ResponsesData } from '../types';

export const useEventCustomFields = (isAttendeesList: boolean) => {
	const { id } = useParams<IdParamsType>();

	const [searchText, setSearchText] = useState('');
	const [pageParam, setPageParam] = useState(1);
	const [resultsPerPage, setResultsPerPage] = useState(30);

	const responsesQueryObject = useInfiniteQuery({
		queryKey: ['responses', id, searchText, isAttendeesList],
		queryFn: ({ pageParam = 1 }): Promise<ResponsesData[]> =>
			getData(`
			events/${id}/custom-fields/responses
			?page=${pageParam}
			&per_page=${resultsPerPage}
			&search=${searchText}
			&all_attendees=${isAttendeesList}
		`),
		getNextPageParam: (lastPage, pages) => (lastPage.length >= resultsPerPage ? pages.length + 1 : undefined),
	});

	return {
		searchText,
		setSearchText,
		pageParam,
		setPageParam,
		resultsPerPage,
		setResultsPerPage,
		responsesQueryObject,
	};
};
