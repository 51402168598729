import { DateSchema, array, date, number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import i18n from 'locals/i18n';
import { CustomFee, DefaultValuesTypes, Event, EventDateType } from './types';
import { convertUTCToDate } from 'utils/utils';
import { EVENT_MODE_NORMAL, ZERO_TIME } from 'utils/constants';
import { EventMode } from 'utils/types';

export const defaultValues: DefaultValuesTypes = {
	name: '',
	venue: '',
	location: '',
	description: '',
	selling_time: null,
	dates: [],
	custom_fees: [],
	timezone: '',
	gate_time: '',
	postal_code: '',
	isMultiDay: false,
	ticketing_mode: 'dynamic',
	custom_email_message: '',
	support: '',
	mode: EVENT_MODE_NORMAL,
	quantity: '0',
	approval_required: false,
};

export const getFormDetails = (eventMode: EventMode) => {
	const sharedDetails = {
		name: string().required(i18n.t('editEvent-requiredName')),
		location: string().required(i18n.t('editEvent-requiredLocation')),
		timezone: string().required(i18n.t('editEvent-requiredTimezone')),
		custom_fees: array(
			object({
				name: string().required('Required'),
				amount: number()
					.required()
					.when('type', {
						is: 'percentage',
						then: number().min(0, 'Must be a number between 0 and 100').max(100, 'Must be a number between 0 and 100'),
					}),
			})
		),
		dates: array(
			object({
				start_date: date().required(),
				end_date: date()
					.required()
					.when('start_date', (start_date: Date, schema: DateSchema) =>
						schema.min(start_date, i18n.t('error-endDate'))
					),
			})
		),
	};

	const normalSchema = object().shape({
		...sharedDetails,
		postal_code: string().required(i18n.t('editEvent-requiredZipcode')),
	});

	const registrationSchema = object().shape({
		...sharedDetails,
		quantity: string().required(i18n.t('editEvent-requiredQuantity')),
	});

	if (eventMode === EVENT_MODE_NORMAL) {
		return yupResolver(normalSchema);
	} else {
		return yupResolver(registrationSchema);
	}
};

export const handleInitialValues = (event: Event) => {
	const dates = event.days.map(({ start_date, end_date, id }: EventDateType) => ({
		start_date: new Date(convertUTCToDate(start_date, event?.time_zone)),
		end_date: new Date(convertUTCToDate(end_date, event?.time_zone)),
		id,
	}));

	const selling_time =
		event.selling_time === ZERO_TIME ? event.selling_time : convertUTCToDate(event.selling_time, event.time_zone);

	const custom_fees = event.custom_fees?.map(({ ID, name, amount, type, taxable }: CustomFee) => ({
		ID,
		name,
		amount,
		type,
		taxable,
	}));

	const allValues: DefaultValuesTypes = {
		name: event.name,
		location: event.street_address,
		venue: event.venue,
		description: event.description,
		selling_time,
		dates: dates,
		timezone: event.time_zone,
		gate_time: event.gate_time ? event.gate_time : '',
		postal_code: event.postal_code,
		isMultiDay: event.days.length > 1,
		ticketing_mode: event.ticketing_mode,
		custom_fees: custom_fees || [],
		custom_email_message: event.custom_email_message,
		support: event.support_contact?.[0]?.contact || '',
		mode: event.mode,
		quantity: `${event.quantity}`,
		approval_required: event.approval_required,
	};
	return allValues;
};
