import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Search } from 'features/common/components/Search';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useGetEvents } from 'hooks/useGetEvents';
import { TicketTag } from '../SpecificPromocodes/TicketTag';

import { EventSpecificContainer, Title, SearchContainer } from './EventSpecificList.styles';
import { Column } from '../SpecificPromocodes/SpecificPromocodes.styles';

const EventSpecificList: React.FC = () => {
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const [searchQuery, setSearchQuery] = useState<string>('');
	const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};
	const { items } = useGetEvents({ searchQuery, per_page: 1000 });

	return (
		<EventSpecificContainer>
			{isLargeScreen && (
				<>
					<Title>{t('promoCode-Form-eventSpecific-events-title')}</Title>
					<SearchContainer>
						<Search onChange={handleSearch} placeholder="Search for events" width="490px" />
					</SearchContainer>
				</>
			)}
			{items &&
				items.map(({ id, name }, index) => (
					<Column key={id}>
						<TicketTag title={name} isLastElement={index === items.length - 1} id={id} fromEventList />
					</Column>
				))}
		</EventSpecificContainer>
	);
};

export { EventSpecificList };
