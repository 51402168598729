import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { airbrake } from 'ErrorBoundary';
import { AppDomainContextType, AppDomains } from 'contexts/types';

export const useSetApp = () => {
	const { search } = useLocation();

	const isProducerDomain = window.location.origin.includes('producer') || window.location.origin.includes('tixologist');
	const isRegistrationDomain = window.location.origin.includes('registration');
	const isManuallySet = new URLSearchParams(search).get('isRegistration');
	const isLocal = window.location.origin.includes('localhost');
	const isPreview = /pr\d+/.test(window.location.origin);
	const [appDomain, setAppDomain] = useState<AppDomainContextType>(null);

	useEffect(() => {
		const isCurrentValue = sessionStorage.getItem('app') !== null;
		if ((isPreview || isLocal) && isManuallySet !== null) {
			sessionStorage.setItem('app', `${isManuallySet === 'true' ? AppDomains.registration : AppDomains.producer}`);
		} else if ((isLocal || isPreview) && !isCurrentValue) {
			sessionStorage.setItem('app', AppDomains.producer);
		} else if (isRegistrationDomain) {
			sessionStorage.setItem('app', AppDomains.registration);
		} else if (isProducerDomain && !isPreview) {
			sessionStorage.setItem('app', AppDomains.producer);
		}

		const newValue = sessionStorage.getItem('app') as AppDomainContextType;
		setAppDomain(newValue);
		if (newValue === null) {
			airbrake.notify('tixologist app view not set by domain. useSetApp.tsx');
		}
	}, [isRegistrationDomain, isProducerDomain, isManuallySet]);

	return { appDomain };
};
