import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupWrapProps } from './types';
import { ConditionalWrapper } from 'features/common/components/ConditionalWrapper';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { HelpIcon } from '../HelpIcon/HelpIcon';

import { InfoLabel, ColumnFlex, RowFlex } from '../../MyProfile.styles';

export const GroupWrap: FC<GroupWrapProps> = ({
	children,
	hasHelpIcon = false,
	label,
	htmlFor,
	childrenInRow = false,
	rowGap = '0',
}) => {
	const { t } = useTranslation();

	return (
		<ColumnFlex rowGap={rowGap}>
			<ConditionalWrapper
				condition={hasHelpIcon}
				wrapper={(children) => (
					<FlexContainer gap="8px">
						{children}
						<HelpIcon />
					</FlexContainer>
				)}
			>
				<InfoLabel htmlFor={htmlFor}>{t(label)}</InfoLabel>
			</ConditionalWrapper>
			{childrenInRow ? <RowFlex>{children}</RowFlex> : children}
		</ColumnFlex>
	);
};
