import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { Input } from '../Input';
import { confirmIcon } from 'assets/images';
import { Information } from './Information';
import { Buttons } from './Buttons.tsx';
import { useMobileView } from 'hooks/useMobileView';

import { Title, Wrapper, Img } from './OrderPreview.styles';

export const OrderPreview = () => {
	const [ticketSent, setTicketSent] = useState<boolean>(false);
	const isMobile = useMobileView();
	const { watch } = useFormContext();
	const { t } = useTranslation();

	return (
		<Wrapper>
			<FlexContainer flexDirection="column" width={isMobile ? '90%' : '30%'}>
				{ticketSent && (
					<FlexContainer justifyContent="center" width="100%">
						<Img src={confirmIcon} />
					</FlexContainer>
				)}
				<Title className={ticketSent ? 'ticketsSent' : 'center'}>
					{t(ticketSent ? 'boxOffice-ticketsSent' : 'boxOffice-title')}
				</Title>
				<Information ticketSent={ticketSent} />
				<FlexContainer flexDirection="column" gap="32px">
					{!ticketSent && watch('total') !== '0' && (
						<Input id="transactionId" name="Transaction ID # from POS" placeholder="enter transaction # here" />
					)}
					<Buttons setTicketSent={setTicketSent} ticketSent={ticketSent} />
				</FlexContainer>
			</FlexContainer>
		</Wrapper>
	);
};
