import styled from 'styled-components';

export const Button = styled.button`
	all: unset;
	cursor: pointer;
	margin-left: 20px;
`;

export const CardContainer = styled.div`
	display: flex;
	width: fit-content;
	padding: 16px;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.colors.green};
	margin: 10px 5px;
`;

export const CardImg = styled.img`
	width: 80px;
	height: 60px;
	object-fit: contain;
`;
