import { getData } from 'services/api';
import { FanTicketData, FanTicketDataGetProps } from 'services/FanTickets/types';

export const fanTicketDataGet = ({
	pageParam,
	fanId,
	search,
	selectedPeriod,
	appliedEvents,
	ticketIds,
	isSummaryPage = false,
}: FanTicketDataGetProps): Promise<FanTicketData> => {
	const params = isSummaryPage
		? `ticket_ids=${ticketIds ? ticketIds.replace(/-/g, ',') : ''}`
		: `page=${pageParam}&search=${search}&date=${selectedPeriod}&events=${
				!appliedEvents || appliedEvents['-1'] ? '' : Object.keys(appliedEvents).join(',')
		  }`;
	return getData(`/tickets/refundable/${fanId}?${params}`);
};
