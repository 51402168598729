import styled from 'styled-components';

export const DragHandleContainer = styled.div`
	position: absolute;
	top: 15px;
	left: 15px;
`;

export const DraggableContainer = styled.div`
	display: flex;
	align-items: stretch;
	overflow: auto;
	height: 100%;
`;

export const TicketCardContainer = styled.div`
	position: relative;
	height: 100%;
`;
