import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { memberDelete } from 'features/Settings/services';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { MemberTable } from './components/MemberTable';
import { ResetModal } from './components/ResetModal';
import { MemberModal } from './components/MemberModal';
import { MobileOptionsModal } from './components/MobileOptionsModal';
import { ModalDelete } from 'features/common/components/ModalDelete';
import { MemberData } from 'features/Settings/types';
import { MemberModalState, TableProps } from './types';
import * as Const from './constants';

import * as Styled from './TableContainer.styles';

export const TableContainer: FC<TableProps> = ({ membersData = [] }) => {
	const { t } = useTranslation();
	const { mutate: deleteMember } = useMutation({
		mutationFn: memberDelete,
	});
	const [member, setMember] = useState<MemberData>(Const.INITIAL_MEMBER);
	const [showResetModal, setShowResetModal] = useState<boolean>(false);
	const [memberModal, setMemberModal] = useState<MemberModalState>(Const.INITIAL_MEMBER_MODAL);
	const [showMobileOptions, setShowMobileOptions] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	return (
		<FlexContainer gap="16px" flexDirection="column">
			<FlexContainer justifyContent="space-between" alignItems="center">
				<Styled.TableTitle>{t('settings-tableContainer-team-members')}</Styled.TableTitle>
				<Styled.AddMemberBtn
					category="outlined"
					text={t('settings-tableContainer-add-member')}
					type="button"
					onClick={() => setMemberModal({ isOpen: true, type: Const.ADD_MEMBER })}
				/>
			</FlexContainer>
			<Styled.CustomContainer>
				<MemberTable
					membersData={membersData}
					setShowMobileOptions={setShowMobileOptions}
					setShowResetModal={setShowResetModal}
					setShowDeleteModal={setShowDeleteModal}
					setMember={setMember}
				/>
			</Styled.CustomContainer>
			{showMobileOptions && (
				<MobileOptionsModal
					isOpen={showMobileOptions}
					closeModal={() => setShowMobileOptions(false)}
					memberData={member}
					setShowResetModal={setShowResetModal}
					setShowDeleteModal={setShowDeleteModal}
					setMemberModal={setMemberModal}
				/>
			)}
			{showResetModal && <ResetModal email={member.email} closeModal={() => setShowResetModal(false)} />}
			{memberModal.isOpen && (
				<MemberModal
					setMember={setMember}
					memberData={member}
					closeModal={() => setMemberModal({ type: '', isOpen: false })}
					modalType={memberModal.type}
				/>
			)}
			{showDeleteModal && (
				<ModalDelete
					showModal={showDeleteModal}
					closeModal={() => setShowDeleteModal(false)}
					modalInfo={{
						modalTitle: t('settings-tableContainer-delete-member'),
						modalMessage: t('settings-tableContainer-delete-member-message'),
						onDelete: () => {
							setShowDeleteModal(false);
							deleteMember(member.member_id);
						},
					}}
				/>
			)}
		</FlexContainer>
	);
};
