import Decimal from 'decimal.js-light';

import { RefundData, RefundPerTicket } from 'features/RefundTicketsSummary/types';
import { TicketData } from 'services/FanTickets/types';
import { RefundStatus, TicketDisplayDetails } from './types';

export const getRefundStatus = (
	refundPerTicket: RefundPerTicket,
	ticketData: TicketData[],
	refundData: RefundData
): RefundStatus => {
	const failedTicketsIds = refundData.errors.reduce(
		(ticketIds, failedTicket) => ticketIds.add(failedTicket.ticket_id),
		new Set()
	);
	let amountRefunded = new Decimal(0);
	let amountFailed = new Decimal(0);

	const ticketDisplayDetails = ticketData.map(({ ticket_id, ticket_type, event }): TicketDisplayDetails => {
		failedTicketsIds.has(ticket_id)
			? (amountFailed = amountFailed.plus(refundPerTicket[ticket_id]))
			: (amountRefunded = amountRefunded.plus(refundPerTicket[ticket_id]));
		return {
			id: ticket_id,
			type: ticket_type,
			event,
			amount: refundPerTicket[ticket_id],
			refundFailed: failedTicketsIds.has(ticket_id),
		};
	});
	return { ticketDisplayDetails, amountRefunded, amountFailed };
};
