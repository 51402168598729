import { HighlightCardProps } from './types';
import { SHOW_RATE } from '../constants';
import { PercentPieChart } from '../../PieChart';
import { common } from 'theme';

import { Container, CustomIcon, Stat, Label, CardButton } from './HighlightCard.styles';

export const convertToNum = (stat: string | number) => {
	if (typeof stat === 'string') {
		return parseFloat(stat);
	}
	return stat;
};

export const HighlightCard = ({
	property,
	icon,
	stat,
	cardLabel,
	buttonLabel,
	onClick,
	dataCy,
}: HighlightCardProps) => {
	return property === SHOW_RATE ? (
		<Container noGap data-cy={dataCy}>
			<Label>{cardLabel}</Label>
			<PercentPieChart
				color={common.colors.green}
				sizes={{ fontSize: 36, iconSize: 164 }}
				rawPercentage={convertToNum(stat)}
			/>
		</Container>
	) : (
		<Container data-cy={dataCy}>
			<CustomIcon iconName={icon} />
			<Stat>{stat}</Stat>
			<Label>{cardLabel}</Label>
			{buttonLabel && onClick && (
				<CardButton text={buttonLabel} onClick={onClick} category="quaternary" data-cy={`${dataCy}-btn`} />
			)}
		</Container>
	);
};
