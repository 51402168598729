import { useFormContext } from 'react-hook-form';
import { Template1, Template2 } from 'features/common/components/Templates';

import * as Styled from './TicketCreated.styles';

export const TicketDisplay = () => {
	const { watch } = useFormContext();

	const displayType = watch('displayType');
	const images = watch('images');

	const selectedType = () => {
		switch (displayType) {
			case 'template0':
				return <Template1 image={watch('images')[0]?.url} name={watch('name')} />;
			case 'template1':
				return <Template2 image={watch('images')[0]?.url} name={watch('name')} />;
			default:
				return <Styled.Ticket src={images[0]?.url} />;
		}
	};

	return selectedType();
};
