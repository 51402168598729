import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import i18n from 'i18next';

import { FormData } from './types';

export const resolver = yupResolver(
	object().shape({
		terms: string().required(i18n.t('dashboard-affiliateCreate-required')),
	})
);

export const defaultValues: FormData = { terms: '' };
