import styled from 'styled-components';
import { Text as UnstyledText } from 'features/common/components/Text';

export const Wrap = styled.div`
	width: 100%;
`;

export const DayContainer = styled.div`
	background: ${({ theme }) => theme.colors.lightGrey};
	padding: 32px;
	border-radius: 4px;
	margin-bottom: 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	position: relative;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 20px;
		justify-content: flex-end;
	}
`;

export const Title = styled(UnstyledText)`
	font-family: ${({ theme }) => theme.fonts.primary};
	color: ${({ theme }) => theme.colors.lightGrey2};
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 46px;
`;

export const ToggleText = styled(UnstyledText)`
	color: ${({ theme }) => theme.colors.lightGrey2};
	font-weight: 500;
	font-size: 20px;
`;

export const ToggleContainer = styled.div`
	display: flex;
	gap: 15px;
	align-items: center;
	box-sizing: border-box;
`;
