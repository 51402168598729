import styled from 'styled-components';

import { Text as UnstyledText } from 'features/common/components/Text';
import { Icon } from 'features/common/components/Icon';
import { CardContainerProps, PromoCodeTypes } from './types';
import { common } from 'theme';

const getCardContainerBackground = (type: PromoCodeTypes, hover = false) => {
	const colors = {
		'Event Specific': hover
			? common.colors.crimsonGradientHover
			: common.colors.crimsonGradient,
		'Ticket Specific': hover
			? common.colors.greenGradient2Hover
			: common.colors.greenGradient2,
		Unlimited: hover
			? common.colors.purpleGradientHover
			: common.colors.purpleGradient,
		'Assign Existing': hover
			? common.colors.blackGradientHover
			: common.colors.blackGradient,
	};
	return colors[type];
};

export const CardContainer = styled('div')<CardContainerProps>`
	align-items: center;
	background: ${({ type }) => getCardContainerBackground(type)};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	height: 250px;
	justify-content: center;
	padding: 24px 16px 24px 16px;
	position: relative;
	width: ${({ fromDashboard }) => (fromDashboard ? '282px' : '304px')};
	z-index: 1;
	box-sizing: border-box;
	&:after {
		background: ${({ type }) => getCardContainerBackground(type, true)};
		border-radius: 8px;
		content: '';
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		opacity: 0;
		transition: 300ms ease-out;
		position: absolute;
		z-index: -1;
	}
	&:hover:after {
		opacity: 1;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: grid;
		grid-template-columns: 88px 216px 12px;
		flex-direction: row;
		height: 120px;
		padding: 0px;
		width: 350px;
	}
`;

export const StyledIcon = styled(Icon)`
	height: 72px;
	margin-bottom: 24px;
	width: 72px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-bottom: 0px;
	}
`;

export const CardTitle = styled(UnstyledText)`
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: center;
`;

export const CardDescription = styled(UnstyledText)`
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0px;
	text-align: center;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		text-align: left;
	}
`;
