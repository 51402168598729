import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Modal } from 'features/common/components/Modal';
import { Form } from 'features/common/components/Form';
import { RoleSelect } from '../RoleSelect';
import { TextInput } from './TextInput';
import { QUATERNARY } from 'features/common/components/Button/constants';
import { ADD_MEMBER } from '../../constants';
import { DefaultValuesTypes, MemberModalProps } from './types';
import { getDefaultValues, validationSchema } from './utils';
import { memberRolePut } from 'features/Settings/services';
import { useAddMember } from '../../hooks/useAddMember';

import * as Styled from './MemberModal.styles';

export const MemberModal: React.FC<MemberModalProps> = ({
	closeModal,
	memberData,
	modalType,
	setMember,
}: MemberModalProps) => {
	const { t } = useTranslation();
	const isAddMember = modalType === ADD_MEMBER;
	const { addMember } = useAddMember();
	const { full_name: name, email } = memberData;
	const formMethods = useForm({
		defaultValues: getDefaultValues(isAddMember, memberData),
		resolver: yupResolver(validationSchema(t)),
	});
	const { mutate: updateRole } = useMutation({
		mutationFn: memberRolePut,
	});

	const onSubmit = (data: DefaultValuesTypes) => {
		setMember({ ...memberData, ...data });
		if (isAddMember) addMember(data);
		else updateRole({ memberData, newRole: data.roles });
		closeModal();
	};

	return (
		<Modal onClose={closeModal}>
			<Modal.Header>{t(`settings-tableContainer-${isAddMember ? 'add' : 'edit'}-member`)}</Modal.Header>

			<Form onSubmit={(data) => onSubmit(data)} formMethods={formMethods}>
				<Styled.BodyWrapper>
					{[
						{ type: 'name', value: name, query: 'full_name' },
						{ type: 'email', value: email, query: 'email' },
					].map(({ type, value, query }) => (
						<TextInput
							key={`add-member-${type}`}
							id={`add-member-${type}`}
							name={query}
							label={t(`settings-tableContainer-${type}`)}
							staticValue={isAddMember ? '' : value}
						/>
					))}
					<RoleSelect memberData={memberData} />
				</Styled.BodyWrapper>

				<Styled.ActionsWrapper>
					<Styled.ModalButton text={t('button-cancel')} category={QUATERNARY} onClick={closeModal} type="button" />
					<Styled.ModalButton text={t('button-confirm')} type="submit" />
				</Styled.ActionsWrapper>
			</Form>
		</Modal>
	);
};
