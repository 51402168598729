import { FlexContainer } from 'features/common/components/FlexContainer';
import { PriceRowProps } from './types';

import { Price, Text } from '../OrderSummary.styles';

export const PriceRow = ({ title, price }: PriceRowProps) => (
	<FlexContainer justifyContent="space-between">
		<Text>{title}</Text>
		<Price>${price}</Price>
	</FlexContainer>
);
