import { useTranslation } from 'react-i18next';

import { PromoCodeCard } from 'features/common/components/PromoCodeCard';
import { SectionTitle } from 'features/common/components/SectionTitle';
import { TopUsedProps } from 'features/PromoCodesSection/types';
import { handleType } from 'features/PromoCodesSection/utils';

import { PromoCodesContainer, Container } from './TopUsedSection.styles';
import { OverflowX } from 'styles';

export const TopUsedSection = ({ promocodes }: TopUsedProps) => {
	const { t } = useTranslation();

	return (
		<Container>
			<SectionTitle title={t('promoCode-topUsed')} paddingVertical />
			<OverflowX>
				<PromoCodesContainer>
					{promocodes?.map(
						({ id, code, promocode_type, total_uses, discount, references, active, percentage, coupon_type }) => (
							<PromoCodeCard
								key={id}
								id={id}
								name={code}
								type={handleType(promocode_type)}
								used={total_uses}
								amount={discount || percentage}
								references={references || []}
								isActive={active}
								isPercentage={coupon_type === 'percentage'}
								background="blackGrey1"
							/>
						)
					)}
				</PromoCodesContainer>
			</OverflowX>
		</Container>
	);
};
