import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { IdParamsType } from 'utils/types';
import { getData } from 'services/api';
import { setLastEvent } from 'store/slices/lastEventSlice';
import { useAppDispatch } from 'store/hooks';
import { camelize } from 'utils/utils';
import { EventData } from '../types';

const useGetEvent = (id?: number) => {
	const [updatedEvent, setUpdatedEvent] = useState<EventData | null>(null);
	const { id: eventId }: IdParamsType = useParams();
	const dispatch = useAppDispatch();

	const { data: event } = useQuery({
		queryKey: ['event', eventId || id],
		queryFn: (): Promise<EventData> => getData(`public/ticketing/events/${eventId || id}`),
		enabled: !!eventId || !!id,
	});

	useEffect(() => {
		if (event) {
			const newTickets = (camelize(event) as EventData).ticketTypes.map((ticket) => ({
				...ticket,
				quantity: 0,
				priceWithNoDiscount: ticket.initialPrice,
				promoCodeApplied: false,
			}));
			const newEvent = { ...(camelize(event) as EventData), ticketTypes: newTickets };
			setUpdatedEvent(newEvent);
			dispatch(setLastEvent(newEvent));
		}
	}, [event]);

	return { event: updatedEvent };
};

export default useGetEvent;
