import { UseFormReturn } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { boolean, date, number, object, string } from 'yup';

import { convertDateWithTimezoneToUTC, sendGAEvent } from 'utils/utils';
import { FormValuesProps, HashNameType, Number1To9 } from './types';

export const defaultValues: FormValuesProps = {
	name: '',
	allowSecondarySales: false,
	secondaryRoyalty: null,
	initialPrice: null,
	sellingTime: new Date(),
	offSellingTime: null,
	ticketVisibility: true,
	description: '',
	customFees: [],
	images: [],
	amount: null,
	buyerAbsorbPaymentFee: 'false',
	buyerAbsorbTixologistFee: 'false',
	displayType: 'template0',
	template: null,
};

export const validationSchema = (t: TFunction<'translation', undefined>) =>
	object().shape({
		name: string().required(t('ticketDetails-requiredTicketTypeName')),
		allowSecondarySales: boolean(),
		secondaryRoyalty: number()
			.optional()
			.when('allowSecondarySales', {
				is: true,
				then: number().typeError(t('ticketDetails-requiredSecondaryTicketPrice')).required(),
				otherwise: () =>
					number()
						.nullable()
						.transform((v, o) => (o === '' ? null : v)),
			}),
		initialPrice: number().typeError(t('ticketDetails-requiredTicketPrice')).required(),
		amount: number().typeError(t('ticketDetails-requiredNumberOfTickets')).required(),
		sellingTime: date().typeError(t('ticketDetails-requiredActiveTime')).required(),
		buyerAbsorbPaymentFee: string().oneOf(['false', 'true'], t('ticketDetails-requiredProcessingFee')),
		buyerAbsorbTixologistFee: string().oneOf(['false', 'true'], t('ticketDetails-requiredTixologistFee')),
	});

export const valuesToPost = [
	'allowSecondarySales',
	'name',
	'secondaryRoyalty',
	'initialPrice',
	'sellingTime',
	'offSellingTime',
	'ticketVisibility',
	'description',
	'buyerAbsorbPaymentFee',
	'buyerAbsorbTixologistFee',
	'displayType',
	'template',
	'amount',
];

export const setNewBodyValue = (
	key: string,
	value: string | number,
	timezone: string,
	ticketFormMethods: UseFormReturn<FormValuesProps, any>
) => {
	if (key === 'buyerAbsorbPaymentFee' || key === 'buyerAbsorbTixologistFee') return value === 'true';
	if (key === 'secondaryRoyalty' || key === 'initialPrice' || key === 'amount') return Number(value);
	if (key === 'template')
		return {
			image: value,
			displayType: ticketFormMethods.getValues('displayType'),
		};
	if (key === 'sellingTime') return convertDateWithTimezoneToUTC(new Date(value), timezone);
	if (key === 'offSellingTime') return value ? convertDateWithTimezoneToUTC(new Date(value), timezone) : null;
	return value;
};

export const createBody = (ticketFormMethods: UseFormReturn<FormValuesProps, any>, eventId: number, timezone: string) =>
	ticketFormMethods.getValues(valuesToPost as any).reduce(
		(accumulator: FormValuesProps, currentValue: string, index: number) => ({
			...accumulator,
			[valuesToPost[index]]: setNewBodyValue(valuesToPost[index], currentValue, timezone, ticketFormMethods),
		}),
		{ eventId }
	);

export const handleNavigation = (hash: number) => (hash === 6 ? '#3' : `#${hash - 1}`);

const hashName: HashNameType = {
	1: 'add',
	2: 'details',
	3: 'type selector',
	4: 'template image',
	5: 'select template',
	6: 'image upload',
	7: 'confirm upload',
	8: 'created',
	9: 'success',
};

export const handleGAStep = (hash: Number1To9) =>
	sendGAEvent({ category: 'Ticket Creation', action: `Create ticket ${hashName[hash]}` });
