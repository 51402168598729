import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from 'features/common/components/Divider';
import { PromoCodeAddCard, PromoCodeCard } from 'features/common/components/PromoCodeCard';
import { Search } from 'features/common/components/Search';
import { Filter } from './components/Filter';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { MainLayout } from 'layouts/MainLayout';
import { PromoCodesHeader } from './components/PromoCodesHeader';
import { TopUsedSection } from './components/TopUsedSection';
import { PromoCode } from 'utils/types';
import { handleTopUsed, handleType, initialSelectedValue, handleOptions } from './utils';
import { FilterOptionType } from './components/Filter/types';
import { useGetEvents } from 'hooks/useGetEvents';
import { useGetPromoCodes } from 'hooks/useGetPromoCodes';

import { PromoCodeListContainer, ContentWrapper, ScreenContainer, Container } from './PromoCodesSection.styles';

export const PromoCodesSection = () => {
	const [selectedEvent, setSelectedEvent] = useState<FilterOptionType>(initialSelectedValue);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();

	const { promocodes, initialPromocodes } = useGetPromoCodes({
		selectedEvent,
		searchQuery,
	});

	const { items } = useGetEvents({
		searchQuery: '',
		per_page: 1000,
	});

	const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	const onChangeFilter = (option: FilterOptionType) => {
		setSelectedEvent(option);
	};

	return (
		<MainLayout>
			<ScreenContainer>
				{isLargeScreen && <PromoCodesHeader />}
				<TopUsedSection promocodes={handleTopUsed(initialPromocodes)} />
				<ContentWrapper>
					<Container>
						<Search onChange={onChangeSearch} placeholder={t('promoCode-search')} />
						{items && (
							<Filter onChange={onChangeFilter} options={handleOptions(items)} selectedOption={selectedEvent} />
						)}
					</Container>
					<Divider />
					<PromoCodeListContainer>
						<PromoCodeAddCard />
						{promocodes.map(
							({
								id,
								code,
								promocode_type,
								total_uses,
								discount,
								references,
								active,
								percentage,
								coupon_type,
							}: PromoCode) => (
								<PromoCodeCard
									key={id}
									id={id}
									name={code}
									type={handleType(promocode_type)}
									used={total_uses}
									amount={discount || percentage}
									references={references || []}
									isActive={active}
									isPercentage={coupon_type === 'percentage'}
								/>
							)
						)}
					</PromoCodeListContainer>
				</ContentWrapper>
			</ScreenContainer>
		</MainLayout>
	);
};
