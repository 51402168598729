import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { CustomField } from './components/CustomField';
import { useGetCustomFields } from './useGetCustomFields';
import { CustomField as CustomFieldType } from 'features/CustomFields/types';
import { EmptySection } from '../EmptySection';
import { ROUTES } from 'features/routes';
import { CustomFieldsComponentProps } from './types';

import { Wrapper } from './CustomFieldsComponent.styles';
import { EVENT_MODE_NORMAL } from 'utils/constants';

export const CustomFieldsComponent = ({ eventId, eventMode }: CustomFieldsComponentProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { customFields } = useGetCustomFields();
	const text = t(`dashboard-${eventMode === EVENT_MODE_NORMAL ? 'custom-fields' : 'registration'}-info`);

	return customFields && customFields?.length > 0 ? (
		<Wrapper>
			{customFields?.map((field: CustomFieldType) => (
				<CustomField key={field.ID} {...field} />
			))}
		</Wrapper>
	) : (
		<EmptySection onClick={() => push(generatePath(ROUTES.DASHBOARD_ADD_CUSTOM_FIELDS, { eventId }))} text={text} />
	);
};
