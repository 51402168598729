import { InventoryTicketTypes, Sale, SalesTicketType } from 'hooks/useHighlights/types';
import { Action, AggregateDataObject, PayoutBy, ProcessTimelineProps, TicketTypesPerDate, WindowInfo } from './types';

export const initWindowSize = 5;

const getWkStart = (dateStr: Date) => {
	const date = new Date(dateStr);
	const dayOfWk = date.getDay();
	date.setDate(date.getDate() - dayOfWk);
	return date;
};

export const aggregateTTSold = (oldAggregate: SalesTicketType[], newData: SalesTicketType[]) => {
	const aggregateObj: AggregateDataObject = {};
	oldAggregate.concat(newData).reduce((newAggregate, ticketType) => {
		const ttData = newAggregate[ticketType.name];
		if (ttData) {
			ttData.sold += ticketType.sold;
			ttData.payout += ticketType.payout;
		}
		newAggregate[ticketType.name] = ticketType;
		return newAggregate;
	}, aggregateObj);

	return Object.values(aggregateObj);
};

export const processTimeline = ({ eventHighlights, timeline }: ProcessTimelineProps) => {
	if (!eventHighlights) return undefined;
	if (timeline === 'days') return eventHighlights;
	//{12341234 :{date:'', tt:[], payout:0}}
	const salesbyWeek: PayoutBy = {};

	for (const salesDate of eventHighlights.sales) {
		const wkOfCurrDate = getWkStart(salesDate.date).valueOf();
		if (salesbyWeek[wkOfCurrDate]) {
			salesbyWeek[wkOfCurrDate].payout += salesDate.payout;
			salesbyWeek[wkOfCurrDate].ticket_types = aggregateTTSold(
				salesbyWeek[wkOfCurrDate].ticket_types,
				salesDate.ticket_types
			);
		} else {
			salesbyWeek[wkOfCurrDate] = { ...salesDate, date: new Date(wkOfCurrDate) };
		}
	}
	return { ...eventHighlights, sales: Object.values(salesbyWeek || {}) };
};

export const getTicketsSold = (sales: Sale<Date>[], ticketTypeInv?: InventoryTicketTypes[]) => {
	if (!ticketTypeInv || sales.length === 0) return;

	const dataKeys = ticketTypeInv.map((ticket) => ticket.name);
	let ticketsSold = 0;
	const ticketTypeDataFrame = Object.values(sales).map((date) => {
		const ttPerDate: TicketTypesPerDate = { date: date.date };
		date.ticket_types.forEach((ticketType) => {
			ticketsSold += ticketType.sold;
			ttPerDate[ticketType.name] = ticketType.sold;
		});
		return ttPerDate;
	});

	return { ticketTypeDataFrame, dataKeys, ticketsSold };
};

const adjustStart = (isWindowOdd: boolean, counter: number) => {
	// this logic makes sure a more recent value is always added before an older value.
	if ((isWindowOdd && counter % 2 === 0) || (!isWindowOdd && counter > 1 && counter % 2 === 1)) return true;
	else return false;
};

// startIdx + size = length or startIdx + size = lastIdx + 1
export const reducer = (state: WindowInfo, action: Action): WindowInfo => {
	if (action.type === 'set_timeline') {
		return { ...state, xAxis: action.payload };
	} else if (action.type === 'zoom_in') {
		if (state.windowSize <= 5) {
			return state;
		} else {
			return {
				...state,
				windowSize: state.windowSize - 1,
				windowSizeShadow: state.windowSize - 1,
				windowStart: state.windowStart + 1,
			};
		}
	} else if (action.type === 'zoom_out') {
		if (state.windowSize === state.dataPoints) {
			return state;
		} else if (state.windowSize + state.windowStart === state.dataPoints) {
			return {
				...state,
				windowSize: state.windowSize + 1,
				windowSizeShadow: state.windowSize + 1,
				windowStart: state.windowStart - 1,
			};
		} else {
			return {
				...state,
				windowSize: state.windowSize + 1,
				windowSizeShadow: state.windowSize + 1,
			};
		}
	} else if (action.type === 'move_forward') {
		if (state.windowSize + state.windowStart === state.dataPoints) {
			return state;
		} else {
			return {
				...state,
				windowStart: state.windowStart + 1,
			};
		}
	} else if (action.type === 'move_back') {
		if (state.windowStart === 0) {
			return state;
		} else {
			return {
				...state,
				windowStart: state.windowStart - 1,
			};
		}
	} else if (action.type === 'zoom_input') {
		if (isNaN(action.payload) || action.payload < 5)
			return {
				...state,
				windowSizeShadow: undefined,
			};
		else if (action.payload >= state.dataPoints)
			return {
				...state,
				windowStart: 0,
				windowSize: state.dataPoints,
				windowSizeShadow: state.dataPoints,
			};
		const isWindowOdd = state.windowSize % 2 === 1;
		const midIdx = isWindowOdd ? Math.floor(state.windowSize / 2) : state.windowSize / 2 - 1;
		const center = midIdx + state.windowStart;
		let newSize = 1;
		let newStart = center;
		while (newSize < action.payload) {
			newSize += 1;
			if ((adjustStart(isWindowOdd, newSize) && newStart > 0) || newStart + newSize === state.dataPoints) newStart -= 1;
		}

		return {
			...state,
			windowSize: newSize,
			windowSizeShadow: newSize,
			windowStart: newStart,
		};
	} else if (action.type === 'init') {
		const windowSize = action.payload > initWindowSize ? initWindowSize : action.payload;
		return {
			...state,
			windowSize,
			windowSizeShadow: windowSize,
			windowStart: action.payload - windowSize,
			dataPoints: action.payload,
		};
	} else return state;
};

export const TICKETS_SOLD = 'ticketsSold';
