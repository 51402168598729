import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';

export const ContentWrapper = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 32px;
	padding: 72px 50px 72px 150px;
	width: 100%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 32px 24px;
	}
`;

export const Span = styled(Text)`
	font-size: 24px;
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const BackgroundBlur = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ theme }) => theme.colors.blackGradient2};
	box-sizing: border-box;
	height: 200px;
	position: sticky;
	bottom: 0;
	width: calc(100% - 36px);
	margin-left: 36px;
	padding-left: 114px;
	padding-right: 50px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 0;
		padding: 0;
		width: 100%;
	}
`;

export const RefundButton = styled(Button)`
	border-radius: 8px;

	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	margin: 0;
	padding: 15px 75px;
	width: 275px;
`;
