import styled, { css } from 'styled-components';

const requiredFields = css`
	border: 1px solid ${({ theme }) => theme.colors.greenBlue3};
`;
export const StyledLabel = styled.label`
	position: relative;
	width: 168px;
	height: 34px;
	box-sizing: border-box;
`;

export const Input = styled.input`
	transform: scale(0);
	+ span {
		width: 168px;
		height: 34px;
		background: ${({ theme }) => theme.colors.black2};
		display: flex;
		align-items: center;
		@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			bottom: 10px;
		}
	}
	&.requiredFields {
		+ span {
			${requiredFields}
		}
	}
`;

export const Background = styled.span`
	border-radius: 2px;
	height: 100%;
	left: 0;
	transition: background 0.4s;
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: space-between;
	transition: transform 0.45s;
`;

export const Circle = styled.span`
	width: 82px;
	height: 32px;
	background: ${({ theme }) => theme.colors.green};
	border-radius: 2px;
	transition: transform 0.45s;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
`;

export const UncheckedIconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 84px;
	height: 34px;
	background: ${({ theme }) => theme.colors.black2};
	border-radius: 2px;
	z-index: 1;
`;
