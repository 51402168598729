import Decimal from 'decimal.js-light';

import { FanTicketData, TicketData, CreditCard } from 'services/FanTickets/types';
import { DEFUALT_PERCENT, MAX_VALUE } from 'features/RefundTicketsSummary/constants';
import {
	OptionsEnum,
	ResolverEnum,
	CreditCardInfo,
	TicketRefundDetails,
	GetRefundDetails,
	RefundCalculations,
	RefundPerTicket,
} from 'features/RefundTicketsSummary/types';

export const safeParseFloat = (string: string): number => (string === '' ? 0 : parseFloat(string));

export const formatNum = (decimal: Decimal): number | string =>
	Number.isInteger(decimal.toNumber()) ? decimal.toNumber() : decimal.toFixed(2);

export const getRefundDetails = (
	resolver: ResolverEnum,
	partialAmountString: string,
	refundType: OptionsEnum,
	ticketData: TicketData[] = []
): GetRefundDetails => {
	const partialAmountNum = new Decimal(safeParseFloat(partialAmountString));

	const calculateAmount = (pricePaid: Decimal) => {
		if (refundType === OptionsEnum.FULL) return pricePaid;
		else if (resolver === ResolverEnum.percentage) return pricePaid.times(partialAmountNum).dividedBy(100);
		else return partialAmountNum;
	};
	const initialVal: RefundCalculations = {
		purchaseTotal: new Decimal(0),
		refundTotal: new Decimal(0),
		minTicketPrice: new Decimal(MAX_VALUE),
		refundPerTicket: {},
	};
	const { refundTotal, purchaseTotal, ...refundCalculations }: RefundCalculations = ticketData.reduce(
		({ purchaseTotal, refundTotal, minTicketPrice, refundPerTicket }, { ticket_id, price_paid }) => {
			if (price_paid === 'registration') {
				price_paid = '0';
			}
			const pricePaidNum = new Decimal(safeParseFloat(price_paid));
			purchaseTotal = purchaseTotal.plus(pricePaidNum);
			if (pricePaidNum.lessThan(minTicketPrice)) minTicketPrice = pricePaidNum;
			const ticketRefund = calculateAmount(pricePaidNum);
			refundTotal = refundTotal.plus(ticketRefund);
			refundPerTicket[ticket_id] = ticketRefund;
			return { purchaseTotal, refundTotal, minTicketPrice, refundPerTicket };
		},
		initialVal
	);

	let refundPercent: string;
	if (refundType === OptionsEnum.FULL) refundPercent = `${DEFUALT_PERCENT}%`;
	else if (resolver === ResolverEnum.percentage) refundPercent = `${partialAmountNum}%`;
	else refundPercent = `${formatNum(refundTotal.dividedBy(purchaseTotal).times(100))}%`;

	return { refundPercent, refundTotal, purchaseTotal, ...refundCalculations };
};

export const getTicketRefundDetails = (refundPerTicket: RefundPerTicket): TicketRefundDetails[] =>
	Object.entries(refundPerTicket).map(([id, amount]) => ({
		id: parseFloat(id),
		amount: amount.toNumber(),
	}));

export const getCardInfo = (FanTicketData: FanTicketData | undefined): CreditCardInfo => {
	const last4Set = new Set();
	FanTicketData?.refundable_tickets.forEach((ticket) => last4Set.add(ticket.card_last4));
	const cardsInfo = FanTicketData?.user_info.cards.filter(({ last4 }: CreditCard) => last4Set.has(last4)) || [];
	const isMultipleCards = cardsInfo ? cardsInfo.length > 1 : false;
	return { cardsInfo, isMultipleCards };
};
