import { FC, useRef, useState } from 'react';
import { Navbar } from 'features/common/components/Sidebar/components/Navbar';
import { ROUTES } from 'features/routes';
import logo from 'assets/images/logo.svg';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { MenuOptions } from './components/MenuOptions';
import { SideBarButton } from './components/SideBarButton';
import { useClickAway } from '../../hooks/useClickAway';

import { Container, Logo } from './Sidebar.styles';

const Sidebar: FC = () => {
	const {
		CREATE_EVENT_PATH,
		EMBED_PATH,
		HOME_PATH,
		PROMO_CODES_PATH,
		ANALYTICS_PATH,
		FAN_DATABASE_PATH,
		SETTINGS_PATH,
		ORDERS_PATH,
		SUPPORT,
		MARKETING_PATH,
	} = ROUTES;

	const ref = useRef(null);
	const isLargeScreen = useResponsiveScreen();

	const [isOpened, setIsOpened] = useState<boolean>(isLargeScreen);

	const onClickSidebar = () => setIsOpened((prevState) => !prevState);

	const onClickAway = () => setIsOpened(false);

	useClickAway(ref, onClickAway);

	const sideBarButton = isLargeScreen && <SideBarButton onClick={onClickSidebar} isOpened={isOpened} />;

	const navBar = !isLargeScreen && <Navbar onClick={onClickSidebar} />;

	const menuOptions = [
		{ name: 'home', icon: 'home', path: HOME_PATH },
		{ name: 'create-event', icon: 'calendar', path: CREATE_EVENT_PATH },
		{ name: 'marketing-title', icon: 'campaign', path: MARKETING_PATH },
		// {
		// 	name: 'ticketDesigner',
		// 	icon: 'ticketDesigner',
		// 	path: HOME_PATH,
		// },
		{
			name: 'promoCode-title',
			icon: 'promoCode',
			path: PROMO_CODES_PATH,
		},
		{
			name: 'myFans',
			icon: 'myFans',
			path: FAN_DATABASE_PATH,
		},
		// {
		// 	name: 'myReports',
		// 	icon: 'myReports',
		// 	path: HOME_PATH,
		// },
		{
			name: 'myAnalytics',
			icon: 'myAnalytics',
			path: ANALYTICS_PATH,
		},
		{
			name: 'embed',
			icon: 'website',
			path: EMBED_PATH,
		},
		{
			name: 'orders',
			icon: 'myAnalytics',
			path: ORDERS_PATH,
		},
		{
			name: 'settings',
			icon: 'settings',
			path: SETTINGS_PATH,
		},
		{
			name: 'Support',
			icon: 'helpSupport',
			path: SUPPORT,
			external: true,
		},
	];

	return (
		<>
			<div ref={ref}>{navBar}</div>
			<Container ref={ref} aria-label="side-bar" isOpened={isOpened}>
				{isLargeScreen && isOpened && <Logo src={logo} alt="Logo" />}
				{sideBarButton}
				<MenuOptions options={menuOptions} />
			</Container>
		</>
	);
};

export { Sidebar };
