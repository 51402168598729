import styled from 'styled-components';

import { Text } from 'features/common/components/Text';

export const TitleSearchBar = styled(Text)`
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
`;


export const Container = styled.div`
	display: flex;
	margin: 24px 0;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
		justify-content: space-around;
	}
`;

export const PromoCodeListContainer = styled.div`
	flex: 1;
	gap: 24px;
	display: flex;
	flex-wrap: wrap;
	overflow-y: scroll;
	padding: 24px 0;
	box-sizing: border-box;
	height: calc(100vh - 390px);

	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		justify-content: center;
		height: calc(100vh - 451px);
	}
`;