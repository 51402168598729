import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { InputLabel } from './InputLabel';
import { FormInputDateAndTimeProps } from './types';
import { ZERO_TIME } from 'utils/constants';
import FlexContainer from '../FlexContainer/FlexContainer';
import { handleNewDateTime } from './utils';

import { IconCalendar, CalendarWrapper, Calendar, Error, InputContainer } from './Form.styles';

const FormInputDateAndTime: FC<FormInputDateAndTimeProps> = ({
	id,
	name,
	label,
	onChange,
	index,
	value,
	isFromRequiredFields,
	calcErrors,
	origin,
}: FormInputDateAndTimeProps) => {
	const {
		getValues,
		setValue,
		formState: { errors },
		watch,
	} = useFormContext<any>();

	const isInputArray = index !== undefined;
	const error = isInputArray ? calcErrors : errors[name]?.message;
	const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

	const _value = watch(id);

	const handleOnChangeDate = (event: any) => {
		if (event) {
			isInputArray
				? setValue(
						name,
						handleNewDateTime({
							event,
							currentDates: getValues(name),
							index: Number(index),
							label: id,
						})
				  )
				: setValue(name, event);
		}
		onChange && onChange(event);
	};

	const handleCalendarStatus = () => {
		setIsCalendarOpen((prevState) => !prevState);
	};

	const handleValue = () => {
		if (value === null || value === undefined) {
			const zeroTime = _value === ZERO_TIME;
			if (zeroTime) return null;
			else return _value;
		} else return value;
	};

	return (
		<FlexContainer flexDirection="column" width="100%">
			<InputLabel className={isInputArray ? 'SM' : ''} htmlFor={id ?? name} label={label} />
			<InputContainer className={`relative ${origin === 'event-creation' ? origin : ''}`} hasError={Boolean(error)}>
				<CalendarWrapper isCalendarOpen={isCalendarOpen} className={isFromRequiredFields ? 'requiredFields' : ''}>
					<DatePicker
						autoComplete="off"
						id={id ?? name}
						selected={handleValue()}
						onChange={handleOnChangeDate}
						showTimeSelect
						dateFormat="d MMMM, yyyy h:mm aa"
						calendarContainer={Calendar}
						onCalendarOpen={handleCalendarStatus}
						onCalendarClose={handleCalendarStatus}
						data-cy="test"
					/>
				</CalendarWrapper>
				<IconCalendar
					iconName={`${origin === 'event-creation' ? 'blue' : 'green'}Calendar`}
					isCalendarOpen={isCalendarOpen}
				/>
			</InputContainer>
			<Error>{error}</Error>
		</FlexContainer>
	);
};

export { FormInputDateAndTime };
