import { AxiosResponse } from 'axios';

import api, { deleteData } from 'services/api';
import { UploadPartnerImage } from './types';
import { AppDispatch, store } from 'store';
import { setPartners, setLoggedUser, setCurrentPartner, setLoading } from 'store/slices/AuthSlice';
import { IAsyncActionCallback, LoggedUser } from 'services/auth/types';

export const uploadPartnerImage =
	({ base64_image, prefix }: UploadPartnerImage, callback?: IAsyncActionCallback) =>
	async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const postImageResponse = await api.post('/images', {
				base64_image: base64_image,
				image_type: 'partner',
				prefix,
			});

			const _partner = {
				image: postImageResponse.data.image_url,
			};
			await api.put('/partners/me', _partner);

			const {
				auth: { loggedUser },
			} = store.getState();

			const getLoggedUser: AxiosResponse<LoggedUser> = await api.get('/users/me');
			const currentPartner = loggedUser?.currentPartner;
			const _currentPartner = await getLoggedUser.data.Partners.find((partner) => partner.ID === currentPartner?.ID);
			const _loggedUser = { ...getLoggedUser.data, currentPartner: _currentPartner };

			if (getLoggedUser && getLoggedUser.data) {
				dispatch(setLoggedUser(_loggedUser));
				if (callback) callback(_loggedUser, undefined);
				dispatch(setLoading(false));
			}
		} catch (error) {
			if (callback) callback(undefined, error);
			dispatch(setLoading(false));
		}
	};

export const handlePictureChange =
	(target: HTMLInputElement, callback?: IAsyncActionCallback) => (dispatch: AppDispatch) => {
		if (target.files !== null && target.files[0]) {
			const image = target.files[0];
			const reader = new FileReader();
			reader.readAsDataURL(image);
			reader.onload = () => {
				if (reader.result) {
					dispatch(
						uploadPartnerImage(
							{
								base64_image: reader.result.toString(),
								prefix: image.name,
							},
							callback
						)
					);
				}
			};
		}
	};

export const deletePartnerImage = (callback?: IAsyncActionCallback) => async (dispatch: AppDispatch) => {
	try {
		dispatch(setLoading(true));
		const _partner = {
			image: '-',
		};
		await api.put('/partners/me', _partner);

		const getPartnerResponse = await api.get('/partners/me');
		if (getPartnerResponse && getPartnerResponse.data) {
			const {
				auth: { loggedUser },
			} = store.getState();
			const partners = loggedUser?.Partners;
			const _partners = [];
			if (partners) {
				for (let i = 0; i < partners.length; i++) {
					const partner = partners[i];
					if (partner.ID === getPartnerResponse.data.ID) {
						_partners.push(getPartnerResponse.data);
						deleteData('/images', { data: { path: partner.image } });
					} else {
						_partners.push(partner);
					}
				}
			}
			const _currentPartner = _partners.find((partner) => partner.ID === loggedUser?.currentPartner.ID);
			dispatch(setCurrentPartner(_currentPartner));
			dispatch(setPartners(_partners));
			dispatch(setLoading(false));
			if (callback) callback(getPartnerResponse.data, undefined);
		}
	} catch (error) {
		if (callback) callback(undefined, error);
		dispatch(setLoading(false));
	}
};
