import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { useGetLastEvent } from '../../hooks/useGetLastEvent';
import { validatePromoCode } from './services';
import { PromoCodeInputProps } from './types';
import { handleValidation } from './utils';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';

import { Label, Container, Input, Button, Error } from './PromoCodeInput.styles';

export const PromoCodeInput = ({ setPromoCodeId, setTicketsWithPromoCodes }: PromoCodeInputProps) => {
	const [value, setValue] = useState<string>('');
	const [isValid, setIsValid] = useState<boolean>(true);
	const { ticketTypesId } = useGetLastEvent();
	const { setValue: setFormValue } = useFormContext();
	const { loggedUser } = useAppSelector((state: RootState) => state.auth);
	const { t } = useTranslation();

	const { mutate: mutatePromoCode } = useMutation(validatePromoCode, {
		onSuccess: ({ data }) =>
			handleValidation({ data, setPromoCodeId, setTicketsWithPromoCodes, setValue, setFormValue }),
		onError: () => setIsValid(false),
	});

	const handlePromoCode = () => {
		mutatePromoCode({
			promoCode: value,
			ticketTypesId: ticketTypesId as number[],
			partnerId: loggedUser?.Partners[0].ID,
		});
	};

	useEffect(() => {
		setIsValid(true);
	}, [value]);

	return (
		<FlexContainer flexDirection="column" gap="8px">
			<Label>{t('selectTicketType-havePromocode')}</Label>
			<Container>
				<Input placeholder={t('selectTicketType-enterCode')} value={value} onChange={(e) => setValue(e.target.value)} />
				<Button text={t('apply')} onClick={handlePromoCode} type="button" />
			</Container>
			{!isValid && <Error>* {t('promoCodeInvalid')}</Error>}
		</FlexContainer>
	);
};
