import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ToolTip } from 'features/common/components/InfoTooltip';
import { Icon } from 'features/common/components/Icon';
import { Media } from 'features/EventImgContent/types';
import { isUploadedOrFetched } from 'features/EventImgContent/utils';

import { SubTitle } from '../../MediaContent.styles';
import { Error, Input, Root } from './GalleryVideos.styles';

const videoLinkRegex = /^https:\/\/(www\.youtube\.com\/(embed|live)|youtu\.be)\/.+$/;

const GalleryVideos = () => {
	const [videoLinkError, setVideoLinkError] = useState<boolean>(false);
	const { t } = useTranslation();
	const { setValue, watch, setError, clearErrors } = useFormContext();

	const allMedia: Media[] = watch('mediaContent.media');
	const images = allMedia.filter((media) => !isUploadedOrFetched(media) || media.type !== 'video');
	const videos = allMedia.filter((media) => isUploadedOrFetched(media) && media.type === 'video');

	const onChangeVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.trim().replace('/live/', '/embed/').replace('youtu.be/', 'www.youtube.com/embed/');
		const match = value.length === 0 || videoLinkRegex.test(value);
		setVideoLinkError(!match);
		if (!match) {
			setError('mediaContent.media', {
				type: 'manual',
				message: t('website-media-content-gallery-videos-error'),
			});
		} else {
			clearErrors('mediaContent.media');
		}

		if (value === '') {
			return setValue('mediaContent.media', images);
		} else if (videos.length > 0) {
			allMedia.forEach((media) => {
				if (isUploadedOrFetched(media) && media.type === 'video' && media.url !== value) {
					media.url = value;
				}
			});
		} else {
			allMedia.push({ type: 'video', url: value, position: allMedia?.length });
		}
		setValue('mediaContent.media', allMedia);
	};

	return (
		<Root>
			<div>
				<SubTitle>{t('website-media-content-gallery-videos')}</SubTitle>
				<ToolTip
					className="top"
					title={t('website-media-content-gallery-videos-tooltip-title')}
					text={t('website-media-content-gallery-videos-tooltip-text')}
				>
					<Icon iconName="help" />
				</ToolTip>
			</div>

			<div>
				<Input
					onChange={onChangeVideo}
					value={videos[0]?.url}
					placeholder={t('website-media-content-gallery-videos-placeholder')}
					data-cy="youtube-input"
				/>
				{videoLinkError && <Error>{t('website-media-content-gallery-videos-error')}</Error>}
			</div>
		</Root>
	);
};
export { GalleryVideos };
