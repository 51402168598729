import { FC } from 'react';
import { Icon } from 'features/common/components/Icon';
import { SideBarButtonProps } from '../../types';

import { CircularButton } from './SideBarButton.styles';

const SideBarButton: FC<SideBarButtonProps> = ({ onClick, isOpened }: SideBarButtonProps) => {
	const iconToShow = isOpened ? <Icon iconName="arrowLeft" /> : <Icon iconName="arrowRight" />;

	return (
		<CircularButton type="button" onClick={onClick} data-cy="sidebar-btn">
			{iconToShow}
		</CircularButton>
	);
};

export { SideBarButton };
