import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Color } from 'react-color-palette';
import { useTranslation } from 'react-i18next';

import { Colors } from 'features/common/components/Colors';
import { PaletteModal } from 'features/common/components/Colors/PaletteModal';
import { InputProps } from '../../types';
import { InputLabel } from 'features/common/components/Form/InputLabel';

import { Container } from './ColorInput.styles';

export const ColorInput = ({ name, label }: InputProps) => {
	const { t } = useTranslation();
	const [paletteIsOpen, setPaletteIsOpen] = useState(false);
	const { setValue, watch } = useFormContext();
	const currentColor = watch(name);

	const handleSaveChanges = (newColor: Color) => {
		setValue(name, newColor.hex);
		setPaletteIsOpen(false);
	};

	const handleOpenModal = () => {
		setPaletteIsOpen(true);
	};

	return (
		<>
			<InputLabel label={label} htmlFor={name} />
			<Container onClick={handleOpenModal}>
				<Colors
					color={currentColor}
					handleOpenModal={handleOpenModal}
					title={t('marketing-campaignForm-themeColor')}
					text={name}
					isExample={false}
				/>
			</Container>
			{paletteIsOpen && (
				<PaletteModal
					currentColor={currentColor}
					handleChange={handleSaveChanges}
					onClose={() => setPaletteIsOpen(false)}
				/>
			)}
		</>
	);
};
