import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Form } from 'features/common/components/Form';
import { AUTH } from 'features/common/components/Form/Input/constants';
import { ROUTES } from 'features/routes';
import { RootState } from 'store';
import { fields, fieldsSingUp } from '../utils';
import { CreateAccountFormProps } from '../types';
import { ErrorText } from 'features/Login/components/LoginForm/LoginForm.styles';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';

import {
	Button,
	CreateAccountButton,
	FormContainer,
	GradientContainer,
	TextAgreeTermsOfService,
	TextTermsOfService,
	TextWrapper,
	Title,
} from './CreateAccountForm.styles';

export const CreateAccountForm = ({ errors }: CreateAccountFormProps) => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const { isNewGoogleAccount } = useSelector((state: RootState) => state.google);
	const { isAlreadyCreated } = useSelector((state: RootState) => state.login);

	const { partnerAlreadyExist, emailAlreadyExist, weakPassword } = errors;

	const handleErrors = () => {
		if (partnerAlreadyExist) return 'createAccount-partnerNameAlreadyExists';
		else if (emailAlreadyExist) return 'createAccount-emailAlreadyExists';
		else return 'createAccount-weakPassword';
	};

	const handleOpenTermsOfService = () => window.open('https://tixologi.com/terms-of-service/', '_blank');

	const handleFormInputs = () => {
		if (isNewGoogleAccount)
			return (
				<Form.Input
					key="organizationName"
					id="organizationName"
					name="organizationName"
					label="Organization Name"
					type="text"
					category={AUTH}
				/>
			);
		else if (isAlreadyCreated)
			return fieldsSingUp.map(({ id, label, type }) => (
				<Form.Input key={id} id={id} name={id} label={label} type={type} category={AUTH} />
			));
		else
			return fields.map(({ id, label, type }) => (
				<Form.Input key={id} id={id} name={id} label={label} type={type} category={AUTH} />
			));
	};
	return (
		<FormContainer>
			<Title>{t('login-title')}</Title>
			{handleFormInputs()}
			{(partnerAlreadyExist || emailAlreadyExist || weakPassword) && (
				<FlexContainer flexDirection="column">
					<ErrorText>{t(handleErrors())}</ErrorText>
				</FlexContainer>
			)}
			<Button text={t('createAccount')} type="submit" />
			{!isNewGoogleAccount && !isAlreadyCreated && (
				<GradientContainer>
					<CreateAccountButton onClick={() => push(ROUTES.LOGIN_PATH)} text={t('createAccount-back')} type="button" />
				</GradientContainer>
			)}
			<TextWrapper onClick={handleOpenTermsOfService}>
				<TextAgreeTermsOfService> {t('createAccount-terms-of-service')} </TextAgreeTermsOfService>
				<TextTermsOfService> {t('settings-terms-of-service')} </TextTermsOfService>
			</TextWrapper>
		</FormContainer>
	);
};
