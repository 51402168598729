import styled from 'styled-components';

import { Text as UnstyledText } from 'features/common/components/Text';
import { ContainerType } from './types';

export const Container = styled.main<ContainerType>`
	border-radius: 8px;
	border: 1px solid ${({ theme, active }) => (active ? theme.colors.green : 'none')};
	padding: 16px;
	width: fit-content;
	max-width: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.grey18};
	box-sizing: border-box;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		min-width: -webkit-fill-available;
	}
`;

export const TicketImage = styled.img`
	width: 45px;
	height: 96px;
	margin 0px 16px;
`;

export const Text = styled(UnstyledText)`
	font-size: 14px;
	line-height: 20px;
	color: ${({ theme }) => theme.colors.lightGrey7};

	&.gradient {
		background: -webkit-linear-gradient(112deg, #a2c166 3.91%, #2a8c7b 114.43%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
`;

export const Price = styled(UnstyledText)`
	font-size: 20px;
	color: ${({ theme }) => theme.colors.lightGrey7};
`;

export const Title = styled(UnstyledText)`
	color: ${({ theme }) => theme.colors.lightGrey7};
	font-size: 16px;
`;

export const DateTimeContainer = styled.div`
	display: flex;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
		flex-direction: column;
	}
`;
