import { useTranslation } from 'react-i18next';

import { useResponsiveScreen } from 'hooks/useWindowSize';
import { TicketsAnalyticsCardProps } from './types';
import CustomPieChart from '../PieChart/CustomPieChart';

import {
	IconContainer,
	Ref,
	RefBottom,
	RefTitle,
	RefsContainer,
	Root,
	SquareColor,
} from './TicketsAnalyticsCard.styles';

const TicketsAnalyticsCard = ({
	color = '#A2C166',
	sm = false,
	value = 'All Ticket Types',
	sold = 0,
	available = 0,
}: TicketsAnalyticsCardProps) => {
	const isLargeScreen = useResponsiveScreen();
	const { t } = useTranslation();
	const sizes = {
		fontSize: sm ? (isLargeScreen ? 24 : 14) : 40,
		iconSize: sm ? (isLargeScreen ? 180 : 130) : 250,
	};
	return (
		<Root sm={sm}>
			<IconContainer>
				<CustomPieChart sold={sold} available={available} color={color} sizes={sizes} sm={sm} />
			</IconContainer>
			<RefsContainer>
				<RefTitle>{value}</RefTitle>
				<Ref>
					<SquareColor color={color} />
					{sold} {t('ticket-analytic-card-tickets-sold')}
				</Ref>
				<RefBottom>
					<SquareColor color="#F2F2F2" />
					{sm && available} {t('ticket-analytic-card-tickets-available')}
				</RefBottom>
			</RefsContainer>
		</Root>
	);
};

export default TicketsAnalyticsCard;
