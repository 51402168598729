import { useFormContext } from 'react-hook-form';

import { FormInputTextProps } from 'features/common/components/Form/types';
import { Form } from 'features/common/components/Form';

import { Error, InputContainer, TextArea } from 'features/common/components/Form/Form.styles';

export const TextInput = ({ id, name, label, ...rest }: FormInputTextProps) => {
	const {
		setValue,
		watch,
		formState: { errors },
	} = useFormContext();
	const error = errors[name];

	return (
		<InputContainer hasError={Boolean(error)}>
			<Form.InputToggle htmlFor={id ?? name} label={label} name={`${name}Enabled`} />
			<TextArea id={id ?? name} value={watch(name)} onChange={(e) => setValue(name, e.target.value)} {...rest} />
			<Error>{error?.message}</Error>
		</InputContainer>
	);
};
