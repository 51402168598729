import { useTranslation } from 'react-i18next';
import { ToolTip } from 'features/common/components/InfoTooltip/InfoTooltip';
import { Icon } from 'features/common/components/Icon';
import { ThemeColorsTitleProps } from './types';

import { ContainerRow, Title, Wrapper } from './WebstieSectionTitle.styles';

const WebsiteSectionTitle = ({
	// setIsDefaultStyles,
	// isDefaultStyles,
	title,
	showTooltip = false,
}: ThemeColorsTitleProps) => {
	const { t } = useTranslation();
	/* TODO show when they need */
	// const onChangeDefaultSyles = () => setIsDefaultStyles((prevState) => !prevState);

	return (
		<Wrapper>
			<ContainerRow>
				<Title>{title}</Title>
				{showTooltip && (
					<ToolTip
						className="top"
						title={t('website-color-templates-tooltip-title')}
						text={t('website-color-templates-tooltip')}
					>
						<Icon iconName="help" />
					</ToolTip>
				)}
			</ContainerRow>
			{/* TODO show when they need */}
			{/* <ContainerRow>
				<Subtitle isChecked={isDefaultStyles}>
					{t('website-default-styles')}
				</Subtitle>
				<Toggle
					isChecked={isDefaultStyles}
					name="defaultStyles"
					id={title}
					handleChange={onChangeDefaultSyles}
				/>
			</ContainerRow> */}
		</Wrapper>
	);
};

export { WebsiteSectionTitle };
