import styled from 'styled-components';

import { Text } from 'features/common/components/Text';

export const Card = styled.div`
	margin-top: 10px;
	border-radius: 8px;
	padding: 20px;
	border: 3px solid ${({ theme }) => theme.colors.lightGrey5};
`;

export const QuantityText = styled(Text)`
	font-size: 16px;
	font-weight: 500;
`;

export const QuantityButton = styled.div`
	font-family: ${({ theme }) => theme.fonts.primary};
	display: flex;
	justify-content: center;
	gap: 16px;
	width: fit-content;
	padding: 12px 8px;
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};
	background: ${({ theme }) => theme.colors.greenGradient2};
	width: 100px;
	cursor: pointer;
`;

export const SoldOut = styled.div`
	font-family: ${({ theme }) => theme.fonts.primary};
	display: flex;
	justify-content: center;
	gap: 10px;
	width: fit-content;
	padding: 8px 4px;
	border-radius: 2px;
	color: ${({ theme }) => theme.colors.white};
	background: ${({ theme }) => theme.colors.grey};
	width: 100px;
`;

export const QuantityIcon = styled.div`
	font-size: 18px;
`;
