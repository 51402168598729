import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export enum DateFilterEnumPage {
	allTime = '',
	pastDay = 'past_day',
	pastWeek = 'past_week',
	pastMonth = 'past_month',
	pastYear = 'past_year',
}

export interface EventTracker {
	[key: string]: boolean;
}

interface RefundsState {
	selectedPeriod: {
		unapplied: DateFilterEnumPage;
		applied: DateFilterEnumPage;
	};
	selectedEvents: {
		unapplied: EventTracker;
		applied: EventTracker;
	};
	eventCount: number;
}

export const initialState: RefundsState = {
	selectedPeriod: {
		unapplied: DateFilterEnumPage.allTime,
		applied: DateFilterEnumPage.allTime,
	},
	selectedEvents: {
		unapplied: { '-1': true },
		applied: { '-1': true },
	},
	eventCount: 0,
};

const RefundableTicketsSlice = createSlice({
	name: 'refundableTickets',
	initialState,
	reducers: {
		alterUnappliedDateRefundableTickets: (state, action) => {
			state.selectedPeriod.unapplied = action.payload;
		},
		alterAppliedDateRefundableTickets: (state, action: PayloadAction<DateFilterEnumPage>) => {
			state.selectedPeriod.applied = action.payload;
		},
		alterUnappliedEventsRefundableTickets: (state, action: PayloadAction<number>) => {
			const id = action.payload;
			const { unapplied } = state.selectedEvents;
			if (id !== -1) {
				delete unapplied[-1];
				unapplied[id] ? delete state.selectedEvents.unapplied[id] : (unapplied[id] = true);
			}
			if (id === -1 || Object.keys(unapplied).length === 0) {
				state.selectedEvents.unapplied = initialState.selectedEvents.unapplied;
			}
		},
		alterAppliedEventsRefundableTickets: (state) => {
			state.selectedEvents.applied = state.selectedEvents.unapplied;
			RefundableTicketsSlice.caseReducers.updateEventCountRefundableTickets(state);
		},
		resetUnappliedEventsRefundableTickets: (state) => {
			state.selectedEvents.unapplied = state.selectedEvents.applied;
		},
		resetFiltersRefundableTickets: (state) => {
			state.selectedEvents.applied = initialState.selectedEvents.applied;
			state.selectedPeriod.applied = initialState.selectedPeriod.applied;
		},
		updateEventCountRefundableTickets: (state) => {
			const allEvents = state.selectedEvents.applied['-1'] ? 1 : 0;
			state.eventCount = Object.keys(state.selectedEvents.applied).length - allEvents;
		},
		resetFiltersToInitialRefundableTickets: (state) => {
			state.eventCount = initialState.eventCount;
			state.selectedPeriod = initialState.selectedPeriod;
			state.selectedEvents = initialState.selectedEvents;
		},
	},
});

export const {
	alterUnappliedDateRefundableTickets,
	alterAppliedDateRefundableTickets,
	alterUnappliedEventsRefundableTickets,
	alterAppliedEventsRefundableTickets,
	updateEventCountRefundableTickets,
	resetUnappliedEventsRefundableTickets,
	resetFiltersToInitialRefundableTickets,
} = RefundableTicketsSlice.actions;

export default RefundableTicketsSlice.reducer;

export const selectedPeriod = (state: RootState) => state.refundableTickets.selectedPeriod;
