import { ROUTES } from 'features/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Title, ContainerNewEvent, IconNewEvent } from './CreateEvent.styles';

const NewEvent: FC = () => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const { CREATE_EVENT_PATH } = ROUTES;

	return (
		<ContainerNewEvent type="button" onClick={() => push(CREATE_EVENT_PATH)}>
			<IconNewEvent iconName="plus" />
			<Title>{t('createEvent-new-title')}</Title>
		</ContainerNewEvent>
	);
};

export { NewEvent };
