import { EventCardComponent } from 'features/common/components/EventCardComponent';
import { EventProps } from 'features/Home/components/Events/types';
import { CardContainerProps } from '../types';
import { useGetEvents } from 'hooks/useGetEvents';
import { LazyLoader } from 'features/common/animations/LazyLoader';
import defaultImage from 'assets/images/createTicket.svg';

import { Root } from './CardContainer.styles';

export const CardContainer = ({ searchQuery, selectedOption }: CardContainerProps) => {
	const { items, isLoading } = useGetEvents({ selectedOption, searchQuery, per_page: 9 });

	return (
		<Root>
			{isLoading ? (
				<LazyLoader height="90vh" margin="72px 0 0 0" />
			) : (
				items?.map(({ id, images, name, start_date, end_date, time_zone, live_on_widget }: EventProps) => (
					<EventCardComponent
						image={images[0]?.image ? `${images[0]?.image}?alt=media` : defaultImage}
						name={name}
						key={id}
						id={id}
						start_date={start_date}
						end_date={end_date}
						time_zone={time_zone}
						live={live_on_widget}
					/>
				))
			)}
		</Root>
	);
};
