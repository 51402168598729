import { useFormContext } from 'react-hook-form';
import { InputLabel } from './InputLabel';
import { FormSelectProps } from './types';

import { Error, InputContainer, Select } from './Form.styles';

const FormSelect = ({ id, name, options, label, withDefaultValue, handleClick, ...rest }: FormSelectProps) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	const error = errors[name];

	return (
		<InputContainer hasError={Boolean(error)} onClick={(e) => handleClick && handleClick(e)}>
			<InputLabel htmlFor={id ?? name} label={label} />
			<Select {...rest} id={id ?? name} aria-label={name} {...register(name)}>
				{withDefaultValue && <option value="">Select One...</option>}
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</Select>
			<Error>{error?.message}</Error>
		</InputContainer>
	);
};

export { FormSelect };
