import api, { getData } from 'services/api';
import { UpdateWebsiteDataProps } from './types';

export const updateWebisteData = ({ attributes, eventId }: UpdateWebsiteDataProps) =>
	api.put(`/events/${eventId}/websettings`, {
		event_id: eventId,
		background_color: attributes.themeColors.background_color,
		theme_color: attributes.themeColors.theme_color,
		header_color: attributes.themeColors.header_color,
		family_font: attributes.typoSection.fontFamily,
		callouts_font_style: attributes.typoSection.fontStyle.callouts.name,
		callouts_brand_color: attributes.typoSection.fontStyle.callouts.color,
		header_font_style: attributes.typoSection.fontStyle.headerText.name,
		header_brand_color: attributes.typoSection.fontStyle.headerText.color,
		title_font_style: attributes.typoSection.fontStyle.title.name,
		title_brand_color: attributes.typoSection.fontStyle.title.color,
		sub_title_font_style: attributes.typoSection.fontStyle.subtitle.name,
		sub_title_brand_color: attributes.typoSection.fontStyle.subtitle.color,
		text_font_style: attributes.typoSection.fontStyle.text.name,
		text_brand_color: attributes.typoSection.fontStyle.text.color,
	});

export const getWebSettingsData = async (id: string) => {
	const res = await getData(`events/${id}/websettings`);
	return {
		eventName: res.event_name,
		themeColors: {
			isDefault: res?.default_theme_colors_style,
			background_color: res?.background_color,
			theme_color: res?.theme_color,
			header_color: res?.header_color,
		},
		typoSection: {
			isDefault: res?.default_typography_style,
			fontFamily: res?.family_font || 'Poppins',
			fontStyle: {
				title: {
					name: res?.title_font_style.toLowerCase(),
					color: res?.title_brand_color,
				},
				subtitle: {
					name: res?.sub_title_font_style.toLowerCase(),
					color: res?.sub_title_brand_color,
				},
				text: {
					name: res?.text_font_style.toLowerCase(),
					color: res?.text_brand_color,
				},
				callouts: {
					name: res?.callouts_font_style,
					color: res?.callouts_brand_color,
				},
				headerText: {
					name: res?.header_font_style,
					color: res?.header_brand_color,
				},
			},
		},
	};
};
