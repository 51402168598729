import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Modal } from 'features/common/components/Modal/Modal';
import { QUATERNARY, TERTIARY } from 'features/common/components/Button/constants';
import { ModalDeleteProps } from './types';

import * as Styled from './ModalDelete.styles';

const ModalDelete: FC<ModalDeleteProps> = ({ setDisplayType }: ModalDeleteProps) => {
	const { t } = useTranslation();
	const { setValue } = useFormContext();
	const [showModal, setShowModal] = useState(false);

	const deleteImage = () => {
		setValue('ticketImage', '');
		setValue('template', 'empty');
		setValue('thumbnail', '');
		setValue('image', '');
		setValue('displayType', '');
		setDisplayType('');
	};

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	return showModal ? (
		<Modal onClose={closeModal}>
			<>
				<Modal.Header>{t('ticketDetails-ticket-deleteTicketDesign')}</Modal.Header>
				<Modal.Body>
					<Styled.ContainerBody>
						<Styled.Icon iconName="trashGrey" />
						<Styled.Text element="p">{t('ticketDetails-ticket-deleteTicketDesign-message')}</Styled.Text>
					</Styled.ContainerBody>
				</Modal.Body>
				<Modal.ActionButtons>
					<Styled.ContainerActionButtons>
						<Styled.ModalButton category={QUATERNARY} onClick={closeModal} text={t('button-cancel')} type="button" />
						<Styled.ModalButton
							category={TERTIARY}
							onClick={deleteImage}
							text={t('button-delete')}
							type="button"
							data-cy="confirmation-btn"
						/>
					</Styled.ContainerActionButtons>
				</Modal.ActionButtons>
			</>
		</Modal>
	) : (
		<Styled.DeleteOption type="button" onClick={openModal} data-cy="delete-img">
			{t('ticketDetails-ticket-delete')}
		</Styled.DeleteOption>
	);
};

export { ModalDelete };
