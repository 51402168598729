import { useState } from 'react';
// import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

// import { getTzList } from './unTypedUtils';
import { timezones } from './utils';
import { Form } from '../Form';

export const TimezoneSelect = () => {
	const { t } = useTranslation();

	// const { setValue, watch } = useFormContext();
	// const timezone = watch('timezone');
	// const zipcode = watch('postal_code');
	// const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	// useEffect(() => {
	// 	if (zipcode) {
	// 		// getTzFromZip function is not yet defined
	// 		// setValue('timezone', getTzFromZip(zipcode));
	// 	} else if (timezone === '' && zipcode === '') {
	// 		setValue('timezone', browserTimezone);
	// 	}
	// }, [browserTimezone, timezone, zipcode]);

	const [time, setTime] = useState(moment());

	return (
		<Form.Select
			id="timezone"
			name="timezone"
			label={t('createEvent-eventSummary-timezone')}
			// options={getTzList()}
			options={timezones(time, t)}
			handleClick={() => setTime(moment())}
			withDefaultValue
		/>
	);
};
