import { FC, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputLabel } from 'features/common/components/Form/InputLabel';
import { ToolTip } from 'features/common/components/InfoTooltip';
import { Icon } from 'features/common/components/Icon';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { TicketImageCard } from 'features/Dashboard/components/TicketsSection/TicketCard/TicketImageCard';
import { ModalDelete } from './components/ModalDelete';
import { ModalReplace } from './components/ModalReplace';
import { ButtonGroup } from './components/ButtonGroup';
// import { DownloadButton } from './components/DownloadButton';
import { ImageSectionProps } from './types';
import { SelectTemplate } from './SelectTemplate/SelectTemplate';

import * as Styled from './ImageSection.styles';

const ImageSection: FC<ImageSectionProps> = ({ isFromRequiredFields, ticket }: ImageSectionProps) => {
	const { t } = useTranslation();
	const inputRef = useRef<HTMLInputElement | null>(null);
	const { watch, setValue } = useFormContext();
	const [isDisplayType, setDisplayType] = useState('');
	const [showModal, setShowModal] = useState(true);
	const displayType = watch('displayType');
	const [isCancel, setIsCancel] = useState(false);

	const ticketImage: string | File | Blob = useWatch({
		name: 'ticketImage',
	});

	const handleImage = () => {
		if (isCancel) return ticket?.image;
		else {
			if (ticketImage instanceof Blob) return URL.createObjectURL(ticketImage);
			return ticketImage;
		}
	};

	const hasValidImage = ticketImage !== undefined && ticketImage !== '';

	const getTicketClassName = () => {
		if (ticketImage) return 'with-image';
		else if (isFromRequiredFields && !hasValidImage) return 'requiredFields';
		else return 'className';
	};

	const handleClick = () => inputRef.current?.click();

	useEffect(() => {
		setShowModal(isDisplayType === 'template');
		if (isDisplayType === 'upload') {
			setValue('displayType', 'image');
		}
	}, [isDisplayType]);

	return (
		<Styled.Container>
			<InputLabel htmlFor="ticketImage" label={t('ticketDetails-ticket-ticketDesigner')} />
			<FlexContainer flexDirection="row" gap="12px" alignItems="center">
				<Styled.StyledText>{t('ticketDetails-attachDesign')}</Styled.StyledText>
				<ToolTip
					title={t('ticketDetails-ticket-ticketDesigner-tooltip-title')}
					text={t('ticketDetails-ticket-ticketDesigner-tooltip')}
				>
					<Icon iconName="help" />
				</ToolTip>
			</FlexContainer>
			{/* <DownloadButton /> */}
			<Styled.ButtonContainer hasValidImage={hasValidImage} className={getTicketClassName()}>
				{hasValidImage && showModal ? (
					<SelectTemplate
						ticketImage={ticketImage}
						closeModal={() => setShowModal(false)}
						display={ticket?.template?.display_type}
						setIsCancel={setIsCancel}
					/>
				) : hasValidImage ? (
					<TicketImageCard isEdit isCard={false} image={handleImage()} name={watch('name')} displayType={displayType} />
				) : (
					<ButtonGroup setDisplayType={setDisplayType} inputRef={inputRef} onClick={handleClick} />
				)}
				{hasValidImage && (
					<Styled.ImageOptions>
						<ModalReplace setDisplayType={setDisplayType} inputRef={inputRef} />
						<ModalDelete setDisplayType={setDisplayType} />
					</Styled.ImageOptions>
				)}
			</Styled.ButtonContainer>
		</Styled.Container>
	);
};

export { ImageSection };
