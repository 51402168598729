import { TFunction } from 'react-i18next';
import { date, number, object, string } from 'yup';
import { camelizeKeys } from 'humps';

import { BooleanString, CreateTicketForm, EventCanGoLive, TicketBody } from './types';
import { convertDateWithTimezoneToUTC, convertUTCToDate, keepUpperCase } from 'utils/utils';
import { EventData } from 'features/common/types';

export const NEW_TICKET = 'new-ticket';

export const defaultValues: CreateTicketForm = {
	amount: undefined,
	buyerAbsorbPaymentFee: 'false',
	buyerAbsorbTixologistFee: 'false',
	initialPrice: null,
	name: undefined,
	secondaryRoyalty: undefined,
	sellingTime: new Date(),
	offSellingTime: null,
	description: '',
	allowSecondarySales: false,
	template: undefined,
	ticketImage: undefined,
	ticketVisibility: true,
	image: '',
	displayType: '',
};

export const validationSchema = (t: TFunction<'translation', undefined>) =>
	object().shape({
		name: string().required(t('ticketDetails-requiredTicketTypeName')),
		initialPrice: number().typeError(t('ticketDetails-requiredTicketPrice')).required(),
		amount: number().typeError(t('ticketDetails-requiredQuantity')).required(),
		sellingTime: date().typeError(t('ticketDetails-requiredActiveTime')).required(),
	});

export const createBody = (
	ticketForm: CreateTicketForm,
	eventId: number,
	timezone: string,
	ticketId?: number
): TicketBody => {
	const _sellingTime = new Date(ticketForm.sellingTime ?? 0);
	const _offSellingTime = ticketForm.offSellingTime
		? convertDateWithTimezoneToUTC(new Date(ticketForm.offSellingTime), timezone)
		: null;
	const _ticketForm = {
		allowSecondarySales: ticketForm.allowSecondarySales,
		amount: Number(ticketForm.amount),
		buyerAbsorbPaymentFee: ticketForm.buyerAbsorbPaymentFee === 'true',
		buyerAbsorbTixologistFee: ticketForm.buyerAbsorbTixologistFee === 'true',
		description: ticketForm.description,
		ticketVisibility: ticketForm.ticketVisibility,
		eventId,
		//PUT
		ID: ticketId ?? undefined,
		initialPrice: Number(ticketForm.initialPrice),
		name: ticketForm.name ?? '',
		secondaryRoyalty: Number(ticketForm.secondaryRoyalty),
		sellingTime: convertDateWithTimezoneToUTC(_sellingTime, timezone),
		offSellingTime: _offSellingTime,
		image: ticketForm.image ?? '',
		displayType: ticketForm.displayType ?? '',
		template: {
			image: ticketForm.image,
			displayType: ticketForm.displayType,
		},
	};

	return _ticketForm;
};

export const getBooleanString = (boolean: boolean): BooleanString => {
	const booleanString: BooleanString = boolean ? 'true' : 'false';
	return booleanString;
};

export const fillForm = (ticket: TicketBody | undefined, timezone: string) => {
	const ticketCamel = camelizeKeys(ticket, {
		// This function keeps ID as ID
		process: keepUpperCase,
	});
	if (ticketCamel) {
		const _form = {
			...ticketCamel,
			buyerAbsorbPaymentFee: getBooleanString(ticketCamel.buyerAbsorbPaymentFee),
			buyerAbsorbTixologistFee: getBooleanString(ticketCamel.buyerAbsorbTixologistFee),
			sellingTime: convertUTCToDate(ticketCamel.sellingTime, timezone),
			offSaleTime: ticketCamel.offSellingTime ? convertUTCToDate(ticketCamel.offSellingTime, timezone) : null,
			template: ticketCamel.image,
			ticketImage: ticketCamel.image,
			displayType: ticketCamel.template.displayType,
			ticketVisibility: ticketCamel.ticketVisibility,
		};
		return _form;
	}
};

export const getTicketTypeCanGoLive = (eventCanGoLive: EventCanGoLive, ticketTypeId?: number): EventCanGoLive => {
	const ticketsDetails = ticketTypeId
		? { [ticketTypeId]: eventCanGoLive.ticketTypeDetails[ticketTypeId] }
		: { 0: true };

	return {
		hasTickets: true,
		eventDetails: true,
		allTicketDetails: true,
		ticketTypeDetails: ticketsDetails,
		partnerInfo: eventCanGoLive.partnerInfo,
	};
};

export const showReqFieldsModal = (event: EventData, eventCanGoLive: EventCanGoLive, ticketPrice: number): boolean => {
	const isFreeEvent = event.ticketTypes.every((ticket) => ticket.initialPrice === 0);
	const eventIsLive = event.liveOnWidget || event.liveOnWebpage;
	const missingInfo = !eventCanGoLive.partnerInfo.taxInfo || !eventCanGoLive.partnerInfo.payoutsInfo;
	if (eventIsLive && isFreeEvent && ticketPrice > 0 && missingInfo) {
		return true;
	}

	return false;
};
