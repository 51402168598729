import { useFormContext } from 'react-hook-form';

import { AdvancedDetails } from './components/AdvancedDetails';
import { KeyDetails } from './components/KeyDetails';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { EventModeProp } from '../types';

import { ButtonContainer, SubmitButton } from './EventSummary.styles';
import { FlexContainer } from 'features/common/components/FlexContainer';

export const EventSummary = ({ eventMode }: EventModeProp) => {
	const isLargeScreen = useResponsiveScreen();
	const methods = useFormContext();
	return (
		<>
			{eventMode && (
				<FlexContainer flexDirection="column" gap="24px" width="100%">
					<KeyDetails eventMode={eventMode} />
					<AdvancedDetails eventMode={eventMode} />
					<ButtonContainer isMobile={isLargeScreen}>
						<SubmitButton text="Continue" type="submit" onClick={() => methods.trigger()} />
					</ButtonContainer>
				</FlexContainer>
			)}
		</>
	);
};
