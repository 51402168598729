import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Search } from 'features/common/components/Search';
import { FilterOptionType } from 'features/common/components/Filters/LocalFilter/types';
import { ALL_TIME, ALL_TIME_VALUE } from 'features/Dashboard/components/constants';
import { debounce } from 'utils/utils';
import { EventCard } from './EventCard';
import { useGetEvents } from 'hooks/useGetEvents';

import * as Styled from '../../Analytics.styles';

const EventsAnalytics = () => {
	const { t } = useTranslation();
	const [searchQuery, setSearchText] = useState('');
	const searchDebounce = debounce(setSearchText);

	const selectedOption: FilterOptionType = {
		label: ALL_TIME,
		value: ALL_TIME_VALUE,
	};

	const { items } = useGetEvents({
		selectedOption,
		searchQuery,
		per_page: 10,
	});

	return (
		<Styled.Wrapper>
			<Styled.Title element="h2">{t('analytics-by-events')}</Styled.Title>
			<Styled.ContainerEvent>
				<Search onChange={(e) => searchDebounce(e.target.value)} placeholder={t('events-search')} />
			</Styled.ContainerEvent>
			<Styled.EventAnalyticsDivider />
			<Styled.EventsContainer>
				{items?.map(({ id, name, start_date, ticket_types, time_zone }) => (
					<EventCard
						key={id}
						id={id}
						startDate={start_date}
						ticketTypes={ticket_types}
						name={name}
						timezone={time_zone}
					/>
				))}
			</Styled.EventsContainer>
		</Styled.Wrapper>
	);
};

export { EventsAnalytics };
