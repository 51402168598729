export enum DateFilterEnum {
	allTime = '',
	pastDay = 'past_day',
	pastWeek = 'past_week',
	pastMonth = 'past_month',
	pastYear = 'past_year',
}

export interface FanData {
	user_id: number;
	full_name: string;
	email: string;
}

export enum Modals {
	confirmContact = 'confirm identity',
	verifyCode = 'verification code',
	refund = 'refund',
	reset = 'reset',
	optionsModal = 'options modal',
	none = '',
}

export interface ModalStatus {
	openModal: Modals;
	fanData: FanData;
}
