import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory, useParams, generatePath } from 'react-router-dom';

import { setTicketsSelected } from 'store/slices/BoxOfficeSlice';
import { MainLayout } from 'layouts/MainLayout';
import { SelectTicketTypesBody } from './SelectTicketTypesBody';
import useGetEvent from '../common/hooks/useGetEvent';
import { selectTicket } from 'assets/images';
import { MainHeader } from 'features/common/components/MainHeader';
import { OrderPreview } from './OrderPreview';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { defaultValues } from './utils';
import { Form } from 'features/common/components/Form';
import { Icon } from 'features/common/components/Icon';
import { ROUTES } from 'features/routes';
import { IdParamsType } from 'utils/types';

import { SubTitle, Img, Wrapper, Header } from './BoxOffice.styles';

export const BoxOffice = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { event } = useGetEvent();
	const { ticketsSelected } = useAppSelector(({ boxOffice }: RootState) => boxOffice);
	const { push } = useHistory();
	const { id } = useParams<IdParamsType>();

	const formMethods = useForm({
		defaultValues,
	});

	useEffect(() => {
		dispatch(setTicketsSelected(false));
	}, []);

	return (
		<Form formMethods={formMethods} onSubmit={() => {}}>
			<MainLayout>
				<Header lessPadding gap="12px">
					<Icon
						iconName="arrowLeft"
						onClick={() => push(generatePath(ROUTES.DASHBOARD_PATH, { id }))}
						cursor="pointer"
					/>
					<MainHeader.Title element="h1">{t('boxOffice-tickets')}</MainHeader.Title>
				</Header>
				{!ticketsSelected ? (
					<Wrapper flexDirection="column" gap="32px">
						<SubTitle>
							<Img src={selectTicket} />
							{t('selectTicketType')}
						</SubTitle>
						{event && <SelectTicketTypesBody />}
					</Wrapper>
				) : (
					<OrderPreview />
				)}
			</MainLayout>
		</Form>
	);
};
