import i18n from 'locals/i18n';
import { common } from 'theme';

export const COLUMN_HEADINGS = [
	i18n.t('payouts-columns-event'),
	i18n.t('payouts-columns-amount'),
	i18n.t('payouts-columns-bank'),
	i18n.t('payouts-columns-last4'),
	i18n.t('payouts-columns-payoutType'),
	i18n.t('payouts-columns-initiatedOn'),
	i18n.t('payouts-columns-status'),
];

export const COLUMN_MIN_WIDTH = [150, 150, 150, 150, 150, 150, 150];

export const GRID_COLUMNS = 'repeat(7, 1fr)';

export const statusOptions = {
	payout_updated: {
		color: common.colors.grey9,
		text: i18n.t('payouts-status-updated'),
	},
	payout_pending: {
		color: common.colors.grey9,
		text: i18n.t('payouts-status-pending'),
	},
	payout_created_at_stripe: {
		color: common.colors.grey9,
		text: i18n.t('payouts-status-created'),
	},
	payout_paid: {
		color: common.colors.greenSuccess,
		text: i18n.t('payouts-status-paid'),
	},
	payout_canceled: {
		color: common.colors.red,
		text: i18n.t('payouts-status-canceled'),
	},
	payout_failed: {
		color: common.colors.red,
		text: i18n.t('payouts-status-failed'),
	},
	payout_declined: {
		color: common.colors.red,
		text: i18n.t('payouts-status-declined'),
	},
};
