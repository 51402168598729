import styled from 'styled-components';

import { Table } from 'features/common/components/Table';
import { Button as UnstyledButton } from 'features/common/components/Button';

export const Root = styled(Table.TableContainer)`
	margin: 0;
	width: 100%;
`;

export const Button = styled(UnstyledButton)`
	border-color: ${({ theme }) => theme.colors.lightGrey9};
	color: ${({ theme }) => theme.colors.lightGrey9};
	width: fit-content;
	padding: 8px 32px;
`;

export const AcceptedDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
