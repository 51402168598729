import { FC } from 'react';
import { RadioButton } from 'features/common/components/RadioButton/RadioButton.styles';
import { MobileFilterProps } from './types';

import {
	MobileContainer,
	IconFilter,
	OptionsContainer,
	Option,
	SecondaryOption,
	SecondaryOptionsContainer,
} from './LocalFilter.styles';

const MobileFilter: FC<MobileFilterProps> = ({
	onClickSelect,
	isOpened,
	isLargeScreen,
	options,
	selectedOption,
	onClickOption,
	responsiveSecondary,
}: MobileFilterProps) => {
	const isCheckedOption = (option: string) => option === selectedOption?.value;

	return (
		<MobileContainer responsiveSecondary={responsiveSecondary}>
			{responsiveSecondary ? (
				<SecondaryOptionsContainer onClick={onClickSelect}>
					{options.map((option) => (
						<SecondaryOption
							key={option.value}
							checked={isCheckedOption(option.value)}
							responsiveSecondary={responsiveSecondary}
							onClick={() => onClickOption(option)}
							value={option.value}
						>
							{option.label}
						</SecondaryOption>
					))}
				</SecondaryOptionsContainer>
			) : (
				<>
					<IconFilter iconName="filter" onClick={onClickSelect} />
					<OptionsContainer isOpened={isOpened} isLargeScreen={isLargeScreen}>
						{isOpened &&
							options.map((option) => (
								<Option key={option.value} onClick={() => onClickOption(option)}>
									<RadioButton
										type="radio"
										checked={isCheckedOption(option.value)}
										name={option.label}
										value={option.value}
										onClick={() => onClickOption(option)}
									/>
									{option.label}
								</Option>
							))}
					</OptionsContainer>
				</>
			)}
		</MobileContainer>
	);
};

export { MobileFilter };
