import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { TicketRow } from './TicketRow';
import { Buttons } from './Buttons';
import { PromoCodeInput } from './PromoCodeInput';
import { PriceRow } from './PriceRow';
import { useGetLastEvent } from '../hooks/useGetLastEvent';
import useGetTaxes from '../hooks/useGetTaxes';
import { useGetPromoCodeData } from '../hooks/useGetPromoCodeData';
import { handleApplyPromoCodes, handleSubtotal, handleTaxesAndFees, handleTotal, handleQuantity } from './utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { MultidayType } from 'features/common/types';
import { CustomFeesMap } from './types';
import { useMobileView } from 'hooks/useMobileView';

import { Container, Currency, Title, TotalPrice } from './OrderSummary.styles';
import { Divider } from '../BoxOffice.styles';

export const OrderSummary = () => {
	const [myTaxes, setMyTaxes] = useState<number>(0);
	const [myFees, setMyFees] = useState<number>(0);
	const [customFees, setCustomFees] = useState<CustomFeesMap>();
	const [promoCodeId, setPromoCodeId] = useState<number>(0);
	const [ticketsWithPromoCodes, setTicketsWithPromoCodes] = useState<number[]>([]);
	const [promoCodeApplied, setPromoCodeApplied] = useState<boolean>(true);
	const [isFree, setIsFree] = useState<boolean>(false);
	const { t } = useTranslation();
	const { event, isMultiDay } = useGetLastEvent();
	const { taxes } = useGetTaxes();
	const { promoCodeData } = useGetPromoCodeData({ promoCodeId, setPromoCodeApplied });
	const { multiday } = useAppSelector(({ lastMultiDay }: RootState) => lastMultiDay);
	const dispatch = useAppDispatch();
	const isMobile = useMobileView();
	const { watch, setValue } = useFormContext();

	useEffect(() => {
		if (event && multiday) {
			setIsFree(handleSubtotal({ event, multiday, isMultiDay }) === 0);
		}
	}, [event, isMultiDay, multiday]);

	useEffect(() => {
		if (event && multiday && taxes) {
			if (!promoCodeApplied) {
				dispatch(
					handleApplyPromoCodes({
						event,
						ticketsWithPromoCodes,
						promoCodeData,
						setPromoCodeApplied,
						isMultiDay,
						multiday,
					})
				);
			}

			handleTaxesAndFees({ event, taxes, setMyFees, setCustomFees, setMyTaxes, isMultiDay, multiday });
		}
	}, [event, promoCodeApplied, JSON.stringify(taxes), multiday, isMultiDay]);

	useEffect(() => {
		if (event && multiday) {
			const customFeesTotal = customFees ? Object.values(customFees).reduce((acc, fee) => acc + fee, 0) : 0;
			setValue(
				'total',
				isFree
					? '0'
					: handleTotal({ myTaxes, myFees, customFees: customFeesTotal, event, isMultiDay, multiday }).toFixed(2)
			);
			setValue('fixedPrice', (handleSubtotal({ event, multiday, isMultiDay }) as number).toFixed(2));
		}
	}, [event, myTaxes, myFees, isMultiDay, multiday, isFree]);

	useEffect(() => {
		if (ticketsWithPromoCodes.length) setValue('ticketsWithPC', ticketsWithPromoCodes);
	}, [ticketsWithPromoCodes]);

	const handleTicketRow = () =>
		isMultiDay
			? // TODO: uncomment when multiday feature exits, on the meanwhile we will use multiday[0]
			  // multiday?.map(MultidayTicketRow)
			  (multiday as MultidayType[])[0].ticketTypes.map((ticket) => ticket.quantity > 0 && TicketRow(ticket))
			: event?.ticketTypes.map((ticket) => ticket.quantity > 0 && TicketRow(ticket));

	const handleAtLeastOne = () => Number(handleQuantity({ multiday, event, isMultiDay })) > 0;

	return (
		<FlexContainer width={isMobile ? '100%' : '50%'} justifyContent="center">
			{event && multiday && (
				<Container>
					<Title>{t('orderSummary')}</Title>
					<FlexContainer flexDirection="column" gap="8px">
						{handleTicketRow()}
					</FlexContainer>
					<Divider />
					<FlexContainer flexDirection="column" gap="8px">
						<PriceRow title={t('orderSummary-subTotal')} price={watch('fixedPrice')} />
						{!isFree && (
							<>
								{customFees &&
									event.customFees.map(({ ID, name }) => (
										<PriceRow key={ID} title={name} price={customFees[ID]?.toFixed(2)} />
									))}
								<PriceRow title={t('fees')} price={myFees.toFixed(2)} />
								<PriceRow title={t('taxes')} price={myTaxes.toFixed(2)} />
							</>
						)}
					</FlexContainer>
					<Divider />
					{!isFree && (
						<FlexContainer justifyContent="space-between">
							<TotalPrice>{t('total')}</TotalPrice>
							<FlexContainer alignItems="center" gap="4px">
								<Currency>USD</Currency>
								<TotalPrice>${watch('total')}</TotalPrice>
							</FlexContainer>
						</FlexContainer>
					)}
					<PromoCodeInput setPromoCodeId={setPromoCodeId} setTicketsWithPromoCodes={setTicketsWithPromoCodes} />
					<Buttons atLeastOne={handleAtLeastOne()} isFree={isFree} />
				</Container>
			)}
		</FlexContainer>
	);
};
