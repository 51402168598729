import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Form } from 'features/common/components/Form';
import { ImgWithHover } from 'features/common/components/ImageInputs';
import { InputLabel } from 'features/common/components/Form/InputLabel';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { EventSchedule } from 'features/common/components/EventSchedule';
import { allowsOnlyNumbers } from 'utils/numberInput';
import { EventModeProp } from 'features/CreateEvent/components/CreateEventForm/types';
import { EVENT_MODE_NORMAL, EVENT_MODE_REGISTRATION } from 'utils/constants';

export const KeyDetails = ({ eventMode }: EventModeProp) => {
	const { t } = useTranslation();
	const { watch } = useFormContext();
	const address = watch('location');

	return (
		<>
			<Form.Input
				id="name"
				label={t('createEvent-eventSummary-name')}
				name="name"
				placeholder={t('createEvent-name-placeholder')}
				autoComplete="off"
			/>
			<Form.InputText
				id="description"
				name="description"
				label={t('createEvent-eventSummary-description')}
				placeholder={t('createEvent-description-placeholder')}
			/>
			<Form.AddressAutocomplete
				id="location"
				name="location"
				label={t('createEvent-eventSummary-location')}
				value={address}
			/>
			{eventMode === EVENT_MODE_NORMAL && (
				<Form.Input
					autoComplete="off"
					id="postal_code"
					label={t('createEvent-zip-code')}
					name="postal_code"
					placeholder={t('createEvent-zip-code-placeholder')}
					info={t('only-usa')}
				/>
			)}
			{eventMode === EVENT_MODE_REGISTRATION && (
				<Form.Input
					id="quantity"
					label={t('createEvent-registrations')}
					name="quantity"
					placeholder="100"
					type="number"
					onKeyDown={allowsOnlyNumbers}
				/>
			)}
			{/* {eventMode === EVENT_MODE_REGISTRATION && (
				<Form.InputToggle htmlFor="approval_required" name="approval_required" label={t('createEvent-approval')} />
			)} */}
			<FlexContainer flexDirection="column" width="100%">
				<InputLabel htmlFor="images" label={t('generic-event-image')} />
				<ImgWithHover inputId="images" />
			</FlexContainer>
			<EventSchedule isEventCreation />
			<Form.InputDateAndTime
				id="sale_date"
				name="sale_date"
				label={t(`createEvent-${eventMode == EVENT_MODE_NORMAL ? 'tickets' : 'reg'}-saleDate`)}
			/>
			<Form.TimeZone />
		</>
	);
};
