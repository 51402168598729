import styled from 'styled-components';

import { ContainerStylesProps } from './types';

export const Container = styled.nav<ContainerStylesProps>`
	position: fixed;
	height: 100%;
	width: ${({ theme }) => theme.widths.sidebar};
	left: 0;
	top: 0;
	transform: translateX(${({ isOpened }) => (isOpened ? '0vw' : '-84%')});
	transition: all 0.2s ease;
	background-color: ${({ theme }) => theme.colors.grey};
	z-index: 10;
	box-shadow: ${({ theme }) => theme.shadows.menuShadow};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		transform: translateX(${({ isOpened }) => (isOpened ? '0vw' : '-100%')});
		top: 125px;
	}
`;

export const Content = styled.div`
	align-items: flex-end;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	width: 100%;

	a {
		padding: 5px;
		height: 43px;
		min-height: 43px;
		min-width: 91px;
		width: 91px;
	}
`;

export const LinkMenuIcon = styled.img`
	max-width: unset;
`;

export const MenuIcon = styled.img`
	display: none;
`;

export const Logo = styled.img`
	padding: 35px 65px 30px 20px;
`;
