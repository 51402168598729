import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { queryClient } from 'index';
import { allowsOnlyNumbers } from 'utils/numberInput';
import { IdParamsType, RequiredFieldsProps } from 'utils/types';
import { Form } from 'features/common/components/Form';
import { EventProps } from 'features/Home/components/Events/types';
import { EVENT_MODE_NORMAL, EVENT_MODE_REGISTRATION, ZERO_TIME } from 'utils/constants';
import { ticketingModeOptions } from 'features/CreateEvent/components/CreateEventForm/EventSummary/constants';

const LeftForm: FC<RequiredFieldsProps> = ({ isFromRequiredFields }: RequiredFieldsProps) => {
	const { t } = useTranslation();
	const { watch } = useFormContext();
	const { id }: IdParamsType = useParams();
	const event: EventProps = queryClient.getQueryData([`event-${id}`]) as EventProps;

	const mode = watch('mode');
	const address = watch('location');
	const sellingTime = watch('selling_time');
	const zeroTime = event?.selling_time === ZERO_TIME;

	return (
		<>
			<Form.Input id="name" name="name" label={t('editEvent-eventName')} />
			<Form.Input id="venue" name="venue" label={t('editEvent-venue')} />
			<Form.AddressAutocomplete id="location" name="location" label={t('editEvent-eventLocation')} value={address} />
			{mode === EVENT_MODE_NORMAL && <Form.Input id="postal_code" name="postal_code" label={t('editEvent-zip-code')} />}
			{mode === EVENT_MODE_REGISTRATION && (
				<Form.Input
					id="quantity"
					label={t('createEvent-registrations')}
					name="quantity"
					placeholder="100"
					type="number"
					onKeyDown={allowsOnlyNumbers}
				/>
			)}
			{/* {mode === EVENT_MODE_REGISTRATION && (
				<Form.InputToggle htmlFor="approval_required" name="approval_required" label={t('createEvent-approval')} />
			)} */}
			<Form.TimeZone />
			<Form.InputText id="description" name="description" label={t('editEvent-event-description')} />
			{mode === EVENT_MODE_NORMAL && (
				<Form.Select
					id="ticketing_mode"
					name="ticketing_mode"
					label={t('editEvent-event-ticketingMode')}
					options={ticketingModeOptions}
					withDefaultValue={false}
				/>
			)}
			<Form.InputDateAndTime
				id="selling_time"
				name="selling_time"
				label={t(`createEvent-${mode == EVENT_MODE_NORMAL ? 'tickets' : 'reg'}-saleDate`)}
				value={zeroTime ? null : new Date(sellingTime)}
				isFromRequiredFields={isFromRequiredFields && zeroTime}
			/>
			<Form.InputText
				id="custom_email_message"
				name="custom_email_message"
				label={t('editEvent-custom-email-message')}
				subtitle={t('editEvent-custom-email-message-subtitle')}
			/>
			<Form.Input
				id="support"
				name="support"
				label={t('createEvent-advancedDetails-support')}
				placeholder={t('createEvent-advancedDetails-supportCopy')}
			/>
		</>
	);
};

export { LeftForm };
