import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DateOptionProps, AllDateFilters } from '../../types';
import { RadioButton } from 'features/common/components/RadioButton/RadioButton.styles';
import { allPages } from 'features/common/hooks/useFilterButtonClick';

import { Option } from '../../RTKFilter.styles';

export const DateOption: FC<DateOptionProps> = ({ option, className, selectedPeriod, page }) => {
	const { t } = useTranslation();
	const { translationKey } = AllDateFilters[option];

	const dispatch = useDispatch();
	const checked = () => selectedPeriod.unapplied === option;

	return (
		<Option className={className} onClick={(e) => e.stopPropagation()}>
			<RadioButton
				type="radio"
				checked={checked()}
				name={option}
				value={option}
				onChange={() => dispatch(allPages[page].dateCancel(option))}
			/>
			{t(translationKey)}
		</Option>
	);
};
