import { useEffect } from 'react';
import { Notifier } from '@airbrake/browser';

import { ErrorBoundaryType, INotice, INoticeError } from 'utils/types';
import { GET_CONTEXT_NULL, INVALID_ADDRESS } from 'utils/errors';

export const airbrake = new Notifier({
	projectId: Number(process.env.REACT_APP_AIRBRAKE_PROJECT_ID),
	projectKey: String(process.env.REACT_APP_AIRBRAKE_PROJECT_KEY),
	environment: String(process.env.REACT_APP_AIRBRAKE_ENVIRONMENT),
});

const ErrorBoundary = ({ children }: ErrorBoundaryType) => {
	airbrake.addFilter(function (notice: INotice) {
		const errors = notice.errors as INoticeError[];
		const error = errors[0];
		if (error.message === (GET_CONTEXT_NULL || INVALID_ADDRESS)) {
			return null;
		}
		return notice;
	});

	useEffect(() => {
		const handleError = (error: string | Event, info: string | undefined) => {
			airbrake.notify({
				error: error,
				params: { info: info },
			});
		};

		window.onerror = handleError;

		return () => {
			window.onerror = null;
		};
	}, []);

	return children;
};

export default ErrorBoundary;
