import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Form } from 'features/common/components/Form';

import { SendTicketsSection } from 'features/SendTickets/SendTickets.styles';

export const Root = styled(SendTicketsSection)`
	padding: 46px 100px;
	gap: 16px;

	background-color: ${({ theme }) => theme.colors.grey4};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 18px;
	}
`;

export const Subtitle = styled(Text)`
	font-size: 32px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 20px;
	}
`;

export const Select = styled(Form.Select)`
	margin-bottom: 20px;
	width: 90% !important;
`;

export const AddRecipientBtn = styled.div`
	cursor: pointer;
	background-color: ${({ theme }) => theme.colors.lightGrey14};
	padding: 8px 0px;
	font-family: ${({ theme }) => theme.fonts.primary};
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
`;
