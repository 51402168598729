import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { getData } from 'services/api';
import { CohortData } from './types';
import { Event } from 'features/common/components/Filters/types';

export const useAudienceData = () => {
	const [search, setSearch] = useState('');
	const [totalPerPage, setPerPage] = useState(4);
	const isViewAll = totalPerPage === -1;

	const { data: uncamelizedCohortsData } = useQuery({
		queryKey: ['cohorts'],
		queryFn: () => getData('/marketing/cohorts?per_page=-1'),
	});

	const eventsPerPage = isViewAll ? -1 : totalPerPage - (uncamelizedCohortsData?.length || totalPerPage);

	const { data: uncamelizedEventsData } = useQuery({
		queryKey: ['audience-events', eventsPerPage, search],
		queryFn: () => getData(`/events?date=all_time&per_page=${eventsPerPage}&search=${search}`),
		enabled: isViewAll || eventsPerPage > 0 || !!search,
	});

	const eventsData = camelizeKeys(uncamelizedEventsData) as Event[];
	const cohortsData = camelizeKeys(uncamelizedCohortsData) as CohortData[];

	return { cohortsData, eventsData, search, setSearch, totalPerPage, setPerPage };
};
