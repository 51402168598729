import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { ExtraWidthProp, IsDraft } from '../../types';

export const CampaignContainer = styled.div<IsDraft>`
	box-sizing: border-box;
	cursor: ${({ isDraft }) => isDraft && 'pointer'};
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.colors.grey2};
	max-width: 1300px;
	width: 100%;
	padding: 42px 32px;
`;

export const InfoFlex = styled.div`
	align-items: center;
	display: flex;
	gap: 16px;
	justify-content: space-between;
	width: calc(100% - 25px);
	max-width: 1000px;
`;

export const LabeledGroup = styled.div<ExtraWidthProp>`
	display: flex;
	flex-direction: column;
	height: 48px;
	line-height: normal;
	width: ${({ extraWidth }) => (extraWidth ? '30%' : '90px')};
`;

export const Label = styled(Text)`
	color: ${({ theme }) => theme.colors.lightGrey10};
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: normal;
`;

export const InfoText = styled(Text)<ExtraWidthProp>`
	font-size: 18px;
	font-weight: 600;
	line-height: normal;
	width: ${({ extraWidth }) => (extraWidth ? '30%' : '100%')};
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;
