import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';

import useGetEvent from 'features/common/hooks/useGetEvent';
import { useReorderCustomFieldsApi } from './hooks';
import { ROUTES } from 'features/routes';
import { MainHeader } from 'features/common/components/MainHeader';
import { useGetCustomFields } from 'features/common/components/CustomFieldsComponent/useGetCustomFields';
import { CustomField } from 'features/CustomFields/types';
import { onDragEnd, transformData } from './utils';
import { MainLayout } from 'layouts/MainLayout';
import { Fields } from './Fields/Fields';

import * as Styled from './Reorder.styles';

export const Reorder = () => {
	const { t } = useTranslation();
	const { goBack, push } = useHistory();

	const { event } = useGetEvent();
	const { customFields: customFieldsData } = useGetCustomFields(true);
	const { reorderCustomFields } = useReorderCustomFieldsApi();

	const [rows, setRows] = useState<number[]>([]);
	const [customFields, setCustomFields] = useState<CustomField[]>([]);

	useEffect(() => {
		if (!customFieldsData) return;
		const { rows, newCustomFields } = transformData(customFieldsData);
		setRows(rows);
		setCustomFields(newCustomFields);
	}, [customFieldsData]);

	const onAdd = () => push(generatePath(ROUTES.DASHBOARD_ADD_CUSTOM_FIELDS, { eventId: event?.id }));
	const onEdit = (id: number) => push(generatePath(ROUTES.DASHBOARD_EDIT_CUSTOM_FIELDS, { eventId: event?.id, id }));

	const onReorder = () => {
		if (customFields.length > 0) {
			reorderCustomFields(customFields.map((cf) => ({ ID: cf.ID, row: cf.row })));
		}
	};

	return (
		<MainLayout>
			<MainHeader lessPadding>
				<Styled.HeaderWrapper>
					<Styled.InfoContainer>
						<Styled.TextContainer>
							<Styled.Title>{t('customFields-reorder-title')} </Styled.Title>
							<Styled.Subtitle> {event?.name}</Styled.Subtitle>
						</Styled.TextContainer>
					</Styled.InfoContainer>
				</Styled.HeaderWrapper>
			</MainHeader>

			<Styled.ContainersWrapper>
				<Styled.DragDropContainer>
					<DragDropContext onDragEnd={(result) => setCustomFields(onDragEnd(result, customFields, rows))}>
						<Droppable droppableId="droppable" direction="vertical">
							{(provided, snapshot) => {
								return (
									<Styled.DraggableContainer
										ref={provided.innerRef}
										{...provided.droppableProps}
										className={snapshot.isDraggingOver ? 'dragging-over' : ''}
									>
										<Fields rows={rows} customFields={customFields} onEdit={onEdit} />
										{provided.placeholder}
									</Styled.DraggableContainer>
								);
							}}
						</Droppable>
					</DragDropContext>

					<Styled.ButtonContainer>
						<Styled.SubmitButton text={t('add')} category="outlined" onClick={onAdd} />
					</Styled.ButtonContainer>
				</Styled.DragDropContainer>
			</Styled.ContainersWrapper>

			<Styled.ActionButtonsContainer>
				<Styled.ActionButton text={t('cancel')} onClick={goBack} category="outlined" />
				<Styled.ActionButton text={t('save')} onClick={onReorder} />
			</Styled.ActionButtonsContainer>
		</MainLayout>
	);
};
