import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import todosReducer from 'features/Home/slices/ExampleSlice';
import PromoCodesFormSlice from 'features/PromoCodesForm/slice/PromoCodesFormSlice';
import FanDatabaseSlice from 'features/FanDatabase/Slices/FanDatabaseSlice';
import AuthSlice from './slices/AuthSlice';
import GoogleSlice from './slices/GoogleSlice';
import LoginSlice from './slices/LoginSlice';
import OrdersPageSlice from './slices/OrdersPageSlice';
import RefundableTicketsSlice from './slices/RefundableTicketsSlice';
import lastEvent from './slices/lastEventSlice/index';
import lastMultiDay from './slices/lastMultiDayEventSlice/index';
import boxOfficeSlice from './slices/BoxOfficeSlice';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth'],
};

const rootReducer = combineReducers({
	todos: todosReducer,
	promoCodesForm: PromoCodesFormSlice,
	fanDatabase: FanDatabaseSlice,
	ordersPage: OrdersPageSlice,
	refundableTickets: RefundableTicketsSlice,
	auth: AuthSlice,
	google: GoogleSlice,
	login: LoginSlice,
	lastEvent,
	lastMultiDay,
	boxOffice: boxOfficeSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
