import { MutableRefObject } from 'react';
import { camelizeKeys } from 'humps';

import api, { getData, post } from 'services/api';
import { CohortInfo, EmailCampaignData } from './types';

export const getNanosToken = async () => {
	return await getData('/login/nanos');
};

export const getEmailCampaigns = async (
	{ pageParam = 1 },
	numOfPages: MutableRefObject<number>
): Promise<EmailCampaignData[]> => {
	const perPage = 5;
	const { data, headers } = await api.get(`/marketing/email-campaigns?per_page=${perPage}&page=${pageParam}`);
	numOfPages.current = Math.ceil(parseInt(headers['x-total-count']) / perPage);
	const camelizedCampaignData: Promise<EmailCampaignData[]> = camelizeKeys(data) as any;
	return await camelizedCampaignData;
};

export const postCohort = async ({ name, file }: CohortInfo) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('name', name);
	return post('/marketing/cohorts', formData);
};
