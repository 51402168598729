import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterOptionType } from 'features/common/components/Filters/LocalFilter/types';
import { LocalFilter } from 'features/common/components/Filters/LocalFilter';
import { Search } from 'features/common/components/Search';
import { CreateEvent } from './CreateEvent';
import { FILTER_OPTIONS } from 'features/common/components/Filters/LocalFilter/constants';
import { debounce } from 'utils/utils';
import { Event } from './Event/';
import { useGetEvents } from 'hooks/useGetEvents';

import { Container, EventsContainer, EventsDivider, Title, Wrapper } from './Events.styles';
import { LazyLoader } from 'features/common/animations/LazyLoader';

const Events: FC = () => {
	const { t } = useTranslation();
	const [selectedOption, setSelectedOption] = useState<FilterOptionType>(FILTER_OPTIONS[2]);
	const [searchQuery, setSearchQuery] = useState<string>('');

	const onChangeSearch = debounce(setSearchQuery);

	const onChangeFilter = (option: FilterOptionType) => {
		setSelectedOption(option);
	};

	const { items, isLoading } = useGetEvents({ selectedOption, searchQuery, per_page: 10 });

	const hasEvents = Boolean(items);
	return (
		<Wrapper>
			<Title element="h2">{t('events')}</Title>
			<Container>
				<Search width="300px" onChange={(e) => onChangeSearch(e.target.value)} placeholder={t('events-search')} />
				<LocalFilter onChange={onChangeFilter} options={FILTER_OPTIONS} selectedOption={selectedOption} />
			</Container>
			<EventsDivider />
			<EventsContainer>
				{isLoading ? (
					<LazyLoader height="90vh" margin="96px 0 0 0" />
				) : (
					<>
						<CreateEvent hasEvents={hasEvents} />
						{items?.map(Event)}
					</>
				)}
			</EventsContainer>
		</Wrapper>
	);
};

export { Events };
