import styled from 'styled-components';

import { SectionTitleContainerProps } from './types';
import { Button } from 'features/common/components/Button';

export const SectionTitleContainer = styled.div<SectionTitleContainerProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	// TODO: Delete this prop (and the interface if there are not more props) when other screens's responsiveness are done
	${({ paddingVertical, theme }) =>
		paddingVertical
			? `
		padding: 20px 3% 20px 6%;
		@media screen and (max-width: ${theme.breakpoints.sm}) {
			padding: 20px 3%;
		}
		`
			: `
	padding: 20px 0
	`}
`;

export const Row = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		align-items: flex-end;
		flex-direction: column;
		gap: 5px;
	}
`;

export const Title = styled.div`
	display: flex;
	font-size: 25px;
	font-weight: 600;
	justify-content: space-between;
	font-family: ${({ theme }) => theme.fonts.primary};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
		font-size: 20px;
	}
`;

export const SButton = styled(Button)`
	width: fit-content;
	margin: 0;
	margin-bottom: 11px;
	padding: 8px 41px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 5px 11px;
		margin-bottom: 0px;
	}
`;
