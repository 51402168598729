import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { getData } from 'services/api';
import { ROUTES } from 'features/routes';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { Divider } from 'features/common/components/Divider';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { SectionTitle } from 'features/common/components/SectionTitle';
import { TermsData } from '../../types';
import { AvailableToS } from './AvailableToS';

import { StyledLoader } from 'features/common/animations/Loader/Loader.styles';
import { Container } from './ToSSection.styles';

export const ToSSection: FC = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const isLargeScreen = useResponsiveScreen();
	const pushToPath = (path: string, id: string) => push(generatePath(path, { id }));

	const terms = useQuery({
		queryFn: (): Promise<TermsData[]> => getData('/partners/tos'),
		queryKey: ['all-terms'],
		initialData: [],
	});

	return (
		<FlexContainer flexDirection="column">
			<SectionTitle
				title={t('settings-tosSettings-title')}
				buttonLabel={t('dashboard-add')}
				onClick={() => pushToPath(ROUTES.TERMS_OF_SERVICE, 'new')}
			/>
			{!isLargeScreen && <Divider />}
			<Container>
				{terms.isLoading ? (
					<FlexContainer justifyContent="center" alignItems="center" width="100%">
						<StyledLoader />
					</FlexContainer>
				) : (
					<AvailableToS terms={terms} pushToPath={pushToPath} t={t} />
				)}
			</Container>
		</FlexContainer>
	);
};
