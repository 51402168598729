import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { Divider } from 'features/common/components/Divider';
import { ROUTES } from 'features/routes';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { SectionTitle } from 'features/common/components/SectionTitle';
import { IdParamsType } from 'utils/types';
import { useGetAffiliates } from 'hooks/useGetAffiliates/useGetAffiliates';
import { DASHBOARD_AFFILIATES } from 'utils/constants';
import { SectionContent } from './SectionContent';
import { FlexContainer } from 'features/common/components/FlexContainer';

import { StyledLoader } from 'features/common/animations/Loader/Loader.styles';
import { AffiliatesContainer } from './AffiliatesSection.styles';
import { AffiliateSectionProps } from './types';

export const AffiliatesSection: FC<AffiliateSectionProps> = ({ event }) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { id }: IdParamsType = useParams();
	const isLargeScreen = useResponsiveScreen();
	const pushToPath = (path: string, id: string) => push(generatePath(path, { id }));
	const { affiliates, affiliateCt } = useGetAffiliates({ queryKey: [DASHBOARD_AFFILIATES], eventId: id, perPage: -1 });

	return (
		<>
			<SectionTitle
				title={t('dashboard-affiliates')}
				buttonLabel={t('dashboard-add')}
				onClick={() => pushToPath(ROUTES.DASHBOARD_NEW_AFFILIATE, id)}
			/>
			<AffiliatesContainer>
				{affiliates.isLoading ? (
					<FlexContainer justifyContent="center" alignItems="center" width="100%">
						<StyledLoader />
					</FlexContainer>
				) : (
					<SectionContent affiliateCt={affiliateCt} id={id} pushToPath={pushToPath} t={t} event={event} />
				)}
			</AffiliatesContainer>
			{!isLargeScreen && <Divider />}
		</>
	);
};
