import { FC, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { ProgressBar } from 'features/common/components/ProgressBar';
import { ROUTES } from 'features/routes';
import { EventCardProps } from './types';

import * as Styled from './EventCard.styles';

const EventCard: FC<EventCardProps> = ({ status, title, date, time, image, id, ticketTypes }: EventCardProps) => {
	const { push } = useHistory();

	const onClickCard = () => push(ROUTES.DASHBOARD_PATH.replace(':id', String(id)));
	const { totalAvailable, totalSold } = ticketTypes.reduce(
		(acc, { amount, sold }) => ({
			totalAvailable: acc.totalAvailable + +amount,
			totalSold: acc.totalSold + +sold,
		}),
		{ totalAvailable: 0, totalSold: 0 }
	);

	return (
		<Styled.Container onClick={onClickCard}>
			<Styled.ImageContainer>
				<Styled.Image src={image} />
				<Styled.StatusContainer status={status}>
					<Styled.StatusColor status={status} />
					<Styled.Info>{status}</Styled.Info>
				</Styled.StatusContainer>
			</Styled.ImageContainer>
			<Styled.InfoContainer>
				<Styled.Title>{title}</Styled.Title>
				<Styled.Row>
					<Styled.Info>
						{date} - {time}
					</Styled.Info>
				</Styled.Row>
				<ProgressBar progressQty={totalSold} totalAvailable={totalAvailable} />
			</Styled.InfoContainer>
		</Styled.Container>
	);
};

const memorizedEventCard: FC<EventCardProps> = memo(EventCard);

export { memorizedEventCard as EventCard };
