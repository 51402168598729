import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Divider } from 'features/common/components/Divider';

export const Title = styled(Text)`
	line-height: 27px;
	margin-left: 3%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 18px;
	}
`;

export const Container = styled.div`
	display: flex;
	margin-bottom: 24px;
	padding: 0 3%;
	gap: 12px;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
		justify-content: space-around;
	}
`;

export const EventsContainer = styled.div`
	min-height: 80vh;
	margin-top: 17px;
	display: grid;
	grid-gap: 37px;
	grid-template-columns: repeat(auto-fit, minmax(423px, 423px));
	justify-content: center;
	margin-bottom: 50px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: flex;
		flex-direction: column;
	}
`;

export const Wrapper = styled.div`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: flex;
		padding: 0px 10px;
		align-items: center;
		flex-direction: column;
	}
`;

export const EventsDivider = styled(Divider)`
	margin: 0 3%;
`;
