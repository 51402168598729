import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { WelcomeToNanos } from './WelcomeToNanos';
import { ViewEmailCampaigns } from './ViewEmailCampaigns';
import { ManageNanos } from './ManageNanos';
import { getData } from 'services/api';
import { MainLayout } from 'layouts/MainLayout';
import { EMAIL_CAMPAIGN, SHOW_NANOS_SCREEN, SOCIAL_MEDIA } from './constants';
import { MainHeader } from 'features/common/components/MainHeader';
import { ROUTES } from 'features/routes';

import { SubheaderContainer, TabButton } from 'features/common/components/TabSubheader';

export const Marketing = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { search } = useLocation();
	const tab = search.replace('?tab=', '');

	const { data: hasVisitedMarketing, isLoading } = useQuery({
		queryKey: SHOW_NANOS_SCREEN,
		queryFn: (): Promise<boolean> => getData('partners/marketing'),
	});
	const SocialMediaComponent = hasVisitedMarketing ? ManageNanos : WelcomeToNanos;

	if (isLoading) return <></>;
	return (
		<MainLayout>
			<MainHeader>
				<MainHeader.Title element="h1">{t('marketing-title')}</MainHeader.Title>
			</MainHeader>
			<SubheaderContainer>
				<TabButton
					className={tab !== EMAIL_CAMPAIGN ? 'selected' : ''}
					onClick={() => push(`${ROUTES.MARKETING_PATH}?tab=${SOCIAL_MEDIA}`)}
					disabled={tab !== EMAIL_CAMPAIGN}
				>
					{t('marketing-social-media')}
				</TabButton>
				<TabButton
					className={tab === EMAIL_CAMPAIGN ? 'selected' : ''}
					onClick={() => push(`${ROUTES.MARKETING_PATH}?tab=${EMAIL_CAMPAIGN}`)}
					disabled={tab === EMAIL_CAMPAIGN}
				>
					{t('marketing-email-campaigns')}
				</TabButton>
			</SubheaderContainer>
			{tab !== EMAIL_CAMPAIGN ? <SocialMediaComponent /> : <ViewEmailCampaigns />}
		</MainLayout>
	);
};
