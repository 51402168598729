import styled from 'styled-components';

export const CustomFlexContainer = styled.div`
	width: 91%;
	padding: 40px 3% 40px 6%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px 22px;
		width: calc(100% - 22px - 22px);
	}
`;
