import styled from 'styled-components';
import { Carousel as UnstyledCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Modal } from 'features/common/components/Modal/Modal';
import { Text as UnstyledText } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';

export const ContainerBody = styled.div`
	gap: 16px;
	padding: 30px;
	min-height: 250px;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
	align-content: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 30px 20px;
	}
`;

export const CustomModal = styled(Modal)`
	background-color: ${({ theme }) => theme.colors.lightGrey};
	border-radius: 16px;
	height: fit-content;
	margin: auto;
	width: 1000px;
	margin-top: 90px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: 614px;
		margin-top: 0px;
	}
`;

export const CarouselContainer = styled.div`
	gap: 20px;
	width: 100%;
	display: flex;
	align-items: center;
	padding-bottom: 10px;
	flex-direction: column;
	justify-content: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 90%;
	}
`;

export const Title = styled(UnstyledText)`
	max-width: 60%;
	line-height: 48px;
	text-align: center;
	text-transform: none;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		min-width: 90%;
		line-height: 30px;
	}
`;

export const CenteredContainer = styled.div`
	flex: 1;
	gap: 8px;
	width: 80%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 90%;
	}
`;

export const ButtonContainer = styled.div`
	gap: 16px;
	display: flex;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 8px;
		flex-direction: column;
	}
`;

export const SubmitButton = styled(Button)`
	width: 240px;
	height: 42px;
	display: flex;
	margin: 10px 0;
	cursor: pointer;
	font-size: 18px;
	line-height: 42px;
	text-align: center;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const Carousel = styled(UnstyledCarousel)`
	&.carousel-root {
		width: 600px;
		min-width: 600px;

		@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
			min-width: 90%;
			max-width: 90vw;
		}
	}

	li.slide {
		opacity: 0.4;
	}

	li.slide.selected {
		opacity: 1;
	}
`;
