import styled from 'styled-components';

export const PromoCodesContainer = styled.div`
	gap: 16px;
	display: flex;
	height: 140px;
	max-width: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
	align-items: center;

	::-webkit-scrollbar {
		display: none;
	}
`;
