import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { RootState } from 'store';
import { getData } from 'services/api';
import { Modal } from 'features/common/components/Modal';
import { QUATERNARY } from 'features/common/components/Button/constants';
import { Modals } from 'features/FanDatabase/Slices/types';
import { closeFanModal } from 'features/FanDatabase/Slices/FanDatabaseSlice';

import * as Styled from '../../FansTable.styles';
import { WhiteBox, NumberSpan } from './ConfirmIdModal2.styles';

export const ConfirmIdModal2: FC = () => {
	const { t } = useTranslation();
	const { openModal, fanData } = useSelector(
		(state: RootState) => state.fanDatabase.modalStatus
	);
	const dispatch = useDispatch();

	const { data, refetch } = useQuery({
		queryKey: ['verificationCode'],
		queryFn: () =>
			getData(`/users/confirm_identity/${fanData.user_id}?channel=EMAIL`),
		refetchOnWindowFocus: false,
		cacheTime: 0,
		enabled: openModal === Modals.verifyCode,
	});

	return (
		<>
			{openModal === Modals.verifyCode && (
				<Styled.CustomModal onClose={() => dispatch(closeFanModal())}>
					<Modal.Header>{t('fanDatabase-confirm-identity')}</Modal.Header>
					<Styled.Wrapper>
						<Styled.ModalMessage element="p">
							{t('fanDatabase-identityModal-message')}
						</Styled.ModalMessage>
						<Styled.IdentityInfoContainer>
							{data
								?.toString()
								.split('')
								.map((number: string, i: number) => (
									<WhiteBox key={i}>
										<NumberSpan>{number}</NumberSpan>
									</WhiteBox>
								))}
						</Styled.IdentityInfoContainer>
					</Styled.Wrapper>
					<Styled.ActionsWrapper>
						<Styled.ModalButton
							text={t('fanDatabase-identityModal-resend-code')}
							category={QUATERNARY}
							onClick={() => refetch()}
							type="button"
						/>
						<Styled.ModalButton
							text={t('fanDatabase-dismiss')}
							type="button"
							onClick={() => {
								dispatch(closeFanModal());
							}}
						/>
					</Styled.ActionsWrapper>
				</Styled.CustomModal>
			)}
		</>
	);
};
