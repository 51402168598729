import styled from 'styled-components';

export const ScreenContainer = styled.div`
	width: 100vw;
	max-height: 100vh;
	box-sizing: border-box;
	background-color: ${({ theme }) => theme.colors.blackGrey};
	overflow-y: scroll;
	overflow-x: hidden;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-height: calc(100vh - 125px);
	}
`;

export const ContentWrapper = styled.div`
	padding: 0 3% 6% 6%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 0;
		width: 100vw;
	}
`;

export const PromoCodeListContainer = styled.div`
	flex: 1;
	gap: 24px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 24px 0;
	box-sizing: border-box;
	height: calc(100vh - 471px);

	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		justify-content: center;
		height: calc(100vh - 451px);
	}
`;

export const Container = styled.div`
	box-sizing: border-box;
	display: flex;
	gap: 24px;
	margin: 24px 0;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
		padding: 0 3%;
	}
`;
