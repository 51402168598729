import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { camelizeKeys } from 'humps';
import { useQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';

import { getData } from 'services/api';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { DropdownInput } from '../DropdownInput';
import { InputLabel } from 'features/common/components/Form/InputLabel';
import { getSelectedName } from '../../utils';
import { Event } from 'features/common/components/Filters/types';
import { LabelProp } from '../../types';

import { FormText, ListContainer, Option, RadioButton } from '../../CampaignForm.styles';

export const Content = ({ label }: LabelProp) => {
	const { t } = useTranslation();
	const [search, setSearch] = useState('');
	const [eventsPerPage, setPerPage] = useState(5);
	const { watch, setValue } = useFormContext();

	const eventFormValue = watch('focus');
	const [selectedEvent, setEvent] = useState<number>(eventFormValue);

	const { data: uncamelizedEventsData } = useQuery({
		queryKey: ['audience-events', eventsPerPage, search],
		queryFn: () => getData(`/events?date=all_time&per_page=${eventsPerPage}&search=${search}`),
	});
	const eventsData = camelizeKeys(uncamelizedEventsData) as Event[];
	const isNotFullPage = eventsData?.length < 5 && eventsPerPage > 0;
	const updateValues = () => {
		setValue('focus', [selectedEvent]);
	};

	useEffect(() => {
		setEvent(eventFormValue[0]);
	}, [eventFormValue.length]);

	const memoizedNames = useMemo(() => getSelectedName({ eventsData, eventFormValue }), [eventsData, eventFormValue]);

	return (
		<FlexContainer flexDirection="column" gap="16px">
			<FormText>{label}</FormText>
			<FlexContainer flexDirection="column" gap="8px">
				<InputLabel label={t('marketing-campaignForm-contentInfo')} htmlFor={label} />
				<DropdownInput
					filterName={memoizedNames || t('marketing-campaignForm-selectFocus')}
					id={label}
					setPerPage={setPerPage}
					setSearch={setSearch}
					hideViewAll={eventsPerPage === -1 || isNotFullPage}
					updateValues={updateValues}
				>
					<ListContainer>
						{eventsData?.map(({ id, name }) => (
							<Option key={id}>
								<RadioButton
									type="radio"
									checked={selectedEvent === id}
									name={name}
									value={name}
									onChange={() => setEvent(id)}
								/>
								{name}
							</Option>
						))}
					</ListContainer>
				</DropdownInput>
			</FlexContainer>
		</FlexContainer>
	);
};
