export class Bubble {
	constructor(canvasWidth, canvasHeight) {
		this.maxHeight = canvasHeight;
		this.maxWidth = canvasWidth;
		this.randomise();
	}

	generateDecimalBetween(min, max) {
		return (Math.random() * (min - max) + max).toFixed(2);
	}

	generateVelocity(min, max) {
		return (Math.random() * (min - max) + max).toFixed(2);
	}

	update() {
		this.posX = this.posX - this.movementX;
		this.posY = this.posY - this.movementY;

		if (this.posY < 0 || this.posX < 0 || this.posX > this.maxWidth) {
			this.randomise();
			this.posY = this.maxHeight;
		}
	}

	randomise() {
		const colours = [
			'hsl(45, 83%, 66%)',
			'hsl(276, 35%, 58%)',
			'hsl(80, 42%, 67%)',
			'hsl(345, 52%, 55%)',
			'hsl(180, 39%, 58%)',
		];
		this.colour = colours[Math.floor(Math.random() * 5) % 5];
		this.size = 5;
		this.movementX = 0;
		this.movementY = this.generateVelocity(-0.15, 0.15);
		this.posX = this.generateDecimalBetween(0, this.maxWidth);
		this.posY = this.generateDecimalBetween(0, this.maxHeight);
	}
}

export class Background {
	constructor() {
		this.canvas = document.getElementById('floatingbubbles');
		this.ctx = this.canvas.getContext('2d');
		this.canvas.height = window.innerHeight;
		this.canvas.width = window.innerWidth;
		this.bubblesList = [];
		this.generateBubbles();
		this.animate();
	}

	animate() {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let self = this;
		self.ctx.clearRect(0, 0, self.canvas.width, self.canvas.height);
		self.bubblesList.forEach(function (bubble) {
			bubble.update();
			self.ctx.beginPath();
			self.ctx.arc(bubble.posX, bubble.posY, bubble.size, 0, 2 * Math.PI);
			self.ctx.fillStyle = bubble.colour;
			self.ctx.fill();
			self.ctx.shadowBlur = 13;
			self.ctx.shadowColor = bubble.colour;
			self.ctx.strokeStyle = bubble.colour;
			self.ctx.stroke();
		});

		requestAnimationFrame(this.animate.bind(this));
	}

	addBubble(bubble) {
		return this.bubblesList.push(bubble);
	}

	generateBubbles() {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let self = this;
		for (let i = 0; i < self.bubbleDensity(); i++) {
			self.addBubble(new Bubble(self.canvas.width, self.canvas.height));
		}
	}

	bubbleDensity() {
		return Math.sqrt((this.canvas.height, this.canvas.width) * 0.75);
	}
}

window.requestAnimFrame = (function () {
	return (
		window.requestAnimationFrame ||
		window.webkitRequestAnimationFrame ||
		window.mozRequestAnimationFrame ||
		window.oRequestAnimationFrame ||
		window.msRequestAnimationFrame ||
		function (callback) {
			window.setTimeout(callback, 1000 / 60);
		}
	);
})();
