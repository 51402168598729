import { TableProps } from './types';
import { LoadMore } from './LoadMore';

import * as S from './Table.styles';

const Table = ({ children }: TableProps) => (
	<S.HTMLTable>{children}</S.HTMLTable>
);
Table.TableContainer = S.TableContainer;
Table.HeaderRow = S.HeaderRow;
Table.DataRow = S.DataRow;
Table.Cell = S.Cell;
Table.ColumnHeader = S.ColumnHeader;
Table.Button = S.CustomButton;
Table.LoadMore = LoadMore;

export { Table };
