import styled from 'styled-components';

import { Form } from 'features/common/components/Form';
import { Text } from 'features/common/components/Text';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';

export const CardContainer = styled.div`
	border-radius: 3px;
	background-color: ${({ theme }) => theme.colors.lightGrey14};
	height: 100%;
	padding: 10px 24px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 16px 18px;
	}
`;

export const Input = styled(Form.Input)``;

export const WrapperInputs = styled.div`
	display: flex;
	gap: 35px;
	margin-bottom: 10px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		gap: 10px;
	}
`;

export const RecipientName = styled(Text)`
	font-weight: 500;
	font-size: 16px;
	line-height: 46px;
	margin: 0;
`;

export const Icon = styled(UnstyledIcon)`
	cursor: pointer;
`;
