import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ReorderCustomField } from 'features/CustomFields/types';
import { useToastNotification } from 'hooks/useToastNotification';
import { TYPES } from 'hooks/useToastNotification/constants';
import { put } from 'services/api';

export const useReorderCustomFieldsApi = () => {
	const { t } = useTranslation();
	const { showNotification } = useToastNotification();
	const { goBack } = useHistory();

	const { mutate } = useMutation({
		mutationFn: (body: ReorderCustomField[]) => put('/custom-fields/reorder', body),
		onSuccess: () => {
			goBack();
			showNotification({ message: t('customFields-reorder-fields-saveSuccess'), type: TYPES.SUCCESS });
		},
		onError: () => showNotification({ message: t('customFields-reorder-fields-saveError'), type: TYPES.ERROR }),
	});

	return { reorderCustomFields: mutate };
};
