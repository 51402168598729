import { useQuery } from '@tanstack/react-query';

import { TaxesType } from '../OrderSummary/types';
import { getData } from 'services/api';
import { useGetLastEvent } from './useGetLastEvent';
import { camelize } from 'utils/utils';

const useGetTaxes = () => {
	const { ticketTypesId } = useGetLastEvent();

	const { data: taxes } = useQuery(
		['getTaxes', (ticketTypesId as number[]).join(',')],
		() => getData(`public/ticketing/ticket_types/taxes/${(ticketTypesId as number[]).join(',')}`),
		{ enabled: !!ticketTypesId?.length }
	);

	return { taxes: camelize(taxes) as TaxesType };
};

export default useGetTaxes;
