import styled from 'styled-components';

import { Button } from 'features/common/components/Button';
import { LocalFilter as UnstyledFilter } from 'features/common/components/Filters/LocalFilter';

export const FiltersContainer = styled.div`
	width: calc(100% - 144px - 72px);
	padding: 40px 72px 0px 144px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px 22px;
		width: calc(100% - 22px - 22px);
		flex-direction: column;
		gap: 20px;
	}
`;

export const SendTicketsButton = styled(Button)`
	width: fit-content;
	padding: 8px 21px;
	height: 100%;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;

export const Filter = styled(UnstyledFilter)``;
