import styled from 'styled-components';

export const MainContentWrap = styled.div`
	display: flex;
	flex-direction: column;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 500;
	font-size: 16px;
	gap: 24px;
	color: ${({ theme }) => theme.colors.white};

	box-sizing: border-box;
	width: calc(100% - 86px - 130px);
	margin: 16px 86px 32px 130px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: calc(100% - 24px - 24px);
		margin: 0 24px 50px 24px;
		justify-content: center;
	}
`;
