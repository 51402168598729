import styled from 'styled-components';

import { Text } from 'features/common/components/Text';

export const Wrapper = styled.div``;

export const Title = styled(Text)``;

export const Date = styled.p`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 14px;
	line-height: 21px;
`;

export const Location = styled(Date)`
	display: flex;
	align-items: center;
	gap: 10px;
	text-decoration: underline;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.white};
`;
