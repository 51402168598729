import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { Modal } from 'features/common/components/Modal';
import { QUATERNARY } from 'features/common/components/Button/constants';
import { ResetModalProps } from './types';
import { useResetPassword } from 'features/common/hooks/useResetPassword';

import * as Styled from './ResetModal.styles';

export const ResetModal: FC<ResetModalProps> = ({ email, closeModal }) => {
	const { t } = useTranslation();
	const resetPassword = useResetPassword(email);

	return (
		<Styled.CustomModal onClose={closeModal}>
			<Modal.Header>{t('fanDatabase-reset-password')}</Modal.Header>

			<Styled.BodyWrapper>
				<Styled.UserIcon iconName="lockGrey" />
				<Styled.ModalMessage element="p">
					{t('fanDatabase-reset-password-message')}
				</Styled.ModalMessage>
				<Styled.ResetInfoContainer>
					<FlexContainer gap="8px" alignItems="center">
						<Styled.MailIcon iconName="mail" />
						<Styled.Email> {email}</Styled.Email>
					</FlexContainer>
				</Styled.ResetInfoContainer>
			</Styled.BodyWrapper>

			<Styled.ActionsWrapper>
				<Styled.ModalButton
					text={t('button-cancel')}
					category={QUATERNARY}
					onClick={closeModal}
					type="button"
				/>
				<Styled.ModalButton
					text={t('button-confirm')}
					onClick={() => {
						resetPassword();
						closeModal();
					}}
					type="button"
				/>
			</Styled.ActionsWrapper>
		</Styled.CustomModal>
	);
};
