import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'features/common/components/Modal/Modal';
import { DeleteModalProps } from './types';
import {
	QUATERNARY,
	TERTIARY,
} from 'features/common/components/Button/constants';

import {
	ContainerActionButtons,
	ContainerBody,
	ModalButton,
	Icon,
	Text,
} from './ModalDelete.styles';

export const ModalDelete: FC<DeleteModalProps> = ({
	modalInfo,
	showModal,
	closeModal,
}) => {
	const { t } = useTranslation();

	return (
		<>
			{showModal && (
				<Modal onClose={closeModal}>
					<>
						<Modal.Header>{modalInfo.modalTitle}</Modal.Header>
						<Modal.Body>
							<ContainerBody>
								<Icon iconName="trashGrey" />
								<Text element="p">{modalInfo.modalMessage}</Text>
							</ContainerBody>
						</Modal.Body>
						<Modal.ActionButtons>
							<ContainerActionButtons>
								<ModalButton
									category={QUATERNARY}
									onClick={closeModal}
									text={t('button-cancel')}
									type="button"
								/>
								<ModalButton
									category={TERTIARY}
									onClick={modalInfo.onDelete}
									text={t('button-delete')}
									type="button"
								/>
							</ContainerActionButtons>
						</Modal.ActionButtons>
					</>
				</Modal>
			)}
		</>
	);
};
