import styled from 'styled-components';

import { WrapperProps } from './types';

export const Wrapper = styled.div<WrapperProps>`
	display: flex;
	justify-content: center;
	margin: ${({ margin }) => margin};
	height: ${({ height }) => height};
	width: 100%;
`;
