import { useTranslation } from 'react-i18next';

import { Modal } from 'features/common/components/Modal';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { ModalNeedHelpProps } from './types';

import * as Styled from './ModalNeedHelp.styles';

const ModalNeedHelp: React.FC<ModalNeedHelpProps> = ({ onClose }: ModalNeedHelpProps) => {
	const { t } = useTranslation();
	return (
		<Modal>
			<Modal.Header>{t('embedInfo-helpModal-title')}</Modal.Header>
			<Modal.Body>
				<FlexContainer flexDirection="column" alignItems="center" padding="56px 0px">
					<Styled.StyledIcon iconName="alert" />
					<Styled.BodyDescription>{t('embedInfo-helpModal-description')}</Styled.BodyDescription>
				</FlexContainer>
			</Modal.Body>
			<Modal.ActionButtons>
				<Styled.ButtonsContainer>
					<Styled.StyledButton text="Dismiss" category="primary" type="button" onClick={onClose} />
				</Styled.ButtonsContainer>
			</Modal.ActionButtons>
		</Modal>
	);
};

export { ModalNeedHelp };
