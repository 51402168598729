import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AUTH } from 'features/common/components/Form/Input/constants';
import { Form } from 'features/common/components/Form';
import { ForgotPasswordFormProps } from '../types';
import { ROUTES } from 'features/routes';

import { FormContainer, Title, Button } from 'features/Login/components/LoginForm/LoginForm.styles';

export const ForgotPasswordForm = ({ emailSent }: ForgotPasswordFormProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();

	return (
		<FormContainer>
			<Title>{t(emailSent ? 'forgot-password-email-sent' : 'forgot-password-title')}</Title>
			<Form.Input id="email" name="email" label="Email" category={AUTH} />
			<Button text={t('forgot-password-button')} type="submit" />
			<Button text={t('reset-password-button-confirmation')} type="button" onClick={() => push(ROUTES.LOGIN_PATH)} />
		</FormContainer>
	);
};
