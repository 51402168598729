import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { DefaultValuesTypes, IPromoCodesFormParams } from './types';
import { MainLayout } from 'layouts/MainLayout';
import { PromoCodesFormTitle } from './components/PromoCodesFormTitle';
import SpecificPromocodes from './components/SpecificPromocodes/SpecificPromocodes';
import {
	defaultValues,
	handleInitialValues,
	handleType,
	validationSchema,
} from './utils';
import { RootState } from 'store';
import { onChangeShowFooter } from './slice/PromoCodesFormSlice';
import { PromoCodesFormFooter } from './components/PromoCodesFormFooter';
import { PromoCodesFormContent } from './components/PromoCodesFormContent';
import { useEditPromoCodeApi } from 'hooks/useEditPromoCode';

import { StyledForm } from './PromoCodesForm.styles';

const PromoCodesForm: React.FC = () => {
	const { type, id } = useParams<IPromoCodesFormParams>();
	const { pathname } = useLocation();
	const isEdit = pathname.includes('edit');
	const dispatch = useDispatch();
	const { showTicketTypes, showFooter } = useSelector(
		(state: RootState) => state.promoCodesForm
	);
	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema()),
	});

	const { promocode, mutateCreatePromoCode, mutateEditPromoCode } =
		useEditPromoCodeApi();

	useEffect(() => {
		if (type === 'existing' && showFooter) dispatch(onChangeShowFooter());
	}, [type]);

	useMemo(() => {
		if (promocode) {
			const allValues = handleInitialValues(promocode);
			formMethods.reset(allValues);
		}
	}, [promocode]);

	const onSubmit = (values: DefaultValuesTypes) => {
		if (isEdit) mutateEditPromoCode({ values, type: handleType(type), id });
		else mutateCreatePromoCode({ values, type: handleType(type) });
	};

	return (
		<MainLayout>
			<PromoCodesFormTitle type={type} />
			<StyledForm formMethods={formMethods} onSubmit={onSubmit}>
				{showTicketTypes ? (
					<SpecificPromocodes />
				) : (
					<PromoCodesFormContent type={type} />
				)}
				{showFooter && <PromoCodesFormFooter type={type} />}
			</StyledForm>
		</MainLayout>
	);
};

export { PromoCodesForm };
