import styled from 'styled-components';

import { Table } from 'features/common/components/Table';

export const HeaderRow = styled(Table.HeaderRow)`
	display: table-row;

	td:nth-child(1) {
		padding-left: 48px;
	}

	td:last-child {
		padding-right: 48px;
	}

	td.text-center {
		text-align: center;
	}
`;

export const DataRow = styled(Table.DataRow)`
	display: table-row;

	td:nth-child(1) {
		padding-left: 48px;
	}

	td:last-child {
		padding-right: 48px;
	}
`;
