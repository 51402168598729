import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getData } from 'services/api';
import { UseGetPromoCodesType } from './types';
import { PromoCodeDataType } from '../OrderSummary/types';
import { camelize } from 'utils/utils';

export const useGetPromoCodeData = ({ promoCodeId, setPromoCodeApplied }: UseGetPromoCodesType) => {
	const { data: promoCodeData, refetch } = useQuery(
		['getPromoCodeInfo', promoCodeId],
		() => getData(`/public/ticketing/promocodes/${promoCodeId}`),
		{
			onSuccess: () => setPromoCodeApplied(false),
			enabled: promoCodeId > 0,
		}
	);

	useEffect(() => {
		if (promoCodeId > 0) refetch();
	}, [promoCodeId]);

	return { promoCodeData: camelize(promoCodeData) as PromoCodeDataType };
};
