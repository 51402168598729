import { TicketsSold } from 'features/Dashboard/components/TicketsSold';

import * as Styled from '../AnalyticByEvent.styles';

const TicketsSoldSection: React.FC = () => (
	<Styled.AnalyticsContainer>
		<TicketsSold />
	</Styled.AnalyticsContainer>
);

export default TicketsSoldSection;
