import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Icon } from 'features/common/components/Icon';
import { Divider } from 'features/common/components/Divider';
import { StyledDropzoneProps } from '../../types';
import { ROUTES } from 'features/routes';

import {
	UploadImageContainer,
	AddImageText,
	Row,
	AddImageDescription,
	SizeRecommendation,
} from './StyledDropzone.styles';

export const StyledDropzone = ({ cyId }: StyledDropzoneProps) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	return (
		<UploadImageContainer data-cy={`${cyId ? `${cyId}-` : ''}img-input`}>
			<AddImageText>{t('createEvent-img-upload')}</AddImageText>
			{pathname.includes(ROUTES.CREATE_EVENT_PATH) && (
				<SizeRecommendation>{t('createEvent-img-size')}</SizeRecommendation>
			)}
			<Divider />
			<Row>
				<Icon iconName="picture" />
				<AddImageDescription>{t('createEvent-img-selectImages')}</AddImageDescription>
			</Row>
		</UploadImageContainer>
	);
};
