import styled from 'styled-components';

export const Container = styled.div`
	height: fit-content;
	object-fit: fill;
	width: 350px;
	display: flex;
	padding: 8px 16px;
	cursor: pointer;
	border-radius: 8px;
	align-items: center;
	box-sizing: border-box;
	gap: 20px;
	background-color: ${({ theme }) => theme.colors.grey12};
	text-transform: capitalize;
	font-family: ${({ theme }) => theme.fonts.primary};
	position: relative;
	flex-direction: column;
	justify-content: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

export const ContainerColor = styled.div`
	height: 50px;
	width: 50px;
`;

export const ContainerFont = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-left: 20px;
`;

export const OptionStyled = styled.div`
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	text-transform: capitalize;
	font-family: ${({ theme }) => theme.fonts.primary};
	padding: 5px;
	padding-left: 0;
`;

export const SelectBox = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.lightGrey};
	position: absolute;
	bottom: -262px;
	left: 0;
	width: 94%;
	height: auto;
	padding: 10px;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.blackGrey};
	z-index: 1;
`;

export const ColorBox = styled.div`
	width: 50px;
	height: 100%;
	background: ${({ color }) => color};
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;

	&.data {
		background: ${(props) => props.color};
		border: none;
	}

	&.none {
		background: transparent;
		border: 1.5px dashed ${({ theme }) => theme.colors.white};
	}
`;

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

export const Text = styled.div`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-transform: initial;
`;

export const WrapperInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const Image = styled.img`
	width: 100%;
`;
