import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { Modal } from 'features/common/components/Modal';
import { QUATERNARY } from 'features/common/components/Button/constants';
import { Modals } from 'features/FanDatabase/Slices/types';
import { FlexContainer } from 'features/common/components/FlexContainer';
import {
	closeFanModal,
	openFanModal,
} from 'features/FanDatabase/Slices/FanDatabaseSlice';

import * as S from '../../FansTable.styles';

export const ConfirmIdModal1: FC = () => {
	const { t } = useTranslation();
	const { openModal, fanData } = useSelector(
		(state: RootState) => state.fanDatabase.modalStatus
	);
	const dispatch = useDispatch();

	return (
		<>
			{openModal === Modals.confirmContact && (
				<S.CustomModal onClose={() => dispatch(closeFanModal())}>
					<Modal.Header>{t('fanDatabase-confirm-identity')}</Modal.Header>
					<S.Wrapper>
						<S.UserIcon iconName="userCheckGrey" />
						<S.ModalMessage element="p">
							{t('fanDatabase-confirm-identity-message')}
						</S.ModalMessage>
						<S.IdentityInfoContainer>
							<FlexContainer flexDirection="column" gap="8px">
								<S.Name>{`${fanData.full_name}:`} </S.Name>
								<FlexContainer gap="8px" alignItems="center">
									<S.MailIcon iconName="mail" />
									<S.Email> {fanData.email}</S.Email>
								</FlexContainer>
							</FlexContainer>
						</S.IdentityInfoContainer>
					</S.Wrapper>
					<S.ActionsWrapper>
						<S.ModalButton
							text={t('fanDatabase-cancel')}
							category={QUATERNARY}
							onClick={() => dispatch(closeFanModal())}
							type="button"
						/>
						<S.ModalButton
							text={t('fanDatabase-confirm')}
							type="button"
							onClick={() => {
								dispatch(
									openFanModal({
										fanData,
										openModal: Modals.verifyCode,
									})
								);
							}}
						/>
					</S.ActionsWrapper>
				</S.CustomModal>
			)}
		</>
	);
};
