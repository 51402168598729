import styled from 'styled-components';

export const CircularButton = styled.button`
	position: fixed;
	box-sizing: border-box;
	cursor: pointer;
	width: 57px;
	height: 57px;
	left: 231px;
	top: 137px;
	background: ${({ theme }) => theme.colors.lightGrey};
	color: white;
	border-radius: 100%;
	border: none;
	z-index: 1;
`;
