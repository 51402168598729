import styled from 'styled-components';

import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import {
	DesktopContainerStylesProps,
	MobileContainerStylesProps,
	OptionsContainerStylesProps,
	OptionStylesProps,
} from './types';

export const Icon = styled(UnstyledIcon)`
	cursor: pointer;
`;

export const IconFilter = styled(UnstyledIcon)`
	cursor: pointer;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;

	max-width: 100%;
	width: 100%;
	justify-content: space-between;

	position: relative;
	top: 6px;
	left: 4px;
	cursor: pointer;
`;

export const MobileContainer = styled.div<MobileContainerStylesProps>`
	display: block;
	flex-direction: ${({ responsiveSecondary }) => (responsiveSecondary ? 'row' : 'row-reverse')};
	flex-wrap: wrap;
	position: relative;
	font-family: ${({ theme }) => theme.fonts.primary};
	justify-content: ${({ responsiveSecondary }) => responsiveSecondary && 'space-around'};
	background-color: ${({ theme, responsiveSecondary }) => responsiveSecondary && theme.colors.grey2};
	border-radius: ${({ responsiveSecondary }) => responsiveSecondary && '4px'};
	margin-bottom: ${({ responsiveSecondary }) => responsiveSecondary && '32px'};
	align-items: ${({ responsiveSecondary }) => responsiveSecondary && 'center'};
	height: ${({ responsiveSecondary }) => !responsiveSecondary && '25px'};

	> div {
		border-bottom: ${({ responsiveSecondary }) => responsiveSecondary && 'none'};
	}
`;

export const DesktopContainer = styled.div<DesktopContainerStylesProps>`
	background-repeat: no-repeat;
	background-color: ${({ theme }) => theme.colors.blackGrey};
	color: ${({ theme, secondaryStyle }) => (secondaryStyle ? theme.colors.green : theme.colors.white)};
	width: ${({ secondaryStyle }) => (secondaryStyle ? '120px' : '240px')};
	height: 48px;
	left: 1382px;
	top: 870px;
	padding: 5px 15px 5px 15px;
	border: ${({ theme, secondaryStyle }) =>
		secondaryStyle ? `0.5px solid ${theme.colors.green}` : `0.5px solid ${theme.colors.white}`};
	border-radius: ${({ isOpened }) => (isOpened ? '6px 6px 0px 0px' : '6px')};
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 400;
	font-size: 16px;
	line-height: 153%;
	box-sizing: border-box;

	> option {
		position: relative;
	}

	&:focus {
		outline: 0;
	}
`;

export const OptionsContainer = styled.div<OptionsContainerStylesProps>`
	position: ${({ isLargeScreen }) => (isLargeScreen ? 'relative' : 'absolute')};
	top: ${({ isLargeScreen }) => (isLargeScreen ? '16px' : '34px')};
	left: ${({ isLargeScreen }) => (isLargeScreen ? '-16px;' : '-170px')};
	width: ${({ secondaryStyle }) => (secondaryStyle ? '118px' : '240px')};
	z-index: 1;
	background-color: ${({ theme }) => theme.colors.blackGrey};
	border: ${({ theme, secondaryStyle, isOpened }) =>
		isOpened && secondaryStyle ? `0.5px solid ${theme.colors.green}` : `0.5px solid ${theme.colors.white}`};
	border: ${({ isOpened }) => !isOpened && 'none'};
	box-sizing: border-box;
	border-radius: ${({ isOpened, isLargeScreen }) => (isOpened && isLargeScreen ? '0px 0px 6px 6px' : '6px')};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 195px;
	}
`;

export const Option = styled.div<OptionStylesProps>`
	display: flex;
	height: 35px;
	align-items: center;
	padding: 5px 15px 5px 15px;
	justify-content: flex-start;
	border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGrey3}`};
	margin: 0;
	gap: 16px;
	cursor: pointer;
`;

export const SecondaryOptionsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 30px;
`;

export const SecondaryOption = styled.button<OptionStylesProps>`
	display: flex;
	height: 22px;
	align-items: center;
	border-style: none;
	padding: 5px 15px 5px 15px;
	justify-content: center;
	margin: 0;
	gap: 16px;
	background: ${({ checked, theme }) => (checked ? theme.colors.green : 'transparent')};
	margin: ${({ checked }) => checked && '4px'};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
`;
