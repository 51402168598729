import { toast as showToast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ToastProps } from './types';
import { TYPES, options } from './constants';
import i18n from 'locals/i18n';

const useToastNotification = () => {
	const { t } = useTranslation();
	const showNotification = ({ message, type }: ToastProps) => {
		switch (type) {
			case TYPES.INFO:
				return showToast.info(message || TYPES.INFO, options);
			case TYPES.ERROR:
				return showToast.error(message || TYPES.ERROR, options);
			case TYPES.SUCCESS:
				return showToast.success(message || TYPES.SUCCESS, options);
			default:
				break;
		}
	};
	return {
		showNotification,
		TYPES,
		t,
	};
};

export const toastError = (message: string) => showToast.error(i18n.t(message), options);
export const toastSuccess = (message: string) => showToast.success(i18n.t(message), options);

export { useToastNotification };
