import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation /* useParams */ } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
// import { useQuery } from '@tanstack/react-query';

import { Page } from 'features/common/components/Carousel';
import { Text } from 'features/common/components/Text';
import { Form } from 'features/common/components/Form';
// TODO: Uncomment when backend is ready
// import { SecondarySales } from '../../SecondarySales';
// TODO: Uncomment when Fees are ready
// import { Fees } from '../../Fees';
import { allowsOnlyNumbers, allowsDecimals } from 'utils/numberInput';
import { handleLink } from 'utils/handleLink';
import { ButtonContainer, SubmitButton } from '../Steps.styles';
// import { EventIdParamsType } from 'utils/types';
// import { getData } from 'services/api';
// import { handleTotalPrice } from './utils';

import * as Styled from './TicketDetails.styles';

export const TicketDetails = () => {
	const { t } = useTranslation();
	const { hash } = useLocation();
	const {
		formState: { errors },
		getValues,
		trigger,
		watch,
	} = useFormContext();
	// const { eventId }: EventIdParamsType = useParams();

	const isTicketDetails = useMemo(() => hash === '#2' || hash === '#3', [hash]);
	const price: number = watch('initialPrice');
	// const { data: taxesData } = useQuery({
	// 	queryKey: ['ticketTaxes'],
	// 	queryFn: () => getData(`/public/ticketing/events/taxes/${eventId}`),
	// 	enabled: hash === '#2',
	// });

	const onClick = () => {
		const { name, initialPrice, amount } = getValues();
		let isMissedValue = false;
		if (name === '') {
			trigger('name');
			isMissedValue = true;
		}
		if (initialPrice === null) {
			trigger('initialPrice');
			isMissedValue = true;
		}
		if (amount === null) {
			trigger('amount');
			isMissedValue = true;
		}

		if (!isMissedValue) handleLink('#3');
	};

	return (
		<Page id="2" className="no-padding">
			<Styled.Column>
				{isTicketDetails && (
					<Styled.ScreenTitle>
						<Text>{t('createEvent-tickets-title')}</Text>
						<Styled.Subtitle>{t('createEvent-tickets-subtitle')}</Styled.Subtitle>
					</Styled.ScreenTitle>
				)}
				<Styled.FieldSet>
					<Form.Input name="name" id="name" label="Ticket Name" />
					{/* TODO: Uncomment when backend is ready */}
					{/* <SecondarySales /> */}
					<Form.Input
						type="number"
						inputMode="numeric"
						id="initialPrice"
						name="initialPrice"
						label={t('createEvent-tickets-ticketPrice')}
						onKeyDown={(e) => allowsDecimals(e, price)}
						placeholder="$"
						step=".01"
					/>
					<Form.Input
						type="number"
						inputMode="numeric"
						id="amount"
						name="amount"
						label={t('createEvent-tickets-amount')}
						onKeyDown={allowsOnlyNumbers}
					/>
					<Form.InputText id="description" name="description" label={t('ticketDetails-ticket-description')} />
					<Form.InputDateAndTime id="sellingTime" name="sellingTime" label={t('createEvent-tickets-onSale')} />
					{/* TODO: Uncomment when fees are ready */}
					{/* <Fees /> */}
					{/* <Styled.TotalPriceContainer>
						{taxesData && <Styled.TotalPrice>${handleTotalPrice({ watch, taxesData })}</Styled.TotalPrice>}
						<Styled.TotalPriceInfo>{t('ticketDetails-priceBuyerPays')}</Styled.TotalPriceInfo>
					</Styled.TotalPriceContainer> */}
					<Form.InputDateAndTime id="offSellingTime" name="offSellingTime" label={t('createEvent-tickets-offSale')} />
					<Form.InputToggle
						htmlFor="ticketVisibility"
						name="ticketVisibility"
						label={t('ticketDetails-ticket-visbility')}
					/>
				</Styled.FieldSet>
				<ButtonContainer>
					<SubmitButton type="button" onClick={onClick} text="Continue" disabled={Object.keys(errors).length > 0} />
				</ButtonContainer>
			</Styled.Column>
		</Page>
	);
};
