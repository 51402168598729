import { useMutation } from '@tanstack/react-query';

import { addMemberPost } from 'features/Settings/services';
import { sendGAEvent } from 'utils/utils';

export const useAddMember = () => {
	const { mutate: addMember } = useMutation({
		mutationFn: addMemberPost,
		onSuccess: () => sendGAEvent({ category: 'Member', action: 'Add member' }),
	});

	return { addMember };
};
