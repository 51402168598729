import { camelize } from 'humps';

import { SelectFontStyleProps } from './types';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { SelectFont } from 'features/common/components/SelectFontNewSettings';

import { SelectContainer } from '../../TypographySettings.styles';

const SelectFontStyle = ({ title, onChange, text, image }: SelectFontStyleProps) => {
	const camelizedTitle = camelize(title);
	return (
		<SelectContainer>
			<FlexContainer>
				<SelectFont onChange={onChange} title={camelizedTitle} image={image} titleOptions={title} text={text} />
			</FlexContainer>
		</SelectContainer>
	);
};

export { SelectFontStyle };
