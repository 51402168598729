import { ROUTES } from 'features/routes';
import { AvailableToSProps } from './types';
import { Icon } from 'features/common/components/Icon';
import { EmptySection } from 'features/common/components/EmptySection';

import { EllipsisText, SummaryWrap } from '../ToSSection.styles';

export const AvailableToS = ({ terms, pushToPath, t }: AvailableToSProps) => (
	<>
		{terms.data.length > 0 ? (
			terms.data.map(({ ID, text }) => {
				if (text)
					return (
						<SummaryWrap key={ID} onClick={() => pushToPath(ROUTES.TERMS_OF_SERVICE, `${ID}`)}>
							<EllipsisText>{text}</EllipsisText>
							<Icon iconName="pencil" />
						</SummaryWrap>
					);
			})
		) : (
			<EmptySection
				onClick={() => pushToPath(ROUTES.TERMS_OF_SERVICE, 'new')}
				text={t('settings-availableToS-addNew')}
			/>
		)}
	</>
);
