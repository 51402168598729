import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';

import { getData } from 'services/api';
import { schema, defaultValues, convertResToForm } from './utils';
import { useResponsiveScreen, useResponsiveScreenLG } from 'hooks/useWindowSize';
import { MainHeader } from 'features/common/components/MainHeader';
import { MainLayout } from 'layouts/MainLayout';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { CampaignForm } from './components/CampaignForm';
import { EmailPreview } from './components/EmailPreview';
import { CapacityModal } from './components/CapacityModal';
import { StickyBtnContainer } from './components/StickyBtnContainer';
import { CampaignBody, CampaignId, FormFields } from './types';
import { useCampaignMutatation } from './hooks/useCampaignMutation';

import { Subtitle, FormContainer } from './ManageEmailCampaign.styles';
import { ContentTitle } from '../ViewEmailCampaigns/ViewEmailCampaigns.styles';

export const ManageEmailCampaign = () => {
	const { campaignId } = useParams<CampaignId>();
	const { t } = useTranslation();
	const isMobile = useResponsiveScreen();
	const isLargeScreen = useResponsiveScreenLG();
	const [isOverCapacity, setIsOverCapacity] = useState(false);

	const { data: campaign } = useQuery({
		queryKey: ['getCampaignFields', campaignId],
		queryFn: (): Promise<CampaignBody> => getData(`/marketing/email-campaigns/${campaignId}`),
		enabled: !!campaignId,
	});

	const formMethods = useForm<FormFields>({
		defaultValues: defaultValues(t),
		resolver: yupResolver(schema),
		values: convertResToForm(t, campaign),
	});

	const { partnerInfo, checkCapacity, handleClick, totalEmails } = useCampaignMutatation({
		campaignId,
		formMethods,
		setIsOverCapacity,
	});

	return (
		<MainLayout containsStickyEl>
			<MainHeader>
				<FlexContainer flexDirection="column">
					<MainHeader.Title element="h1">{t('marketing-email-campaigns')}</MainHeader.Title>
					<Subtitle>{campaign ? campaign.name : t('marketing-manageEmailCampaign-newCampaign')}</Subtitle>
				</FlexContainer>
			</MainHeader>
			{isMobile ? (
				<FormContainer formMethods={formMethods} onSubmit={checkCapacity}>
					<CampaignForm
						handleClick={handleClick}
						isLargeScreen={isLargeScreen}
						emailsSent={partnerInfo?.emailsSent}
						monthlyAllowance={partnerInfo?.emailsAllowance}
					/>
					<EmailPreview partnerImage={partnerInfo?.image || ''} />
					<StickyBtnContainer handleClick={handleClick} isLargeScreen={isLargeScreen} />
					{isOverCapacity && (
						<CapacityModal
							partnerInfo={partnerInfo}
							emailCount={totalEmails}
							toggleModal={setIsOverCapacity}
							handleClick={handleClick}
						/>
					)}
				</FormContainer>
			) : (
				<ContentTitle>{t('marketing-viewEmailCampaigns-mobileOnly')}</ContentTitle>
			)}
		</MainLayout>
	);
};
