import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { Search } from 'features/common/components/Search';
import { Toggle } from 'features/common/components/Toggle';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { EventTag } from './EventTag';
import { useGetEvents } from 'hooks/useGetEvents';
import { selectedType } from 'features/PromoCodesForm/types';

import { Wrapper, Text, Title } from './SpecificPromocodes.styles';

const SpecificPromocodes: React.FC = () => {
	const [unlimitedAll, setUnlimitedAll] = useState<boolean>(false);
	const [shouldExecuteUnlimited, setShouldExecuteUnlimited] =
		useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const { pathname } = useLocation();
	const isEdit = pathname.includes('edit');
	const { t } = useTranslation();
	const { setValue } = useFormContext();

	const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};
	const { items } = useGetEvents({ searchQuery, per_page: 1000 });

	useEffect(() => {
		if (unlimitedAll && items) {
			const newValues = items.reduce((accumulated: selectedType[], current) => {
				current.ticket_types.map(({ ID }) =>
					accumulated.push({ id: ID, quantity: -1 })
				);
				return accumulated;
			}, []);
			setValue('selected', newValues);
		} else if (!isEdit || shouldExecuteUnlimited) setValue('selected', []);
		else setShouldExecuteUnlimited(true);
	}, [unlimitedAll]);

	return (
		<Wrapper>
			<Title>{t('promoCode-Form-specific-title')}</Title>
			<Search
				onChange={handleSearch}
				placeholder={t('promoCode-Form-search-events')}
				width="100%"
			/>
			<FlexContainer
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
				margin="0px 24px"
			>
				<Text>{t('events')}</Text>
				<FlexContainer alignItems="center" gap="12px" margin="16px 0px">
					<Text className="span">
						{t('promoCode-Form-specific-span-toggle')}
					</Text>
					<Toggle
						isChecked={unlimitedAll}
						name="unlimitedAll"
						id="unlimitedAll"
						handleChange={() => setUnlimitedAll(!unlimitedAll)}
					/>
				</FlexContainer>
			</FlexContainer>
			{items?.map(({ name, id, ticket_types }) => (
				<EventTag
					title={name}
					key={id}
					tickets={ticket_types}
					unlimitedAll={unlimitedAll}
				/>
			))}
		</Wrapper>
	);
};

export default SpecificPromocodes;
