import styled from 'styled-components';
import { Text } from 'features/common/components/Text';

import { BaseWrapStyle, ModalMessage } from '../../../../../FanDatabase/components/FansTable/FansTable.styles';

export const BodyWrapper = styled(BaseWrapStyle)`
	padding: 60px 25px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px;
	}
`;

export const AlertMessage = styled(Text)`
	font-size: 16px;
	font-weight: 600;
`;

export const ResetMessage = styled(ModalMessage)`
	max-width: 300px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: 260px;
	}
`;
