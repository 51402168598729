import { MainLayout } from 'layouts/MainLayout';
import EventSites from './components/EventSites/EventSites';
import { MainHeader } from 'features/common/components/MainHeader';
import { useTranslation } from 'react-i18next';

export const Embed = () => {
	const { t } = useTranslation();

	return (
		<MainLayout>
			<MainHeader lessPadding>
				<MainHeader.Title element="h1">{t('embed')}</MainHeader.Title>
			</MainHeader>
			<EventSites />
		</MainLayout>
	);
};
