import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Button as UnstyledButton } from 'features/common/components/Button';

export const Label = styled(Text)`
	color: ${({ theme }) => theme.colors.lightGrey7};
	font-size: 16px;
`;

export const Error = styled(Text)`
	color: ${({ theme }) => theme.colors.red};
	font-size: 12px;
`;

export const Container = styled.div`
	display: flex;
`;

export const Input = styled.input`
	all: unset;
	border-radius: 4px 0px 0px 4px;
	width: 100%;
	border: 1px solid ${({ theme }) => theme.colors.lightGrey};
	padding: 8px 16px;
	font-family: ${({ theme }) => theme.fonts.primary};
	background-color: ${({ theme }) => theme.colors.grey};
`;

export const Button = styled(UnstyledButton)`
	padding: 8px 24px;
	border-radius: 0px 8px 8px 0px;
	border: 1px solid ${({ theme }) => theme.colors.lightGrey};
	margin: 0;
	width: fit-content;
	font-size: 16px;
`;
