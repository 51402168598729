import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ProgressBar } from 'features/common/components/ProgressBar';
import { ROUTES } from 'features/routes';
import { Icon } from 'features/common/components/Icon';
import { convertUTCToDateBarFormat } from 'utils/utils';
import { EventCardAnalyticsProps } from './types';

import * as Styled from './EventCard.styles';

const EventCard: FC<EventCardAnalyticsProps> = ({
	id,
	name,
	startDate,
	ticketTypes,
	timezone,
}: EventCardAnalyticsProps) => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const { date, time } = convertUTCToDateBarFormat(startDate, timezone);

	const { totalAvailable, totalSold } = ticketTypes.reduce(
		(acc, { amount, sold }) => ({
			totalAvailable: acc.totalAvailable + +amount,
			totalSold: acc.totalSold + +sold,
		}),
		{ totalAvailable: 0, totalSold: 0 }
	);

	const onClickCard = () => push(ROUTES.ANALYTICS_BY_EVENT_PATH.replace(':id', id.toString()));

	return (
		<Styled.Container onClick={onClickCard}>
			<Styled.InfoContainer>
				<Styled.Title>{name}</Styled.Title>
				<Styled.IconContainer>
					<ProgressBar className="analytics" progressQty={totalSold} totalAvailable={totalAvailable} />
					<Icon iconName="arrowRight" />
				</Styled.IconContainer>
				<Styled.Info>
					{date} - {time}
				</Styled.Info>
				<Styled.Info>{`${ticketTypes.length} ${
					ticketTypes.length === 1 ? t('events-ticket-type') : t('events-tickets-types')
				}`}</Styled.Info>
			</Styled.InfoContainer>
		</Styled.Container>
	);
};

const memorizedEventCard: FC<EventCardAnalyticsProps> = memo(EventCard);

export { memorizedEventCard as EventCard };
