export const filterReducer = (
	prevState: boolean[],
	type: string
): boolean[] => {
	switch (type) {
		case 'close':
			return [false, false];
		case 'openDate':
			return [true, false];
		case 'openEvent':
			return [false, true];
		default:
			return [false, false];
	}
};
