import styled from 'styled-components';
import { Text as UnstyledText } from 'features/common/components/Text';
import { CustomFeeTypeProps } from './types';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 15px;
`;

export const DeleteButtonContainer = styled.button`
	position: absolute;
	right: 15px;
	top: 15px;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		right: 10px;
		top: 10px;
	}
`;

export const AddButtonContainer = styled.button`
	cursor: pointer;
	align-items: center;
	justify-content: center;
	display: inline-flex;
	gap: 10px;
	width: 100%;
	height: 43px;
	background: ${({ theme }) => theme.colors.lightGrey};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};
	border-width: 0;
	margin-bottom: 15px;

	> img {
		width: 14px;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
		height: 45px;
		flex-direction: row;
		gap: 9px;
	}
`;

export const Text = styled(UnstyledText)`
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 46px;
`;

export const Title = styled(UnstyledText)`
	font-family: ${({ theme }) => theme.fonts.primary};
	color: ${({ theme }) => theme.colors.lightGrey2};
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 46px;
`;

export const ToggleText = styled(UnstyledText)`
	color: ${({ theme }) => theme.colors.lightGrey2};
	font-weight: 500;
	font-size: 20px;
`;

export const CustomFeeContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 15px;
	color: white;
`;

export const AmountContainer = styled.div`
	display: flex;
	gap: 15px;
`;

export const CustomFeeTypeContainer = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: center;
	gap: 1px;
`;

export const CustomFeeType = styled.div<CustomFeeTypeProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${({ isSelected }) => (isSelected ? '35px' : '33px')};
	width: 50%;
	padding: 0 40px;
	border-radius: 2px;
	background-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.green : theme.colors.black)};
	color: ${({ isSelected, theme }) => (isSelected ? theme.colors.white : theme.colors.lightGrey11)};
	margin: ${({ isSelected }) => (isSelected ? '0' : '1px 0')};
	cursor: pointer;
`;

export const TaxableContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const TaxableDescription = styled(UnstyledText)`
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	color: ${({ theme }) => theme.colors.lightGrey2};
`;

export const CheckboxContainer = styled.div`
	margin-top: 5px;
	display: flex;
	align-items: center;
	gap: 10px;
`;
