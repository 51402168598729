import { FC, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'features/routes';
import { TicketInfoItem } from './TicketInfoItem';
import { TicketCardProps } from './types';
import defaultTicketImage from 'assets/images/defaultTicketImage.svg';

import * as Styled from './TicketCard.styles';

const TicketCard: FC<TicketCardProps> = ({
	displayType,
	price,
	image,
	soldAmount,
	ticketAmount,
	holdAmount,
	eventId,
	ticketId,
	name,
}: TicketCardProps) => {
	const { push } = useHistory();
	const { t } = useTranslation();

	const onClickDetail = () => push(ROUTES.TICKET_DETAILS_PATH.replace(':id', eventId).replace(':ticketId', ticketId));

	return (
		<Styled.CardContainer>
			<Styled.Column>
				<Styled.Price>{`$${price}`}</Styled.Price>
				<Styled.Information>
					<Styled.Image
						className="isCard"
						src={image ? `${image}?alt=media` : defaultTicketImage}
						data-cy="ticket-img"
						imageType={displayType}
					/>
					<Styled.InfoColumn>
						<Styled.TicketName>{name}</Styled.TicketName>
						<TicketInfoItem label={`${soldAmount}/${ticketAmount} Sold`} icon="roundDollar" />
						<Styled.InfoRow>
							<TicketInfoItem label={`${holdAmount} Holds`} icon="roundHold" />
							<Styled.DetailsButton
								text={t('dashboard-tickets-details')}
								onClick={onClickDetail}
								type="button"
								data-cy="edit-ticket"
							/>
						</Styled.InfoRow>
					</Styled.InfoColumn>
				</Styled.Information>
			</Styled.Column>
		</Styled.CardContainer>
	);
};

const memorizedTicketCard: FC<TicketCardProps> = memo(TicketCard);

export { memorizedTicketCard as TicketCard };
