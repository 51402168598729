import defaultImage from 'assets/images/createTicket.svg';
import { EventCard } from '../EventCard';
import { EventProps } from '../types';
import { convertUTCToDateBarFormat } from 'utils/utils';

const Event = ({ name, start_date, ticket_types, status, id, images, time_zone }: EventProps) => {
	const { date, time } = convertUTCToDateBarFormat(start_date, time_zone);

	return (
		<EventCard
			key={id}
			title={name}
			date={date}
			time={time}
			status={status}
			id={id}
			image={images[0]?.image ? `${images[0]?.image}?alt=media` : defaultImage}
			ticketTypes={ticket_types}
		/>
	);
};

export { Event };
