import { useTranslation } from 'react-i18next';

import { Container, Label } from './PromoCodeTag.styles';

export const PromoCodeTag = () => {
	const { t } = useTranslation();

	return (
		<Container>
			<Label>{t('promoCodeApplied')}</Label>
		</Container>
	);
};
