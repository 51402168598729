import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';

import { deleteTicket } from 'features/TicketDetails/services';
import { TicketDetailsParams } from 'features/TicketDetails/types';
import { Modal } from 'features/common/components/Modal/Modal';
import { Icon as Trash } from 'features/common/components/Icon';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import {
	QUATERNARY,
	TERTIARY,
} from 'features/common/components/Button/constants';

import * as Styled from './ModalDelete.styles';

const ModalDelete: FC = () => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const isLargeScreen = useResponsiveScreen();
	const { ticketId } = useParams<TicketDetailsParams>();
	const [showModal, setShowModal] = useState(false);

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);
	const { mutateAsync: mutateDeleteTicket } = useMutation(deleteTicket, {
		onSuccess: () => {
			closeModal();
			goBack();
		},
	});

	const handleDelete = () => mutateDeleteTicket(Number(ticketId));

	return showModal ? (
		<Modal onClose={closeModal}>
			<>
				<Modal.Header>{t('ticketDetails-modal-delete-title')}</Modal.Header>
				<Modal.Body>
					<Styled.ContainerBody>
						<Styled.Icon iconName="trashGrey" />
						<Styled.Text element="p">
							{t('ticketDetails-modal-delete-message')}
						</Styled.Text>
					</Styled.ContainerBody>
				</Modal.Body>
				<Modal.ActionButtons>
					<Styled.ContainerActionButtons>
						<Styled.ModalButton
							category={QUATERNARY}
							onClick={closeModal}
							text={t('button-cancel')}
							type="button"
						/>
						<Styled.ModalButton
							category={TERTIARY}
							onClick={handleDelete}
							text={t('button-delete')}
							type="button"
						/>
					</Styled.ContainerActionButtons>
				</Modal.ActionButtons>
			</>
		</Modal>
	) : isLargeScreen ? (
		<Styled.Button
			type="button"
			category={TERTIARY}
			onClick={openModal}
			text={t('ticketDetails-modal-delete-button')}
		/>
	) : (
		<Styled.DeleteButton type="button" onClick={handleDelete}>
			<Trash iconName="trash" />
		</Styled.DeleteButton>
	);
};

export { ModalDelete };
