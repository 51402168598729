import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useResponsiveScreen } from 'hooks/useWindowSize';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { Table } from 'features/common/components/Table';
import { Icon } from 'features/common/components/Icon';
import { TicketTableBody } from './components/TicketTableBody';
import { TicketTableProps } from './types';
import { COLUMN_HEADERS, GRID_COLUMNS, COLUMN_WIDTH } from './constants';

import { CheckboxButton } from 'features/common/components/CheckboxButton/CheckBoxButton.styles';
import { TableTitle, TableContainer, CustomFlex } from './TicketTable.styles';

export const TicketTable: FC<TicketTableProps> = ({ ticketQueryObject, selectedTicketsState }) => {
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const { data: ticketData, hasNextPage, fetchNextPage } = ticketQueryObject;
	return (
		<FlexContainer flexDirection="column" gap={isLargeScreen ? '24px' : '16px'} alignItems="center">
			<CustomFlex>
				<Icon iconName="ticketGreen" />
				<TableTitle>{t('refundableTickets-ticketTable-ticket-history')}</TableTitle>
			</CustomFlex>
			<TableContainer>
				<Table>
					<thead>
						<Table.HeaderRow gridColumns={GRID_COLUMNS}>
							{COLUMN_HEADERS.map((header: string, i: number) => (
								<Table.Cell key={header} minWidth={COLUMN_WIDTH[i]}>
									{i === 0 ? (
										<FlexContainer justifyContent="center" alignItems="center">
											<CheckboxButton type="checkbox" disabled={true} btnSize="18px" />
										</FlexContainer>
									) : (
										<Table.ColumnHeader>{t(header)}</Table.ColumnHeader>
									)}
								</Table.Cell>
							))}
						</Table.HeaderRow>
					</thead>
					<TicketTableBody ticketData={ticketData} selectedTicketsState={selectedTicketsState} />
				</Table>
			</TableContainer>
			<Table.LoadMore handleClick={fetchNextPage} isDisabled={!hasNextPage} />
		</FlexContainer>
	);
};
