import { FC } from 'react';
import { Text } from 'features/common/components/Text';
import { TooltipProps } from './types';

import { Wrapper, ToolTipText } from './InfoTooltip.styles';

const ToolTip: FC<TooltipProps> = ({
	children,
	title,
	text,
	className,
}: TooltipProps) => (
	<Wrapper>
		{children}
		<ToolTipText className={className}>
			{title && <Text>{title}</Text>}
			{text}
		</ToolTipText>
	</Wrapper>
);

export { ToolTip };
