import './locals/i18n';

import { Routes } from 'features/common/components/Routes';
import ErrorBoundary from 'ErrorBoundary';

import GlobalStyle from 'styles/globalStyles';

if (process.env.REACT_APP_ENV === 'production') {
	import('react-ga4').then((ReactGA) => ReactGA.default.initialize(`${process.env.REACT_APP_MEASUREMENT_ID}`));
	import('@hotjar/browser').then((Hotjar) => Hotjar.default.init(5019452, 6));
}

function App(): JSX.Element {
	return (
		<ErrorBoundary>
			<>
				<GlobalStyle />
				<Routes />
			</>
		</ErrorBoundary>
	);
}

export default App;
