import { useEffect } from 'react';

import { PromoCodes as PromoCodesComponent } from 'features/PromoCodes';
import { sendGAPage } from 'utils/utils';
import { ROUTES } from 'features/routes';

const PromoCodes = (): JSX.Element => {
	useEffect(() => {
		sendGAPage({ page: ROUTES.PROMO_CODES_NEW_PATH, title: 'Promo Code Selector' });
	}, []);
	return <PromoCodesComponent />;
};
export default PromoCodes;
